import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { merchantDetails, orderDetails, user, UserCountryData } from 'src/app/app.models';

import { ApiService } from 'src/app/services/api.service';
import { OrderDetailService } from 'src/app/services/order-detail.service';
import { SharedService } from 'src/app/services/shared.service';

import tracking from '../../utils/tracking';

@Component({
  selector: 'app-user-info',
  templateUrl: './user-info.component.html',
  styleUrls: ['./user-info.component.css']
})

export class UserInfoComponent implements OnInit {
  userForm: FormGroup;
  mca_allowed;
  order_details;
  @Output() onMobileNumberSubmit: EventEmitter<any> = new EventEmitter<any>();
  @Output() onCountryListClicked:EventEmitter<any>=new EventEmitter<any>();
  @Input() user_mobileNumber: string = null;
  @Input() errorMessage: string = null;
  @Input() showLoader: boolean = false;
  // showLoader:boolean=false;
  dispalyBtn:boolean=false;
  orderDetail:orderDetails
  merchant_detail:merchantDetails;
  userCountryData
  constructor(private fb: FormBuilder, public router: Router,private shredOrderService: OrderDetailService, public apiService: ApiService,private cookies:CookieService,private orderDetailsService:OrderDetailService,private sharedService:SharedService, private activatedRoute: ActivatedRoute) {
    this.sharedService.sharedUserCountry.subscribe(res=>{
      this.userCountryData=res;
      
    });
    
  }

  ngOnInit(): void {

    this.merchant_detail=JSON.parse(JSON.stringify(this.orderDetailsService.getMerchantDetail()));

    this.orderDetailsService.sharedMerchantDetails.subscribe((merchantData) => {
			this.merchant_detail = JSON.parse(JSON.stringify(merchantData));
		});

    if(!this.userCountryData)
      {
        this.userCountryData=this.sharedService.getauserCountry();
      }

    if(this.sharedService.getuseAnotherAccount()=='yes')
    {
      this.dispalyBtn=true;
    }
    this.userForm = this.fb.group({
      mobileNumber: [null, [Validators.required,  Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]],
    });

    
    let interval = setInterval(() => {
      this.mca_allowed = this.shredOrderService.getMerchantDetail();
      if (this.mca_allowed['mca_allowed']) {
        clearInterval(interval);        
      }
    }, 10);

  }

  getButtonClass(isValid: boolean) {
    let isDarkButton = (!this.merchant_detail?.continue_btn_is_white && typeof this.merchant_detail?.continue_btn_is_white === 'boolean');
    return (
      isValid ? 
      (isDarkButton ? 'btn-dark' : 'btn-light') : 
      (isDarkButton ? 'btn-outline-dark' : 'btn-outline-light')
    )
  }
  
  sumbitForm(formData: any) {
    if (this.userForm.status == 'VALID') {
      // this.showLoader=true;

      this.orderDetail=JSON.parse(JSON.stringify(this.orderDetailsService.getOrderDetails()));
      this.merchant_detail=JSON.parse(JSON.stringify(this.orderDetailsService.getMerchantDetail()));

      //segment tracking code for identify user
      // window.analytics.identify({
      //   mobile: formData['mobileNumber'],
      // });

      tracking.enterMobile({
        order_details: this.orderDetail,
        merchant_details: this.merchant_detail,
        mobile: formData['mobileNumber']
      });

      this.onMobileNumberSubmit.emit(formData);
    }
  }
  ngOnChanges() {

  }
  redirectToAccountList()
  {
    let orderInterval = setInterval(() => {
      this.orderDetail=JSON.parse(JSON.stringify(this.orderDetailsService.getOrderDetails()));
      if (this.orderDetail['order_id']) {

        clearInterval(orderInterval);
        this.sharedService.updateuseAnotherAccount('no');
        this.cookies.delete('useAnotherAccount');
        let options = { order_id: this.orderDetail['order_id']  };
        let transaction_token = this.activatedRoute.snapshot.queryParams['transaction_token'];
        if(transaction_token) {
          options['transaction_token'] = transaction_token;
        }
        else {
          options['expires'] = this.sharedService.getTokenExpiryTime();
        }
        this.router.navigate(['user-account'], { queryParams: options });
      }
    },10);
  }
  showCountryCodeList(){
    this.onCountryListClicked.emit();
  }
}
