<div class="box-accor">
	<div class="box-item container">
		<div class="box-row py-3 title-payment">
			<div class="px-2 fS-18">
				<div class="qr-header-row">
					<div class="qr-header-container">
						<img src="../../../assets/images/icons/bhim.png" />
					</div>
					<div class="col px-2 fS-18">
						<strong>Pay with QR</strong>
					</div>
				</div>
				<div class="qr-scan-apps">
					<p>Scan the QR with any UPI app on your phone.</p>
					<div class="scan-img-apps">
						<span><img src="../../../assets/images/icons/googlepay.svg" class="img-fluid" /></span>
						<span><img src="../../../assets/images/icons/phone-pe.png" class="img-fluid" /></span>
						<span><img src="../../../assets/images/logos/bhim.png" class="img-fluid" /></span>
						<span><img src="../../../assets/images/icons/Paytm_Logo.png" class="img-fluid" /></span>
					</div>
				</div>
				<div class="qr-code-container">
					<div class="processingScreen" *ngIf="!showQR">
						<div class="loaderhmtl processingLoader"></div>
					</div>
					<img *ngIf="showQR" [src]="qrcodeDataUrl" alt="UPI QR Code" />
					<p>
						QR will expire in
						<span
							><countdown #cd [config]="config" (event)="countdownEvent($event)"></countdown
						></span>
					</p>
					<!-- <div class="countDown">
			<countdown #cd [config]="config"></countdown>
		</div> -->
					<div class="step_note">
						Please do not close the window until the payment is processed
						<button
							type="button"
							class="btn btn-outline-dark yesBtn me-2 mr-3"
							id="cancelBtn"
							(click)="showReason()"
						>
							Cancel
						</button>
					</div>
				</div>
			</div>

			<div class="closeBox" *ngIf="showPopup">
				<div class="confirmationBox">
					<div class="header">Tell us why you want to cancel</div>
					<div class="popupBody">
						<div *ngIf="displayReasons">
							<label
								*ngFor="let reason of reasons; let i = index; let last = last"
								for="reason+{{ i }}"
								class="radioContainer"
							>
								{{ reason }}

								<input
									type="radio"
									name="reson"
									id="reason+{{ i }}"
									class="customradio"
									[value]="reason"
									[(ngModel)]="selectedReason"
									checked="last?'last':''"
								/>
								<span class="radiomark"></span>
							</label>
							<div class="text-right">
								<button
									type="button"
									class="btn btn-outline-dark yesBtn me-2 mr-3"
									id="backBtn"
									(click)="closePopup()"
								>
									Back
								</button>
								<button
									type="button"
									class="btn btn-outline-dark yesBtn me-2 mr-3"
									id="okBtn"
									[disabled]="!selectedReason"
									(click)="submitReason()"
								>
									OK
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
