import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { JSEncrypt } from 'jsencrypt';
import { SharedService } from 'src/app/services/shared.service';

@Injectable({
	providedIn: 'root',
})
export class CardDetailService {
	constructor(private sharedService: SharedService) {}
	private cardDetails = new BehaviorSubject([]);
	updateCardDetails(cardData) {
		this.cardDetails.next([]);
		this.cardDetails.next(cardData);
	}
	getCardDetails() {
		return this.cardDetails.value;
	}

	generateCardDetail() {
		let card = this.getCardDetails();
		let publicKey = this.sharedService.getccdcPublicKey()?.public_key?.replace(/\\n/g, '');

		let obj = {
			card_no: card['card_number']?.split(' ').join(''),
			cvv: card['cvv'],
			card_holder_name: card['card_holder_name'],
			expiry: card['card_expiry'],
		};
		console.log('generate Card Details', obj, publicKey, card);
		const encrypt = new JSEncrypt();
		encrypt.setPublicKey(publicKey);

		return encrypt.encrypt(JSON.stringify(obj));
	}
}
