import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler, Injectable, NgZone } from '@angular/core';
import { OrderDetailService } from './order-detail.service';
import { ModalService } from './modal.service'
import { SharedService } from './shared.service';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  order_detail;
  globalError;
  transaction_details = null;
  constructor(
    private sharedOrderService: OrderDetailService,
    private sharedService: SharedService,
    private zone: NgZone,
    private errorModalService: ModalService ) {
    this.sharedOrderService.sharedOrderDetails.subscribe((res) => {
      this.order_detail = JSON.parse(JSON.stringify(res));
    });
  }

  handleError(error: any) {
    // console.log(error.name);
    // if (!(error instanceof HttpErrorResponse)) {
    //   error = error.rejection; // get the error object
    // }
    this.zone.run(() =>
      this.sharedService.updateGlobalErrorDetails(error),
      // uncomment the below code once you need to show error popup
      // this.errorModalService.open()
    ) ;

    this.sharedService.sharedTransactionResponse.subscribe((res) => {
      this.transaction_details = res;
    });

    let errorData = {
      status_code: error?.status,
      message: error?.message,
      api_url: error?.url,
      type: error?.name,
      sub_merchant_id:this.order_detail['sub_merchant_id'],
      order_id:this.order_detail['order_id'],
      transaction_id: this.transaction_details ? this.transaction_details['transaction_id'] : null,
      transaction_details: this.transaction_details ? JSON.stringify(this.transaction_details) : null,
    };

    window.newrelic.addPageAction('HttpErrorResponse', errorData);
  }
}
