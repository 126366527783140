export class RetrieveRequest {
	constructor(
		public page: number = 0,
		public per_page: number = 20,
		public order_by: string = 'id',
		public sort_order: string = 'desc'
	) {}
}
export class user {
	constructor(
		public id: number = null,
		public email: string = null,
		public first_name: string = null,
		public last_name: string = null,
		public mobile_number: number = null,
		public verification_done: string = null,
		public user_id: string = null
	) {}
}
export class token {
	constructor(id: number = null, access_key: string = null, active: boolean, type: string = null) {}
}
export class resolveUserRequest {
	constructor(
		public mobile_number: string = null,
		public device_verified: string = null,
		public country_code: string = null,
		public order_id: string = null
	) {}
}
export class resendOTPRequest {
	constructor(public mobile_number: string = null) {}
}
export class allowedPaymentPartnersRequest {
	constructor(public order_id: string = null) {}
}
export class gpayValidationCheckRequest {
	constructor(public order_id: string = null) {}
}
export class initPaymentRequest {
	constructor(
		public order_id: string = null,
		public payment_mode: string = null,
		public payment_mode_id: number = null,
		public bank: string = null,
		public canMakePayment: boolean = false,
		public app_present: boolean = null,
		public callback_url: string = null,
		public offer_id: string = null,
		public scheme_id: string = null,
		public partner_name: string = null
	) {}
}
export class transactionDetails {
	constructor(
		public order_id: string = null,
		public message: string = null,
		public status: string = null,
		public transaction_id: string = null
	) {}
}
export class makePaymentRequest {
	constructor(
		public order_id: string = null,
		public payment_mode: string = null,
		public payment_type: string = null,
		public otp: string = null,
		public transaction_id: string = null,
		public upi_id: string = null,
		public flow: string = 'collect'
	) {}
}
export class orderLineItem {
	constructor(
		public id: number = null,
		public referrer_platform_sku_id: string = null,
		public title: string = null,
		public description: string = null,
		public image_url: string = null,
		public quantity: number = null,
		public uom: string = null,
		public rate: number = null,
		public total_amount: number = null
	) {}
}
export class orderDetails {
	constructor(
		public id: number = null,
		public sub_merchant_id: number = null,
		public order_date: string = null,
		public order_id: string = null,
		public amount_before_tax: number = null,
		public tax: number = null,
		public total_amount: number = null,
		public referrer_platform: string = null,
		public referrer_platform_identifer: string = null,
		public merchant_shopfront_domain: string = null,
		public user_id: number = null,
		public shipping_address_id: string = null,
		public device_user_agent: string = null,
		public status: null,
		public order_line_item: orderLineItem[],
		public user: user = null,
		public callback_url: string = null,
		public callback_mode: string = null
	) {}
}
export class paymentStatusPollingRequest {
	constructor(
		public order_id: string = null,
		public payment_mode: string = null,
		public transaction_id: string = null
	) {}
}
export class razorPayCCDCRequest {
	constructor(
		public amount: number = null,
		public callback_url: string = null,
		public contact: string = null,
		public currency: string = null,
		public base_amount: string = null,
		public email: string = null,
		public key_id: string = null,
		public name: string = null,
		public order_id: string = null
	) {}
}

export class payuCcDcRequest {
	constructor(
		public action: string = null,
		public address1: string = null,
		public address2: string = null,
		public amount: string = null,
		public city: string = null,
		public country: string = null,
		public email: string = null,
		public firstname: string = null,
		public furl: string = null,
		public hash: string = null,
		public hash_string: string = null,
		public key: string = null,
		public lastname: string = null,
		public pg: string = null,
		public phone: string = null,
		public productinfo: string = null,
		public state: string = null,
		public surl: string = null,
		public txnid: string = null,
		public udf1: string = null,
		public udf2: string = null,
		public udf3: string = null,
		public udf4: string = null,
		public udf5: string = null,
		public zip: string = null,
		public ccnum: string = null,
		public ccname: string = null,
		public ccvv: number = null,
		public ccexpmon: number = null,
		public ccexpyr: number = null
	) {}
}
export class checkBinRequest {
	constructor(public card_no: string = null, public order_id: string = null) {}
}
export class binService {
	constructor(
		public isDomestic: string = null,
		public issuingBank: string = null,
		public cardType: string = null,
		public cardCategory: string = null,
		public additional_charges: number
	) {}
}
export class merchantDetails {
	constructor(
		public checkout_session_duration: number = 0,
		public display_name: string = null,
		public logo: string = null,
		public logo_file_url: string = null,
		public email_id_required: boolean,
		public developer_name: string = null,
		public logo_file_name: string = null,
		public merchant_id: number = null,
		public skip_device_verification: boolean = null,
		public fast_payment_enabled: boolean = null,
		public other_payment_enabled: boolean = null,
		public continue_btn_is_white: boolean = null,
		public order_summary_enabled: boolean = null,
		public payment_experience: string = null,
		public description: string = null,
		public footer: Object = {}
	) {}
}
export class updatePaymentUpdateRequest {
	constructor(public transaction_id: string = null, public message: string = null) {}
}
export class lazypayResednOtpRequest {
	constructor(
		public transaction_id: string = null,
		public order_id: string = null,
		public payment_mode: string = null
	) {}
}
export class UserCountryData {
	constructor(
		public countryName: string = 'India',
		public dailerCode: string = '+91',
		public flag: String = 'indianFlag.png'
	) {}
}

export class IListOfBanks {
	constructor(
		public bank_name: string = '',
		public additional_charges?: number,
		public code?: string,
		public offers?: [
			{
				grand_total_amount: number;
				offer_available: string;
				offer_discount: number;
				offer_id: string;
				total_amount: number;
			}
		]
	) {}
}

export class IListOfDefaultBanks {
	offers?:
		| any[]
		| [
				{
					grand_total_amount: number;
					offer_available: string;
					offer_discount: number;
					offer_id: string;
					total_amount: number;
				}
		  ];
	constructor(
		public bankName?: string,
		public logo?: string,
		public code?: string,
		public additional_charges?: number
	) {}
}

export class IListOfDefaultWallets {
	constructor(public walletName?: string, public logo?: string) {}
}

export class IListOfWallets {
	constructor(public additional_charges?: string, public wallet_name?: string) {}
}
