import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-upi-intent',
  templateUrl: './upi-intent.component.html',
  styleUrls: ['./upi-intent.component.css']
})
export class UpiIntentComponent implements OnInit {
  @Input() iframeSrc: string = '';
  upiFrameSrc: SafeUrl = '';
  // iframeSrc: string = 'upi://pay?pa=shopnimbbltech.payu@indus&pn=Bigital+Technologies+Pvt+ltd&tr=17907921501&am=40.00&cu=INR&tn=UPI+Transaction+for+PPPL17907921501090823175810';

  constructor(private sanitizer: DomSanitizer,) { }

  ngOnInit(): void {
    console.log('ngOnInit');
    this.upiFrameSrc = this.sanitizer.bypassSecurityTrustUrl(this.iframeSrc);

    console.log(this.upiFrameSrc);
    
  }

}
