import {
	Component,
	EventEmitter,
	Input,
	OnInit,
	Output,
	ViewChild,
	ElementRef,
} from '@angular/core';
import {
	binService,
	checkBinRequest,
	IListOfBanks,
	IListOfDefaultBanks,
	merchantDetails,
	orderDetails,
} from 'src/app/app.models';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { ApiService } from 'src/app/services/api.service';
import { OrderDetailService } from 'src/app/services/order-detail.service';
import { PaymentModeService } from 'src/app/services/payment-mode.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CardDetailService } from 'src/app/services/card-detail.service';
import { SharedBankingService } from 'src/app/services/shared-banking.service';
import { DatePipe } from '@angular/common';
import { CreditCardValidators } from 'angular-cc-library';
import { CookieService } from 'ngx-cookie-service';
import { SharedService } from 'src/app/services/shared.service';
import { ToastService } from 'src/app/services/toast.service';

import tracking from '../../utils/tracking';
import { ActivatedRoute } from '@angular/router';
import { hasMaxRetriesReached, isPopupBlockedInBrowser } from 'src/app/utils';
import { getCheckoutId } from '../../utils/checkoutId';
import { hasTouchSupport } from 'src/app/utils';

var valid = require('card-validator');

const PAYMENT_ERRORS = {
	CARD_INVALID: 'Please enter a valid card number',
	CARD_TYPE_NOT_SUPPORTED: 'Card is not supported, please try another card',
	EXPIRY_DATE: 'Please enter a valid expiry date',
	CARD_HOLDER_NAME: 'Please enter a valid name',
	CVV: 'Please enter the valid CVV',
	UPI: 'UPI id provided is incorrect, please try another UPI id',
};

const PAYMENT_PROPS = {
	CARD: {
		CARD_NUMBER: 'Card Number',
		EXPIRY_DATE: 'Expiry Date',
		CARD_HOLDER_NAME: 'Card Holder Name',
		CVV: 'CVV',
	},
	UPI: 'UPI Input',
};
@Component({
	selector: 'app-pay-with',
	templateUrl: './pay-with.component.html',
	styleUrls: ['./pay-with.component.css'],
})
export class PayWithComponent implements OnInit {
	@Output() initiatePay: EventEmitter<any> = new EventEmitter<any>();
	@Output() payNow: EventEmitter<any> = new EventEmitter<any>();
	@Output() cancelPay: EventEmitter<any> = new EventEmitter<any>();
	@Output() qrCodeScanned: EventEmitter<any> = new EventEmitter<any>();
	@Input() payment_partner: string = null;
	@Input() isIntentAllowed: boolean = false;
	@Input() isUpiCollectAllowed: boolean = false;
	@Input() hasIntentFromSDK: boolean = false;
	@Input() isIosDevice: boolean = false;
	@Input() isReactNative: boolean = false;
	is_autoDebit: boolean = false;
	@Input() savedVPAClicked: boolean = false;
	@Input() savedVapId: string = '';
	UPIModal: boolean = false;
	@Input() savedVpaName: string = '';
	showCardType: boolean = false;
	binServiceResponse: binService;
	mode;
	logo;
	time;
	description;
	dueDate;
	order_details: orderDetails;
	merchant_details: merchantDetails;
	selectedPaymentMode;
	UPIId;
	isbuttonEnabled: boolean = true;
	isVpaValid: boolean = false;
	UPIUserName: string = '';
	convenienceFee = 0;
	grandTotal = 0;
	grandTotalWithConvenienceFees: number = null;
	offerType: string;
	offerName: string;
	offerGrandTotal: number;
	offerAvailable: boolean = false;
	offerDiscount: number;
	offerID: string;
	offerTotalAmount: number;
	vpaList = [
		'@airtel',
		'@allbank',
		'@axisbank',
		'@andb',
		'@pingpay',
		'@okaxis',
		'@axisgo',
		'@apl',
		'@freecharge',
		'@barodampay',
		'@boi',
		'@mahb',
		'@cnrb',
		'@csbpay',
		'@centralbank',
		'@dbs',
		'@dcb',
		'@denabank',
		'@fbl',
		'@okicici',
		'@equitas',
		'@federal',
		'@finobank',
		'@okhdfcbank',
		'@hdfcbank',
		'@hsbc',
		'@icici',
		'@idbi',
		'@idfcbank',
		'@indianbank',
		'@iob',
		'@indus',
		'@jkb',
		'@jsb',
		'@pockets',
		'@icicipay',
		'@kbl',
		'@kvb',
		'@kotak',
		'@kaypay',
		'@lvb',
		'@obc',
		'@paytm',
		'@pnb',
		'@psb',
		'@rbl',
		'@s2b',
		'@oksbi',
		'@sib',
		'@scb',
		'@sbi',
		'@syndicate',
		'@tjsb',
		'@uco',
		'@unionbank',
		'@utbi ',
		'@uboi',
		'@united',
		'@ubi',
		'@vijb',
		'@yesbank',
		'@yesbankltd',
		'@ybl',
		'@upi',
	];
	filteredVPA = [];
	listOfBanks: IListOfBanks[] = [];
	listOfWallets = [];
	topSixBanks = [];
	topSixwallets = [];
	remainingWallets = [];
	topSixCardlessEMI = [];
	remainingCardlessEMI = [];
	currency;
	pastedNumber = '';
	listOfDefaultBanks: IListOfDefaultBanks[] = [
		{ bankName: 'State Bank of India', logo: 'SBI-logo.png', code: 'SBI' },
		{ bankName: 'Punjab National Bank', logo: 'pnb.png', code: 'PNB' },
		{ bankName: 'HDFC Bank', logo: 'HDFC_Bank_logo.png', code: 'HDFC' },
		{ bankName: 'ICICI Bank', logo: 'ICICI-Logo-1200x1227.png', code: 'ICIC' },
		{ bankName: 'Union Bank of India', logo: 'union.png', code: 'UBI' },
		{ bankName: 'Canara Bank', logo: 'canara-bank.png', code: 'CANA' },
		{ bankName: 'Bank of Baroda', logo: 'Bank_of_Baroda_logo.png', code: 'BARD' },
		{ bankName: 'Bank of India', logo: 'BOI.png', code: 'BOI' },
		{ bankName: 'Axis Bank', logo: 'Axis_Bank_logo_logotype.png', code: 'AXIS' },
		{ bankName: 'Indian Bank', logo: 'indian.png', code: 'INDB' },
		{ bankName: 'Central Bank of India', logo: 'central.png', code: 'CBI' },
		{ bankName: 'IDBI Bank', logo: 'IDBI.png', code: 'IDBI' },
		{ bankName: 'Indian Overseas Bank', logo: 'indian-overseas.png', code: 'IOB' },
		{ bankName: 'Kotak Mahindra Bank', logo: 'Kotak_Mahindra_Bank_logo.png', code: 'KOTK' },
		{ bankName: 'IndusInd Bank', logo: 'indusind.png', code: 'INDS' },
		{ bankName: 'UCO Bank', logo: 'UCO.png', code: 'UCO' },
		{ bankName: 'Bank of Maharashtra', logo: 'bom.png', code: 'BOM' },
		{ bankName: 'Yes Bank', logo: 'yes-bank.png', code: 'YESB' },
		{ bankName: 'Federal Bank', logo: 'FB.png', code: 'FEDE' },
		{ bankName: 'Punjab & Sind Bank', logo: 'Punjab-and-Sindh.png', code: 'PNSB' },
	];
	listOfDefaultWallets = [
		{ walletName: 'Airtel Money', logo: 'airtel-money.png', code: 'airtel_money' },
		{ walletName: 'Freecharge', logo: 'FreeCharge_Logo.png', code: 'freecharge' },
		{ walletName: 'Jio Money', logo: 'JioMoney.png', code: 'jio_money' },
		{ walletName: 'Mobikwik', logo: 'mobikwik.png', code: 'mobikwik' },
		{ walletName: 'PayZapp', logo: 'payzapp.png', code: 'payzapp' },
		{ walletName: 'PhonePe', logo: 'phonepe.png', code: 'phonepe' },
		{ walletName: 'PayPal', logo: 'paypal.png', code: 'paypal' },
		{ walletName: 'Amazon Pay', logo: 'amazonPay.png', code: 'amzpay' },
		{ walletName: 'Olamoney', logo: 'olamoney.png', code: 'olamoney' },
	];
	findUpiId: boolean = false;
	cvv;
	card_holder_name;
	card_number;
	card_expiry;
	cardDetails: boolean = false;
	cardDetailForm: FormGroup;
	selectedBank = null;
	selectedWallet = null;
	subDescriptio: string = null;
	showLoader;
	paymentModeName: string = '';
	cvvMaxLength: number = 3;
	termsLink: string = null;
	cardValidation: boolean;
	validatedVPA: string;
	isVerifybuttonEnabled: boolean = false;
	vpaAppLogo: string;
	vpaAppName: string;
	PAYMENT_ERRORS = PAYMENT_ERRORS;
	vpa_provider: string = '';
	magicUPIDisplayName: string;
	card_provider;
	bank_name;
	card_type;
	is_check_bin_called: boolean = false;
	isOtherPaymentCollapsed;
	paymentModesWithOffers;
	prevCVVNumber: string = '';
	upiIDUpdate = new Subject<string>();
	isEnforced: boolean;
	hasSubPaymentMode: boolean;
	enforcedPaymentName: string = '';
	showPopup: boolean = false;
	// showUpi: boolean = true;
	isPaymentSuccess: boolean = false;
	enforcedInitPayment: boolean = false;
	apiError: string = '';
	isInvalidUpi: boolean = false;
	listOfcardlessEMI: any = [];
	schemeList: [] = [];
	visibleScheme: string = '';
	isEMIPlanFlow: boolean = false;
	isContinueClicked: boolean = false;
	selectedEmi: {} = {};
	isOfferCalled: boolean = false;
	errorMessage =
		'Sorry, no payment methods are available for you. Please try again after sometime.';
	bankResponse: any;
	walletResponse: any;
	isIntentAndMobile: boolean = false;
	qrMode = false;
	isFastUpi = false;
	urlResponse = '';

	@ViewChild('upiPaymentSelectionqr') upiPaymentSelectionqr: ElementRef;
	@ViewChild('upiPaymentSelectionupi') upiPaymentSelectionupi: ElementRef;

	triggerRadioClickQr() {
		this.qrMode = true;
		// Use nativeElement to access the DOM element and trigger a click event
		this.upiPaymentSelectionqr.nativeElement.click();
		this.convenienceFee = this.selectedPaymentMode.intentConvFee;
		this.grandTotalWithConvenienceFees = this.order_details?.total_amount + Number(this.convenienceFee);
	}

	triggerRadioClickUpi() {
		this.qrMode = false;
		// Use nativeElement to access the DOM element and trigger a click event
		this.upiPaymentSelectionupi?.nativeElement.click();
		this.convenienceFee = this.selectedPaymentMode.collectConvFee;
		this.grandTotalWithConvenienceFees = this.order_details?.total_amount + Number(this.convenienceFee);
	}

	constructor(
		private sharedorderDetails: OrderDetailService,
		private sharedPaymentService: PaymentModeService,
		private apiService: ApiService,
		private fb: FormBuilder,
		private cardDetailsService: CardDetailService,
		private SharedBankingServiceService: SharedBankingService,
		private datePipe: DatePipe,
		private cookies: CookieService,
		private sharedService: SharedService,
		private toast: ToastService,
		public orderService: OrderDetailService,
		public activateRoute: ActivatedRoute
	) {

		this.urlResponse = this.activateRoute.snapshot.queryParams['response'];
		this.paymentModesWithOffers = this.sharedService.getupdatedOffersDetails();

		this.sharedService.shared_card_type.subscribe((res) => {
			// console.log(res);
		});

		/**
			 * This is done for covering the scenario where:
			 * 	- Checkout flow is Enforced, Payment Experience is Redirect,
			 *  - and we do not have Order ID and Grand total in local state after PSP redirect to checkout on payment failure.
			 *  */
		if(this.urlResponse){
			let paymentResponse = JSON.parse(atob(this.urlResponse));
			this.grandTotal = paymentResponse.order.grand_total;
			this.fetchOrderDetails(paymentResponse.nimbbl_order_id)
			// this.order_details['order_id'] = paymentResponse.nimbbl_order_id;
		}

		this.order_details = JSON.parse(JSON.stringify(this.orderService.getOrderDetails()));
		this.sharedorderDetails.sharedOrderDetails.subscribe((res) => {
			if(!this.urlResponse){
				this.grandTotal =  this.order_details['grand_total_with_convience_fees']
				? this.order_details['grand_total_with_convience_fees']
				: this.order_details.total_amount;
			}
		});
		this.sharedPaymentService.sharedSelectedMode.subscribe((res) => {
			this.selectedPaymentMode = res;
			this.selectedPaymentMode.offerApplicable
				? (this.offerType = this.selectedPaymentMode.offerType)
				: '';
			this.selectedPaymentMode.offerApplicable
				? (this.offerName = this.selectedPaymentMode.offerName)
				: '';
			if (this.selectedPaymentMode['isAutodebitFlow']) {
				this.is_autoDebit = true;
			}
			if (
				parseFloat(this.selectedPaymentMode.extra_charges) &&
				!this.selectedPaymentMode.extra_charges.includes('-')
			) {
				this.convenienceFee = parseFloat(this.selectedPaymentMode.extra_charges);
				this.grandTotal = this.order_details['grand_total_amount'];

				this.order_details['grand_total_with_convience_fees'] = this.grandTotal + this.convenienceFee;
				this.sharedorderDetails.updateOrderDetails(this.order_details);
			}
			if (
				this.selectedPaymentMode['paymentMode'] == 'UPI' ||
				this.selectedPaymentMode['paymentMode'] == 'Netbanking'
			) {
				this.isbuttonEnabled = false;
			}
		});

		this.upiIDUpdate.pipe(debounceTime(600), distinctUntilChanged()).subscribe((value) => {
			document.querySelector('.message')?.classList?.add('hidden');
			this.displayList(value);
		});
	}

	enforceInitPay() {
		let response = this.activateRoute.snapshot.queryParams['response'];
		this.activateRoute.queryParams.subscribe((params) => {
			if (params['response']) {
				let paymentResponse = JSON.parse(atob(params['response']));
				if (paymentResponse.status == 'failed') {
					this.enforcedInitPayment = false;
				}
			}
		});
		// If subpayment mode is passed initialize the payment
		if (this.isEnforced && this.hasSubPaymentMode && !response && this.convenienceFee == 0 && !isPopupBlockedInBrowser()) {
			this.enforcedInitPayment = true;
			this.cookies.set('selected_payment_name', '', 0.020833, '/', '', true, 'None');
			this.initiatePay.emit();
		}
	}

	setDefaultCardData() {
		this.selectedPaymentMode['paymentMode'] = `Credit Card`;
		this.selectedPaymentMode['logo'] = 'assets/images/logos/credit-card.png';
		if (!this.sharedService.getccdcPublicKey()) {
			this.apiService.fetchCCDCPublicKey().subscribe((res) => {
				this.sharedService.updateccdcPublicKey(res);
			});
		}
	}

	handleRadioChange(upiPaymentMode: string) {
		if (upiPaymentMode === 'qr') {
			this.qrMode = true;
		} else {
			this.qrMode = false;
		}
	}

	handleContinue() {
		this.isContinueClicked = true;
	}

	handleCancel() {
		this.isContinueClicked = false;
	}

	onEmiChange(emi: {}) {
		this.selectedEmi = emi;
		this.sharedService.updateEMISelectedPlanDetail(emi);
		this.isbuttonEnabled = true;
		console.log(this.selectedEmi);
	}

	async ngOnInit(): Promise<void> {
		setTimeout(() => {
				window.scroll({
					top: this.sharedService.getCollapsibleStatus().docOffset > 700 ? 0 : this.sharedService.getCollapsibleStatus().docOffset,
					left: 0,
					behavior: 'smooth',
				});
		}, 10);
			
		console.log(this.sharedService.getUpdatedEMIPlansDetails(), 'EMI extra info');
		this.bankResponse = this.sharedService.getbankResponse();
		this.walletResponse = this.sharedService.getwalletResponse();
		this.isEMIPlanFlow = this.sharedService.getUpdatedEMIPlansDetails()['isEMIPlanFlow'];
		let schemeData = this.sharedService.getUpdatedEMIPlansDetails();
		if (schemeData['isEMIPlanFlow']) {
			this.isbuttonEnabled = false;
			this.schemeList = schemeData['extraInfo'].schemes;
		}
		if (this.selectedPaymentMode?.vpaId) {
			this.isFastUpi = true;
		} else {
			this.isFastUpi = false;
		}

		if(this.isIntentAllowed && !this.isFastUpi && !this.isIosDevice){
			this.qrMode = true;
		}else {
			this.qrMode = false;
		}

		this.sharedService.updateCardtype('Card');
		this.isEnforced = this.sharedService.getEnforcePaymentDetails();
		console.log(this.isEnforced);

		this.sharedService.sharedEnforcePaymentDetails.subscribe((res) => {
			this.isEnforced = res;
		});
		this.sharedService.sharedEnforcePaymentSuccess.subscribe((res) => {
			this.isPaymentSuccess = res;
		});
		this.sharedService.sharedEnforcePaymentInitiated.subscribe((res) => {
			this.enforcedInitPayment = res;
		});
		this.hasSubPaymentMode = this.sharedService.getSubPaymentMode();
		let urlPaymentMode = this.activateRoute.snapshot.queryParams['payment_mode'];
		let urlPaymentFlow = this.activateRoute.snapshot.queryParams['payment_flow'];
		let urlUpiId = this.activateRoute.snapshot.queryParams['upi_id'];
		let urlUpiAppName = this.activateRoute.snapshot.queryParams['upi_app_code'];

		if (
			this.selectedPaymentMode?.paymentMode == 'Credit Card' ||
			this.selectedPaymentMode?.paymentMode == 'Debit Card' ||
			this.selectedPaymentMode?.paymentMode == 'Prepaid Card'
		) {
			this.isbuttonEnabled = false;
			if (!this.sharedService.getccdcPublicKey()) {
				this.apiService.fetchCCDCPublicKey().subscribe((res) => {
					this.sharedService.updateccdcPublicKey(res);
				});
			}
		}
		this.currency = this.sharedService.getCurrency();
		if (this.isEnforced && urlPaymentMode === 'Card') {
			this.setDefaultCardData();
			this.sharedService.sharedIsCardPayment.subscribe((res) => {
				if (res) {
					this.apiError = '';
					this.setDefaultCardData();
				} else {
					if(!this.urlResponse){
						this.apiError = this.errorMessage;
					}
				}
			});

			// this.paymentModeName = 'Credit Cards';
			this.isbuttonEnabled = false;
		} else if (this.isEnforced && urlPaymentMode === 'Netbanking') {
			this.paymentModeName = 'Netbanking';
			this.activateRoute.queryParams.subscribe((params) => {
				this.enforcedPaymentName = params.bank_code;
				this.selectedPaymentMode['paymentMode'] = `${params.payment_mode}`;
				this.selectedPaymentMode['paymentModeName'] = `${params.bank_code}`;
			});
			this.loadAllBanks();
			this.sharedService.updatePaymentNameDetails(this.enforcedPaymentName);
		} else if (this.isEnforced && urlPaymentMode === 'Wallet') {
			this.paymentModeName = 'Wallet';
			this.activateRoute.queryParams.subscribe((params) => {
				this.enforcedPaymentName = params.wallet_code;
				this.selectedPaymentMode['paymentMode'] = `${params.payment_mode}`;
				this.selectedPaymentMode['paymentModeName'] = `${params.wallet_code}`;
			});
			this.loadAllWallets();
			this.sharedService.updatePaymentNameDetails(this.enforcedPaymentName);
		} else if (this.isEnforced && urlPaymentMode === 'UPI') {
			this.paymentModeName = 'UPI';
			let otherPaymentModesArr = this.sharedPaymentService.getOtherPaymentMode().filter(item => item.compareName === 'upi');
			this.selectedPaymentMode.intentConvFee = otherPaymentModesArr[0].intentConvFee;
			this.selectedPaymentMode.collectConvFee = otherPaymentModesArr[0].collectConvFee;
	

			// this.showUpi = false;
			this.isbuttonEnabled = false;
			this.activateRoute.queryParams.subscribe((params) => {
				this.selectedPaymentMode['paymentMode'] = `${params.payment_mode}`;
			});

			let otherPaymentArr = this.sharedPaymentService.getOtherPaymentMode();

			let filteredOtherPaymentArr = otherPaymentArr.filter((item) => item.mode === 'UPI');
			console.log(filteredOtherPaymentArr);
			
			if (
				filteredOtherPaymentArr.length &&
				(filteredOtherPaymentArr[0]?.mode === 'UPI')
			) {
				this.convenienceFee = parseFloat(filteredOtherPaymentArr[0]?.ConvenienceFees)
				this.order_details['grand_total_with_convience_fees'] = this.grandTotal + this.convenienceFee 
				if (urlPaymentFlow === 'intent') {
					this.isUpiCollectAllowed = false;
					if (hasTouchSupport()) {
						this.isIntentAndMobile = true;
						this.selectedPaymentMode['logo'] = 'assets/images/icons/bhim.png';
					} else {
						
						if(this.isIntentAllowed){
							if(this.convenienceFee == 0){
								this.enforcedInitPayment = true;
								this.apiError = '';
								this.isIntentAndMobile = false;
								setTimeout(() => {
									this.payNow.emit();
								}, 500);
							}
						} else {
							this.apiError = this.errorMessage;
						}
						
					}
				} else if (urlPaymentFlow === 'collect'){
					this.isIntentAllowed = false;
					this.qrMode = false;
					if(!filteredOtherPaymentArr[0].isCollect) {
						this.apiError = this.errorMessage;
					} else {
						this.isIntentAndMobile = false;
						this.selectedPaymentMode['logo'] = 'assets/images/logos/bhim.png';
						this.convenienceFee = parseFloat(filteredOtherPaymentArr[0].ConvenienceFees);
						this.apiError = '';
						this.grandTotal = this.order_details['grand_total_amount'];
	
						this.order_details['grand_total_with_convience_fees'] =
							this.grandTotal + this.convenienceFee;
					}
					
				} else {
					// do nothing
				}
			} else {
				this.apiError = this.errorMessage;
				this.enforcedInitPayment = false;
			}

			if (filteredOtherPaymentArr.length === 0) {
				this.apiError = this.errorMessage;
			}

			if (urlUpiId) {
				this.UPIId = urlUpiId;
				if(filteredOtherPaymentArr[0].isCollect){
					if (this.convenienceFee !== 0) {
						this.isVerifybuttonEnabled = true;
					} else if (filteredOtherPaymentArr.length) {
						setTimeout(() => {
							this.checkForValidUPI();
						}, 100);
					} else {
						this.apiError = this.errorMessage;
					}
				}
			}

			this.sharedorderDetails.updateOrderDetails(this.order_details);
		} else if (this.selectedPaymentMode['paymentMode'] === 'Netbanking') {
			this.loadAllBanks();
		} else if (this.selectedPaymentMode['paymentMode'] === 'Wallet') {
			this.loadAllWallets();
		} else if (this.selectedPaymentMode['paymentMode'] === 'Cardless EMI') {
			this.loadCardlessEMI();
		}
		let due_Date = '';
		const ele = this.sharedPaymentService.getAllowedPaymentTypeArray();
		ele.map((item) => {
			if (item['extra_info']['due_date']) {
				due_Date = item['extra_info']['due_date'];
			}
		});
		this.apiService.fetchPaymentModesMessage().subscribe((res) => {
			this.dueDate = due_Date;
			if (
				this.selectedPaymentMode['paymentMode'] != 'Netbanking' &&
				(this.selectedPaymentMode['paymentMode'] != 'Credit Card' ||
					this.selectedPaymentMode['paymentMode'] != 'Debit Card' ||
					this.selectedPaymentMode['paymentMode'] != 'Prepaid Card') &&
				this.selectedPaymentMode['paymentMode'] != 'UPI' &&
				this.selectedPaymentMode['paymentMode'] != 'Wallet' &&
				this.selectedPaymentMode['paymentMode'] != 'Cardless EMI'
			) {
				this.description = this.selectedPaymentMode?.paymentMode
					? res[this.selectedPaymentMode?.paymentMode.toLowerCase()]?.payWithDesc ?? ''
					: '';
				if (
					this.selectedPaymentMode['paymentMode'] == 'LazyPay' ||
					this.selectedPaymentMode['paymentMode'] == 'Lazypay' ||
					this.selectedPaymentMode['paymentMode'] == 'ICICI PayLater' ||
					this.selectedPaymentMode['paymentMode'] == 'icici paylater' ||
					this.selectedPaymentMode['paymentMode'] == 'Olamoney' ||
					this.selectedPaymentMode['paymentMode'] == 'olamoney'
				) {
					if (!this.is_autoDebit) {
						this.subDescriptio =
							res[this.selectedPaymentMode['paymentMode'].toLowerCase()][
								'textAboveBtnsForAutoDebitFlowNotPossible'
							];
					} else {
						this.subDescriptio =
							res[this.selectedPaymentMode['paymentMode'].toLowerCase()]['textAboveBtns'];
					}
				} else {
					const modeName = res[this.selectedPaymentMode['paymentMode'].toLowerCase()];
					this.subDescriptio = this.selectedPaymentMode.is_intent
						? modeName['intentTextAboveBtns']
						: modeName['textAboveBtns'];
				}
				this.paymentModeName =
					res && res[this.selectedPaymentMode?.paymentMode.toLowerCase()]['modeName']
						? res[this.selectedPaymentMode?.paymentMode.toLowerCase()]['modeName'] === 'Card'
							? this.sharedService.getCardType()
							: res[this.selectedPaymentMode?.paymentMode.toLowerCase()]['modeName']
						: ''; // default value
			} else if (
				this.selectedPaymentMode['paymentMode'] == 'Credit Card' ||
				this.selectedPaymentMode['paymentMode'] == 'Debit Card' ||
				this.selectedPaymentMode['paymentMode'] == 'Prepaid Card'
			) {
				this.paymentModeName = this.selectedPaymentMode['paymentMode'];
				this.isbuttonEnabled = false;
			} else if (this.selectedPaymentMode['paymentMode'] === 'Cardless EMI') {
				this.isbuttonEnabled = false;
				console.log(this.selectedPaymentMode);
			} else {
				this.paymentModeName = this.selectedPaymentMode['paymentMode'];
			}
			this.termsLink = res[this.selectedPaymentMode['paymentMode'].toLowerCase()]['t&c'];
			// let date = new Date(this.dueDate).toISOString();
			this.dueDate = due_Date;
		});
		this.merchant_details = JSON.parse(JSON.stringify(this.sharedorderDetails.getMerchantDetail()));
		this.cardDetailForm = this.fb.group({
			card_number: [
				null,
				[Validators.required, Validators.minLength(12), Validators.maxLength(19)],
			],
			// add validation for leading and trailing spaces. FEED-2445
			card_holder_name: [
				null,
				[Validators.required, Validators.pattern(/^(?! )(?![\s\S]* $)[a-zA-Z ]*$/)],
			],
			cvv: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(4)]],
			card_expiry: [null, [Validators.required, CreditCardValidators.validateExpDate]],
			isValid: [true, [Validators.required]],
		});
		if (!this.order_details) {
			this.order_details = JSON.parse(JSON.stringify(this.sharedorderDetails.getOrderDetails()));
		}
		if (this.savedVPAClicked) {
			this.isVpaValid = true;
			this.isbuttonEnabled = true;
			this.isVerifybuttonEnabled = true;
			this.vpa_provider = this.selectedPaymentMode['vpa_provider'];
			this.magicUPIDisplayName = this.selectedPaymentMode['magicUPIDisplayName'];
			this.findVPADetail(
				this.savedVapId,
				this.savedVpaName,
				this.vpa_provider,
				this.magicUPIDisplayName
			);
		}

		tracking.selectPaymentMethod({
			order_details: this.order_details,
			merchant_details: this.merchant_details,
			selected_payment_mode: this.selectedPaymentMode,
		});

		console.log(this.sharedService.getEnforcePaymentDetails());

		this.sharedService.sharedPaymentNameDetails.subscribe((res) => {
			this.enforcedPaymentName = res;
		});

		console.log(this.sharedService.getEnforcePaymentSuccess());

		if (this.selectedPaymentMode['paymentMode'] === 'UPI') {
			if (hasTouchSupport()) {
				this.isIntentAndMobile = true;
			} else {
				this.isIntentAndMobile = false;
			}
		}

		if(this.isReactNative) {
			this.qrMode = false;
		}
	}

	ngOnchanges() {}
	setPaymentTracking(input) {
		tracking.enterPaymentDetail({
			order_details: this.order_details,
			merchant_details: this.merchant_details,
			selected_payment_mode: this.selectedPaymentMode,
			field_name: input,
			card_type:
				this.selectedPaymentMode['paymentMode'] == 'Credit Card' ||
				this.selectedPaymentMode['paymentMode'] == 'Debit Card' ||
				this.selectedPaymentMode['paymentMode'] == 'Prepaid Card'
					? this.card_type
					: null,
			card_provider:
				this.selectedPaymentMode['paymentMode'] == 'Credit Card' ||
				this.selectedPaymentMode['paymentMode'] == 'Debit Card' ||
				this.selectedPaymentMode['paymentMode'] == 'Prepaid Card'
					? this.card_provider
					: null,
			bank_name:
				this.selectedPaymentMode['paymentMode'] == 'Netbanking' ||
				this.selectedPaymentMode['paymentMode'] == 'Wallet'
					? this.bank_name
					: null,
		});
	}

	setInvalidPaymentDetailTracking(input, invalid_reason) {
		tracking.enterInvalidPaymentDetail({
			order_details: this.order_details,
			merchant_details: this.merchant_details,
			selected_payment_mode: this.selectedPaymentMode,
			field_name: input,
			invalid_reason: invalid_reason,
			card_provider: this.card_provider,
		});
	}

	initiatePayment() {
		this.bank_name = '';
		if (this.selectedPaymentMode['paymentMode'] == 'Netbanking') {
			if (this.isEnforced) this.enforcedInitPayment = true;
			if (this.selectedBank) {
				this.bank_name = this.SharedBankingServiceService.getbankDetails()['bank_name'];
				this.cookies.set('selected_payment_name', this.bank_name, 0.020833, '/', '', true, 'None');
			}
		}
		if (this.selectedPaymentMode['paymentMode'] == 'Wallet') {
			if (this.isEnforced) this.enforcedInitPayment = true;
			if (this.selectedWallet) {
				this.bank_name = this.SharedBankingServiceService.getWallet()['wallet_name'];
				this.cookies.set('selected_payment_name', this.bank_name, 0.020833, '/', '', true, 'None');
			}
		}
		if (this.selectedPaymentMode['paymentMode'] == 'Cardless EMI') {
			this.isbuttonEnabled = false;
		}

		if (this.selectedPaymentMode['paymentMode'] == 'UPI') {
			// this.showUpi = false;
			this.initiatePay.emit(this.UPIId);
			if (this.isEnforced) this.enforcedInitPayment = true;
		} else if (
			this.selectedPaymentMode['paymentMode'] == 'Credit Card' ||
			this.selectedPaymentMode['paymentMode'] == 'Debit Card' ||
			this.selectedPaymentMode['paymentMode'] == 'Prepaid Card' ||
			this.selectedPaymentMode['paymentMode'] == 'Card'
		) {
			let card_type = this.binServiceResponse?.cardType;
			this.cardDetailsService.updateCardDetails({
				...this.cardDetailForm.value,
				card_type: card_type,
			});
			if (this.isEnforced) this.enforcedInitPayment = true;
			this.initiatePay.emit();
		} else {
			this.initiatePay.emit();
		}
		tracking.initiatePayment({
			order_details: this.order_details,
			merchant_details: this.merchant_details,
			selected_payment_mode: this.selectedPaymentMode,
			bank_name: this.bank_name,
			card_type: this.sharedService.getCardType(),
			card_provider: this.card_provider,
		});
		if (!this.activateRoute.snapshot.queryParams['payment_mode']) {
			this.sharedService.updateEnforcePaymentDetails(false);
		}
	}

	handleQrCode() {
		setTimeout(() => {
			this.enforcedInitPayment = true;
			this.payNow.emit({ is_intent: true });
		}, 500);
	}

	cancelPayment() {
		if (this.order_details['grand_total_with_convience_fees']) {
			this.order_details['grand_total_with_convience_fees'] = 0;
			this.sharedorderDetails.updateOrderDetails(this.order_details);
		}

		if (this.selectedPaymentMode['paymentMode'] == 'Netbanking') {
			if (this.selectedBank) {
				this.bank_name = this.SharedBankingServiceService.getbankDetails()['bank_name'];
			}
		}
		if (this.selectedPaymentMode['paymentMode'] == 'Wallet') {
			if (this.selectedWallet) {
				this.bank_name = this.SharedBankingServiceService.getWallet()['wallet_name'];
			}
		}

		tracking.cancelPaymentMethod({
			order_details: this.order_details,
			merchant_details: this.merchant_details,
			selected_payment_mode: this.selectedPaymentMode,
			card_type:
				this.selectedPaymentMode['paymentMode'] == 'Credit Card' ||
				this.selectedPaymentMode['paymentMode'] == 'Debit Card' ||
				this.selectedPaymentMode['paymentMode'] == 'Prepaid Card'
					? this.card_type
					: null,
			card_provider:
				this.selectedPaymentMode['paymentMode'] == 'Credit Card' ||
				this.selectedPaymentMode['paymentMode'] == 'Debit Card' ||
				this.selectedPaymentMode['paymentMode'] == 'Prepaid Card'
					? this.card_provider
					: null,
			bank_name:
				this.selectedPaymentMode['paymentMode'] == 'Netbanking' ||
				this.selectedPaymentMode['paymentMode'] == 'Wallet'
					? this.bank_name
					: null,
		});

		!this.isEnforced && this.cancelPay.emit();
	}

	trimInput(event) {
		if (event.keyCode == 32) {
			event.preventDefault();
		}
	}

	displayList(inputValue) {
		this.isbuttonEnabled = false;
		this.isVpaValid = false;
		let indxOf = this.UPIId?.indexOf('@');
		let vpatoCheck = this.UPIId?.substring(indxOf);
		if (this.validatedVPA !== inputValue) {
			this.isbuttonEnabled = false;
			this.isVpaValid = false;
			this.isVerifybuttonEnabled = false;
		}
		if (indxOf == -1) {
			this.hideList();
			this.isVerifybuttonEnabled = false;
		} else {
			this.isVerifybuttonEnabled = true;
		}

		if (indxOf != -1) {
			this.filteredVPA = [];
			this.vpaList.filter((element) => {
				let vpa = element.toLowerCase();
				if (vpa.includes(vpatoCheck)) {
					let newVPAID = this.UPIId?.substring(0, indxOf) + element;
					this.filteredVPA.push(newVPAID);
				}
			});
			if (this.filteredVPA.length > 0) {
				document.querySelector('.optionLabList').classList.remove('hidden');
			} else {
				document.querySelector('.optionLabList').classList.add('hidden');
			}
		}
	}

	checkVPAOnclick(id) {
		this.UPIId = this.filteredVPA[id];
		this.hideList();
		this.isbuttonEnabled = true;
	}

	hideList() {
		document.querySelector('.optionLabList').classList.add('hidden');
	}

	checkIsMagicUpi = (fastPaymentList, str) => {
		const item = fastPaymentList.filter((item) => {
			if (item.mode == 'savedUPI' && item.savedVPAId == str) return true;
			return false;
		});
		return item.length ? item[0] : null;
	};

	async checkForValidUPI() {
		try {
			let data = {
				upi_id: this.UPIId,
			};

			const validateToken = this.apiService.checkForValidToken();
			if (!validateToken) {
				const resp = await this.apiService
					.getEncryptedAuthorizationToken(this.order_details['order_id'])
					.toPromise();
				this.apiService.setTokenCookies(resp['token'], btoa(resp['expires_at']));
			}

			const res = await this.apiService.validateVpa(data).toPromise();
			this.showLoader = false;
			document.querySelector('.message')?.classList?.remove('hidden');

			if (res['isVPAValid']) {
				this.validatedVPA = res['vpa'];
				this.UPIUserName = res['payerAccountName'];
				this.isbuttonEnabled = true;
				this.isVpaValid = true;
				if(this.isEnforced){
					this.sharedPaymentService.updateSelectedMode({paymentMode: 'UPI'});
				}
				this.initiatePay.emit(this.UPIId);

				if (!validateToken) {
					this.enforcedInitPayment = true;
					this.isInvalidUpi = false;
					const fastPaymentList = this.sharedPaymentService.getFastPaymentMode();
					const validName = this.checkIsMagicUpi(fastPaymentList, this.validatedVPA);
					if (validName) {
						validName['paymentMode'] = 'UPI';
						const arr = {
							paymentMode: validName.paymentMode == 'savedUPI' ? 'UPI' : validName.paymentMode,
							logo: validName.logo,
							eta: validName.eta,
							vpaId: validName.savedVPAId || validName.vpa_id,
							payment_partner: validName.payment_partner,
							extra_charges: validName.additionalCharges || 0,
							isFastPayment: validName.true,
							is_intent: validName.is_intent,
							isAutodebitFlow: validName.isAutodebitFlow,
							vpa_provider: validName.vpa_provider,
							magicUPIDisplayName: validName.magicUPIDisplayName,
							is_magic_UPI: validName.is_magic_UPI,
							intentTextAboveBtns: validName.intentTextAboveBtns,
							intentDisplayName: validName.intentDisplayName,
							compareName: validName?.compareName,
						};
						this.UPIUserName = validName['savedVPA'];
						this.vpa_provider = arr['vpaId'];
						this.magicUPIDisplayName = arr['magicUPIDisplayName'];
						this.sharedPaymentService.updateSelectedMode(arr);
					} else {
						/* 
							was removed during merge conflicts
								this.setInvalidPaymentDetailTracking(PAYMENT_PROPS.UPI, PAYMENT_ERRORS.UPI);
								this.fetchOrderDetails(this.order_details['order_id']);
						*/
						this.vpa_provider = undefined;
						this.magicUPIDisplayName = undefined;
						const upiData =
							this.sharedPaymentService
								.getOtherPaymentMode()
								.filter((item) => item.mode === 'UPI')[0] || {};
						const arr = {
							paymentMode: upiData['mode'] || '',
							logo: upiData['logo'] || '',
							eta: upiData['eta'] || '',
							vpaId: this.validatedVPA || '',
							payment_partner: upiData['payment_partner'] || '',
							extra_charges: upiData['ConvenienceFees'] || '',
							isFastPayment: true,
							is_intent: false,
							isAutodebitFlow: upiData['autoDebitFlowPossible'] || '',
							vpa_provider: '',
							is_magic_UPI: false,
							compareName: upiData['compareName'] || '',
						};
						this.sharedPaymentService.updateSelectedMode(arr);
					}

					this.findVPADetail(
						this.validatedVPA,
						this.UPIUserName,
						this.vpa_provider,
						this.magicUPIDisplayName
					);
				}
			} else {
				this.setInvalidPaymentDetailTracking(PAYMENT_PROPS.UPI, PAYMENT_ERRORS.UPI);
			}
		} catch (error) {
			this.showLoader = false;
			
			const failedValidateVPAData = {
				order_id: this.order_details['order_id'],
				failure_error: error,
				api_name: 'Validate VPA V3',
			}
			tracking.apiFailedError(failedValidateVPAData);

		}
	}

	handleOnCardNumberPaste(event) {
		setTimeout(() => {
			// console.log(event.type);
			if (event.type == 'paste') {
				this.card_type = null;
				this.showCardType = false;
				this.binServiceResponse = undefined;
				this.is_check_bin_called = false;
				// let clipboardData = event.clipboardData || window && window['clipboardData'];
				// let pastedText = clipboardData.getData('text');
				this.pastedNumber = event.target.value;
				this.checkBin(event);
			}
		}, 1000);
	}

	// enableCardButton() {
	// 	if (!this.card_number || this.card_number.length < 16) {
	// 		alert('enter correct card number');
	// 	}
	// }
	updateBankData(bank_name) {
		if (bank_name) {
			this.selectedBank = bank_name;
			this.bank_name = this.selectedBank;
			let bankData = {
				bank_name: bank_name,
				offer_id: null,
			};
			/*
			 **** Resetting the offersAvailable flag to false
			 **** on every Bank click on Netbanking component.
			 */
			this.offerAvailable = false;
			this.listOfBanks.filter((bank: IListOfBanks) => {
				if (bank.bank_name.toLowerCase() === bank_name.toLowerCase()) {
					/*
					 **** In below if condition we are updating
					 **** the Netbanking price break-up table with Offers if applicable
					 */
					if (bank?.offers && bank.offers.length > 0) {
						this.offerAvailable = bank.offers[0].offer_available === 'Y';
						this.offerGrandTotal = bank.offers[0].grand_total_amount;
						this.offerDiscount = bank.offers[0].offer_discount;
						this.offerID = bank.offers[0].offer_id;
						this.offerTotalAmount = bank.offers[0].total_amount;
						bankData.offer_id = bank.offers[0].offer_id;
					}
					/* setting grandTotal based on Offers and ConvenienceFee */
					this.grandTotal = this.offerAvailable
						? this.offerGrandTotal
						: this.order_details.total_amount + (bank?.additional_charges || 0);
					this.order_details['grand_total_with_convience_fees'] = this.grandTotal;
					this.sharedorderDetails.updateOrderDetails(this.order_details);
					this.convenienceFee = bank?.additional_charges;
				}
			});
			this.SharedBankingServiceService.updateBankDetails(bankData);
			this.isbuttonEnabled = true;
			this.enforceInitPay();
		} else {
			this.isbuttonEnabled = false;
		}
	}

	/* Display list of Six Wallets */
	displayWalletList() {
		let walletObject = {};
		this.listOfDefaultWallets.map((item) => {
			walletObject[item.walletName] = item;
		});
		const listOfWalletsInArray = Object.keys(walletObject);
		let res = this.sharedService.getwalletResponse();
		if (res['wallet_list']) {
			this.listOfWallets = res['wallet_list'];
			this.sharedService.updateListOfWalletsDetails(res['wallet_list']);
			this.remainingWallets = [];
			this.listOfWallets.forEach((wallet) => {
				if (listOfWalletsInArray.includes(wallet['wallet_name']) && this.topSixwallets.length < 6) {
					walletObject[wallet['wallet_name']]['additional_charges'] = wallet?.additional_charges;
					this.topSixwallets.push(walletObject[wallet['wallet_name']]);
				} else {
					this.remainingWallets.push(wallet);
				}
			});

			if (this.hasSubPaymentMode && this.paymentModeName === 'Wallet') {
				this.updateWalletData(this.enforcedPaymentName);
			}

			setTimeout(() => {
				document.getElementById('spinnerLoaderContainerWallet')?.remove();
				document.getElementById('listOfWalletUl')
					? (document.getElementById('listOfWalletUl').style.background = 'none')
					: '';
			}, 1000);
		}
	}

	/* Load All Wallets */
	loadAllWallets() {
		this.listOfWallets = [];
		this.isbuttonEnabled = false;
		let order_id_params = this.activateRoute.snapshot.queryParams['order_id'];
		let data = {
			order_id: this.order_details['order_id'] || order_id_params,
		};
		let walletObject = {};
		this.listOfDefaultWallets.map((item) => {
			walletObject[item.walletName] = item;
		});
		const listOfWalletsInArray = Object.keys(walletObject);
		// console.log('abcd', walletObject, );
		if (this.walletResponse.length === 0) {
			!this.isEnforced
				? this.apiService.fetchAllWallets(data).subscribe((res) => {
						this.walletResponse = res;
						this.sharedService.updatewalletResponse(res);
						if (res['wallet_list']) {
							this.listOfWallets = res['wallet_list'];
							this.sharedService.updateListOfWalletsDetails(res['wallet_list']);
							this.remainingWallets = [];
							this.listOfWallets.forEach((wallet) => {
								if (
									listOfWalletsInArray.includes(wallet['wallet_name']) &&
									this.topSixwallets.length < 6
								) {
									walletObject[wallet['wallet_name']]['additional_charges'] =
										wallet?.additional_charges;
									this.topSixwallets.push(walletObject[wallet['wallet_name']]);
								} else {
									this.remainingWallets.push(wallet);
								}
							});

							if (this.hasSubPaymentMode && this.paymentModeName === 'Wallet') {
								this.updateWalletData(this.enforcedPaymentName);
							}
						}
						setTimeout(() => {
							document.getElementById('spinnerLoaderContainerWallet')?.remove();
							document.getElementById('listOfWalletUl')
								? (document.getElementById('listOfWalletUl').style.background = 'none')
								: '';
						}, 1000);
				  })
				: this.apiService.fetchAllWalletsV3(data).subscribe(
						(res) => {
							if (res['wallet_list']) {
								this.isbuttonEnabled = false;
								this.apiError = '';
								this.listOfWallets = res['wallet_list'];
								this.sharedService.updateListOfWalletsDetails(res['wallet_list']);
								this.remainingWallets = [];
								this.listOfWallets.forEach((wallet) => {
									if (
										listOfWalletsInArray.includes(wallet['wallet_name']) &&
										this?.topSixwallets.length < 6
									) {
										walletObject[wallet['wallet_name']]['additional_charges'] =
											wallet?.additional_charges;
										this.topSixwallets.push(walletObject[wallet['wallet_name']]);
									} else {
										this.remainingWallets.push(wallet);
									}
								});

								if (
									this.hasSubPaymentMode &&
									this.paymentModeName === 'Wallet' &&
									!this.isPaymentSuccess
								) {
									const result = this.listOfWallets.filter((wallet) => {
										if (wallet?.code?.toLowerCase() === this?.enforcedPaymentName) {
											return wallet.wallet_name;
										}
									});

									if (result.length === 0) {
										this.isbuttonEnabled = false;
										this.apiError = this.errorMessage;
										throw Error(this.errorMessage);
									} else {
										this.apiError = '';
									}
									console.log(result[0]?.wallet_name);
									this.sharedService.updatePaymentNameDetails(result[0]?.wallet_name);
									this.selectedPaymentMode['paymentModeName'] = result[0]?.wallet_name;
									this.selectedPaymentMode['logo'] = 'assets/images/logos/wallet.svg';
									this.updateWalletData(this.enforcedPaymentName);
								}
								this.selectedPaymentMode['logo'] = 'assets/images/logos/wallet.svg';
							}
							document.getElementById('spinnerLoaderContainerWallet')?.remove();
							document.getElementById('listOfWalletUl')
								? (document.getElementById('listOfWalletUl').style.background = 'none')
								: '';
						},
						(error: any) => {
							
							const failedWalletV3Data = {
								order_id: this.order_details['order_id'] || order_id_params,
								failure_error: error,
								api_name: 'List of Wallet V3',
							}
							tracking.apiFailedError(failedWalletV3Data);

							this.isbuttonEnabled = false;
							this.apiError = this.errorMessage;
							throw Error(this.errorMessage);
						}
				  );
		} else {
			!this.isEnforced && this.displayWalletList();
		}
	}

	/* Display List of Six Banks */
	displayBankList() {
		let res = this.bankResponse;
		if (res['bank_list']) {
			this.listOfBanks = res['bank_list'];
			this.sharedService.updateListOfBanksDetails(res['bank_list']);
			this.listOfBanks.forEach((bank) => {
				this.listOfDefaultBanks.forEach((defaultBank) => {
					if (bank.bank_name == defaultBank?.bankName && this.topSixBanks.length < 6) {
						defaultBank.additional_charges = bank?.additional_charges;
						defaultBank.offers = bank?.offers ? bank?.offers : [];
						this.topSixBanks.push(defaultBank);
					}
				});
			});

			// if(this.hasSubPaymentMode && this.paymentModeName === 'Netbanking'){
			// 	this.updateBankData(this.enforcedPaymentName);
			// }
		}
		setTimeout(() => {
			document.getElementById('spinnerLoaderContainerBankList')?.remove();
			document.getElementById('listOfBanksUl')
				? (document.getElementById('listOfBanksUl').style.background = 'none')
				: '';
		}, 1000);
	}

	/* Load All Banks  */
	loadAllBanks() {
		this.listOfBanks = [];
		let order_id_params = this.activateRoute.snapshot.queryParams['order_id'];
		let data = {
			order_id: this.order_details['order_id'] || order_id_params,
		};
		if (this.bankResponse.length === 0) {
			!this.isEnforced
				? this.apiService.fetchBanks(data).subscribe((res) => {
						this.sharedService.updatebankResponse(res);
						this.bankResponse = res;
						if (res['bank_list']) {
							this.listOfBanks = res['bank_list'];
							this.sharedService.updateListOfBanksDetails(res['bank_list']);
							this.listOfBanks.forEach((bank) => {
								this.listOfDefaultBanks.forEach((defaultBank) => {
									if (bank.bank_name == defaultBank?.bankName && this.topSixBanks.length < 6) {
										defaultBank.additional_charges = bank?.additional_charges;
										defaultBank.offers = bank?.offers ? bank?.offers : [];
										this.topSixBanks.push(defaultBank);
									}
								});
							});

							// if(this.hasSubPaymentMode && this.paymentModeName === 'Netbanking'){
							// 	this.updateBankData(this.enforcedPaymentName);
							// }
						}
						setTimeout(() => {
							document.getElementById('spinnerLoaderContainerBankList')?.remove();
							document.getElementById('listOfBanksUl')
								? (document.getElementById('listOfBanksUl').style.background = 'none')
								: '';
						}, 1000);
				  })
				: this.apiService.fetchBanksV3(data).subscribe(
						(res) => {
							this.sharedService.updatebankResponse(res);
							this.bankResponse = res;
							this.isbuttonEnabled = false;
							if (res['bank_list']) {
								this.apiError = '';
								this.listOfBanks = res['bank_list'];
								this.sharedService.updateListOfBanksDetails(res['bank_list']);
								this.listOfBanks.forEach((bank) => {
									this.listOfDefaultBanks.forEach((defaultBank) => {
										if (
											bank?.code?.toLowerCase() == defaultBank?.code?.toLowerCase() &&
											this?.topSixBanks.length < 6
										) {
											defaultBank.additional_charges = bank?.additional_charges;
											defaultBank.offers = bank?.offers ? bank?.offers : [];
											this.topSixBanks.push(defaultBank);
										}
									});
								});

								if (
									this.hasSubPaymentMode &&
									this.paymentModeName === 'Netbanking' &&
									!this.isPaymentSuccess
								) {
									const result = this.listOfBanks.filter((bank) => {
										if (bank?.code?.toLowerCase() === this?.enforcedPaymentName) {
											return bank.bank_name;
										}
									});

									if (result.length === 0) {
										this.apiError = this.errorMessage;
										this.isbuttonEnabled = false;
										throw Error(this.errorMessage);
									} else {
										this.apiError = '';
									}

									this.sharedService.updatePaymentNameDetails(result[0]?.bank_name);
									this.selectedPaymentMode['paymentModeName'] = result[0]?.bank_name;
									console.log(this.enforcedPaymentName);
									this.selectedPaymentMode['logo'] = 'assets/images/logos/bank.png';
									this.updateBankData(this.enforcedPaymentName);
								}
								this.selectedPaymentMode['logo'] = 'assets/images/logos/bank.png';
							}
							document.getElementById('spinnerLoaderContainerBankList')?.remove();
							document.getElementById('listOfBanksUl')
								? (document.getElementById('listOfBanksUl').style.background = 'none')
								: '';
						},
						(error: any) => {
							const failedBankV3Data = {
								order_id: this.order_details['order_id'] || order_id_params,
								failure_error: error,
								api_name: 'List of Bank V3',
							}
							tracking.apiFailedError(failedBankV3Data);
							this.isbuttonEnabled = false;
							this.apiError = this.errorMessage;
							throw Error(this.errorMessage);
						}
				  );
		} else {
			this.displayBankList();
		}
		// if(this.hasSubPaymentMode && this.paymentModeName === 'Netbanking'){
		// 	this.updateBankData(this.enforcedPaymentName);
		// }
	}

	/* Load All Cardless EMI */
	loadCardlessEMI() {
		this.listOfcardlessEMI = this.sharedPaymentService.getCardlessPaymentMode();
		this.listOfcardlessEMI.map((item, index) => {
			item.mode = item.modeName;
			item.additional_charges = 0;
			if (index < 6) {
				this.topSixCardlessEMI.push(item);
			} else {
				this.remainingCardlessEMI.push(item);
			}
		});

		console.log(this.topSixCardlessEMI);
		console.log(this.listOfcardlessEMI);
		console.log(this.remainingCardlessEMI);
	}

	updateCardlessData(event, data) {
		this.selectedWallet = event;
		this.bank_name = this.selectedWallet;
		this.isbuttonEnabled = true;
		console.log(event, 'event');
		console.log(data, 'data');
		this.sharedService.updateCardlessPaymentSelected(data);
	}

	updateWalletData(event) {
		if (event) {
			this.selectedWallet = event;
			this.bank_name = this.selectedWallet;
			let walletData = {
				wallet_name: event,
			};
			console.log(
				this.listOfWallets.filter((wallet) => {
					if (wallet.wallet_name == event) {
						this.grandTotal =
							this.order_details.total_amount + parseFloat(wallet?.additional_charges);
						this.order_details['grand_total_with_convience_fees'] = this.grandTotal;
						this.sharedorderDetails.updateOrderDetails(this.order_details);
						this.convenienceFee = parseFloat(wallet?.additional_charges);
					}
				})
			);
			this.SharedBankingServiceService.updateWallet(walletData);
			this.isbuttonEnabled = true;
			this.enforceInitPay();
		} else {
			this.isbuttonEnabled = false;
		}
	}

	isValidDataCallFetchOffers = () => {
		let cardData = {
			card_number: this.cardDetailForm.controls.card_number.value,
			card_holder_name: this.cardDetailForm.controls.card_holder_name.value,
			cvv: this.cardDetailForm.controls.cvv.value,
			card_expiry: this.cardDetailForm.controls.card_expiry.value,
		};

		let stateCardValue = this.cardDetailsService.getCardDetails();
		delete stateCardValue['isValid'];
		delete stateCardValue['card_type'];

		console.log(cardData, 'obj self');
		console.log(stateCardValue, 'obj state');

		if (
			this.cardDetailForm.controls.card_number.status === 'VALID' &&
			this.cardDetailForm.controls.card_expiry.status === 'VALID' &&
			this.cardDetailForm.controls.card_holder_name.status === 'VALID' &&
			this.cardDetailForm.controls.cvv.status === 'VALID' &&
			JSON.stringify(stateCardValue) !== JSON.stringify(cardData)
		) {
			this.isOfferCalled = true;
			this.fnFetchOffers();
			this.cardDetailsService.updateCardDetails({
				...this.cardDetailForm.value,
			});
		}
	};

	//handle blur event of card number
	handleOnCardNumberBlur(event = null) {
		this.setPaymentTracking(PAYMENT_PROPS.CARD.CARD_NUMBER);
		if (this.cardDetailForm && this.cardDetailForm?.controls?.card_number?.value) {
			this.cardDetailForm.controls.card_number.setValue(
				this.cardDetailForm.controls.card_number.value.replaceAll(' ', '')
			);
			this.cardDetailForm.controls.card_number.setValue(
				this.cardDetailForm.controls.card_number.value.match(/.{1,4}/g).join(' ')
			);
		}

		if (this.cardDetailForm.controls.card_number.invalid) {
			this.setInvalidPaymentDetailTracking(
				PAYMENT_PROPS.CARD.CARD_NUMBER,
				PAYMENT_ERRORS.CARD_TYPE_NOT_SUPPORTED
			);
		} else {
			this.isValidDataCallFetchOffers();
		}
	}

	checkBin(event) {
		/* Reset ConvenienceFee to Zero for every Bin Check */
		this.convenienceFee = 0;
		this.cardValidation = this.cardDetailForm.controls.card_number.valid;
		let cardNumber;
		if (event) {
			cardNumber = event.target.value.split(' ').join('');
		}

		if (cardNumber.length >= 6 && !this.binServiceResponse && !this.is_check_bin_called) {
			this.is_check_bin_called = true;
			let rr = new checkBinRequest();
			rr.card_no = cardNumber.slice(0, 6);
			if (this.pastedNumber) {
				rr.card_no = this.pastedNumber?.split(' ').join('').slice(0, 6);
				this.pastedNumber = '';
			}
			rr.order_id = this.order_details['order_id'];
			this.apiService.checkBin(rr).subscribe(
				(res) => {
					this.cardDetailForm.controls['isValid'].setValue('');
					this.binServiceResponse = res['data'];
					if (Number(this.binServiceResponse?.additional_charges) > 0) {
						this.grandTotal = this.order_details.total_amount;
						this.grandTotal =
							Number(this.grandTotal) + Number(this.binServiceResponse?.additional_charges);
						this.order_details['grand_total_with_convience_fees'] = this.grandTotal;
						this.sharedorderDetails.updateOrderDetails(this.order_details);

						this.convenienceFee = this.binServiceResponse?.additional_charges;
					} else {
						this.grandTotal = this.order_details.total_amount;
					}

					if (this.binServiceResponse?.cardType !== 'Unknown' && this.binServiceResponse) {
						let CardType;
						if ((this, this.binServiceResponse['cardCategory'] == 'PC')) {
							CardType = 'Prepaid Card';
						} else if (this.binServiceResponse['cardCategory'] == 'CC') {
							CardType = 'Credit Card';
						} else {
							CardType = 'Debit Card';
						}
						this.sharedService.updateCardtype(CardType);
						this.paymentModeName = this.sharedService.getCardType();

						this.cardDetailForm.controls['isValid'].setValue(true);
						this.showCardType = true;
						this.card_provider = this.binServiceResponse?.cardType;
						this.sharedService.updateCardprovider(this.card_provider);
						this.binServiceResponse.cardType = this.binServiceResponse?.cardType.toLowerCase();

						this.binServiceResponse?.cardType === 'american express'
							? (this.cvvMaxLength = 4)
							: (this.cvvMaxLength = 3);

						this.cardDetailForm.controls['cvv'].setValidators([
							Validators.required,
							Validators.minLength(this.cvvMaxLength),
							Validators.maxLength(4),
						]);
						this.cardDetailForm.controls['cvv'].updateValueAndValidity();
					} else {
						this.showCardType = false;
						this.isbuttonEnabled = false;
					}
				},
				(error) => {
					this.cardDetailForm.controls['isValid'].setValue('');
				}
			);
		} else if (event.target.value.length < 6) {
			this.showCardType = false;
			let resp = [];
			this.binServiceResponse = undefined;
			this.is_check_bin_called = false;
		} else {
			var numberValidation = valid.number(event.target.value?.split(' ').join());
			if (numberValidation.isValid) {
				document.getElementById('cardNumber').classList.remove('error');
				document.getElementById('ccExp') && document.getElementById('ccExp').focus();
			} else {
				document.getElementById('cardNumber').classList.add('error');
				if (this.cardDetailForm.controls.card_number.status == 'INVALID') {
					this.cardDetailForm.controls['card_number'].setErrors(
						Validators.minLength(numberValidation['card']?.lengths?.[0] || 0)
					);
				}
			}
		}
	}
	handleUPIBlur = (event) => {
		this.setPaymentTracking(PAYMENT_PROPS.UPI);
	};

	handleExpiryBlur = (event) => {
		this.setPaymentTracking(PAYMENT_PROPS.CARD.EXPIRY_DATE);

		let isExpiry_valid = valid.expirationDate(this.cardDetailForm.controls['card_expiry'].value);

		if (!isExpiry_valid.isValid) {
			this.setInvalidPaymentDetailTracking(
				PAYMENT_PROPS.CARD.EXPIRY_DATE,
				PAYMENT_ERRORS.EXPIRY_DATE
			);
		} else {
			this.isValidDataCallFetchOffers();
		}
	};

	showToast(msg) {
		const data = msg;
		this.toast.initiate({
			content: data,
		});
	}

	handleCVVBlur = (event) => {
		let newCvvNumber = event.target.value;
		this.setPaymentTracking(PAYMENT_PROPS.CARD.CVV);
		if (this.cardDetailForm.controls.cvv.status !== 'VALID') {
			this.setInvalidPaymentDetailTracking(PAYMENT_PROPS.CARD.CVV, PAYMENT_ERRORS.CVV);
		} else {
			if (!this.isOfferCalled) {
				this.cardDetailsService.updateCardDetails({
					...this.cardDetailForm.value,
					card_type: this.binServiceResponse?.cardType,
				});
				newCvvNumber !== this.prevCVVNumber && this.onChangeEvent(event);
				this.prevCVVNumber = newCvvNumber;
			} else {
				this.isValidDataCallFetchOffers();
			}
		}
	};

	onChangeEvent(event: any) {
		let cvvText = event.target.value;
		let amexCvvLen = this.binServiceResponse?.cardType === 'american express' ? 4 : 3;
		if (
			cvvText.length == amexCvvLen &&
			this.cardDetailForm.controls.card_number.status === 'VALID' &&
			this.cardDetailForm.controls.card_expiry.status === 'VALID' &&
			this.cardDetailForm.controls.card_holder_name.status === 'VALID' &&
			this.cardDetailForm.controls.cvv.status === 'VALID' &&
			this.cardDetailForm.controls.isValid.status === 'VALID'
		) {
			if(this.merchant_details && this.merchant_details['offers_enabled'] === true) {
				this.fnFetchOffers();
			} else {
				this.isbuttonEnabled = true;
			}
		}
	}

	fnFetchOffers() {
		if (!this.cardDetailForm.controls.card_number.invalid) {
			let data = {
				order_id: this.order_details['order_id'],
				card_details: this.cardDetailsService.generateCardDetail(),
			};
			this.apiService.fetchCardOffers(data).subscribe((res) => {
				const fetchCardOffersData = res['data'];
				/*
				 **** In below if condition we are updating
				 **** the Card price break-up table with Offers if applicable
				 */
				this.sharedPaymentService.updateCardOffers(fetchCardOffersData);

				if (fetchCardOffersData['offer_available'] === 'Y') {

					this.offerAvailable = fetchCardOffersData['offer_available'] === 'Y';
					this.offerGrandTotal = fetchCardOffersData['grand_total_amount'];
					this.offerDiscount = fetchCardOffersData['offer_discount'];
					this.offerID = fetchCardOffersData['offer_id'];
					this.offerTotalAmount = fetchCardOffersData['total_amount'];

					this.showToast('Offer applied on this card');
					this.isbuttonEnabled = true;

					if (fetchCardOffersData?.additional_charges > 0) {
						this.grandTotal = this.order_details.total_amount;
						this.grandTotal = this.offerAvailable
							? this.offerGrandTotal
							: this.grandTotal + parseFloat(fetchCardOffersData?.additional_charges);
						this.order_details['grand_total_with_convience_fees'] = this.grandTotal;
						this.convenienceFee = parseFloat(fetchCardOffersData?.additional_charges);
						this.isbuttonEnabled = true;
					} else {
						this.grandTotal = this.offerAvailable
							? this.offerGrandTotal
							: this.grandTotal + parseFloat(fetchCardOffersData?.additional_charges);
						this.isbuttonEnabled = true;
					}

				} else {
					this.showToast('No offer is applicable on your card');
					this.isbuttonEnabled = true;
				}
				
			},
			(error) => {
				this.offerAvailable = null;
				this.offerGrandTotal = null;
				this.offerDiscount = null;
				this.offerID = null;
				this.offerTotalAmount = null;
				this.isbuttonEnabled = true;
				console.error('Fetch Card Offer Error:', error);
			});
		}
	}

	setFocus() {
		let isExpiry_valid = valid.expirationDate(this.cardDetailForm.controls['card_expiry'].value);

		if (isExpiry_valid.isValid) {
			document.getElementById('ccExp').classList.remove('error');
			document.getElementById('holderName') && document.getElementById('holderName').focus();
		}
	}

	holderName(event) {
		this.setPaymentTracking(PAYMENT_PROPS.CARD.CARD_HOLDER_NAME);
		if (this.cardDetailForm.controls.card_holder_name.status == 'VALID') {
			document.getElementById('cvvId') && document.getElementById('cvvId').focus();
			this.isValidDataCallFetchOffers();
		} else {
			this.setInvalidPaymentDetailTracking(
				PAYMENT_PROPS.CARD.CARD_HOLDER_NAME,
				PAYMENT_ERRORS.CARD_HOLDER_NAME
			);
		}
	}

	editVPA() {
		let urlUpiId = this.validatedVPA;
		let vpaDetail = this.sharedService.getupdatedUPIDetail();
		this.UPIId = this.isEnforced ? urlUpiId :vpaDetail['vpaId'];
		this.vpaAppLogo = vpaDetail['vpaAppLogo'];
		this.isVpaValid = false;
		this.isVerifybuttonEnabled = true;
	}

	findVPADetail(vpaId, VPAName, vpa_provider, magicUPIDisplayName = null) {
		this.UPIUserName = VPAName;
		this.validatedVPA = vpaId;
		this.UPIId = vpaId;

		const appFounder = vpaId?.substring(vpaId.indexOf('@'), vpaId.length);
		const defaultVpaDetails = {
			vpaAppLogo: 'assets/images/icons/default.png',
			vpaAppName: 'UPI',
		};

		if (!vpa_provider) {
			const paymentModes = {
				'@okhdfcbank': {
					vpaAppName: 'Google Pay',
					vpaAppLogo: 'assets/images/icons/googlepay.svg',
				},
				'@okicici': { vpaAppName: 'Google Pay', vpaAppLogo: 'assets/images/icons/googlepay.svg' },
				'@oksbi': { vpaAppName: 'Google Pay', vpaAppLogo: 'assets/images/icons/googlepay.svg' },
				'@apl': { vpaAppName: 'Amazon Pay', vpaAppLogo: 'assets/images/logos/amazonPay.png' },
				'@yapl': { vpaAppName: 'Amazon Pay', vpaAppLogo: 'assets/images/logos/amazonPay.png' },
				'@rapl': { vpaAppName: 'Amazon Pay', vpaAppLogo: 'assets/images/logos/amazonPay.png' },
				'@waicici': { vpaAppName: 'WhatsApp', vpaAppLogo: 'assets/images/icons/whatsapp.png' },
				'@icici': { vpaAppName: 'WhatsApp', vpaAppLogo: 'assets/images/icons/whatsapp.png' },
				'@waaxis': { vpaAppName: 'WhatsApp', vpaAppLogo: 'assets/images/icons/whatsapp.png' },
				'@wahdfcbank': { vpaAppName: 'WhatsApp', vpaAppLogo: 'assets/images/icons/whatsapp.png' },
				'@wasbi': { vpaAppName: 'WhatsApp', vpaAppLogo: 'assets/images/icons/whatsapp.png' },
				'@ybl': { vpaAppName: 'Phonepe', vpaAppLogo: 'assets/images/icons/phone-pe.png' },
				'@ibl': { vpaAppName: 'Phonepe', vpaAppLogo: 'assets/images/icons/phone-pe.png' },
				'@axl': { vpaAppName: 'Phonepe', vpaAppLogo: 'assets/images/icons/phone-pe.png' },
				'@ikwik': { vpaAppName: 'MobiKwik' },
				'@paytm': { vpaAppName: 'Paytm', vpaAppLogo: 'assets/images/icons/Paytm_Logo.png' },
				'@jupiteraxis': {
					vpaAppName: 'Jupiter Money',
					vpaAppLogo: 'assets/images/icons/jupiteraxis.png',
				},
				'@yesg': { vpaAppName: 'Groww', vpaAppLogo: 'assets/images/icons/groww.png' },
				'@axisb': { vpaAppName: 'CRED', vpaAppLogo: 'assets/images/icons/cred.png' },
			};

			const selectedPaymentMode = paymentModes[appFounder];
			this.vpaAppName = selectedPaymentMode?.vpaAppName || defaultVpaDetails.vpaAppName;
			this.vpaAppLogo = selectedPaymentMode?.vpaAppLogo || defaultVpaDetails.vpaAppLogo;
		} else {
			this.vpaAppLogo = this.selectedPaymentMode['logo'] || 'assets/images/icons/default.png';
			this.vpaAppName = 'UPI';
			this.magicUPIDisplayName = magicUPIDisplayName;
			this.selectedPaymentMode['logo'] = 'assets/images/icons/bhim.png';
		}

		const UPIdetails = {
			isUpiPayment: this.isVpaValid,
			vpaAppLogo: this.vpaAppLogo,
			vpaAppName: this.vpaAppName,
			vpaId: this.validatedVPA,
			vpaUserName: this.UPIUserName,
		};

		if (vpa_provider) {
			UPIdetails['magicUPIDisplayName'] = magicUPIDisplayName;
		}

		this.sharedService.updateUPIDetail(UPIdetails);
	}

	openUPIModal(action) {
		if (action == 'find UPI Id') {
			this.findUpiId = true;
		}
		if (action == 'pay using UPI') {
			this.findUpiId = false;
		}
		this.UPIModal = true;
	}
	closeModal() {
		this.UPIModal = false;
	}

	showConfirmationMessage() {
		this.showPopup = true;
		document.querySelector('body').classList.add('noscroll');
		setTimeout(() => {
			document.getElementById('noBtn') && document.getElementById('noBtn').focus();
		}, 100);
	}

	fetchOrderDetails(orderID) {
		this.apiService.fetchOrderById(orderID).subscribe((response) => {

			if(hasMaxRetriesReached(response)) {
				this.sharedService.setHasMaxRetriesExpiredSubject(true);
			}
			
			this.order_details = JSON.parse(JSON.stringify(response));
			response['checkout_id'] = getCheckoutId(response['order_id']);
			this.orderService.updateOrderDetails(response);
			this.sharedService.updateUserCountryFromAPI(response['user']['country_code']);
		});
	}
}
