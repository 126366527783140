const tracking = (name, data) => {
	//  	console.log('EVENT TRACKING:', name, data); // #NOTE: added for testing purpose. Will remove it once Tester test the tracking
	window?.$ns && window.$ns({ name: name, data: { data }, transactionId: data?.order_id });
	// if(!data.order_id) {
	//     console.log("please provide the order_id in ", name);
	//     return;
	// }

	// new relic event tracker
	window.newrelic && window.newrelic.addPageAction(name, data);

	// sending events to meta base;
	// if (name != 'Page Opened') {
	// 	window.ns({ name: name, data: { data } });
	// } else {
	// 	window.ns({ name: name, data: { data } });
	// }
};

export const renderCheckoutEntries = {
	'/': 'mobile_entry',
	'/payment': 'payment_entry',
	'/verify-otp': 'otp_entry',
};

// This function returns an object that contains enforced tracking data
const handleEnforcedTracking = () => {
	const getSubPaymentMode = () => {
		if(cookieData?.bank_code){
			return cookieData?.bank_code
		}else if(cookieData?.wallet_code){
			return cookieData?.wallet_code
		}else if(cookieData?.upi_id){
			return cookieData?.upi_id
		}else {
			return null
		}
	}
	// @ts-ignore
	const cookieData = Object.fromEntries(document.cookie.split('; ').map(c => c.split('=')));	
	return {
		is_enforce:  cookieData?.isEnforced?.toLowerCase() === 'true' || false,
		enforce_payment_mode: cookieData?.payment_mode || null,
		enforce_sub_payment_mode: getSubPaymentMode()
	}
}

handleEnforcedTracking();
const reactNativeIntent = (data1, value='' ) => {
	tracking('React Native Intent', {
		RN_Intent: data1,
		Value: value,
	});
};

const egtPayload = (data) => {
	tracking('EGT Payload', {
		order_id: data?.order_id,
		client_access_key: data?.client_access_key,
	});
};

const updateOrderAPIPayload = (data) => {
	tracking('Update Order API', {
		order_id: data?.order_id,
		close_reason: data?.reason,
		called_from: data?.from,
	});
};

const egtCallError = (data) => {
	tracking('EGT Call Error Handler', {
		status_code: data?.order_id,
		error_message: data?.client_access_key,
	});
};

const renderCheckout = (data) => {
	tracking('Checkout Rendered', {
		checkout_id: data?.order_details?.checkout_id,
		order_id: data?.order_details?.order_id,
		amount: data?.order_details?.total_amount,
		merchant_id: data.order_details?.sub_merchant?.sub_merchant_id,
		is_sandbox: data.order_details?.sub_merchant?.sandbox == 'Y',
		merchant_name: data.order_details?.sub_merchant?.description,
		step_name: data.step_name,
		redirect_type: data.order_details?.callback_mode,
		...handleEnforcedTracking()
	});
};

const checkoutOpen = (data) => {
	tracking('Checkout Opened', {
		checkout_id: data?.order_details?.checkout_id,
		order_id: data?.order_details?.order_id,
		amount: data?.order_details?.total_amount,
		merchant_id: data.order_details?.sub_merchant?.sub_merchant_id,
		is_sandbox: data.order_details?.sub_merchant?.sandbox == 'Y',
		merchant_name: data.order_details?.sub_merchant?.description,
		redirect_type: data.order_details?.callback_mode,
		isReactNative: data?.isReactNative,
		intentList: data?.intent,
		...handleEnforcedTracking()
	});
};

const checkoutClosed = (data, isSuccess = false) => {
	let options = {
		checkout_id: data?.order_details?.checkout_id,
		order_id:
			data.order_details && data?.order_details?.order_id ? data?.order_details?.order_id : null,
		amount: data?.order_details?.total_amount,
		merchant_id: data.order_details?.sub_merchant?.sub_merchant_id,
		merchant_name: data.order_details?.sub_merchant?.description,
		...handleEnforcedTracking()
	};

	if (isSuccess) {
		options['close_reason'] = 'payment succes';
	} else {
		options['close_reason'] = data?.close_reason;
		options['cancel_reason'] = data?.selected_reason;
	}
	tracking('Checkout Closed', options);
};

const openPage = (data) => {
	let options = {
		order_id: data.order_details && data?.order_details?.order_id,
		amount: data.order_details && data?.order_details?.total_amount,
		merchant_id: data.order_details && data.order_details?.sub_merchant?.sub_merchant_id,
		is_sandbox: data.order_details?.sub_merchant?.sandbox == 'Y',
		merchant_name: data.order_details?.sub_merchant?.description,
		page_name: data.page_name,
		checkout_id: data.order_details && data?.order_details?.checkout_id,
		...handleEnforcedTracking()
	};
	tracking('Page Opened', options);
};

const renderPage = (data) => {
	let options = {
		order_id: data.order_details && data?.order_details?.order_id,
		amount: data.order_details && data?.order_details?.total_amount,
		merchant_id: data.order_details && data.order_details?.sub_merchant?.sub_merchant_id,
		is_sandbox: data.order_details?.sub_merchant?.sandbox == 'Y',
		merchant_name: data.order_details?.sub_merchant?.description,
		page_name: data?.page_name,
		checkout_id: data.order_details && data?.order_details?.checkout_id,
		...handleEnforcedTracking()
	};
	tracking('Page Rendered', options);
};

/* OTP related tracking */
const enterMobile = (data) => {
	tracking('Consumer Mobile Entered', {
		checkout_id: data?.order_details?.checkout_id,
		mobile: data?.mobile,
		merchant_id: data.order_details?.sub_merchant?.sub_merchant_id,
		is_sandbox: data.order_details?.sub_merchant?.sandbox == 'Y',
		merchant_name: data.order_details?.sub_merchant?.description,
		order_id: data?.order_details?.order_id,
		amount: data?.order_details?.total_amount,
		...handleEnforcedTracking()
	});
};

const enterOTP = (data) => {
	tracking('Consumer OTP Entered', {
		checkout_id: data?.order_details?.checkout_id,
		user_id: data.user_details?.user_id,
		merchant_id: data.order_details?.sub_merchant?.sub_merchant_id,
		is_sandbox: data.order_details?.sub_merchant?.sandbox == 'Y',
		merchant_name: data.order_details?.sub_merchant?.description,
		order_id: data?.order_details?.order_id,
		amount: data?.order_details?.total_amount,
		...handleEnforcedTracking()
	});
};

const resendOTP = (data) => {
	tracking('Consumer Resend OTP Request', {
		checkout_id: data?.order_details?.checkout_id,
		user_id: data.user_details?.user_id,
		order_id: data?.order_details?.order_id,
		merchant_id: data.order_details?.sub_merchant?.sub_merchant_id,
		is_sandbox: data.order_details?.sub_merchant?.sandbox == 'Y',
		merchant_name: data.order_details?.sub_merchant?.description,
		...handleEnforcedTracking()
	});
};

/* User related tracking */
//#TODO: Need to ask why do we have source and merchange_name if both provide same data
//#TODO: need to ask why consumer registered is at two places
const registerUser = (data) => {
	tracking('Consumer Registered', {
		checkout_id: data?.order_details?.checkout_id,
		source: data.order_details?.sub_merchant?.description,
		type: 'consumer',
		merchant_id: data.order_details?.sub_merchant?.sub_merchant_id,
		is_sandbox: data.order_details?.sub_merchant?.sandbox == 'Y',
		merchant_name: data.order_details?.sub_merchant?.description,
		order_id: data.order_details.order_id,
		amount: data.order_details.total_amount,
		...handleEnforcedTracking()
	});
};

const verifyUser = (data) => {
	tracking('Consumer Verified', {
		checkout_id: data?.order_details?.checkout_id,
		is_verified: 'yes',
		user_id: data.user?.user_id,
		merchant_id: data.order_details?.sub_merchant?.sub_merchant_id,
		is_sandbox: data.order_details?.sub_merchant?.sandbox == 'Y',
		merchant_name: data.order_details?.sub_merchant?.description,
		order_id: data?.order_details?.order_id,
		amount: data?.order_details?.total_amount,
		...handleEnforcedTracking()
	});
};
/* #TODO: need to ask why some places pass all user data and some places only id */

const recogniseUser = (data, allUserDetail = true) => {
	let options = {
		checkout_id: data?.order_details?.checkout_id,
		user_id: data.user?.user_id,
		merchant_id: data.order_details?.sub_merchant?.sub_merchant_id,
		is_sandbox: data.order_details?.sub_merchant?.sandbox == 'Y',
		merchant_name: data.order_details?.sub_merchant?.description,
		order_id: data?.order_details?.order_id,
		amount: data?.order_details?.total_amount,
		...handleEnforcedTracking()
	};

	if (allUserDetail) {
		(options['name'] = data.user?.first_name),
			(options['email'] = data.user?.email),
			(options['mobile'] = data.user?.mobile);
	}

	tracking('Consumer Recognised', options);
};

const updateUser = (data) => {
	tracking('Consumer Updated', {
		checkout_id: data?.order_details?.checkout_id,
		name: data.formData.value?.first_name,
		email: data.formData.value?.email,
		merchant_id: data.order_details?.sub_merchant?.sub_merchant_id,
		is_sandbox: data.order_details?.sub_merchant?.sandbox == 'Y',
		merchant_name: data.order_details?.sub_merchant?.description,
		...handleEnforcedTracking()
	});
};

/* Can Make Payment related tracking */
const requestCanMakePayment = (data) => {
	tracking('Can Makepayment Requested', {
		checkout_id: data?.order_details?.checkout_id,
		order_id: data?.order_details?.order_id,
		amount: data?.order_details?.total_amount,
		merchant_id: data.order_details?.sub_merchant?.sub_merchant_id,
		is_sandbox: data.order_details?.sub_merchant?.sandbox == 'Y',
		merchant_name: data.order_details?.sub_merchant?.description,
		...handleEnforcedTracking()
	});
};

const receiveCanMakePayment = (data) => {
	tracking('Can Makepayment Received', {
		checkout_id: data?.order_details?.checkout_id,
		order_id: data?.order_details?.order_id,
		amount: data?.order_details?.total_amount,
		merchant_id: data.order_details?.sub_merchant?.sub_merchant_id,
		is_sandbox: data.order_details?.sub_merchant?.sandbox == 'Y',
		merchant_name: data.order_details?.sub_merchant?.description,
		status_canmakepayment: data.status,
		...handleEnforcedTracking()
	});
};

/* Order Details related tracking */

const openOrderDetails = (data) => {
	tracking('Order Details Opened', {
		checkout_id: data?.order_details?.checkout_id,
		order_id: data?.order_details?.order_id,
		amount: data?.order_details?.total_amount,
		merchant_id: data.order_details?.sub_merchant?.sub_merchant_id,
		is_sandbox: data.order_details?.sub_merchant?.sandbox == 'Y',
		merchant_name: data.order_details?.sub_merchant?.description,
		interaction_type: data.interaction_type,
		page: data.page,
		...handleEnforcedTracking()
	});
};

const closeOrderDetails = (data) => {
	tracking('Order Details Closed', {
		checkout_id: data?.order_details?.checkout_id,
		order_id: data?.order_details?.order_id,
		amount: data?.order_details?.total_amount,
		merchant_id: data.order_details?.sub_merchant?.sub_merchant_id,
		is_sandbox: data.order_details?.sub_merchant?.sandbox == 'Y',
		merchant_name: data.order_details?.sub_merchant?.description,
		interaction_type: data.interaction_type,
		...handleEnforcedTracking()
	});
};

/* Fast Payment related tracking */

const renderFastPayment = (data) => {
	const oneClickMethods = getOneClickMethods(data.allowedPaymentTypeArray);

	tracking('Fast Payments Rendered', {
		checkout_id: data?.order_details?.checkout_id,
		order_id: data?.order_details?.order_id,
		amount: data?.order_details?.total_amount,
		num_of_methods: data.allowedPaymentTypeArray.length,
		fast_method_names: data.trackerPaymentName,
		sub_merchant_id: data.order_details?.sub_merchant?.sub_merchant_id,
		is_sandbox: data.order_details?.sub_merchant?.sandbox == 'Y',
		merchant_name: data.order_details?.sub_merchant?.description || '',
		num_of_one_click: oneClickMethods.length,
		one_click_method_names: oneClickMethods,
		...handleEnforcedTracking()
	});
};

const openFastPayment = (data) => {
	tracking('Fast Payments Opened', {
		checkout_id: data?.order_details?.checkout_id,
		order_id: data?.order_details?.order_id,
		amount: data?.order_details?.total_amount,
		merchant_id: data.order_details?.sub_merchant?.sub_merchant_id,
		is_sandbox: data.order_details?.sub_merchant?.sandbox == 'Y',
		merchant_name: data.order_details?.sub_merchant?.description,
		interaction_type: data.interaction_type,
		...handleEnforcedTracking()
	});
};

const closeFastPayment = (data) => {
	tracking('Fast Payments Closed', {
		checkout_id: data?.order_details?.checkout_id,
		order_id: data?.order_details?.order_id,
		amount: data?.order_details?.total_amount,
		merchant_id: data.order_details?.sub_merchant?.sub_merchant_id,
		is_sandbox: data.order_details?.sub_merchant?.sandbox == 'Y',
		merchant_name: data.order_details?.sub_merchant?.description,
		interaction_type: data.interaction_type,
		...handleEnforcedTracking()
	});
};

/* Other Payment related tracking */

const renderOtherPayment = (data) => {
	tracking('Other Payments Rendered', {
		checkout_id: data?.order_details?.checkout_id,
		order_id: data?.order_details?.order_id,
		amount: data?.order_details?.total_amount,
		num_of_methods: data.payment_eta.length,
		other_method_names: data.payment_eta,
		sub_merchant_id: data.order_details?.sub_merchant?.sub_merchant_id,
		is_sandbox: data.order_details?.sub_merchant?.sandbox == 'Y',
		merchant_name: data.order_details?.sub_merchant?.description,
		...handleEnforcedTracking()
	});
};

const openOtherPayment = (data) => {
	tracking('Other Payments Opened', {
		checkout_id: data?.order_details?.checkout_id,
		order_id: data?.order_details?.order_id,
		amount: data?.order_details?.total_amount,
		sub_merchant_id: data.order_details?.sub_merchant?.sub_merchant_id,
		is_sandbox: data.order_details?.sub_merchant?.sandbox == 'Y',
		merchant_name: data.order_details?.sub_merchant?.description,
		interaction_type: data.interaction_type,
		...handleEnforcedTracking()
	});
};

const closeOtherPayment = (data) => {
	tracking('Other Payments Closed', {
		checkout_id: data?.order_details?.checkout_id,
		order_id: data?.order_details?.order_id,
		amount: data?.order_details?.total_amount,
		sub_merchant_id: data.order_details?.sub_merchant?.sub_merchant_id,
		is_sandbox: data.order_details?.sub_merchant?.sandbox == 'Y',
		merchant_name: data.order_details?.sub_merchant?.description,
		interaction_type: data.interaction_type,
		...handleEnforcedTracking()
	});
};

/* Payment Methods related tracking*/
const selectPaymentMethod = (data) => {
	let options = {
		checkout_id: data?.order_details?.checkout_id,
		order_id: data?.order_details?.order_id,
		amount: data?.order_details?.total_amount,
		payment_method: data.selected_payment_mode?.paymentMode,
		// payment_partner: data.selected_payment_mode?.payment_partner
		// 	? data.selected_payment_mode?.payment_partner
		// 	: 'direct',
		merchant_id: data.order_details?.sub_merchant?.sub_merchant_id,
		is_sandbox: data.order_details?.sub_merchant?.sandbox == 'Y',
		merchant_name: data.order_details?.sub_merchant?.description,
		...handleEnforcedTracking()
	};

	options = setFastPaymentMethod(options, data.selected_payment_mode);

	tracking('Payment Method Selected', options);
};

const enterPaymentDetail = (data) => {
	let options = {
		checkout_id: data?.order_details?.checkout_id,
		order_id: data?.order_details?.order_id,
		amount: data?.order_details?.total_amount,
		payment_method: data.selected_payment_mode?.paymentMode,
		payment_partner: data.selected_payment_mode?.payment_partner
			? data.selected_payment_mode?.payment_partner
			: '',
		merchant_name: data.order_details?.sub_merchant?.description,
		merchant_id: data.order_details?.sub_merchant?.sub_merchant_id,
		is_sandbox: data.order_details?.sub_merchant?.sandbox == 'Y',
		field_name: data?.field_name.replaceAll(' ', '_'),
		...handleEnforcedTracking()
	};

	options = setFastPaymentMethod(options, data.selected_payment_mode);
	if (
		data.selected_payment_mode.paymentMode == 'Credit Card' ||
		data.selected_payment_mode.paymentMode == 'Dedit Card' ||
		data.selected_payment_mode.paymentMode == 'prepaid Card'
	) {
		options['payment_method'] = data.card_type
			? data.card_type
			: data.selected_payment_mode.paymentMode;
		options['payment_sub_method'] = data.card_provider;
	}
	// console.log(options);
	tracking('Payment Details Entered', options);
};

const enterInvalidPaymentDetail = (data) => {
	let options = {
		checkout_id: data?.order_details?.checkout_id,
		order_id: data?.order_details?.order_id,
		amount: data?.order_details?.total_amount,
		payment_method: data.selected_payment_mode?.paymentMode,
		payment_partner: data.selected_payment_mode?.payment_partner
			? data.selected_payment_mode?.payment_partner
			: '',
		merchant_name: data.order_details?.sub_merchant?.description,
		merchant_id: data.order_details?.sub_merchant?.sub_merchant_id,
		is_sandbox: data.order_details?.sub_merchant?.sandbox == 'Y',
		field_name: data.field_name.replaceAll(' ', '_'),
		invalid_reason: data?.invalid_reason,
		...handleEnforcedTracking()
	};

	options = setFastPaymentMethod(options, data.selected_payment_mode);
	if (
		data.selected_payment_mode.paymentMode == 'Credit Card' ||
		data.selected_payment_mode.paymentMode == 'Dedit Card' ||
		data.selected_payment_mode.paymentMode == 'prepaid Card'
	) {
		options['payment_method'] = data.card_type
			? data.card_type
			: data.selected_payment_mode.paymentMode;
		options['payment_sub_method'] = data.card_provider;
	}
	// console.log(options);
	tracking('Payment Details Invalid', options);
};
const initiatePayment = (data, otp = false) => {
	let options = {
		checkout_id: data?.order_details?.checkout_id,
		order_id: data?.order_details?.order_id,
		amount: data?.order_details?.total_amount,
		merchant_id: data.order_details?.sub_merchant?.sub_merchant_id,
		payment_method: data.selected_payment_mode?.paymentMode,
		payment_partner: data.selected_payment_mode?.payment_partner
			? data.selected_payment_mode?.payment_partner
			: '',
		merchant_name: data.order_details?.sub_merchant?.description,
		is_sandbox: data.order_details?.sub_merchant?.sandbox == 'Y',
		...handleEnforcedTracking()
	};

	options = setFastPaymentMethod(options, data.selected_payment_mode);

	//#TODO: why with otp sub_merchant being passed and without otp submerchantId as merchant_id
	if (!otp) {
		options['payment_sub_method'] =
			data.selected_payment_mode?.paymentMode == 'Netbanking' ||
			data.selected_payment_mode?.paymentMode == 'Wallet'
				? data.bank_name
				: null;
		options['merchant_id'] = data.order_details?.sub_merchant?.sub_merchant_id;
		if (
			data.selected_payment_mode.paymentMode == 'Credit Card' ||
			data.selected_payment_mode.paymentMode == 'Dedit Card' ||
			data.selected_payment_mode.paymentMode == 'prepaid Card'
		) {
			options['payment_method'] = data.card_type;
			options['payment_sub_method'] = data.card_provider;
		}
	} else {
		options['merchant_id'] = data.order_details?.sub_merchant;
	}

	tracking('Payment Initiated', options);
};

const submitPayment = (data, isNBorWallet = false) => {
	let options = {
		checkout_id: data?.order_details?.checkout_id,
		order_id: data?.order_details?.order_id,
		payment_id: data.transaction_id,
		payment_method: data.selected_payment_mode?.paymentMode,
		payment_partner: data.selected_payment_mode?.payment_partner
			? data.selected_payment_mode?.payment_partner
			: '',
		merchant_id: data.order_details?.sub_merchant?.sub_merchant_id,
		is_sandbox: data.order_details?.sub_merchant?.sandbox == 'Y',
		merchant_name: data.order_details?.sub_merchant?.description,
		...handleEnforcedTracking()
	};

	options = setFastPaymentMethod(options, data.selected_payment_mode);
	options['merchant_id'] = data.order_details?.sub_merchant?.sub_merchant_id;
	if (isNBorWallet) {
		options['payment_sub_method'] =
			data.selected_payment_mode?.paymentMode == 'Netbanking' ||
			data.selected_payment_mode?.paymentMode == 'Wallet'
				? data.bank_name
				: null;
	}
	if (
		data.selected_payment_mode.paymentMode == 'Credit Card' ||
		data.selected_payment_mode.paymentMode == 'Dedit Card' ||
		data.selected_payment_mode.paymentMode == 'prepaid Card'
	) {
		options['payment_method'] = data.card_type;
		options['payment_sub_method'] = data.card_provider;
	}

	tracking('Payment Submitted', options);
};

const timeOutPayment = (data) => {
	let options = {
		checkout_id: data?.order_details?.checkout_id,
		amount: data?.order_details?.total_amount,
		order_id: data?.order_details?.order_id,
		payment_id: data.transaction_id,
		payment_method: data.selected_payment_mode?.paymentMode,
		payment_sub_method:
			data.selected_payment_mode?.paymentMode == 'Netbanking' ||
			data.selected_payment_mode?.paymentMode == 'Wallet'
				? data.bank_name
				: null,
		payment_partner: data.selected_payment_mode?.payment_partner
			? data.selected_payment_mode?.payment_partner
			: '',
		merchant_id: data.order_details?.sub_merchant?.sub_merchant_id,
		is_sandbox: data.order_details?.sub_merchant?.sandbox == 'Y',
		merchant_name: data.order_details?.sub_merchant?.description,
		...handleEnforcedTracking()
	};

	options = setFastPaymentMethod(options, data.selected_payment_mode);
	if (
		data.selected_payment_mode.paymentMode == 'Credit Card' ||
		data.selected_payment_mode.paymentMode == 'Dedit Card' ||
		data.selected_payment_mode.paymentMode == 'prepaid Card'
	) {
		options['payment_method'] = data.card_type
			? data.card_type
			: data.selected_payment_mode.paymentMode;
		options['payment_sub_method'] = data.card_provider;
	}

	if(data.time_taken){
		options["time_taken"] = data.time_taken;
	}

	tracking('Payment Timed Out', options);
};

const viewMoreFastPaymentClicked = (data) => {
	let options = {
		checkout_id: data?.order_details?.checkout_id,
		order_id: data?.order_details?.order_id,
		merchant_id: data.order_details?.sub_merchant?.sub_merchant_id,
		merchant_name: data.order_details?.sub_merchant?.description,
		amount: data?.order_details?.total_amount,
		...handleEnforcedTracking()
	};

	tracking('Fast Payments View More ', options);
};

const viewMoreOrderDetails = (data) => {
	let options = {
		checkout_id: data?.order_details?.checkout_id,
		order_id: data?.order_details?.order_id,
		merchant_id: data.order_details?.sub_merchant?.sub_merchant_id,
		merchant_name: data.order_details?.sub_merchant?.description,
		amount: data?.order_details?.total_amount,
		...handleEnforcedTracking()
	};

	tracking('Order Details View More ', options);
};


const cancelPaymentMethod = (data) => {
	let options = {
		checkout_id: data?.order_details?.checkout_id,
		order_id: data?.order_details?.order_id,
		amount: data?.order_details?.total_amount,
		payment_method: data.selected_payment_mode?.paymentMode,
		payment_sub_method:
			data.selected_payment_mode?.paymentMode == 'Netbanking' ||
			data.selected_payment_mode?.paymentMode == 'Wallet'
				? data.bank_name
				: null,
		payment_partner: data.selected_payment_mode?.payment_partner
			? data.selected_payment_mode?.payment_partner
			: '',
		merchant_id: data.order_details?.sub_merchant?.sub_merchant_id,
		is_sandbox: data.order_details?.sub_merchant?.sandbox == 'Y',
		merchant_name: data.order_details?.sub_merchant?.description,
		...handleEnforcedTracking()
	};

	options = setFastPaymentMethod(options, data.selected_payment_mode);
	if (
		data.selected_payment_mode.paymentMode == 'Credit Card' ||
		data.selected_payment_mode.paymentMode == 'Dedit Card' ||
		data.selected_payment_mode.paymentMode == 'prepaid Card'
	) {
		options['payment_method'] = data.card_type
			? data.card_type
			: data.selected_payment_mode.paymentMode;
		options['payment_sub_method'] = data.card_provider;
	}
	tracking('Payment Method', options);
};

const failPayment = (data, isNBorWallet = false) => {
	let options = {
		called_from: data.called_from,
		amount: data.order_details.total_amount,
		checkout_id: data?.order_details?.checkout_id,
		order_id: data?.order_details?.order_id,
		payment_id: data.transaction_id,
		payment_method: data.paymentMethodDetails?.paymentMode,
		payment_partner: data?.paymentMethodDetails?.payment_partner
			? data.paymentMethodDetails?.payment_partner
			: '',
		failure_reason: data.paymentErrorMessage ? data.paymentErrorMessage : data.failure_reason ,
		retry_allowed: data.showTryAgainButton,
		merchant_id: data.order_details?.sub_merchant?.sub_merchant_id,
		is_sandbox: data.order_details?.sub_merchant?.sandbox == 'Y',
		merchant_name: data.order_details?.sub_merchant?.description,
		nimbbl_error_code: data?.nimbbl_error_code ? data?.nimbbl_error_code : '' ,
		...handleEnforcedTracking()
	};

	if (isNBorWallet) {
		options['payment_sub_method'] =
			data.paymentMethodDetails?.paymentMode == 'Netbanking' ||
			data.paymentMethodDetails?.paymentMode == 'Wallet'
				? data.bank_name
				: null;
	}
	options = setFastPaymentMethod(options, data.paymentMethodDetails);
	if (
		data.paymentMethodDetails?.paymentMode == 'Credit Card' ||
		data.paymentMethodDetails?.paymentMode == 'Dedit Card' ||
		data.paymentMethodDetails?.paymentMode == 'Prepaid Card'
	) {
		options['payment_method'] = data.card_type;
		options['payment_sub_method'] = data.card_provider;
	}
	tracking('Payment Failed', options);
};

const successfulPayment = (data) => {
	let options = {
		checkout_id: data?.order_details?.checkout_id,
		order_id: data?.order_details?.order_id,
		amount: data?.order_details?.total_amount,
		payment_id: data.transaction_id,
		payment_method: data.paymentMethodDetails?.paymentMode,
		payment_sub_method:
			data.paymentMethodDetails?.paymentMode == 'Netbanking' ||
			data.paymentMethodDetails?.paymentMode == 'Wallet'
				? data.bank_name
				: null,
		payment_partner: data.paymentMethodDetails?.payment_partner
			? data.paymentMethodDetails?.payment_partner
			: '',
		merchant_id: data.order_details?.sub_merchant?.sub_merchant_id,
		is_sandbox: data.order_details?.sub_merchant?.sandbox == 'Y',
		merchant_name: data.order_details?.sub_merchant?.description,
		time_taken: data.time_taken,
		...handleEnforcedTracking()
	};
	// console.log('Payment Successful', data);
	options = setFastPaymentMethod(options, data.paymentMethodDetails);
	if (
		data.paymentMethodDetails?.paymentMode == 'Credit Card' ||
		data.paymentMethodDetails?.paymentMode == 'Dedit Card' ||
		data.paymentMethodDetails?.paymentMode == 'Prepaid Card'
	) {
		options['payment_method'] = data.card_type;
		options['payment_sub_method'] = data.card_provider;
	}
	tracking('Payment Successful', options);
};

const cancelPayment = (data) => {
	
	let options = {
		checkout_id: data?.order_details?.checkout_id,
		order_id: data?.order_details?.order_id,
		amount: data?.order_details?.total_amount,
		payment_method: data.selected_payment_mode?.paymentMode,
		payment_sub_method:
			data.selected_payment_mode?.paymentMode == 'Netbanking' ||
			data.selected_payment_mode?.paymentMode == 'Wallet'
				? data.bank_name
				: null,
		// payment_partner: data.selected_payment_mode?.payment_partner
			// ? data.selected_payment_mode?.payment_partner
			// : '',
		merchant_id: data.order_details?.sub_merchant?.sub_merchant_id,
		is_sandbox: data.order_details?.sub_merchant?.sandbox == 'Y',
		merchant_name: data.order_details?.sub_merchant?.description,
		...handleEnforcedTracking()
	};

	options = setFastPaymentMethod(options, data.selected_payment_mode);

	tracking('Payment Cancelled', options);
};

const merchantResponse = (data) => {
	const options = {
		checkout_id: data?.order_details?.checkout_id,
		order_id: data['nimbbl_order_id'] ? data['nimbbl_order_id'] : null,
		transaction_id: data['nimbbl_transaction_id'],
		signature: data['nimbbl_signature'],
		status: data['status'],
		...handleEnforcedTracking()
	};

	tracking('Merchant Response', options);
};

// intent app

const foundIntentApp = (data) => {
	tracking('Intent Apps Found', {
		checkout_id: data?.order_details?.checkout_id,
		order_id: data?.order_details?.order_id,
		amount: data?.order_details?.total_amount,
		sub_merchant_id: data.order_details?.sub_merchant?.sub_merchant_id,
		is_sandbox: data.order_details?.sub_merchant?.sandbox == 'Y',
		merchant_name: data.order_details?.sub_merchant?.description,
		num_of_intent_apps: data.intentList.length,
		intent_app_pack_names: data.intentPkgList,
		intent_app_names: data.intentList,
		...handleEnforcedTracking()
	});
};
const paymentIntermediateOpened = (data, isNBorWallet = false) => {
	let options = {
		checkout_id: data?.order_details?.checkout_id,
		order_id: data?.order_details?.order_id,
		payment_id: data.transaction_id,
		payment_method: data.selected_payment_mode?.paymentMode,
		payment_partner: data.selected_payment_mode?.payment_partner
			? data.selected_payment_mode?.payment_partner
			: '',
		merchant_id: data.order_details?.sub_merchant?.sub_merchant_id,
		is_sandbox: data.order_details?.sub_merchant?.sandbox == 'Y',
		merchant_name: data.order_details?.sub_merchant?.description,
		...handleEnforcedTracking()
	};

	options = setFastPaymentMethod(options, data.selected_payment_mode);
	options['merchant_id'] = data.order_details?.sub_merchant?.sub_merchant_id;
	if (isNBorWallet) {
		options['payment_sub_method'] =
			data.selected_payment_mode?.paymentMode == 'Netbanking' ||
			data.selected_payment_mode?.paymentMode == 'Wallet'
				? data.bank_name
				: null;
	}
	if (
		data.selected_payment_mode.paymentMode == 'Credit Card' ||
		data.selected_payment_mode.paymentMode == 'Dedit Card' ||
		data.selected_payment_mode.paymentMode == 'prepaid Card'
	) {
		options['payment_method'] = data.card_type;
		options['payment_sub_method'] = data.card_provider;
	}

	tracking('Payment Intermediate Opened', options);
};
const paymentIntermediateClosed = (data, isNBorWallet = false) => {
	let options = {
		checkout_id: data?.order_details?.checkout_id,
		order_id: data?.order_details?.order_id,
		payment_id: data.transaction_id,
		payment_method: data.selected_payment_mode?.paymentMode,
		payment_partner: data.selected_payment_mode?.payment_partner
			? data.selected_payment_mode?.payment_partner
			: '',
		merchant_id: data.order_details?.sub_merchant?.sub_merchant_id,
		is_sandbox: data.order_details?.sub_merchant?.sandbox == 'Y',
		merchant_name: data.order_details?.sub_merchant?.description,
		...handleEnforcedTracking()
	};

	options = setFastPaymentMethod(options, data.selected_payment_mode);
	options['merchant_id'] = data.order_details?.sub_merchant?.sub_merchant_id;
	if (isNBorWallet) {
		options['payment_sub_method'] =
			data.selected_payment_mode?.paymentMode == 'Netbanking' ||
			data.selected_payment_mode?.paymentMode == 'Wallet'
				? data.bank_name
				: null;
	}
	if (
		data.selected_payment_mode.paymentMode == 'Credit Card' ||
		data.selected_payment_mode.paymentMode == 'Dedit Card' ||
		data.selected_payment_mode.paymentMode == 'prepaid Card'
	) {
		options['payment_method'] = data.card_type;
		options['payment_sub_method'] = data.card_provider;
	}

	tracking('Payment Intermediate Closed', options);
};

const callBackResponse = (options) => {
	tracking('Callback Response', options);
}

const sdkStatusTracking = (sdkStatusoptions) => {
	tracking('SDK Status Handling', sdkStatusoptions);
}

const apiFailedError = (data) => {
	tracking('API Failure', {
		api_failure_reason: data?.failure_error,
		order_id: data?.order_id,
		api_name: data?.api_name,
	});
};

export default {
	/* Checkout */
	renderCheckout,
	checkoutOpen,
	checkoutClosed,
	/* Page */
	openPage,
	renderPage,
	/* OTP */
	enterMobile,
	resendOTP,
	enterOTP,
	/* User */
	registerUser,
	recogniseUser,
	updateUser,
	verifyUser,
	/* Can Make Payment */
	requestCanMakePayment,
	receiveCanMakePayment,
	/* Order Details */
	openOrderDetails,
	closeOrderDetails,
	/* Fast Payment */
	renderFastPayment,
	openFastPayment,
	closeFastPayment,
	/* Other Payment */
	renderOtherPayment,
	openOtherPayment,
	closeOtherPayment,
	/* Payment Methods */
	selectPaymentMethod,
	cancelPaymentMethod,
	/* Payment */
	enterPaymentDetail,
	enterInvalidPaymentDetail,
	initiatePayment,
	timeOutPayment,
	viewMoreFastPaymentClicked,
	viewMoreOrderDetails,
	submitPayment,
	failPayment,
	successfulPayment,
	cancelPayment,
	merchantResponse,
	// poup open and close event
	paymentIntermediateOpened,
	paymentIntermediateClosed,
	/* Intent */
	foundIntentApp,
	/* EGT DATA */
	egtPayload,
	egtCallError,
	reactNativeIntent,
	callBackResponse,
	sdkStatusTracking,
	updateOrderAPIPayload,
	apiFailedError
};

// utils
const getOneClickMethods = (data) => {
	return data.reduce((acc, { autoDebitFlowPossible, mode }) => {
		if (autoDebitFlowPossible) acc.push(mode);
		return acc;
	}, []);
};

export const getFastPaymentMethodName = (selected_payment_mode) => {
	let name = selected_payment_mode?.paymentMode || selected_payment_mode?.mode;

	if (name === 'savedUPI' || name === 'UPI') {
		let vpaId = selected_payment_mode?.savedVPAId || selected_payment_mode?.vpaId;
		name = `other_magic_${vpaId.split('@')[1]}`;
	}
	if (selected_payment_mode.is_intent) {
		return selected_payment_mode?.compareName + '_server_intent';
	}
	if (selected_payment_mode.is_magic_UPI) {
		return selected_payment_mode?.compareName + '_magic';
	}
	return name;
};

const setFastPaymentMethod = (options, selected_payment_mode) => {
	if (selected_payment_mode.isFastPayment) {
		options.fast_method = getFastPaymentMethodName(selected_payment_mode);
		return options;
	}
	return options;
};
