export const getCheckoutId = (order_id) => {
    let checkout_id;
    try {
        checkout_id = localStorage.getItem(`checkout_id--${order_id}`);
        let order = checkout_id && checkout_id.split('--')[0];
        const isSame = order === order_id;

        if (!isSame) {
            return order_id.concat('--1');
        }
    } catch {
        checkout_id = `cookies blocked--${order_id}`;
        let order = checkout_id && checkout_id.split('--')[0];
        const isSame = order === order_id;

        if (!isSame) {
            return checkout_id.concat('--1');
        }
    }

    return checkout_id
}

export const updateCheckoutId = (order_id) => {
    let checkout_id
    if (!order_id) return;
    try {
        checkout_id = localStorage.getItem(`checkout_id--${order_id}`);
        let order = checkout_id && checkout_id.split('--')[0];
        const isSame = order === order_id;

        if (isSame) {
            const id = (Number(checkout_id.split('--')[1]) + 1).toString();
            localStorage.setItem(`checkout_id--${order_id}`, order_id.concat(`--${id}`))
            return order_id.concat(`--${id}`);
        } else {
            localStorage.setItem(`checkout_id--${order_id}`, order_id.concat('--1'))

            return order_id.concat('--1');
        }
    } catch {
        checkout_id = `cookies blocked--${order_id}`;
        let order = checkout_id && checkout_id.split('--')[0];
        const isSame = order === order_id;

        if (isSame) {
            const id = (Number(checkout_id.split('--')[1]) + 1).toString();
            // localStorage.setItem(`checkout_id--${order_id}`, order_id.concat(`--${id}`))
            return checkout_id.concat(`--${id}`);
        } else {
            // localStorage.setItem(`checkout_id--${order_id}`, order_id.concat('--1'))

            return checkout_id.concat('--1');
        }
    }

}