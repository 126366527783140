import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { clear } from 'console';
import { CookieService } from 'ngx-cookie-service';
import { merchantDetails, orderDetails } from 'src/app/app.models';
import { ApiService } from 'src/app/services/api.service';
import { OrderDetailService } from 'src/app/services/order-detail.service';
import { PaymentModeService } from 'src/app/services/payment-mode.service';
import { SharedService } from 'src/app/services/shared.service';
import { CommonService } from 'src/app/services/common.service';

import tracking from '../../utils/tracking';

@Component({
  selector: 'app-payment-successful',
  templateUrl: './payment-successful.component.html',
  styleUrls: ['./payment-successful.component.css']
})
export class PaymentSuccessfulComponent implements OnInit {
  @Input() eta: number = null;
  selectedPaymentMode;
  paymentModeName;
  order_details: orderDetails;
  merchat_details: merchantDetails;
  magicUPIDisplayName: string;
  paymentName: string;
  constructor(private sharedPaymentService: PaymentModeService, private apiService: ApiService, private router: Router, private cookies: CookieService, private shredOrderService: OrderDetailService, private sharedService: SharedService, private commonService: CommonService,) {
    this.sharedPaymentService.sharedSelectedMode.subscribe(res => {
      this.selectedPaymentMode = res;
      this.apiService.fetchPaymentModesMessage().subscribe(res => {
        if (this.selectedPaymentMode['paymentMode'] != 'Netbanking' && (this.selectedPaymentMode['paymentMode'] != 'Credit Card'|| this.selectedPaymentMode['paymentMode'] != 'Debit Card' ||this.selectedPaymentMode['paymentMode'] != 'Prepaid Card' )&& this.selectedPaymentMode['paymentMode'] != 'UPI' && this.selectedPaymentMode['paymentMode'] != 'Wallet') {

          if (this.selectedPaymentMode && this.selectedPaymentMode?.paymentMode) {
            let modeName;
            if (this.selectedPaymentMode['is_intent']) {
                const paymentModeSubstr = this.selectedPaymentMode?.paymentMode.substring(0, this.selectedPaymentMode?.paymentMode).toLowerCase();
                modeName = res && res[paymentModeSubstr] && res[paymentModeSubstr]['modeName'];
            } else {
                modeName = res && res[this.selectedPaymentMode?.paymentMode.toLowerCase()] && res[this.selectedPaymentMode?.paymentMode.toLowerCase()]['modeName'];
            }
            this.paymentModeName = modeName || '';
          } else {
              this.paymentModeName = '';
          }
        
        }
        else {
          this.paymentModeName = this.selectedPaymentMode['paymentMode'];
        }
        this.magicUPIDisplayName = this.selectedPaymentMode.is_magic_UPI ? this.selectedPaymentMode['magicUPIDisplayName'] : '';

      });
    });
  }

  ngOnInit(): void {
    let interval = setInterval(() => {
      this.merchat_details = JSON.parse(JSON.stringify(this.shredOrderService.getMerchantDetail()));
      if (this.merchat_details['display_name']) {
        clearInterval(interval);

      }
    }, 1)

    this.paymentName = this.sharedService.getPaymentNameDetails();

  }
  closeWindow() {
    this.order_details = JSON.parse(JSON.stringify(this.shredOrderService.getOrderDetails()));
    let paymentResponse = this.shredOrderService.getPaymentResponse();

    this.commonService.getCallBackPayloadResponse(
      this.order_details, 
      paymentResponse['nimbbl_transaction_id']
    );

    let data = {
      "order_id": paymentResponse['nimbbl_order_id'] ? paymentResponse['nimbbl_order_id'] : null,
      "order_level": 6,
      "closed_reason": "payment success",
      "checkout_id": this.order_details['checkout_id']
    };
    this.apiService.updateOrderLevel(data).subscribe(res => {}, error => {});
  }
}
