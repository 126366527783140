import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { merchantDetails, orderDetails } from 'src/app/app.models';
import { ApiService } from 'src/app/services/api.service';
import { OrderDetailService } from 'src/app/services/order-detail.service';
import tracking from '../../utils/tracking';

@Component({
  selector: 'app-user-other-detail',
  templateUrl: './user-other-detail.component.html',
  styleUrls: ['./user-other-detail.component.css']
})
export class UserOtherDetailComponent implements OnInit {
  user_other_info_form: FormGroup;
  order_detail:orderDetails
  merchant_details:merchantDetails;
  mobileNumber;
  @Output() updateUserInforFormSubmit: EventEmitter<any> = new EventEmitter<any>();
  constructor(private fb: FormBuilder, public router: Router, public apiService: ApiService,private cookies:CookieService,private sharedOrderDetails:OrderDetailService) { }

  ngOnInit(): void {
    
    this.order_detail=JSON.parse(JSON.stringify(this.sharedOrderDetails.getOrderDetails()));
    this.merchant_details=JSON.parse(JSON.stringify(this.sharedOrderDetails.getMerchantDetail()));
    this.user_other_info_form=this.fb.group({
      first_name: [null, [Validators.required,Validators.pattern('^[a-zA-Z ]*$')]],
      email: [null, [Validators.required, Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$')]],
    });
    if(!this.merchant_details['email_id_required'])
    {
      this.user_other_info_form.controls['email'].clearValidators();
            this.user_other_info_form.controls['email'].updateValueAndValidity();
    }
  }

  updateUserInfo(formData)
  {
    formData['id']=this.order_detail['user']['id'];
    
    if(this.user_other_info_form.status=="VALID")
    {

      let name = formData.value['first_name'].split(' ');
      const firstName = name.slice(0, name.length - 1)?.join(' ')?name?.slice(0, name.length - 1).join(' '):name[0];
      const lastName = name.length > 1 ? name[name.length - 1] : '';
      formData.value['first_name'] = firstName;
      formData.value['last_name'] = lastName;

      formData['id']=this.order_detail['user']['id'];
      // window.analytics.identify({
      //   name:formData.value['first_name'],
      //   email:formData.value['email']
      // });

      tracking.updateUser({
        formData: formData,
        order_details: this.order_detail,
        merchant_details: this.merchant_details,
        order_id: this.order_detail?.order_id,
        amount: this.order_detail?.total_amount
      });
      this.updateUserInforFormSubmit.emit(formData.value);
    }
  }
}
