import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { merchantDetails, orderDetails } from 'src/app/app.models';
import { ApiService } from 'src/app/services/api.service';
import { OrderDetailService } from 'src/app/services/order-detail.service';
import { SharedService } from 'src/app/services/shared.service';

import tracking from '../../utils/tracking';

@Component({
	selector: 'app-order-summary',
	templateUrl: './order-summary.component.html',
	styleUrls: ['./order-summary.component.css'],
})
export class OrderSummaryComponent implements OnInit {
	order_details: orderDetails;
	showMore: boolean = false;
	order_id: string;
	@Input() isOPen: boolean = false;
	@Input() page = '';
	merchant_detail: merchantDetails;
	tokenDetails;
	currency;
	isOpenTriggered = false;
	constructor(
		public orderService: OrderDetailService,
		private apiService: ApiService,
		private cookies: CookieService,
		private activeRoute: ActivatedRoute,
		private sharedService: SharedService
	) {
		this.sharedService.sharedCurrency.subscribe((currency) => {
			this.currency = currency;
		});
		this.orderService.sharedOrderDetails.subscribe((order_data) => {
			this.order_details = JSON.parse(JSON.stringify(order_data));
			if (this.tokenDetails && this.order_details['order_line_item'] && !this.isOpenTriggered) {
				this.isOpenTriggered = true;
				this.openAccordian();
			}
		});
		this.sharedService.sharedTokenDetail.subscribe((access_tkn) => {
			this.tokenDetails = access_tkn;
			if (this.order_details['order_line_item'] && this.tokenDetails && !this.isOpenTriggered) {
				this.isOpenTriggered = true;

				this.openAccordian();
			}
		});
		this.orderService.sharedMerchantDetails.subscribe((merchat_data) => {
			this.merchant_detail = JSON.parse(JSON.stringify(merchat_data));
		});
		this.orderService.sharedOrderDetails.subscribe((res) => {
			this.order_details = JSON.parse(JSON.stringify(res));
		});
	}

	ngOnInit(): void {
		this.activeRoute.queryParams.subscribe((params) => {
			this.order_id = params['order_id'];
			this.registerEventListner();
		});
	}

	viewMore() {
		this.showMore = true;
		let card = document.getElementById('collapseOne');
		card.classList.add('all');

		tracking.viewMoreOrderDetails({
			order_details: this.order_details,
		});
	}

	registerEventListner() {
		setTimeout(() => {
			let loader = setInterval(() => {
				var firstCollapse = document.getElementById('collapseOneBtn');
				if (firstCollapse) {
					clearInterval(loader);
					firstCollapse.addEventListener('click', () => {
						var target = document.getElementById(
							firstCollapse.getAttribute('data-target').replace('#', '')
						);
						if (target.classList.contains('show')) {
							this.fireCloseEvent('manual');
						} else {
							this.fireOpenEvent('manual');
						}
					});
				}
			}, 100);
		}, 100);
	}

	fireOpenEvent(interaction_type) {
		let checkForMerchantDetails = setInterval(() => {
			if (this.merchant_detail['display_name']) {
				clearInterval(checkForMerchantDetails);

				tracking.openOrderDetails({
					order_details: this.order_details,
					merchant_details: this.merchant_detail,
					interaction_type: interaction_type || 'auto',
					page: this.page,
				});
			}
		}, 100);
	}
	fireCloseEvent(interaction_type) {
		let checkForMerchantDetails = setInterval(() => {
			if (this.merchant_detail['display_name']) {
				clearInterval(checkForMerchantDetails);

				tracking.closeOrderDetails({
					order_details: this.order_details,
					merchant_details: this.merchant_detail,
					interaction_type: interaction_type || 'auto',
					page: this.page,
				});
			}
		}, 100);
	}

	openAccordian() {
		let payment_mode = this.activeRoute.snapshot.queryParams['payment_mode'];
		let bank_name = this.activeRoute.snapshot.queryParams['bank_code'];
		let wallet_name = this.activeRoute.snapshot.queryParams['wallet_code'];
		let payment_flow = this.activeRoute.snapshot.queryParams['payment_flow'];
		let upi_id = this.activeRoute.snapshot.queryParams['upi_id'];
		let upi_app_code = this.activeRoute.snapshot.queryParams['upi_app_code'];
		if (this.order_details['order_line_item'].length && !this.isOPen) {
			this.isOPen = true;
			this.fireOpenEvent('auto');
		} else if (!this.order_details['order_line_item'].length && this.isOPen) {
			this.isOPen = false;
			this.fireCloseEvent('auto');
		}

		if (this.order_details['callback_mode'] == 'callback_handler') {
			this.cookies.set('is_modal', 'true', 365, '', '', true, 'None');
		} else {
		}
		if (payment_mode) {
			//Note:- 0.020833 denotes 30 mins
			this.cookies.set('isEnforced', 'true', 0.020833, '/', '', true, 'None');
			this.cookies.set('payment_mode', payment_mode, 0.020833, '/', '', true, 'None');
			this.cookies.set('selected_payment_name', '', 0.020833, '/', '', true, 'None');
		
			if (bank_name) {
				this.cookies.set('wallet_code', '', 0, '/', '', true, 'None');
				this.cookies.set('bank_code', bank_name, 0.020833, '/', '', true, 'None');
			} else if (wallet_name) {
				this.cookies.set('bank_code', '', 0, '/', '', true, 'None');
				this.cookies.set('wallet_code', wallet_name, 0.020833, '/', '', true, 'None');
			} else if (payment_mode === 'UPI') {
				this.cookies.set('bank_code', '', 0, '/', '', true, 'None');
				this.cookies.set('wallet_code', '', 0, '/', '', true, 'None');
				this.cookies.set('payment_flow', payment_flow, 0.020833, '/', '', true, 'None');
		
				if (payment_flow === 'intent') {
					this.cookies.set('upi_app_code', upi_app_code, 0.020833, '/', '', true, 'None');
					this.cookies.set('upi_id', '', 0, '/', '', true, 'None');
				} else if (upi_id) {
					this.cookies.set('upi_app_code', '', 0, '/', '', true, 'None');
					this.cookies.set('upi_id', upi_id, 0.020833, '/', '', true, 'None');
				} else {
					this.cookies.set('upi_app_code', '', 0, '/', '', true, 'None');
					this.cookies.set('upi_id', '', 0, '/', '', true, 'None');
				}
			} else {
				this.cookies.set('wallet_code', '', 0, '/', '', true, 'None');
				this.cookies.set('bank_code', '', 0, '/', '', true, 'None');
				this.cookies.set('payment_flow', '', 0, '/', '', true, 'None');
				this.cookies.set('upi_app_code', '', 0, '/', '', true, 'None');
				this.cookies.set('upi_id', '', 0, '/', '', true, 'None');
			}
		} else {
			this.cookies.set('isEnforced', '', 0, '/', '', true, 'None');
			this.cookies.set('payment_mode', '', 0, '/', '', true, 'None');
			this.cookies.set('selected_payment_name', '', 0.020833, '/', '', true, 'None');
			this.cookies.set('wallet_code', '', 0, '/', '', true, 'None');
			this.cookies.set('bank_code', '', 0, '/', '', true, 'None');
			this.cookies.set('payment_flow', '', 0, '/', '', true, 'None');
			this.cookies.set('upi_app_code', '', 0, '/', '', true, 'None');
			this.cookies.set('upi_id', '', 0, '/', '', true, 'None');
		}
		
	}
}
