import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { merchantDetails, orderDetails } from 'src/app/app.models';
import { ApiService } from 'src/app/services/api.service';
import { OrderDetailService } from 'src/app/services/order-detail.service';
import { PaymentModeService } from 'src/app/services/payment-mode.service';
import { SharedService } from 'src/app/services/shared.service';

@Component({
	selector: 'app-money-deduction-confirmation',
	templateUrl: './money-deduction-confirmation.component.html',
	styleUrls: ['./money-deduction-confirmation.component.css'],
})
export class MoneyDeductionConfirmationComponent implements OnInit {
	selectedPaymentMode;
	isDelayedTransactionAllowed: boolean = false;
	displayMessage: boolean = false;
	@Output() displayErrorScreen: EventEmitter<any> = new EventEmitter<any>();
	@Output() displayTimeOutMessage: EventEmitter<any> = new EventEmitter<any>();
	userEmail;
	order_details: orderDetails;
	merchant_details: merchantDetails;
	merchant_name: string;
	paymentModeName;
	magicUPIDisplayName: string;
	paymentName: string;
	constructor(
		private sharedPaymentService: PaymentModeService,
		private apiService: ApiService,
		private cookies: CookieService,
		private sharedOrderDetail: OrderDetailService,
		private sharedService: SharedService,
		public orderService: OrderDetailService
	) {
		this.sharedPaymentService.sharedSelectedMode.subscribe((res) => {
			this.selectedPaymentMode = res;

			this.apiService.fetchPaymentModesMessage().subscribe((responseModes) => {
				if (this.selectedPaymentMode['paymentMode'].toLocaleLowerCase().includes('card')) {
					this.paymentModeName = this.sharedService.getCardType();
				} else {
					if (responseModes && responseModes[this.selectedPaymentMode?.paymentMode.toLowerCase()]) {
						this.paymentModeName =
							responseModes[this.selectedPaymentMode?.paymentMode.toLowerCase()]['modeName'];
					} else {
						this.paymentModeName = '';
					}
				}
				this.magicUPIDisplayName = this.selectedPaymentMode.is_magic_UPI
					? this.selectedPaymentMode['magicUPIDisplayName']
					: '';
			});
		});
	}

	ngOnInit(): void {
		this.order_details = JSON.parse(JSON.stringify(this.sharedOrderDetail.getOrderDetails()));
		this.userEmail = this.order_details['user']['email'];

		this.orderService.sharedMerchantDetails.subscribe((merchat_data) => {
			this.merchant_details = JSON.parse(JSON.stringify(merchat_data));
		});

		this.merchant_name = this.merchant_details.display_name
			? this.merchant_details.display_name
			: 'Merchant';

		this.paymentName = this.sharedService.getPaymentNameDetails();
		this.sharedService.updateEnforcePaymentInitiated(false);
	}
	showErrorMessage() {
		this.displayErrorScreen.emit();
	}
	showTimeOutMessage() {
		this.displayMessage = true;
	}
	timeoutError() {
		this.displayTimeOutMessage.emit();
	}
}
