<div class="box-accor">
  <div class="box-item">
    <div *ngIf="!displayMessage"></div>
    <div class="pt-1">
      <div class="col align-self-center">
        <div class="box-date-details">
          <div class="row">
            <div class="col-auto p-0">
              <img
                [src]="selectedPaymentMode?.logo"
                class="img-fluid"
                [ngClass]="
                  selectedPaymentMode.vpa_provider == 'bhim' ||
                  selectedPaymentMode.paymentMode == 'BHIM'
                    ? 'bhim'
                    : ''
                "
              />
            </div>
            <div class="col px-2 fS-18">
              <strong
                *ngIf="
                  paymentModeName != 'Credit Card' &&
                  paymentModeName != 'Debit Card' &&
                  paymentModeName != 'Prepaid Card'
                "
                >Pay with {{ paymentName || magicUPIDisplayName || paymentModeName }}</strong
              >
              <strong
                *ngIf="
                  paymentModeName == 'Credit Card' ||
                  paymentModeName == 'Debit Card' ||
                  paymentModeName == 'Prepaid Card'
                "
                >Pay with {{ paymentModeName || "Card" }}</strong
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="!displayMessage">
      <div class="fS-15">
        <div class="">
          <p class="sectionHeading">
            Was any money deducted from your account?
          </p>
          <p class="subText">
            We were unable to receive a response from bank/card/ payment method.
          </p>
        </div>
      </div>
      <div class="fs-15">
        <button
          type="button"
          class="btn btn-outline-dark me-2 mr-3"
          (click)="showTimeOutMessage()"
        >
          Yes
        </button>
        <button
          type="button"
          class="btn btn-outline-dark me-2 mr-3"
          (click)="timeoutError()"
        >
          No
        </button>
      </div>
    </div>

    <div *ngIf="displayMessage" class="fS-15 mt-3">
      <div *ngIf="isDelayedTransactionAllowed" class="text">
        <p>We will keep checking the status with your bank /card/ payment method
        every 15 minutes and process your payment automatically.</p>
        <p>Please reach out to {{ merchant_name }} for more details.</p>
      </div>
      <div *ngIf="!isDelayedTransactionAllowed" class="text">
        <p class="mb-1">We will keep checking the status with your bank /card/ payment method
        every 15 minutes and refund your payment automatically.</p>
        <p class="mb-1">Refunds take between 2 - 4 business days to process, Please reach out to {{ merchant_name }} for more details after this period.</p>

        </div>
      <!-- <div class="fs-15">
        <p class="label">We will keep you updated on</p>
        <input type="text" readonly [value]="userEmail" autocomplete="off" />
      </div> -->
      <div class="mt-2 fs-15">
        <button
          type="button"
          class="btn btn-primary btn-orange me-2 mr-3 no-btn"
          (click)="showErrorMessage()"
        >
          Ok
        </button>
      </div>
    </div>
  </div>
</div>
