import { Component, OnInit, HostListener } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import {
	allowedPaymentPartnersRequest,
	merchantDetails,
	orderDetails,
	resendOTPRequest,
	user,
	initPaymentRequest,
} from 'src/app/app.models';
import { ApiService } from 'src/app/services/api.service';
import { OrderDetailService } from 'src/app/services/order-detail.service';
import { PaymentModeService } from 'src/app/services/payment-mode.service';
import { SharedService } from 'src/app/services/shared.service';
import { CommonService } from 'src/app/services/common.service';
import { environment } from 'src/environments/environment';
import tracking from '../../utils/tracking';
import { updateCheckoutId } from '../../utils/checkoutId';
import { isMobile } from '../../utils/device.js';

@Component({
	selector: 'app-verify-otp',
	templateUrl: './verify-otp.component.html',
	styleUrls: ['./verify-otp.component.css'],
})
export class VerifyOtpComponent implements OnInit {
	nimbbl_user_name: string = '';
	nimbbl_user_mobile;
	ipAddress;
	otpErrorMessage;
	isLoader: boolean = false;
	user: user;
	userFounOnDevice: boolean = false;
	showPopup: boolean = false;
	orderDetail: orderDetails;
	merchant_details: merchantDetails;
	gpayRequest: any;
	showGpayButton;
	canMakePaymentCache = 'canMakePaymentCache';
	pa;
	pn;
	tn;
	mc;
	tr;
	url;
	cu;
	amount;
	isAPiInvoked: boolean = false;
	constructor(
		private apiService: ApiService,
		public router: Router,
		private cookies: CookieService,
		private orderDetailService: OrderDetailService,
		private sharedPaymentservice: PaymentModeService,
		private activeRoute: ActivatedRoute,
		private sharedService: SharedService,
		private commonService: CommonService
	) {}

	ngOnInit(): void {
		setTimeout(() => {
			document.querySelector('.top_bg').classList.add('otp');
		}, 500);
		// this.getIP();
		this.merchant_details = JSON.parse(JSON.stringify(this.orderDetailService.getMerchantDetail()));
		this.orderDetail = JSON.parse(JSON.stringify(this.orderDetailService.getOrderDetails()));
		this.user = this.orderDetail['user'];

		tracking.openPage({
			page_name: 'Verify OTP',
			order_details: this.orderDetail,
			merchant_details: this.merchant_details,
		});

		if (this.user) {
			this.nimbbl_user_name = this.user['first_name'];
			this.nimbbl_user_mobile = this.user['mobile_number'];
			let chars = this.nimbbl_user_mobile.split('');
			for (let index = 2; index < 8; index++) {
				chars[index] = 'x';
			}
			this.nimbbl_user_mobile = chars?.join('');
		} else if (
			this.cookies.get('is_popup') !== 'true' &&
			!this.cookies.get('no_refresh') &&
			this.cookies.get('pagerefresh')
		) {
			// to handle refresh checkout. here, `no_refresh` cookie is for CC/DC
			this.activeRoute.queryParams.subscribe((params) => {
				if (params['order_id']) {
					this.cookies.delete('pagerefresh', '/');
					this.cookies.delete('no_refresh', '/');
					this.cookies.delete('pagerefresh', '/payment'); //#TODO: rectify it. currently in preprod same cookie store in two diff path `/` and `/payment`
					this.cookies.delete('no_refresh', '/payment'); //#TODO: rectify it. currently in preprod same cookie store in two diff path `/` and `/payment`

					let options = { order_id: params['order_id'] };
					if (params['transaction_token']) {
						options['transaction_token'] = params['transaction_token'];
					} else {
						options['expires'] = this.sharedService.getTokenExpiryTime();
					}
					this.router.navigate(['/'], { queryParams: options });
				}
			});
		}
	}

	verifyOtp(event) {
		this.isLoader = true;
		this.orderDetail = JSON.parse(JSON.stringify(this.orderDetailService.getOrderDetails()));
		let otpData = {
			mobile_number: this.user['mobile_number'],
			user_agent: window.navigator.userAgent,
			last_accessed_from_ip: this.ipAddress,
			otp: event,
			order_id: this.orderDetail['order_id'],
			// otp: parseInt(event)
		};
		let validateToken = this.apiService.checkForValidToken();
		if (!validateToken) {
			this.apiService
				.getEncryptedAuthorizationToken(this.orderDetail['order_id'])
				.subscribe((resp) => {
					this.apiService.setTokenCookies(resp['token'], btoa(resp['expires_at']));
					this.apiService.verifyOTP(otpData).subscribe(
						(resp) => {
							this.user = resp['item'];
							/* window.analytics.identify(this.user['user_id'], {
								is_verified: 'yes',
							}); */
							if (resp['item']['token']) {
								this.sharedService.updateAuthToken(resp['item']['token']);
							}
							tracking.verifyUser({
								user: this.user,
								order_details: this.orderDetail,
								merchant_details: this.merchant_details,
							});
							let userData = {
								mobile: this.user['mobile_number'],
								name: this.user['first_name'],
								email: this.user['email'],
								id: this.user['id'],
							};

							// this.checkGpayApi();
							setTimeout(() => {
								this.commonService.setPaymentModes(false, () => {
									setTimeout(() => {
										document.querySelector('.top_bg').classList.remove('otp');
									}, 1000);
									setTimeout(() => {
										let options = { order_id: this.orderDetail['order_id'] };
										let transaction_token =
											this.activeRoute.snapshot.queryParams['transaction_token'];
										if (transaction_token) {
											options['transaction_token'] = transaction_token;
										} else {
											options['expires'] = this.sharedService.getTokenExpiryTime();
										}
										this.router.navigate(['payment'], { queryParams: options });
									}, 2000);
								});
							}, 700);
						},
						(error) => {
							this.isLoader = false;
							this.otpErrorMessage = 'Please enter vaild OTP code';
						}
					);
				});
		} else {
			this.apiService.verifyOTP(otpData).subscribe(
				(resp) => {
					this.user = resp['item'];
					this.orderDetail = JSON.parse(JSON.stringify(this.orderDetailService.getOrderDetails()));
					/* window.analytics.identify(this.user['user_id'], {
						is_verified: 'yes',
					}); */
					if (resp['item']['token']) {
						this.sharedService.updateAuthToken(resp['item']['token']);
					}
					tracking.verifyUser({
						user: this.user,
						order_details: this.orderDetail,
						merchant_details: this.merchant_details,
					});

					// this.checkGpayApi();
					setTimeout(() => {
						this.commonService.setPaymentModes(true, () => {
							setTimeout(() => {
								document.querySelector('.top_bg').classList.remove('otp');
							}, 1000);
							setTimeout(() => {
								let options = { order_id: this.orderDetail['order_id'] };
								let transaction_token = this.activeRoute.snapshot.queryParams['transaction_token'];
								if (transaction_token) {
									options['transaction_token'] = transaction_token;
								} else {
									options['expires'] = this.sharedService.getTokenExpiryTime();
								}
								this.router.navigate(['payment'], { queryParams: options });
							}, 2000);
						});
					}, 700);
				},
				(error) => {
					// console.log(error);
					this.isLoader = false;
					if (error) {
						this.otpErrorMessage = error?.error?.error?.message;
					}
				}
			);
		}
	}
	getIP() {

	  let ip;
	  this.apiService.getIpAddreess().subscribe(resp => {
	    this.ipAddress = resp['ip'];
	    return ip
	  })
	}

	// to show built in customization dialog on page refresh
	@HostListener('window:beforeunload')
	beforeUnloadHandler() {
		if (!this.cookies.get('no_refresh')) {
			updateCheckoutId(this.orderDetail['order_id']);
		}

		if (this.cookies.get('is_popup') === 'false' && !this.cookies.get('no_refresh')) {
			this.cookies.set('pagerefresh', 'true', undefined, '/');
			return false;
		}
	}

	setPaymentModes(validateToken, fn = null) {
		if (!this.orderDetail) return;
		let rr = new allowedPaymentPartnersRequest();
		rr.order_id = this.orderDetail['order_id'];
		let allowedPaymentTypeArray;
		let allowedModes = [];
		let fastPaymentModes = [];
		let otherPaymentModes = [];

		this.apiService.loadAllowedPaymentTypes(rr).subscribe((res) => {
			allowedPaymentTypeArray = res;
			let paymentModeDetails;
			this.apiService.fetchPaymentModesMessage().subscribe(
				(res) => {
					paymentModeDetails = res;
					allowedPaymentTypeArray.forEach((element) => {
						let data;
						if (paymentModeDetails[element['payment_mode'].toLowerCase()]) {
							data = {
								mode:
									element['extra_info']['user_vpa'] || element['extra_info']['vpa_id']
										? 'savedUPI'
										: element['payment_mode'],
								eta: element['extra_info']['eta_completion'],
								savedVPA: element['extra_info']['user_vpa']
									? element['extra_info']['user_vpa'].toLowerCase()
									: '',
								savedVPAId: element['extra_info']['vpa_id']
									? element['extra_info']['vpa_id'].toLowerCase()
									: '',
								logo: paymentModeDetails[element['payment_mode'].toLowerCase()]['logo'],
								desc: paymentModeDetails[element['payment_mode'].toLowerCase()][
									'listPagedescription'
								],
								payment_partner: element['extra_info']['partner_name']
									? element['extra_info']['partner_name']
									: '',
								ConvenienceFees: element?.extra_info?.additional_charges
									? element?.extra_info?.additional_charges
									: 0,
								autoDebitFlowPossible: element['extra_info']['auto_debit_flow_possible']
									? element['extra_info']['auto_debit_flow_possible']
									: false,
							};
							if (
								element['payment_mode'] == 'Simpl' ||
								element['payment_mode'] == 'PayTM' ||
								element['payment_mode'] == 'Lazypay' ||
								element['payment_mode'] == 'Phonepe' ||
								element['payment_mode'] == 'Google Pay' ||
								(validateToken &&
									(element['payment_mode'] == 'Cred' ||
										element['payment_mode'] == 'ICICI PayLater')) ||
								(element['payment_mode'] == 'UPI' &&
									(element['extra_info']['user_vpa'] || element['extra_info']['vpa_id']))
							) {
								if (element['payment_mode'] == 'Google Pay' && !this.showGpayButton) {
								} else {
									fastPaymentModes.push(data);
								}
							} else {
								otherPaymentModes.push(data);
							}
						}
						allowedModes.push(element['payment_mode']);
					});

					this.sharedPaymentservice.updatePaymentMode(allowedModes);
					// this.showLoader = false;

					this.sharedPaymentservice.updateAllowedPaymentTypeArray(allowedPaymentTypeArray);
					this.sharedPaymentservice.updateOtherPaymentMode(otherPaymentModes);
					this.sharedPaymentservice.updateFastPaymentMode(fastPaymentModes);

					if (fastPaymentModes.length && this.merchant_details?.fast_payment_enabled) {
						this.sharedService.updateCollapsibleStatus({
							...this.sharedService.getCollapsibleStatus(),
							fastPaymentOption: true,
							otherPaymentOption: false,
						});
					} else {
						this.sharedService.updateCollapsibleStatus({
							...this.sharedService.getCollapsibleStatus(),
							fastPaymentOption: false,
							otherPaymentOption: true,
						});
					}
					fn();
				},
				(error) => {
					alert(error['message']);
				}
			);
		});
	}

	// googlepay can make payment
	readSupportedInstruments() {
		let formValue = {};
		formValue['pa'] = this.pa;
		formValue['pn'] = this.pn == '' ? 'logicloop' : this.pn;
		formValue['tn'] = this.tn;
		formValue['mc'] = this.mc;
		formValue['tr'] = this.tr;
		formValue['url'] = this.url;
		return formValue;
	}

	onBuyClicked() {
		if (!window.PaymentRequest || !isMobile()) {
			//Web payments are not supported in this browser.
			this.showGpayButton = false;
			this.sharedPaymentservice.updateGpayVisibility(this.showGpayButton);
			return;
		}

		let formValue = this.readSupportedInstruments();
		// console.log(formValue);
		const supportedInstruments = [
			{
				supportedMethods: ['https://tez.google.com/pay'],
				data: formValue,
			},
		];
		// console.log(supportedInstruments);
		const details = {
			total: {
				label: 'Total',
				amount: {
					currency: this.cu,
					value: this.amount,
				},
			},
			displayItems: [
				{
					label: 'Original amount',
					amount: {
						currency: this.cu,
						value: this.amount,
					},
				},
			],
		};

		let request = null;
		try {
			request = new PaymentRequest(supportedInstruments, details);
		} catch (e) {
			// console.log('Payment Request Error: ' + e.message);
			return;
		}
		if (!request) {
			// console.log('Web payments are not supported in this browser.');
			return;
		}

		var canMakePaymentPromise = this.checkCanMakePayment(request);
		canMakePaymentPromise
			.then((result) => {
				this.showPaymentUI(request, result);
			})
			.catch((err) => {
				// console.log(err);
				// throwError(err);
			});
	}

	showPaymentUI(request, canMakePayment) {
		this.gpayRequest = request;
		// console.log(request);
		// Redirect to play store if can't make payment.
		if (!canMakePayment) {
			this.showGpayButton = false;
			this.sharedPaymentservice.updateGpayVisibility(this.showGpayButton);

			// return;
		} else {
			this.showGpayButton = true;
			this.sharedPaymentservice.updateGpayVisibility(this.showGpayButton);
		}

		tracking.receiveCanMakePayment({
			order_details: this.orderDetail,
			merchant_details: this.merchant_details,
			status: this.showGpayButton,
		});
	}

	//  checkGpayApi() {
	//     if (this.orderDetail) {

	//       tracking.requestCanMakePayment({
	//         order_details: this.orderDetail,
	//         merchant_details: this.merchant_details
	//       })

	//       this.isAPiInvoked = true
	//       let rr = new initPaymentRequest();
	//       rr.order_id = this.orderDetail['order_id'];
	//       rr.payment_mode = 'Google Pay';
	//       rr.canMakePayment = true;
	//       // rr.payment_mode_id = this.paymentMethodDetails['mode_id'];
	//       let validateToken = this.apiService.checkForValidToken();

	//       if (!validateToken) {
	//         // console.log('InitPayment API ForGpay');
	//         this.apiService.getEncryptedAuthorizationToken(this.orderDetail['order_id']).subscribe(resp => {
	//           this.apiService.setTokenCookies(resp['token'], btoa(resp['expires_at']));

	//           // this.cookies.set('accessToken', resp['token'],365,'','',true,"None");
	//           // this.cookies.set('token_expiry',btoa(resp['expires_at']),365,'','',true,'None');

	//           this.apiService.initiatePayment(rr).subscribe(res => {
	//             // console.log(res);
	//             this.amount = res['extra_info']['data']['am'] ? res['extra_info']['data']['am'] : res['extra_info']['data']['amount'];
	//             this.cu = res['extra_info']['data']['cu'] ? res['extra_info']['data']['cu'] : res['extra_info']['data']['currency'];
	//             this.pa = res['extra_info']['data']['pa'] ? res['extra_info']['data']['pa'] : 'payumoney@hdfcbank';
	//             this.pn = res['extra_info']['data']['pn'];
	//             this.mc = '1234';
	//             this.tr = res['extra_info']['data']['tr'] ? res['extra_info']['data']['tr'] : res['extra_info']['data']['transaction_id'];
	//             this.tn = res['extra_info']['data']['tn'] ? res['extra_info']['data']['tn'] : 'UPI Transaction for ' + res['extra_info']['data']['transaction_id'];

	//             this.url = 'https://uatshop.nimbbl.tech/?order_id=' + rr.order_id;
	//             this.onBuyClicked()
	//           }, error => {
	//             // console.log(error);
	//             this.showGpayButton=false;
	//             this.sharedPaymentservice.updateGpayVisibility(this.showGpayButton);

	//           },
	//           ()=> {
	//             if (this.showGpayButton === undefined) {
	//               this.showGpayButton = false;
	//               this.sharedPaymentservice.updateGpayVisibility(this.showGpayButton);
	//               }
	//             }
	//           );

	//         })
	//       }
	//       else {
	//         this.apiService.initiatePayment(rr).subscribe(res => {
	//           // console.log(res);
	//           this.amount = res['extra_info']['data']['am'] ? res['extra_info']['data']['am'] : res['extra_info']['data']['amount'];
	//           this.cu = res['extra_info']['data']['cu'] ? res['extra_info']['data']['cu'] : res['extra_info']['data']['currency'];
	//           this.pa = res['extra_info']['data']['pa'] ? res['extra_info']['data']['pa'] : 'payumoney@hdfcbank';
	//           this.pn = res['extra_info']['data']['pn'];
	//           this.mc = '1234';
	//           this.tr = res['extra_info']['data']['tr'] ? res['extra_info']['data']['tr'] : res['extra_info']['data']['transaction_id'];
	//           this.tn = res['extra_info']['data']['tn'] ? res['extra_info']['data']['tn'] : 'UPI Transaction for ' + res['extra_info']['data']['transaction_id'];

	//           this.url = 'https://uatshop.nimbbl.tech/?order_id=' + rr.order_id;
	//           this.onBuyClicked()
	//         }, error => {
	//           // console.log(error);
	//           this.showGpayButton=false;
	//           this.sharedPaymentservice.updateGpayVisibility(this.showGpayButton);
	//         },
	//         ()=> {
	//           if(this.showGpayButton === undefined) {
	//             this.showGpayButton = false;
	//             this.sharedPaymentservice.updateGpayVisibility(this.showGpayButton);
	//           }
	//       });
	//       }
	//     }

	// }

	checkCanMakePayment(request) {
		// Checks canMakePayment cache, and use the cache result if it exists.
		if (sessionStorage.hasOwnProperty(this.canMakePaymentCache)) {
			return Promise.resolve(JSON.parse(sessionStorage[this.canMakePaymentCache]));
		}

		// If canMakePayment() isn't available, default to assuming that the method is
		// supported.
		var canMakePaymentPromise = Promise.resolve(true);

		// Feature detect canMakePayment().
		if (request.canMakePayment) {
			canMakePaymentPromise = request.canMakePayment();
		}

		return canMakePaymentPromise
			.then((result) => {
				// Store the result in cache for future usage.
				sessionStorage[this.canMakePaymentCache] = result;
				return result;
			})
			.catch((err) => {
				// console.log('Error calling canMakePayment: ' + err);
			});
	}

	ngAfterViewInit(): void {
		tracking.renderPage({
			page_name: 'Verify OTP',
			order_details: this.orderDetail,
			merchant_details: this.merchant_details,
		});
	}
}
