<div class="merchantInfo">
  <div class="wc_logo">
    <img
      [src]="merchant_detail?.logo_file_url"
      alt="logo images"
      *ngIf="merchant_detail?.logo_file_url"
    />
    <span class="wc_logo_name" *ngIf="merchant_detail?.display_name">{{
      merchant_detail.display_name
    }}</span>
  </div>
  <div
    id="confirmationClose"
    class="close_icon"
    (click)="showConfirmationMessage()"
  >
    <img width="30" height="30" src="./assets/images/icons/close.png" alt="Close" />
  </div>
</div>

<div class="closeBox" *ngIf="showPopup">
  <div class="confirmationBox">
    <div class="header">{{ headerText }}</div>
    <div class="popupBody">
      <div *ngIf="!displayResons">
        <button
          type="button"
          class="btn btn-outline-dark yesBtn me-2 mr-3"
          (click)="showReason()"
        >
          Yes
        </button>
        <button
          type="button"
          class="btn btn-outline-dark no-btn"
          id="yesBtn"
          (click)="closeBox()"
        >
          No
        </button>
      </div>

      <div *ngIf="displayResons">
        <label
          *ngFor="let reason of reasons; let i = index; let last = last"
          for="reason+{{ i }}"
          class="radioContainer"
        >
          {{ reason }}

          <input
            type="radio"
            name="reson"
            id="reason+{{ i }}"
            class="customradio"
            [value]="reason"
            [(ngModel)]="selectedReason"
            checked="last?'last':''"
          />
          <span class="radiomark"></span>
        </label>
        <div class="text-right">
          <button
            type="button"
            class="btn btn-outline-dark yesBtn me-2 mr-3"
            id="cancelBtn"
            (click)="closeModal()"
          >
            Cancel
          </button>
          <button
            type="button"
            class="btn btn-primary btn-orange no-btn"
            id="okBtn"
            (click)="submitReason()"
            [disabled]="!selectedReason"
          >
            OK
          </button>
        </div>
      </div>
    </div>
    <div class="closeBox" *ngIf="showConfirmationModal">
      <div class="timeoutBox confirmationBox">
        <div class="popupBody">
          <div class="timeoutBoxHeader">
            <span class="timeoutBoxClockIcon"></span>
            <p>We have noticed inactivity on checkout for a long time.</p>
          </div>
          <div class="timeoutBoxTimerContainer" *ngIf="seconds > 0">
            <div class="timeoutBoxTimer">
              <div class="circle-wrap">
                <div class="circle">
                  <div class="mask full">
                    <div class="fill"></div>
                  </div>

                  <div class="mask half">
                    <div class="fill"></div>
                  </div>

                  <div class="inside-circle">
                    00:<span class="sec">{{
                      seconds < 10 ? "0" + seconds : seconds
                    }}</span>
                  </div>
                </div>
              </div>
            </div>
            <p class="timeoutBoxTimerTitle">
              Please continue with your transaction before timeout
            </p>
          </div>

          <div class="timeoutBoxButtonContainer">
            <div class="timeoutBoxButtonTitle">Would you like to continue?</div>
            <div class="timeoutBoxButtons" *ngIf="!displayResons">
              <button
                type="button"
                class="btn btn-outline-dark yesBtn me-2 mr-3"
                (click)="redirectToHomePage()"
              >
                Yes
              </button>
              <button
                type="button"
                class="btn btn-outline-dark"
                id="noBtn"
                (click)="showReasonPopup()"
              >
                No
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
