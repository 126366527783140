<div class="accordion offer-accordion" id="offersCard" *ngIf="isOfferApplicable">
    <div class="card">
      <div class="card-header" id="offersHeading">
        <button class="btn btn-link btn-block text-left" [ngClass]="isOfferOpen ? '' : 'collapsed'" type="button" data-toggle="collapse" data-target="#offersOneCollapse" aria-expanded="true" aria-controls="offersOneCollapse">
            <span style="width: 16px; height: 16px;margin-right: 10px;">
                <svg xmlns="http://www.w3.org/2000/svg" fill="#E64977" viewBox="0 0 453.297 453.297" style="enable-background:new 0 0 453.297 453.297" xml:space="preserve">
                    <path d="M448.051 253.434 199.863 5.246A17.909 17.909 0 0 0 186.382.019L24.484 7.409c-9.25.422-16.653 7.825-17.075 17.075L.019 186.382a17.912 17.912 0 0 0 5.227 13.481l248.188 248.188c6.994 6.995 18.335 6.995 25.329 0l169.288-169.288c6.994-6.994 6.994-18.334 0-25.329zM103.146 103.146c-13.649 13.648-35.778 13.648-49.426 0-13.649-13.649-13.649-35.778 0-49.427 13.649-13.649 35.778-13.649 49.426 0 13.649 13.649 13.649 35.778 0 49.427z"/>
                </svg>
            </span>
            <span style="flex-grow: 1;">Available offers</span>
            <span [ngClass]="isOfferOpen ? 'arrowUp': ''">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M6 9L12 15L18 9" stroke="#E64977" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
            </span>
        </button>
      </div>
  
      <div id="offersOneCollapse" class="collapse" [ngClass]="isOfferOpen ? 'show' : ''" aria-labelledby="offersHeading" data-parent="#offersCard">
        <div class="card-body">
            <ul *ngFor="let offer of selectedOffers | slice: 0:5" id="offersList">
              <li *ngIf="isCardOffer">{{ offer.offer_name }}</li>
              <li *ngIf="!isCardOffer">{{ offer.offer_name }}</li>
            </ul>
        </div>
      </div>
    </div>
  </div>