<div class="verifyOtp top_bg">
    <app-header></app-header>
    <app-new-on-nimbl [isOtpVerify]="true" [userFound]="true" [nimbbl_mobile_name]="nimbbl_user_name" [welcome]="false" [user_mobile_number]=nimbbl_user_mobile>
    </app-new-on-nimbl>

    <app-enter-otp [nimbbl_mobile_number]="nimbbl_user_mobile" (submit_otp_request)="verifyOtp($event)" [errorMessage]="otpErrorMessage" [showLoader]="isLoader"></app-enter-otp>
</div>
<div class="mainContentDisplay">
    <app-order-summary [page]="'verify-otp'" [isOPen]="true"></app-order-summary>
</div>
<app-power-by></app-power-by>