<div class="box-accor">
    <div class="box-item">
        <div class=" box-row pt-1">

            <div class="col-12 align-self-center">
                <div class="box-date-details">
                    <div class="row">
                        <div class="col-auto p-0"><img [src]="selectedPaymentMode?.logo" class="img-fluid" [ngClass]="(selectedPaymentMode.vpa_provider == 'bhim' || selectedPaymentMode.paymentMode == 'BHIM') ? 'bhim': ''"></div>
                        <div class="col px-2 fS-18"><strong>Pay with {{paymentName || magicUPIDisplayName || paymentModeName}}</strong></div>
                    </div>

                </div>
            </div>
            <div class="col-2">
                <a href="#" class="align-middle pt-2 text-center d-block"></a>
            </div>
        </div>
        <div class="row">
            <div class="payment-success text-center">
                <div>
                    <img src="assets/images/success.png" class="img-fluid" />
                </div>
                <div class="fS-14 pt-1 smessage">Payment Successful</div>
                <div class="fS-24m my-3">Your payment was<br> completed in {{eta}} secs</div>
                <div>
                    <p class="message">You will now be redirected to {{merchat_details?.display_name || merchat_details?.description }}</p>
                </div>
            </div>
        </div>

    </div>

</div>