export const getISTTime = (date) => {
	let d = new Date(date);
	let t = d.getTime() + 5.5 * 60 * 60 * 1000;

  return new Date(t);
};

export const convertLocatDateToUTC = (date) => {
  return new Date(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate(),
    date.getUTCHours(),
    date.getUTCMinutes(),
    date.getUTCSeconds()
  );
}

export const hasMaxRetriesReached = (orderDetailsParam: any): boolean => {
	let noRetriesLeft: boolean;
	if (orderDetailsParam?.attempts === orderDetailsParam?.max_retries) {
		noRetriesLeft = true;
	} else {
		noRetriesLeft = false;
	}
	return noRetriesLeft;
};

export const mergeObjects = (firstObj, secondObj) => {
  const foundIndex = firstObj.findIndex(item => item.compareName === secondObj.compareName);
  if (foundIndex !== -1) {
      firstObj[foundIndex] = { ...firstObj[foundIndex], ...secondObj };
  } else {
      firstObj.push(secondObj);
  }
  return firstObj;
}

export const hasTouchSupport = () => {
  return 'ontouchstart' in window || navigator.maxTouchPoints > 0;
}

export const isObjectEmpty = (obj: any) => {
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      return false;
    }
  }
  return true;
}

export const isPopupBlockedInBrowser = () => {
  // Attempt to open a new window
  const newWindow = window.open('', '_blank', 'width=1,height=1');

  // Check if the new window was successfully opened
  const isBlocked = !newWindow || newWindow.closed || typeof newWindow.closed === 'undefined';

  // Close the new window (if opened)
  if (!isBlocked) {
    newWindow.close();
  }

  return isBlocked;
}
