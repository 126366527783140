<div class="accordion box-accor" id="accordionExample3" *ngIf="showOtherPaymentCard">
	<div class="card">
		<div class="card-header" id="headingThree">
			<h2 class="mb-0">
				<button
					type="button"
					class="btn accordion-button"
					data-target="#collapseThree"
					[disabled]="!isEnable"
					[ngClass]="!isEnable ? '' : isCollapsibleOpen ? '' : 'collapsed'"
					id="collapseThreeBtn"
					(click)="handleButtonClick($event)"
				>
					<div class="box-item">
						<div class="title"><strong>Other Payments</strong></div>
						<div class="details" *ngIf="showLoader">
							<span class="loader">
								<div class="loaderhmtl fastPaymentLoader"></div>
							</span>
						</div>
					</div>
					<span
						class="icon"
						*ngIf="!showLoader"
						[ngClass]="isCollapsibleOpen ? 'icon--minus' : 'icon--plus'"
					></span>
				</button>
			</h2>
		</div>
		<div
			id="collapseThree"
			class="collapse"
			[ngClass]="isCollapsibleOpen ? 'show' : ''"
			aria-labelledby="headingThree"
			data-parent="#accordionExample"
		>
			<div class="card-body">
				<div *ngIf="paymentAllowed?.length">
					<div
						class="row box-row first cursor"
						*ngFor="let paymentmode of paymentAllowed"
						(click)="
							clickPaymentMethod(
								paymentmode.mode,
								paymentmode.logo,
								paymentmode.eta,
								paymentmode.payment_partner,
								paymentmode.ConvenienceFees,
								paymentmode
							)
						"
					>
						<div class="col-2">
							<img [src]="paymentmode?.logo" class="img-fluid paymentmodeLogo" />
						</div>
						<div class="col-8 pl-0">
							<div class="box-date-details">
								<div class="paymentFlex">
									<div class="payment_mode_name">
										<span
											*ngIf="
												!paymentmode.mode.includes('Card') && !paymentmode.mode.includes('UPI')
											"
											>{{ paymentmode?.mode }}</span
										>
										<span
											*ngIf="
											paymentmode.mode.includes('Card')
											"
											>Debit / Credit / Prepaid Card</span
										>
										<span
											*ngIf="
												paymentmode.mode.includes('UPI') && !isMobile && isUpiIntentAllowed
											"
											>UPI / QR</span
										>
										<span
											*ngIf="
												paymentmode.mode.includes('UPI') && (isMobile || !isUpiIntentAllowed)
											"
											>UPI</span
										>
									</div>
								</div>
								<div class="con-fee-offer-wrapper">
									<div
										class="fS-10 desc"
									>
										<p
											class="fS-10 desc pt-2 red_text"
											*ngIf="paymentmode?.ConvenienceFees != 0 && !paymentmode?.isrange"
										>
											Convenience Fee of
											<span [innerHTML]="currency.currency"></span> {{ paymentmode?.ConvenienceFees }} will be charged
										</p>
										<p
											class="fS-10 desc pt-2 red_text"
											*ngIf="paymentmode?.ConvenienceFees != 0 && paymentmode?.isrange"
										>
											Convenience Fee between
											<span [innerHTML]="currency.currency"></span> {{ paymentmode?.ConvenienceFees }} will be charged
										</p>
										<!-- this data comes from common.services fetchPaymentModesMessage -->
										<p class="fs-10 desc m-0 green_text pt-2" *ngIf="paymentmode?.offerName && merchant_details?.offers_enabled === true">
											<span [innerHTML]="currency.currency"></span> {{ paymentmode.offerRange }} off on payment through
											{{ getPaymentModeText(paymentmode?.mode) }}
										</p>
									</div>
									<p
										*ngIf="
											paymentmode.mode === 'Credit Card' ||
											paymentmode.mode === 'Debit Card' ||
											paymentmode.mode === 'Prepaid Card'
										"
										class="fS-10 bankthumbnail-logowrapper pt-2 mb-0 pb-0"
									>
										<span class="bank-thumbnail-logos visa"></span>
										<span class="bank-thumbnail-logos mastercard"></span>
										<span class="bank-thumbnail-logos rupay"></span>
									</p>
									<p
										*ngIf="paymentmode.mode === 'Netbanking'"
										class="fS-10 bankthumbnail-logowrapper pt-2 mb-0 pb-0"
									>
										<span class="bank-thumbnail-logos sbibank"></span>
										<span class="bank-thumbnail-logos kotakbank"></span>
										<span class="bank-thumbnail-logos hdfcbank"></span>
									</p>
									<p
										*ngIf="paymentmode.mode === 'UPI'"
										class="fS-10 bankthumbnail-logowrapper pt-2 mb-0 pb-0"
									>
										<span class="bank-thumbnail-logos phonepe"></span>
										<span class="bank-thumbnail-logos googlepay"></span>
										<span class="bank-thumbnail-logos paytm"></span>
									</p>
									<p
										*ngIf="paymentmode.mode === 'Wallet'"
										class="fS-10 bankthumbnail-logowrapper pt-2 mb-0 pb-0"
									>
										<span class="bank-thumbnail-logos jiomoney"></span>
										<span class="bank-thumbnail-logos airtelmoney"></span>
										<span class="bank-thumbnail-logos freecharge"></span>
									</p>
								</div>
							</div>
						</div>
						<div class="col-2">
							<span class="align-middle pt-2 arrow_round"></span>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
