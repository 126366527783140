import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';
import * as crypto from 'crypto-js';
import { CookieService } from 'ngx-cookie-service';
import { merchantDetails } from '../app.models';
import { OrderDetailService } from './order-detail.service';
import { SharedService } from './shared.service';
import { BehaviorSubject, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { isObjectEmpty } from '../utils';
import tracking from '../utils/tracking';
import CheckoutSession from '../utils/CheckoutSession';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  apiEndpoint = environment.apiEndPoint;
  ctObj_password = environment.password_egt;
  public url = "assets/data/";
  ipAddress;
  FingerPrint;
  token_Details;
  token;
  order_details:merchantDetails
  constructor(public http: HttpClient, public activatedRoute: ActivatedRoute, private cookies: CookieService,private sharedOrderDetail:OrderDetailService,private sharedService:SharedService) {
    this.getIpAddreess().subscribe(res => {
      this.ipAddress = res['ip'];
    });
    this.sharedService.sharedFingerPrint.subscribe(res=>{
      this.FingerPrint=res;
    });
  }

  getHeaders(authToken = false) {
    let headers = new HttpHeaders().set('content-type', 'application/json')
      .set('X-Requested-With', 'XMLHttpRequest')
      .set('Access-Control-Allow-Origin', '*')
      .set('encryption', 'ignore')
      this.order_details= JSON.parse(JSON.stringify(this.sharedOrderDetail.getOrderDetails()));
      const merchant_details= JSON.parse(JSON.stringify(this.sharedOrderDetail.getMerchantDetail()))?.sub_merchant_id || '';
      this.token_Details=this.sharedService.getTokenDetails();
    if (this.token_Details['accessToken']) {

      headers = headers.set('Authorization', 'Bearer ' + this.token_Details['accessToken']);
      let subMerchantId = this.order_details['sub_merchant_id'] ? this.order_details['sub_merchant_id'] : merchant_details;
      let hasedToke = crypto.MD5(this.token_Details['accessToken']).toString();
      let x_nimbblKey = subMerchantId + '-' + hasedToke;
      headers = headers.set('x-nimbbl-key', x_nimbblKey);
      if(authToken) {
        let user_key = window.sessionStorage.getItem('user_key') || null;
        this.token=this.sharedService.getAuthToken() || user_key;
        if(this.token){
          window.sessionStorage.setItem('user_key',  this.token);
        }
        if(this.token) {
          headers = headers.set('x-nimbbl-user-token', this.token);
        }
      }
    }
    return headers;
  }

  public getEncryptedAuthorizationToken(orderId) {
    let activeQueryParams = this.activatedRoute.snapshot.queryParams;
    let transaction_token = activeQueryParams['transaction_token'];
    let clientAccessKey;

    if(activeQueryParams['resource']) {
      clientAccessKey = activeQueryParams['resource'];
    }else if(activeQueryParams['expires']){
        clientAccessKey = atob(activeQueryParams['expires']).split("=")[1];
    }

    this.sharedService.updateResourceDetails(clientAccessKey);

    if(transaction_token) {
      let token = atob(transaction_token);
      transaction_token = JSON.parse(token)
      return new BehaviorSubject({
          token: transaction_token.token,
          expires_at: transaction_token.expires_at,
          isTransactionToken: true
        })
    } else {
      /* Before EGT Call */
      tracking.egtPayload({
        order_id: orderId,
        client_access_key: clientAccessKey,
      });

      let egtData = {
        "access_key": clientAccessKey,
        "order_id": orderId,
      };

      var password = this.ctObj_password;
      var ctObj = crypto.AES.encrypt(JSON.stringify(egtData), password);
      let headers = this.getHeaders();
      return this.http.post(this.apiEndpoint + 'v2/egt', ctObj.toString(), {
        headers: headers
      }).pipe(catchError(err => this.catchAuthErr(err)));
    }
  }

  catchAuthErr(error): any {
    if(error && error.error && error.error.message) {
      console.log(error.error.message);
    } else if (error && error.error) {
      console.log(error.error);
    } else {
      console.log(JSON.stringify(error));
    }
    return throwError(error);
  }

  // making get request
  public get<T>(url: string) {
    return this.http.get<T>(this.apiEndpoint + url, {
      headers: this.getHeaders()
    });
  }

  // making delete request
  public delete(url: string) {
    return this.http.delete(this.apiEndpoint + url, {
      headers: this.getHeaders()
    });
  }

  // making post request
  public post(url: string, data: any, authToken: boolean = false) {
    data['user_agent'] = window.navigator.userAgent;
    data['ipAddress'] = this.ipAddress;
    data['fingerPrint']=this.FingerPrint;
    return this.http.post(this.apiEndpoint + url, data, {
      headers: this.getHeaders(authToken)
    });
  }

  public put(url: string, data: any) {
    data['user-agent'] = window.navigator.userAgent;
    data['ipAddress'] = this.ipAddress;
    data['fingerPrint']=this.FingerPrint;
    return this.http.put(this.apiEndpoint + url, data, {
      headers: this.getHeaders()
    });
  }

  resolveUser(rr) {

    return this.post('v2/resolve-user', rr);
  }
  updateUserInfo(data) {
    let userId = data['id'];
    return this.put('users/edit/' + userId, data);
  }
  verifyOTP(otp) {

    return this.post('user/verify-otp', otp);
  }
  getIpAddreess() {
    return this.http.get('https://api.ipify.org/?format=json');
  }
  resendOTP(data) {
    return this.post('user/resend-otp', data);
  }
  loadAllowedPaymentTypes(rr) {
    return this.post('v2/payment-modes', rr, true);
  }
  gpayValidate(rr) {
    return this.post('googlepay/validate', rr);
  }

  validateVpa(rr) {
    return this.post('v3/validate-vpa', rr, true);
  }

  initiatePayment(rr) {
    rr['device']['ip_address']= this.ipAddress;
    return this.post('v2/initiate-payment', rr, true)
  }
  makePaymentAPI(rr) {
    return this.post('v2/payment', rr, true)
  }
  getPaymentCard() {
    return this.http.get<any[]>(this.url + 'payment-cards.json');
  }
  fetchOrderById(id) {
    return this.get('v2/get-order/' + id);
  }
  paymentStatusConfirmation(rr) {
    return this.post('v2/transaction-enquiry', rr)
  }
  checkVPA(UPIID) {
    return this.post('v2/validateVPA', UPIID);
  }
  fetchBanks(data) {
    return this.post('v2/list-of-banks', data);
  }
  fetchBanksV3(data) {
    return this.post('v3/list-of-banks', data);
  }
  fetchAllWallets(data) {
    return this.post('v2/list-of-wallets', data);
  }
  fetchAllWalletsV3(data) {
    return this.post('v3/list-of-wallets', data);
  }

  fetchPaymentModesMessage() {
    return this.http.get<any[]>(this.url + 'paymentModesDetails.json');
  }
  checkBin(checkBinRequest) {
    return this.post('v2/get-bin-data', checkBinRequest);
  }
  fetchMerchantDetail(merchantId) {
    return this.get('v2/get-sub-merchant/' + merchantId);
  }

  getCallBackPayload(order_id, transaction_id) {
    let callbackPayloadQueryParams = 
      transaction_id !== null ? '?order_id=' + order_id + '&transaction_id=' + transaction_id : '?order_id=' + order_id;
    return this.get('internal/checkout/callback-payload' + callbackPayloadQueryParams);
  }

  resendLazyPayOtp(rr) {
    return this.post('v2/resend-otp', rr);
  }
  /* transactionUpdate(data, transaction_id) {
    return this.put('v2/update-transaction/' + transaction_id, data);
  } */
  transactionUpdate(data, transaction_id) {
    return this.put('v2/update-transaction-frontend/' + transaction_id, data);
  }

  updateOrder(orderUpdateData) {
    let updateReason = {
      cancellation_reason: orderUpdateData['cancellation_reason'],
      status: orderUpdateData['status']
    }
    return this.put('v2/update-order/' + orderUpdateData['order_id'], updateReason);
  }
  updateUserOnOrder(orderUpdateData) {

    let updateReason = {
      user_id: orderUpdateData['user_id']
    }
    return this.put('v2/update-order/' + orderUpdateData['order_id'], updateReason);
  }
  checkForValidToken() {
    let tokenDetails=this.sharedService.getTokenDetails();

    if (tokenDetails['token_expiry'] && tokenDetails['accessToken']) {
      let token_expiry = new Date(atob(tokenDetails['token_expiry']));
      let today = new Date();

      let tokenExpiryDiff = token_expiry.getTime() - today.getTime();
      tokenExpiryDiff = tokenExpiryDiff / 1000;
      tokenExpiryDiff = Math.round(tokenExpiryDiff / 60);
      if (tokenExpiryDiff <= 5) {
        return false
      }
      else {
        return true;
      }
    }
    else {
      return false;
    }

  }
  setTokenCookies(token, expiry) {
    let tokenData={
      accessToken:token,
      token_expiry:btoa(expiry)
    }
    this.sharedService.updateTokenDetails(tokenData);
    let timer = this.activatedRoute.snapshot.queryParams && this.activatedRoute.snapshot.queryParams['expires'];

	// console.log('merchant', this.sharedOrderDetail.sharedMerchantDetails);

    if(!this.sharedService.getTokenExpiryTime()) {
      if(timer) {
        this.sharedService.updateTokenExpiryTime(timer);
      }
      else {
        this.sharedOrderDetail.sharedMerchantDetails.subscribe((merchantDetails) => {
          if(!isObjectEmpty(merchantDetails)) {
            const { checkout_session_duration } = merchantDetails as unknown as merchantDetails;
            const expiryTime = CheckoutSession.getTime(checkout_session_duration);

            const updatedExpiryTime = this.sharedService.getUpdatedResourceDetails() ?
                        btoa(expiryTime + '='+ this.sharedService.getUpdatedResourceDetails()?.toString()) :
                        btoa(expiryTime);

            this.sharedService.updateTokenExpiryTime(updatedExpiryTime);
          }
        });
      }
    }
  }
  fetchUserOnDevice(){
    let data={
      fingerPrint:this.FingerPrint
    }
    return this.post('v2/list-available-user',data);
  }
  updateOrderLevel(data)
  {
    return this.put('v2/checkout-status', data);
  }
  fetchCCDCPublicKey() {
    return this.get('v2/get-nimbbl-public-key');
  }
  fetchCardOffers(data) {
    return this.post('v1/fetch-card-offers', data, true);
  }
}
