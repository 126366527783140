<div class="accordion box-accor" id="accordionExample2" *ngIf="merchant_details?.fast_payment_enabled">
    <div class="card" *ngIf="allowedPaymentTypeArray?.length">
        <div class="card-header" id="headingTwo">
            <h2 class="mb-0">
                <button type="button" class="btn accordion-button" id="collapseTwoBtn" data-target="#collapseTwo" (click)="handleButtonClick($event)" [disabled]="!isEnalbe && !showLoader" [ngClass]="!isEnalbe ? '' : isCollapsibleOpen ? '' : 'collapsed'">
          <div class="box-item">
            <div class="title"><strong>Your Fast Payment Options</strong></div>
            <div class="details" *ngIf="showLoader && !isAllModesLoaded">
              <strong>Searching payment options for
                {{ nimbbl_user_mobile }} .....</strong>
              <span class="loader">
                <div class="loaderhmtl fastPaymentLoader"></div>
              </span>
            </div>
          </div>
          <span class="icon" [ngClass]="isEnalbe && isAllModesLoaded && isCollapsibleOpen? 'icon--minus': 'icon--plus'"
            *ngIf="!showLoader && isAllModesLoaded"></span>
        </button>
            </h2>
        </div>
        <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordionExample2" [ngClass]="isEnalbe && isAllModesLoaded && isCollapsibleOpen ? 'show' : ''">
            <div class="card-body">
                <div class="box-item fpo_div">
                    <div *ngIf="allowedPaymentTypeArray?.length">
                        <div 
                            class="row box-row pt-1 first pb-3 cursor" 
                            *ngFor="let paymentMode of allowedPaymentTypeArray | slice: 0:5" 
                            (click)="clickPaymentMethod(
                                paymentMode.mode,
                                paymentMode.logo,
                                paymentMode.eta,
                                paymentMode.payment_partner,
                                paymentMode.savedVPAId,
                                paymentMode.savedVPA,
                                paymentMode.ConvenienceFees,
                                paymentMode.is_intent,
                                paymentMode.autoDebitFlowPossible,
                                paymentMode.vpa_provider,
                                paymentMode.magicUPIDisplayName, 
                                paymentMode.is_magic_UPI, 
                                paymentMode.intentTextAboveBtns, 
                                paymentMode.intentDisplayName, 
                                paymentMode.compareName,
                                paymentMode.extraInfo
                            )"
                        >
                            <div class="col-2">
                                <img [src]="paymentMode.logo" class="img-fluid paymentModeLogo" [ngClass]="(paymentMode.vpa_provider == 'bhim' || paymentMode.mode == 'BHIM') ? 'bhim': ''" />
                            </div>
                            <div class="col-8 pl-0">
                                <div class="box-date-details">
                                    <div class="paymentFlex">
                                        <div class="payment_mode_name" *ngIf="paymentMode.mode !== 'savedUPI'">
                                            {{ paymentMode.magicUPIDisplayName || paymentMode.mode }}
                                            <span *ngIf="paymentMode.is_intent" class="d-none">Intent</span>
                                        </div>
                                        <div class="payment_mode_name" *ngIf="paymentMode.mode === 'savedUPI'">
                                            {{paymentMode.magicUPIDisplayName ||paymentMode.vpa_provider ||paymentMode.savedVPA}}
                                            <span *ngIf="paymentMode.is_magic_UPI" class="d-none">
                        Magic</span>
                                        </div>
                                    </div>
                                    <div>
                                        <div class="fS-10 desc" *ngIf="paymentMode.mode !== 'savedUPI'">
                                            <div>{{ paymentMode.is_intent ? paymentMode.intentPayWithDesc : paymentMode.desc }}
                                                <span *ngIf="paymentMode.mode == 'LazyPay'">{{dueDate}}</span>
                                            </div>

                                            <p class="fS-10 desc red_text" *ngIf="paymentMode.ConvenienceFees != 0 ">
                                                Convenience Fee will be charged of <span [innerHTML]="currency.currency"></span> {{ paymentMode.ConvenienceFees }}
                                            </p>
                                        </div>
                                        <div class="fS-10 desc" *ngIf="paymentMode.mode === 'savedUPI'">
                                            <p>
                                                {{ paymentMode.savedVPAId }}
                                            </p>
                                            <p class="fS-10 desc red_text" *ngIf="paymentMode.ConvenienceFees != 0 ">
                                                Convenience Fee will be charged of
                                                <span [innerHTML]="currency.currency"></span> {{ paymentMode.ConvenienceFees }}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-2">
                                <span class="align-middle pt-2 arrow_round"></span>
                            </div>
                        </div>
                        <div *ngIf="allowedModes?.length && showMoreOption">
                            <div class="row box-row pt-1 first pb-3 cursor" *ngFor=" let paymentMode of allowedPaymentTypeArray | slice: 5:allowedPaymentTypeArray.length" (click)="clickPaymentMethod(paymentMode.mode,paymentMode.logo,paymentMode.eta,paymentMode.payment_partner,paymentMode.savedVPAId,paymentMode.savedVPA,paymentMode.ConvenienceFees,paymentMode.is_intent,paymentMode.autoDebitFlowPossible,paymentMode.vpa_provider,paymentMode.magicUPIDisplayName, paymentMode.is_magic_UPI, paymentMode.intentTextAboveBtns, paymentMode.intentDisplayName, paymentMode.compareName)">
                                <div class="col-2">
                                    <img [src]="paymentMode.logo" class="img-fluid paymentModeLogo" [ngClass]="(paymentMode.vpa_provider == 'bhim' || paymentMode.mode == 'bhim') ? 'bhim': ''" />
                                </div>
                                <div class="col-8 pl-0">
                                    <div class="box-date-details">
                                        <div class="paymentFlex">
                                            <div class="payment_mode_name" *ngIf="paymentMode.mode !== 'savedUPI'">
                                                {{ paymentMode.magicUPIDisplayName || paymentMode.mode }}
                                                <span *ngIf="paymentMode.is_intent" class="d-none">Intent</span>
                                            </div>
                                            <div class="payment_mode_name" *ngIf="paymentMode.mode === 'savedUPI'">
                                                {{ paymentMode.magicUPIDisplayName || paymentMode.vpa_provider || paymentMode.savedVPA }}
                                                <span *ngIf="paymentMode.is_magic_UPI" class="d-none">Magic</span>
                                            </div>
                                        </div>
                                        <div>
                                            <div class="fS-10 desc" *ngIf="paymentMode.mode !== 'savedUPI'">
                                                <div>{{ paymentMode.is_intent ? paymentMode.intentPayWithDesc : paymentMode.desc }} <span *ngIf="paymentMode.mode == 'LazyPay'">{{dueDate}}</span></div>

                                                <p class="fS-10 desc red_text" *ngIf="paymentMode.ConvenienceFees != 0">
                                                    Convenience Fee will be charged of<span [innerHTML]="currency.currency"></span> {{ paymentMode.ConvenienceFees }}
                                                </p>
                                            </div>
                                            <div class="fS-10 desc" *ngIf="paymentMode.mode === 'savedUPI'">
                                                <p>{{ paymentMode.savedVPAId }}</p>
                                                <p class="fS-10 desc red_text" *ngIf="paymentMode.ConvenienceFees != 0">
                                                    Convenience Fee will be charged of <span [innerHTML]="currency.currency"></span> {{ paymentMode.ConvenienceFees }}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-2">
                                    <span class="align-middle pt-2 arrow_round"></span>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="allowedPaymentTypeArray?.length > 5">
                            <a (click)="viewMore()" class="view-more-fastpayment" *ngIf="!showMoreOption">View More</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>