<div class="fS-15">
	<div class="mt-3 mb-2">
		<span class="enterOtpMessage">Please verify yourself, enter the OTP sent on your number</span>
	</div>
</div>
<form id="otpForm" [formGroup]="otpForm" (submit)="submitOtp(otpForm.value)" style="padding: 0 2px">
	<div>
		<input
			type="tel"
			inputmode="numeric"
			class="text-center otp-input"
			formControlName="otp1"
			id="otp1"
			aria-describedby=""
			maxlength="1"
			(keyup)="setFocus($event)"
			(keydown)="setPrevFocus($event)"
			autocomplete="off"
		/>
		<input
			type="tel"
			inputmode="numeric"
			class="text-center otp-input"
			formControlName="otp2"
			id="otp2"
			maxlength="1"
			(keyup)="setFocus($event)"
			(keydown)="setPrevFocus($event)"
			autocomplete="off"
		/>
		<input
			type="tel"
			inputmode="numeric"
			class="text-center otp-input"
			formControlName="otp3"
			id="otp3"
			maxlength="1"
			(keyup)="setFocus($event)"
			(keydown)="setPrevFocus($event)"
			autocomplete="off"
		/>
		<input
			type="tel"
			inputmode="numeric"
			class="text-center otp-input"
			formControlName="otp4"
			id="otp4"
			maxlength="1"
			(keyup)="setFocus($event)"
			(keydown)="setPrevFocus($event)"
			autocomplete="off"
		/>
	</div>

	<div class="mb-3 fS-12">
		<div class="alert-danger mt-2">{{ errorMessage }}</div>
		<div class="flex flex-row">
			<span *ngIf="showResendOtpButton">Didn't Receive the OTP? </span>
			<span *ngIf="!showResendOtpButton && showCountdown" >
				Resend again in: 00: {{ resendTimer > 9 ? resendTimer : '0' + resendTimer }} secs
			</span>
			<button *ngIf="showResendOtpButton"
				(click)="resendOtp()"
				class='resendOtpText'
				[disabled] = isResendOtpBtnDisabled
				>

				<svg
					[ngClass]="!showResendOtpButton ? 'resend-otp-spin' : ''"
					xmlns="http://www.w3.org/2000/svg"
					width="21.893"
					height="21.893"
					viewBox="0 0 21.893 21.893"
				>
					<g transform="matrix(0.259, -0.966, 0.966, 0.259, 0, 17.266)">
						<path class="a" d="M0,0H17.875V17.875H0Z" />
						<path
							class="b"
							d="M14.317,15.084a7.434,7.434,0,1,1,1.231-1.361L13.172,9.448h2.234a5.958,5.958,0,1,0-1.832,4.3Z"
							transform="translate(-0.51 -0.51)"
						/>
					</g>
				</svg>
				<span>Resend OTP</span>
			</button>
		</div>
	</div>
	<div class="mb-2">
		<button
			type="submit"
			class="btn btn-continue"
			[disabled]="otpForm.pristine || otpForm.invalid"
			[ngClass]="
				otpForm.valid
					? merchant_details?.continue_btn_is_white
						? 'btn-light'
						: 'btn-dark'
					: merchant_details?.continue_btn_is_white
					? 'btn-outline-light'
					: 'btn-outline-dark'
			"
		>
			<span *ngIf="!showLoader">Proceed to Fast Payment</span>
			<span *ngIf="showLoader" class="loader">
				<div class="loaderhmtl enterOTP"></div>
			</span>
		</button>
	</div>
</form>

<div class="closeBox" *ngIf="showPopup">
	<div class="confirmationBox">
		<div class="header">Resend OTP</div>
		<div class="popupBody">
			<div>
				<label> OTP resent to your mobile number </label>
				<div class="text-center">
					<button
						type="button"
						class="btn btn-primary btn-orange"
						id="noBtn"
						(click)="closeModal()"
					>
						OK
					</button>
				</div>
			</div>
		</div>
	</div>
</div>
