import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { orderDetails } from '../app.models';

@Injectable({
  providedIn: 'root'
})
export class OrderDetailService {

  constructor() { }
  private orderDetails = new BehaviorSubject([]);
  sharedOrderDetails = this.orderDetails.asObservable();
  updateOrderDetails(orderDetails) {
    this.orderDetails.next(orderDetails);
  }
  getOrderDetails() {
    return this.orderDetails.value;
  }
  hasOrderDetailsInServices() {
    return Object.keys(this.orderDetails.value).length > 0;
  }


  // merchantDetail 
  private merchantDetails = new BehaviorSubject([]);
  sharedMerchantDetails = this.merchantDetails.asObservable();
  updateMerchantDetails(merchant_detail) {
    this.merchantDetails.next(merchant_detail);
  }
  getMerchantDetail() {
    return this.merchantDetails.value;
  }

  //footer Detail 
  private footerDetails = new BehaviorSubject([]);
  sharedFooterDetails = this.footerDetails.asObservable();
  updateFooterDetails(footer_detail) {
    this.footerDetails.next(footer_detail);
  }
  getFooterDetail() {
    return this.footerDetails.value;
  }


  private tokenDetails=new BehaviorSubject([]);
  sharedTokenDetail=this.tokenDetails.asObservable();

  updateTokenDetails(updatedTokenDetails){
    this.tokenDetails.next(updatedTokenDetails);

  }
  getTokenDetails(){
    return this.tokenDetails.value;
  }
  private paymentResponse=new BehaviorSubject([]);
  sharedPaymentResponse=this.tokenDetails.asObservable();

  updatePaymentResponse(payment_response){
    this.paymentResponse.next(payment_response);

  }
  getPaymentResponse(){
    return this.paymentResponse.value;
  }

  /**
   * To get payment status which can be `true` if payment in the process, otherwise `false`.
   */
  private paymentStatus=new BehaviorSubject(false);
  sharedPaymentStatus=this.paymentStatus.asObservable();

  updatePaymentStatus(payment_status){
    this.paymentStatus.next(payment_status);
  }

  getPaymentStatus(){
    return this.paymentStatus.value;
  }

    /**
   * To get payment status which can be `true` if payment is succeed, otherwise `false`.
   */
  private paymentCompleted=new BehaviorSubject(false);
  sharedPaymentCompleted=this.paymentCompleted.asObservable();

  updatePaymentCompleted(payment_status){
    this.paymentCompleted.next(payment_status);
  }

  getPaymentCompleted(){
    return this.paymentCompleted.value;
  }
}

