import { DatePipe } from '@angular/common';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CookieService } from 'ngx-cookie-service';
import { merchantDetails, orderDetails } from 'src/app/app.models';
import { ApiService } from 'src/app/services/api.service';
import { OrderDetailService } from 'src/app/services/order-detail.service';
import { PaymentModeService } from 'src/app/services/payment-mode.service';
import { SharedBankingService } from 'src/app/services/shared-banking.service';
import { SharedService } from 'src/app/services/shared.service';
import { CardDetailService } from 'src/app/services/card-detail.service';

import tracking from '../../utils/tracking';

@Component({
	selector: 'app-pay-with-otp',
	templateUrl: './pay-with-otp.component.html',
	styleUrls: ['./pay-with-otp.component.css'],
})
export class PayWithOtpComponent implements OnInit {
	@Output() payNow: EventEmitter<any> = new EventEmitter<any>();
	@Output() openInRedirectURL: EventEmitter<any> = new EventEmitter<any>();
	otpForm: FormGroup;
	dueDate;
	order_details: orderDetails;
	merchant_details: merchantDetails;
	selectedPaymentMode;
	description;
	subDescriptio;
	paymentModeName;
	termsLink: string = null;
	currency;
	grandTotal;
	seconds: string | number = 30;
	interval = null;
	showCountdown = false;
	@Output() cancelPay: EventEmitter<any> = new EventEmitter<any>();
	@Output() resendLazyPayOtp: EventEmitter<any> = new EventEmitter<any>();
	constructor(
		private sharedorderDetails: OrderDetailService,
		private sharedPaymentService: PaymentModeService,
		private apiService: ApiService,
		private fb: FormBuilder,
		private datePipe: DatePipe,
		private cookies: CookieService,
		private sharedService: SharedService,
		private CardDetailService: CardDetailService
	) {
		this.sharedorderDetails.sharedOrderDetails.subscribe((res) => {
			this.order_details = JSON.parse(JSON.stringify(res));
			let selectedEmiPlan = this.sharedService.getUpdatedEMISelectedPlanDetail();
			console.log(this.sharedService.getUpdatedEMISelectedPlanDetail());
			
			if (Object.keys(selectedEmiPlan).length) {
				this.grandTotal = selectedEmiPlan['total_emi'] * selectedEmiPlan['emi'];
			} else {
				this.grandTotal = this.order_details['grand_total_with_convience_fees']
					? this.order_details['grand_total_with_convience_fees']
					: this.order_details?.total_amount;
			}
		});
		let due_Date = '';
		const ele = this.sharedPaymentService.getAllowedPaymentTypeArray();
		ele.map((item) => {
			if (item['extra_info']['due_date']) {
				due_Date = item['extra_info']['due_date'];
			}
		});
		this.sharedPaymentService.sharedSelectedMode.subscribe((res) => {
			if (
				res['paymentMode'] === 'Credit Card' ||
				res['paymentMode'] === 'Debit Card' ||
				res['paymentMode'] === 'Prepaid Card'
			) {
				let cardDetails = this.CardDetailService.getCardDetails();
				res['card_details'] = cardDetails;
			}
			this.selectedPaymentMode = res;

			this.apiService.fetchPaymentModesMessage().subscribe((res) => {
				// let today = this.datePipe.transform(new Date(), 'dd-MMM-YYYY');
				// let day = new Date(today).getDate();

				// let month = new Date(today).getMonth() + 1;
				// let year = Number(today.slice(today.length - 4, today.length));
				// if (day <= 15) {
				// 	this.dueDate = month + '/18/' + year;
				// } else if (day > 15) {
				// 	if (month == 12) {
				// 		year = year + 1;
				// 		month = month / 12;
				// 		this.dueDate = month + '/03/' + year;
				// 	} else {
				// 		this.dueDate = month + 1 + '/03/' + year;
				// 	}
				// }

				this.dueDate = due_Date;
				if (res && this.selectedPaymentMode && this.selectedPaymentMode?.paymentMode) {
					const modeName = res[this.selectedPaymentMode?.paymentMode.toLowerCase()]['modeName'];
					this.paymentModeName = modeName || ''; // default value when modeName is null
				} else {
					this.paymentModeName = ''; // default value when res is null or undefined
				}
				// if (
				// 	this.selectedPaymentMode['paymentMode'] != 'Netbanking' &&
				// 	(this.selectedPaymentMode['paymentMode'] != 'Credit Card' ||
				// 		this.selectedPaymentMode['paymentMode'] != 'Debit Card' ||
				// 		this.selectedPaymentMode['paymentMode'] != 'Prepaid Card') &&
				// 	this.selectedPaymentMode['paymentMode'] != 'UPI' &&
				// 	this.selectedPaymentMode['paymentMode'] != 'Wallet'
				// ) {
				// 	this.paymentModeName =
				// 		res[this.selectedPaymentMode['paymentMode'].toLowerCase()]['modeName'];
				// } else if (
				// 	this.selectedPaymentMode['paymentMode'] == 'Credit Card' ||
				// 	this.selectedPaymentMode['paymentMode'] == 'Debit Card' ||
				// 	this.selectedPaymentMode['paymentMode'] == 'Prepaid Card'
				// ) {
				// 	this.paymentModeName =
				// 		res[this.selectedPaymentMode['paymentMode'].toLowerCase()]['modeName'];
				// }
				// this.dueDate = this.datePipe.transform(new Date(this.dueDate), 'dd MMM YYYY');
				this.termsLink = res[this.selectedPaymentMode['paymentMode'].toLowerCase()]['t&c'];
			});
		});
		this.merchant_details = JSON.parse(JSON.stringify(sharedorderDetails.getMerchantDetail()));
	}

	ngOnInit(): void {
		this.currency = this.sharedService.getCurrency();
		let due_Date = '';
		const ele = this.sharedPaymentService.getAllowedPaymentTypeArray();
		ele.map((item) => {
			if (item['extra_info']['due_date']) {
				due_Date = item['extra_info']['due_date'];
			}
		});

		// tracking.initiatePayment({
		//   order_details: this.order_details,
		//   merchant_details: this.merchant_details,
		//   selected_payment_mode: this.selectedPaymentMode
		// }, true);

		// let today = this.datePipe.transform(new Date(), 'dd-MMM-YYYY');
		// let day = new Date(today).getDate();

		// let month = new Date(today).getMonth() + 1;
		// let year = Number(today.slice(today.length - 4, today.length));
		// if (day <= 15) {
		// 	this.dueDate = month + '/18/' + year;
		// } else if (day > 15) {
		// 	if (month == 12) {
		// 		year = year + 1;
		// 		month = month / 12;
		// 		this.dueDate = month + '/03/' + year;
		// 	} else {
		// 		this.dueDate = month + 1 + '/03/' + year;
		// 	}
		// }

		this.dueDate = due_Date;
		this.otpForm = this.fb.group({
			otp1: [
				null,
				[
					Validators.required,
					Validators.pattern('^[0-9]*$'),
					Validators.minLength(4),
					Validators.maxLength(6),
				],
			],
		});
	}
	payNowTrigger() {
		this.stopCountDown();
		if (this.otpForm.valid) {
			let otp = this.otpForm.value['otp1'];
			this.payNow.emit(otp);
		}
	}

	redirectURLTrigger(url) {
		this.openInRedirectURL.emit(url);
	}
	cancelPayment() {
		this.stopCountDown();
		tracking.cancelPayment({
			order_details: this.order_details,
			merchant_details: this.merchant_details,
			selected_payment_mode: this.selectedPaymentMode,
		});
		this.cancelPay.emit();
	}

	startCountDown() {
		this.seconds = 30;
		this.showCountdown = true;
		this.stopCountDown();
		this.interval = setInterval(() => {
			if (Number(this.seconds) > 0) {
				let count: string | number = Number(this.seconds) - 1;
				if (count < 10) {
					count = '0' + count;
				}
				this.seconds = count;
			} else {
				this.stopCountDown();
				this.showCountdown = false;
			}
		}, 1000);
	}

	stopCountDown() {
		this.interval && clearInterval(this.interval);
	}
	resendOtp() {
		this.startCountDown();
		this.resendLazyPayOtp.emit();
	}
	setFocus(event) {
		if (event.target.value.length) {
			let focus = parseInt(event.target.id.charAt(event.target.id.length - 1)) + 1;
			if (focus < 5) {
				let focusEl = focus.toString();
				setTimeout(() => {
					document.getElementById('otp' + focusEl).focus();
				}, 0);
			}
		}
	}

	setPaymentTracking(input) {
		tracking.enterPaymentDetail({
			order_details: this.order_details,
			merchant_details: this.merchant_details,
			selected_payment_mode: this.selectedPaymentMode,
			field_name: input,
		});
	}

	setInvalidPaymentDetailTracking(input, invalid_reason) {
		tracking.enterInvalidPaymentDetail({
			order_details: this.order_details,
			merchant_details: this.merchant_details,
			selected_payment_mode: this.selectedPaymentMode,
			field_name: input,
			invalid_reason: invalid_reason,
		});
	}

	handleOTPBlur(event) {
		this.setPaymentTracking('OTP');

		if (!this.otpForm.valid) {
			this.setInvalidPaymentDetailTracking('OTP', 'Please enter a valid OTP');
		}
	}
}
