import { DatePipe, JsonPipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import {
	allowedPaymentPartnersRequest,
	initPaymentRequest,
	merchantDetails,
	orderDetails,
} from 'src/app/app.models';
import { ApiService } from 'src/app/services/api.service';
import { OrderDetailService } from 'src/app/services/order-detail.service';
import { PaymentModeService } from 'src/app/services/payment-mode.service';
import { SharedService } from 'src/app/services/shared.service';
declare var Razorpay: any;

import tracking, { getFastPaymentMethodName } from '../../utils/tracking';

@Component({
	selector: 'app-fast-payment-option',
	templateUrl: './fast-payment-option.component.html',
	styleUrls: ['./fast-payment-option.component.css'],
})
export class FastPaymentOptionComponent implements OnInit {
	@Input() isEnalbe: boolean = false;
	@Input() paymentAllowed;
	@Input() allowedModes = [];
	@Output() paymentModeSelected: EventEmitter<any> = new EventEmitter<any>();
	@Output() savedUPIClicked: EventEmitter<any> = new EventEmitter<any>();
	@Output() CredClick: EventEmitter<any> = new EventEmitter<any>();
	@Input() showLoader: boolean = false;
	@Input() googlePay_paymentPartner;
	@Input() Razorpay_keyID;
	@Input() vpa_user;
	@Input() vpa_id;
	@Input() isFatpaymentFound: boolean;
	currency;
	showMoreOption: boolean = false;
	razorpay: any;
	showFastPaymentCard: boolean = true;
	canMakePaymentCache = 'canMakePaymentCache';
	phone_pay_redirecturl = "{{data['data']['redirectURL']}}";
	amount;
	pa;
	pn;
	tn;
	mc;
	tr;
	url;
	cu;
	isAutoDebit;
	showLazypay: boolean = false;
	showGpayButton;
	paymentEta = [];
	showPhonePay: boolean = true;
	isAPiInvoked: boolean = false;
	showPayTmButton: boolean = true;
	gpayRequest: any;
	dueDate: any;
	razorpayPayload: any;
	nimbbl_user_mobile;
	allowedPaymentTypeArray = [];
	isAllModesLoaded: boolean = false;
	isSavedVPA: boolean = false;
	tracker: boolean = false;
	order_details: orderDetails;
	loaderTimeOut = 0;
	isCredAppPresent: boolean;
	merchant_details: merchantDetails;
	isCollapsibleOpen: boolean = false;
	isEMIPlanFlow: boolean = false;

	constructor(
		private sharedPaymentservice: PaymentModeService,
		private apiService: ApiService,
		public datePipe: DatePipe,
		private router: Router,
		private cookies: CookieService,
		private orderDetailServeice: OrderDetailService,
		private sharedService: SharedService,
		private activeRoute: ActivatedRoute
	) {
		this.orderDetailServeice.sharedOrderDetails.subscribe((order) => {
			this.order_details = JSON.parse(JSON.stringify(order));
			if (this.order_details['order_id']) {
				this.nimbbl_user_mobile = this.order_details.user.mobile_number;
				let chars = this.nimbbl_user_mobile.split('');
				for (let index = 2; index < 8; index++) {
					chars[index] = 'x';
				}
				this.nimbbl_user_mobile = chars?.join('');
			}
		});

		this.sharedService.sharedFastPaymentViewMore.subscribe((value) => {
			this.showMoreOption = value;
		});
		this.orderDetailServeice.sharedMerchantDetails.subscribe((merchantData) => {
			this.merchant_details = JSON.parse(JSON.stringify(merchantData));
		});
		this.sharedPaymentservice.enableGooglePay.subscribe((res) => {
			this.showGpayButton = res;
		});
		// console.log(this.loaderTimeOut);
		this.sharedService.sharedCollapsibleStatus.subscribe((res) => {
			// handle fast payment collapsible state
			this.isCollapsibleOpen = res.fastPaymentOption;
			// console.log('++++++++++++status', res.fastPaymentOption)
		});
	}

	ngOnInit(): void {

		this.currency = this.sharedService.getCurrency();
		// console.log('fast payment page called');
	}

	ngOnChanges(changes: SimpleChanges) {
		// console.log('allowed modes are', this.allowedModes, this.isFatpaymentFound, this.isEnalbe)
		let due_Date = '';
		const ele = this.sharedPaymentservice.getAllowedPaymentTypeArray();
		ele.map((item) => {
			if (item['extra_info']['due_date']) {
				due_Date = item['extra_info']['due_date'];
			}
		});
		if (this.isEnalbe) {
			// this.showLoader = true;
			this.isAllModesLoaded = false;
		}
		if (this.allowedModes.length > 0 && this.isEnalbe && this.isFatpaymentFound) {
			this.allowedModes.sort((a, b) => a.eta - b.eta);

			let trackerPaymentName = [];
			this.allowedPaymentTypeArray = [];
			this.allowedModes.forEach((element) => {

				if (element['mode'] === 'Lazypay' || element['mode'] == 'LazyPay') {
					element['mode'] = 'LazyPay';
					this.dueDate = due_Date;
				}
				
				if (element['mode'] === 'Google Pay') {
					this.allowedPaymentTypeArray.push(element);
					trackerPaymentName.push(element['mode']);
				} else if (element['mode'] !== 'Google Pay') {
					const name = getFastPaymentMethodName(element);
					this.allowedPaymentTypeArray.push(element);
					trackerPaymentName.push(name);
				}

				setTimeout(() => {
					// this.showLoader = false;
					this.isAllModesLoaded = true;

					if (!this.tracker) {
						this.order_details = JSON.parse(
							JSON.stringify(this.orderDetailServeice.getOrderDetails())
						);
						this.tracker = true;
						if (trackerPaymentName.length && this.merchant_details?.fast_payment_enabled) {
							tracking.renderFastPayment({
								order_details: this.order_details,
								allowedPaymentTypeArray: this.allowedPaymentTypeArray,
								merchant_details: this.merchant_details,
								trackerPaymentName: trackerPaymentName,
							});
						}
					}
				}, this.loaderTimeOut);
			});
		}

		setTimeout(() => {
			if (!this.allowedPaymentTypeArray.length && !this.showLoader) {
				this.showLoader = false;
				this.isAllModesLoaded = true;
			}
		}, this.loaderTimeOut);
		let MerchantDetailInterval = setInterval(() => {
			this.merchant_details = JSON.parse(
				JSON.stringify(this.orderDetailServeice.getMerchantDetail())
			);
			if (this.merchant_details['merchant_id']) {
				clearInterval(MerchantDetailInterval);
				this.showFastPaymentCard = this.merchant_details['fast_payment_enabled'];
				if (this.isFatpaymentFound == false || !this.merchant_details.fast_payment_enabled) {
					this.showLoader = false;
					this.isAllModesLoaded = true;
				}
			}
		}, 1);
	}
	viewMore() {
		this.sharedService.updateFastPaymentViewMore(true);

		tracking.viewMoreFastPaymentClicked({
			order_details: this.order_details,
		});
	}
	clickPaymentMethod(
		paymentMode,
		logo,
		eta,
		payment_partner,
		vpa_id = null,
		vpa_name = null,
		additionalCharges = 0,
		is_intent = false,
		isAutodebitFlow,
		vpa_provider = null,
		magicUPIDisplayName = null,
		is_magic_UPI = false,
		intentTextAboveBtns = null,
		intentDisplayName = null,
		compareName = '',
		extraInfo,
	) {

		// this.isEMIPlanFlow = extraInfo?.schemes && extraInfo?.schemes.length > 0 &&  payment_partner === 'Spur' ? true : false;

		if(extraInfo?.schemes && extraInfo?.schemes.length > 1 && payment_partner === 'Spur') {
			this.isEMIPlanFlow = true;
		}else if(extraInfo?.schemes && extraInfo?.schemes.length === 1 && extraInfo?.schemes[0]?.total_emi > 1){
			this.isEMIPlanFlow = true;
		}else {
			this.isEMIPlanFlow = false;
		}

		this.sharedService.updateEMIPlansDetails({extraInfo, isEMIPlanFlow: this.isEMIPlanFlow});

		console.log('Is EMI Plan Flow', this.isEMIPlanFlow);

		let arr = {
			paymentMode: paymentMode == 'savedUPI' ? 'UPI' : paymentMode,
			logo: logo,
			eta: eta,
			vpaId: vpa_id,
			payment_partner: payment_partner,
			extra_charges: additionalCharges,
			isFastPayment: true,
			is_intent: is_intent,
			isAutodebitFlow: isAutodebitFlow,
			vpa_provider: vpa_provider,
			magicUPIDisplayName: magicUPIDisplayName,
			is_magic_UPI: is_magic_UPI,
			intentTextAboveBtns: intentTextAboveBtns,
			intentDisplayName: intentDisplayName,
			compareName: compareName,
			schemes: extraInfo?.schemes || []
		};
		this.sharedService.updateCollapsibleStatus({
			...this.sharedService.getCollapsibleStatus(),
			docOffset: document.documentElement.scrollTop,
		}); // set document offset so when we go back to payment, it will maintain the offsetTop

		if (paymentMode == 'savedUPI') {
			this.sharedPaymentservice.updateSelectedMode(arr);
			let vpaDtail = {
				vpaName: vpa_name,
				VpaId: vpa_id,
			};
			this.savedUPIClicked.emit(vpaDtail);
		} else {
			this.sharedPaymentservice.updateSelectedMode(arr);
			this.paymentModeSelected.emit(paymentMode);
		}
	}
	switchUser() {
		if (this.cookies.check('usersOnDevice')) {
			let options = { order_id: this.order_details['order_id'] };
			let transaction_token = this.activeRoute.snapshot.queryParams['transaction_token'];
			if (transaction_token) {
				options['transaction_token'] = transaction_token;
			} else {
				options['expires'] = this.sharedService.getTokenExpiryTime();
			}
			this.router.navigate(['user-account'], { queryParams: options });
		}
	}

	handleButtonClick = () => (this.isCollapsibleOpen ? this.fireCloseEvent() : this.fireOpenEvent());

	fireOpenEvent() {
		this.order_details = JSON.parse(JSON.stringify(this.orderDetailServeice.getOrderDetails()));
		setTimeout(() => {
			tracking.openFastPayment({
				order_details: this.order_details,
				// merchant_details: this.merchant_details,
				interaction_type: 'manual',
			});

			this.sharedService.updateCollapsibleStatus({
				...this.sharedService.getCollapsibleStatus(),
				fastPaymentOption: true,
			});
		}, 100);
	}
	fireCloseEvent() {
		setTimeout(() => {
			tracking.closeFastPayment({
				order_details: this.order_details,
				// merchant_details: this.merchant_details,
				interaction_type: 'manual',
			});

			this.sharedService.updateCollapsibleStatus({
				...this.sharedService.getCollapsibleStatus(),
				fastPaymentOption: false,
			});
		}, 100);
	}
}
