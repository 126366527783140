<div class="box-accor">
    <div class="box-item">
        <div class="box-row pt-1">

            <div class="col-12 align-self-center">
                <div class="box-date-details">
                    <div class="row">

                        <div class="col-auto p-0" *ngIf="paymentModeName == 'LazyPay'"><img src="assets/images/icons/FBE75Was.png"></div>
                        <div class="col-auto p-0" *ngIf="paymentModeName == 'ICICI PayLater'"><img src="assets/images/icons/XrMDErhB_400x400.png"></div>
                        <div class="col-auto p-0" *ngIf="paymentModeName == 'Ola Money Postpaid'"><img src="assets/images/icons/ola-money.png"></div>
                        <div class="col-auto p-0" *ngIf="paymentModeName == 'X paylater' || paymentModeName == 'Dash Pay Later'"><img src="assets/images/icons/xpaylater.svg"></div>
                        <div class="col-auto p-0" *ngIf="paymentModeName == 'Y paylater' ||  paymentModeName == 'Flash Pay Later'"><img src="assets/images/icons/y-paylater.svg"></div>
                        <div class="col-auto p-0" *ngIf="paymentModeName == 'SBI Pay Later'"><img src="assets/images/icons/SBI-Pay-later.png"></div>
                        <div class="col-auto p-0" *ngIf="paymentModeName == 'Card'"><img src="assets/images/logos/credit-card.png"></div>
                        <div class="col px-2 fS-18"><strong>Pay with {{paymentModeName}}</strong></div>
                    </div>

                </div>
            </div>

            <div class="row" *ngIf="paymentModeName == 'Card'">
                <div class="col-12">
                    <p class="mb-1 mt-1">
                        <strong>
                            An OTP has been sent on {{order_details?.user?.mobile_number}}
                        </strong>
                    </p>
                    <div class="d-flex">
                        <div class="cardTYpe mr-2">
                            <img src="{{
                            'assets/images/logos/card_type/' +
                                selectedPaymentMode?.card_details?.card_type +
                                '.png'
                            }}" [alt]="selectedPaymentMode?.card_details?.card_type" />
                        </div>
                        Card: {{ selectedPaymentMode?.card_details?.card_number }}
                    </div>
                </div>
            </div>
            <div class="row fS-15 mt-2">
                <div class="col">
                    <div class="pt-2" *ngIf="paymentModeName=='LazyPay'">Your Next Due Date is {{dueDate}}</div>
                    <!-- <div class="pt-3" *ngIf="paymentModeName!='LazyPay'">Enter One Time Password sent on your mobile number</div> -->
                    <div class="pt-3" *ngIf="!selectedPaymentMode?.card_details?.redirectUrl">{{showCountdown ? "We have resent the OTP to your registered mobile number" : "Enter the OTP sent on your mobile number"}}</div>
                </div>
            </div>
            <form class="my-3 row" [formGroup]="otpForm">
                <div class="col-md-12 mb-2">
                    <input type="input" autocomplete="off" class="form-control input_02 otp-input" formControlName="otp1" id="otp1" (blur)="handleOTPBlur($event)">
                    <span class="otp-timer" *ngIf="showCountdown && !selectedPaymentMode?.card_details?.redirectUrl">00:{{seconds}}</span>
                </div>
                <div class="col-12 my-2 otp-container">
                    <button type="button" class="btn btn-outline-dark me-2 mr-3 btn-cancel" (click)="cancelPayment()">Cancel</button>
                    <button type="button" class="btn btn-primary btn-orange btn-pay" (click)="payNowTrigger()" [disabled]="otpForm.pristine || otpForm.invalid" [ngClass]="otpForm.valid?'valid':''">Pay
                    <span [innerHTML]="currency.currency"></span> {{grandTotal | number : '1.2-2'}}*</button>
                </div>
                <div class="col-12 mb-2 fS-12 clr-78" *ngIf="!selectedPaymentMode?.card_details?.redirectUrl && !showCountdown">
                    Didn't Receive OTP? <span style="color:#ED1C24;font-weight: 600;" class="text-decoration-none clr-18 cursor" (click)="resendOtp()">RESEND CODE</span>
                </div>

                <div *ngIf="selectedPaymentMode?.card_details?.redirectUrl" class="col-12 mb-2 fS-12 clr-78 d-flex justify-content-between">
                    <span class="text-decoration-none cursor" (click)="resendOtp()">Resend Otp</span>
                    <div class="text-decoration-none cursor" (click)="redirectURLTrigger(selectedPaymentMode?.card_details?.redirectUrl)">
                        Complete on bank's page
                    </div>
                </div>


                <div class="col-12 my-3 fS-12">*You agree with the <a [href]="termsLink?termsLink:'javascript:void(0)'" class="clr-16 td-ul" [target]="termsLink?'_blank':''">terms & conditions</a></div>
            </form>
        </div>
    </div>