import { Component, EventEmitter, Input, OnInit, Output, HostListener } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { timeStamp } from 'console';
import { CookieService } from 'ngx-cookie-service';
import { merchantDetails, orderDetails } from 'src/app/app.models';
import { ApiService } from 'src/app/services/api.service';
import { OrderDetailService } from 'src/app/services/order-detail.service';
import { PaymentModeService } from 'src/app/services/payment-mode.service';
import { SharedBankingService } from 'src/app/services/shared-banking.service';
import { SharedService } from 'src/app/services/shared.service';
import { hasTouchSupport } from 'src/app/utils';
import tracking from 'src/app/utils/tracking';
const UPI_STEPS_LIST = [
  {
    icon: './assets/images/icons/smartphone.png',
    title: 'Step 1',
    description: 'Go to the Google Pay mobile app.'
  },
  {
    icon: './assets/images/icons/smartphone-with-hand.png',
    title: 'Step 2',
    description: 'Check pending requests and approve payment by entering UPI PIN.'
  }
];

@Component({
  selector: 'app-payment-processing',
  templateUrl: './payment-processing.component.html',
  styleUrls: ['./payment-processing.component.css']
})
export class PaymentProcessingComponent implements OnInit {
  selectedPaymentMode;
  @Input() timeout: number = 0;
  @Input() intentFlow: string;
  @Output() closeWindow: EventEmitter<any> = new EventEmitter<any>();
  @Output() windowFocus: EventEmitter<any> = new EventEmitter<any>();
  @Output() cancelPayment: EventEmitter<any> = new EventEmitter<any>();
  @Output() windowClose: EventEmitter<any> = new EventEmitter<any>();
  paymentModeName;
  bank_name;
  config;
  order_details:orderDetails;
  currency;
  isWindowClosed: boolean = false;
  vpaDetail;
  magicUPIDisplayName: string;
  upi_steps= UPI_STEPS_LIST;
  displayResons: boolean = false;
  showPopup: boolean = false;
  reasons = ["Collect request not received", "Don't want to make a purchase right now", "Payment failed in the APP", "Others"];
  selectedReason: string;
  isEnforced: boolean;
  paymentName: string = '';
  merchant_details: merchantDetails;
  isIntent: boolean = false;
  constructor(private sharedPaymentService: PaymentModeService, private apiService: ApiService, private SharedBankingServiceService: SharedBankingService, private cookies: CookieService,private sharedService:SharedService,private orderDetailService:OrderDetailService, public activateRoute: ActivatedRoute) {
    this.sharedPaymentService.sharedSelectedMode.subscribe(res => {
      this.selectedPaymentMode = res;
      this.apiService.fetchPaymentModesMessage().subscribe(res => {
        if (this.selectedPaymentMode['paymentMode'] != 'Netbanking' && (this.selectedPaymentMode['paymentMode'] != 'Credit Card' ||this.selectedPaymentMode['paymentMode'] != 'Debit Card' || this.selectedPaymentMode['paymentMode'] != 'Prepaid Card') && this.selectedPaymentMode['paymentMode'] != 'UPI' && this.selectedPaymentMode['paymentMode'] != 'Wallet') {

          if (res && this.selectedPaymentMode && this.selectedPaymentMode?.paymentMode) {
            const modeName = res[this.selectedPaymentMode?.paymentMode.toLowerCase()] && res[this.selectedPaymentMode?.paymentMode.toLowerCase()]['modeName'];
            if (modeName && modeName === 'Card') {
                this.paymentModeName = this.sharedService.getCardType();
            } else {
                this.paymentModeName = modeName || ''; // default value when modeName is null or undefined
            }
          } else {
              this.paymentModeName = ''; // default value when res or this.selectedPaymentMode is null or undefined
          }
        }
        else {
          this.paymentModeName = this.selectedPaymentMode['paymentMode'];
        }
        this.magicUPIDisplayName = this.selectedPaymentMode.is_magic_UPI ? this.selectedPaymentMode['magicUPIDisplayName'] : '';
        if (this.paymentModeName == 'Netbanking') {
          this.bank_name = this.SharedBankingServiceService.getbankDetails()['bank_name'];
        }
        if (this.paymentModeName == 'Wallet') {
          this.bank_name = this.SharedBankingServiceService.getWallet()['wallet_name'];
        }
        if(this.paymentModeName=='UPI')
        {
          this.vpaDetail=this.sharedService.getupdatedUPIDetail();
          this.upi_steps[0].description = `Go to the ${this.vpaDetail?.magicUPIDisplayName || this.vpaDetail?.vpaAppName || 'UPI'} mobile app.`;
          if(this.vpaDetail.magicUPIDisplayName) {
            this.selectedPaymentMode['logo'] = this.vpaDetail.vpaAppLogo
          }
        }
      });


    });
    this.orderDetailService.sharedOrderDetails.subscribe(order_data=>{
      this.order_details=JSON.parse(JSON.stringify(order_data));
    })
    console.log(this.order_details); 
  }

  // Listen for the 'popstate' event
  @HostListener('window:popstate', ['$event'])
  onPopState(event: any) {
    // Handle the browser back button click
    this.sharedService.updateEnforcePaymentInitiated(false);
  }

  ngOnInit(): void {
    this.config = {
      leftTime: 4 * 60,
      format: "mm:ss"
    };
    // Check if External window is closed.
    this.sharedService.shareWindowService.subscribe(res => {
      this.isWindowClosed = res['closed'];
    })

    this.sharedService.sharedEnforcePaymentDetails.subscribe((res) => {
			this.isEnforced = res;
		});
    this.paymentName = this.sharedService.getPaymentNameDetails();

    this.isIntent = this.activateRoute.snapshot.queryParams['payment_flow'] === 'intent' || this.intentFlow === 'intent';

    if(hasTouchSupport() && this.isIntent){
      this.reasons[0] = "UPI app did not open"
    }

    this.sharedService.updateEnforcePaymentInitiated(true);
    
  }

  countdownEvent(event: any): void {
		if (event.action === 'done') {
			// Countdown has ended
      // 
      this.closeWindow.emit();
			this.cancelPayment.emit(this.selectedReason);
		}
	}

  setFocus() {
    this.windowFocus.emit();
  }
  showReason() {
    this.showPopup = true;
    this.displayResons = true;
  }
  closePopup() {
    this.selectedReason = null;
    this.showPopup = false;
    this.displayResons = false;
  }
  ngOndestroy() {
    this.closePopup();
  }
  submitReason() {
    // track cancel event
    this.merchant_details = JSON.parse(JSON.stringify(this.orderDetailService.getMerchantDetail()));

    console.log(this.merchant_details);
    
    tracking.cancelPayment({
      order_details: this.order_details,
			merchant_details: this.merchant_details,
			selected_payment_mode: this.selectedPaymentMode,
    })
    this.cancelPayment.emit(this.selectedReason);
  }
  
}

