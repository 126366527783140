import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CookieService } from 'ngx-cookie-service';
import { merchantDetails, orderDetails, resendOTPRequest, user } from 'src/app/app.models';
import { ApiService } from 'src/app/services/api.service';
import { OrderDetailService } from 'src/app/services/order-detail.service';
import tracking from '../../utils/tracking';
import { error } from 'console';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-enter-otp',
  templateUrl: './enter-otp.component.html',
  styleUrls: ['./enter-otp.component.css']
})
export class EnterOtpComponent implements OnInit {
  @Input() nimbbl_mobile_number: string = null;
  @Output() resend_otp_request: EventEmitter<any> = new EventEmitter<any>();
  @Output() submit_otp_request: EventEmitter<any> = new EventEmitter<any>();
  @Input() errorMessage: string = null
  otpForm: FormGroup;
  @Input() showLoader: boolean = false;
  showPopup: boolean = false;
  resendOTPTimerInterval = null;
  resendTimer: number = 30;
  showResendOtpButton = false;
  showCountdown = true;
  isResendOtpBtnDisabled:boolean = false
  user_details: user;
  orderDetails: orderDetails;
  merchant_details:merchantDetails;
  constructor(private fb: FormBuilder, private apiService: ApiService, private cookies: CookieService, private orderDetailService: OrderDetailService) { }

  ngOnInit(): void {
    this.startResendCounter();
    this.orderDetails = JSON.parse(JSON.stringify(this.orderDetailService.getOrderDetails()));
    this.user_details = this.orderDetails['user'];
    this.otpForm = this.fb.group({
      otp1: [null, [Validators.required, Validators.pattern("^((\\+91-?)|0)?[0-9]{1}$")]],
      otp2: [null, [Validators.required, Validators.pattern("^((\\+91-?)|0)?[0-9]{1}$")]],
      otp3: [null, [Validators.required, Validators.pattern("^((\\+91-?)|0)?[0-9]{1}$")]],
      otp4: [null, [Validators.required, Validators.pattern("^((\\+91-?)|0)?[0-9]{1}$")]],
    });
    
    this.merchant_details=JSON.parse(JSON.stringify(this.orderDetailService.getMerchantDetail()));
  }
  startResendCounter() {
    if(this.showCountdown && !this.showResendOtpButton)
    {this.resendOTPTimerInterval=setInterval(() => {
    if (this.resendTimer > 0) {
      this.resendTimer = this.resendTimer - 1;
    }
    else {
      this.stopCountDown();
      this.showResendOtpButton = true;
      this.showPopup = false;
      this.isResendOtpBtnDisabled = false
      this.showCountdown = false;
    }
  }, 1000);}
  
  
  }
  stopCountDown() {
    this.resendOTPTimerInterval && clearInterval(this.resendOTPTimerInterval);
  }
  
  resendOtp() {
    this.isResendOtpBtnDisabled = true;
    this.resendTimer=30;
    tracking.resendOTP({
      user_details: this.user_details,
      order_details: this.orderDetails,
      merchant_details: this.merchant_details
    })

    let rr = new resendOTPRequest();
    rr.mobile_number = this.user_details['mobile_number'].toString();
    let validateToken = this.apiService.checkForValidToken();
    
    if (!validateToken) {
      this.apiService.getEncryptedAuthorizationToken(this.orderDetails['order_id']).subscribe(resp => {
        this.apiService.setTokenCookies(resp['token'],btoa(resp['expires_at']));
        this.apiService.resendOTP(rr).subscribe(res => {
          this.showPopup = true;
        });
      });
    }
    else {
      this.apiService.resendOTP(rr).subscribe(res => {
        if(res['success']) {
          this.showPopup = true;
          this.showCountdown = true;
          this.showResendOtpButton = false;
          this.startResendCounter();
        }

        
      }),(error: HttpErrorResponse) => {
        this.showPopup = false;
        this.showResendOtpButton = true;
        console.log('resendOTP api err: ', error);
        this.isResendOtpBtnDisabled = false
      };
    }

  }
  
  submitOtp(otp) {

    otp = otp['otp1'].toString() + otp['otp2'].toString() + otp['otp3'].toString() + otp['otp4'].toString()
    if (this.otpForm.valid) {

      // window.analytics.identify(this.user_details['user_id'], {
      //   user_id: this.user_details['user_id'],
      //   name: this.user_details['first_name'],
      //   email: this.user_details['email'],
      //   mobile: this.user_details['mobile'],
      // });

      tracking.enterOTP({
        user_details: this.user_details,
        order_details: this.orderDetails,
        merchant_details: this.merchant_details
      });
  
      this.submit_otp_request.emit(otp);
    }

  }

  setPrevFocus(event) {
    if(event.keyCode === 8 && !event.target.value.length) {
      let focus = parseInt(event.target.id.charAt(event.target.id.length - 1)) - 1;
      if (focus < 5 && focus >0) {
        let focusEl = focus.toString();
        setTimeout(() => {
          document.getElementById("otp" + focusEl).focus();
        }, 0);
      }
    }

  }
  setFocus(event) {
    if (event.keyCode !== 8 && event.target.value.length == event.target.maxLength) {
      let focus = parseInt(event.target.id.charAt(event.target.id.length - 1)) + 1;
      if (focus < 5) {
        let focusEl = focus.toString();
        setTimeout(() => {
          document.getElementById("otp" + focusEl).focus();
        }, 0);
      }

    }
  }
  closeModal() {
    this.showPopup = false;
  }
}
