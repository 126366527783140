import { Component, OnInit, HostListener } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import {
  merchantDetails,
  orderDetails,
  paymentStatusPollingRequest,
} from 'src/app/app.models';
import { ApiService } from 'src/app/services/api.service';
import * as CryptoJS from 'crypto-js';
import { CookieService } from 'ngx-cookie-service';
import { OrderDetailService } from 'src/app/services/order-detail.service';
import { PaymentModeService } from 'src/app/services/payment-mode.service';
import FingerprintJS from '@fingerprintjs/fingerprintjs';
import { SharedService } from 'src/app/services/shared.service';
import { CommonService } from 'src/app/services/common.service';

import tracking from '../../utils/tracking';
import { updateCheckoutId } from '../../utils/checkoutId';
import { ModalService } from 'src/app/services/modal.service';
import { isObjectEmpty } from 'src/app/utils';

declare var globalCloseCheckoutModal: Function;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
})
export class HeaderComponent implements OnInit {
  time;
  merchant_detail: merchantDetails;
  headerText: string = null;
  showPopup: boolean = false;
  showConfirmationModal: boolean = false;
  displayResons: boolean = false;
  reasons;
  selectedReason;
  order_details: orderDetails;
  windowEvent;
  paymentMethodDetails;
  seconds: number = 59; // #NOTE: it is dependent with `circle-wrap .circle .fill` animation value
  interval = null;
  isTokenInQueryParams = false;
  egtToken = null;
  transaction_id: any = null;
  selectedPaymentMode: string = null;
  orderIdFromParams: string = null;

  constructor(
    private apiService: ApiService,
    private router: Router,
    private cookies: CookieService,
    private shredOrderService: OrderDetailService,
    private sharedService: SharedService,
    private activeRoute: ActivatedRoute,
    private sharedPaymentservice: PaymentModeService,
    private modalService: ModalService,
    private commonService: CommonService,
  ) {
    this.sharedService.shareWindowService.subscribe((res) => {
      this.windowEvent = res;
    });

    this.activeRoute.queryParams.subscribe((params) => {
      this.selectedPaymentMode = params['paymentMode'] || '';
      if (
        params['order_id'] &&
        !this.sharedService.getTokenDetails()['accessToken']
      ) {
        this.apiService
          .getEncryptedAuthorizationToken(params['order_id'])
          .subscribe(
            (resp) => {
              if(resp['status']) {
                tracking.egtCallError({
                  status_code: resp['status'],
                  error_message: resp['error_msg']
                });
                return (console.log(resp['status_code'], resp['error_msg']));
              }
              // this.egtToken = this.generateToken(resp['token'], resp['expires_at']) //#TODO: will remove after testing
              this.apiService.setTokenCookies(
                resp['token'],
                btoa(resp['expires_at'])
              );
            },
            (error) => {              
              if(error['status'] >= 400) {
                this.sharedService.updateErrorDetails(error);
                tracking.egtCallError({
                  status_code: error['status'],
                  error_message: error['error_msg']
                });
              }
              console.log(error, 'Header Component')
            }
          );
      }
    });
  

    // fetch order detailform order serice

    this.shredOrderService.sharedOrderDetails.subscribe((orderDetail) => {
      // console.log('order detail form shared service');

      this.order_details = JSON.parse(JSON.stringify(orderDetail));
      // console.log(this.order_details['order_id']);
      if (this.order_details['order_id']) {
        this.order_details = JSON.parse(JSON.stringify(orderDetail));
        if (!this.merchant_detail) this.fetchMerchantDetail();
      }
    });

    // to handle refresh checkout
    this.shredOrderService.sharedOrderDetails.subscribe((order) => {
      if (order && Object.keys(order).length) {
        if (order['callback_mode'] === 'callback_url_redirect') {
          this.cookies.set('is_popup', 'false', undefined, '/');
        } else {
          this.cookies.set('is_popup', 'true');
        }
      }
      setTimeout(() => {
        this.activeRoute.queryParams.subscribe((params) => {
          if (params['order_id']) {
            if (
              this.cookies.get('is_popup') !== 'true' &&
              !this.cookies.get('no_refresh') &&
              this.cookies.get('pagerefresh') 
              // && !Object.keys(this.shredOrderService.getOrderDetails()).length
            ) {
              // here, `no_refresh` cookie is for CC/DC
              this.cookies.delete('pagerefresh', '/');
              this.cookies.delete('pagerefresh', '/payment'); //#TODO: rectify it. currently in preprod same cookie store in two diff path `/` and `/payment`
              let options = { order_id: params['order_id'] };
              if (params['transaction_token']) {
                options['transaction_token'] = params['transaction_token'];
              } else {
                options['expires'] = this.sharedService.getTokenExpiryTime();
              }
              if(params['payment_mode']){
                options['expires'] = this.sharedService.getTokenExpiryTime();
                let bank_name = this.activeRoute.snapshot.queryParams['bank_code'];
							  let wallet_name = this.activeRoute.snapshot.queryParams['wallet_code'];
                let payment_flow = this.activeRoute.snapshot.queryParams['payment_flow'];
							  let upi_id = this.activeRoute.snapshot.queryParams['upi_id'];
							  let upi_app_code = this.activeRoute.snapshot.queryParams['upi_app_code'];
                  options['payment_mode'] = params['payment_mode'];
                  if(bank_name){
										options['bank_code'] = bank_name; //State Bank of India
									}else if(wallet_name){
										options['wallet_code'] = wallet_name; ///Airtel Money
									}else if(payment_flow){
										if(payment_flow === 'collect'){
											options['payment_flow'] = 'collect';
											upi_id ? options['upi_id'] = upi_id : null;
										}else if(payment_flow === 'intent'){
											options['payment_flow'] = 'intent';
											upi_app_code ? options['upi_app_code'] = upi_app_code : null;
										}
									}
                  this.router.navigate(['/payment/netbanking'], { queryParams: options }); 
              }
              if (!params['response']) {
                this.router.navigate(['/'], { queryParams: options });
              }
            }
          }
        });
      }, 1000);

      // if(this.cookies.get('isEnforced') === 'true'){
        
      //   this.sharedService.updateEnforcePaymentDetails(true);
      // }

      this.cookies.delete('no_refresh', '/');
      this.cookies.delete('no_refresh', '/payment'); //#TODO: rectify it. currently in preprod same cookie store in two diff path `/` and `/payment`
    });
  }

  generateToken(transaction_token, expires_at) {
    const transaction_payload = {
      token: transaction_token,
      expires_at: expires_at,
    };

    var t = JSON.stringify(transaction_payload);
    let token = btoa(t);
    return token;
  }

  ngOnInit(): void {
    // console.log("Component: header component");
    // this.generateToken();

    this.activeRoute.queryParams.subscribe((params) => {
      if(params['order_id']) {
        this.orderIdFromParams = params['order_id'];
      }
    });

    this.generateFingerPrint();

    this.reasons = [
      'Unable to find my payment method',
      'Unable to complete the payment',
      "Don't want to make a purchase right now",
      "Don't understand how to proceed",
      'Others',
    ];
    this.headerText = 'Are you sure you want to cancel this payment?';
  }

  redirectToFailure() {
    this.showConfirmationModal = false;
    !isObjectEmpty(this.order_details) && this.submitReason('Checkout timer has expired');
  }

  startCountDown() {
    this.seconds = 59;
    this.interval = setInterval(() => {
      if (this.seconds > 0) {
        this.seconds = this.seconds - 1;
      } else {
        this.stopCountDown();
        this.redirectToFailure();
      }
    }, 1000);
  }

  stopCountDown() {
    this.interval && clearInterval(this.interval);
  }

  showConfirmationMessage() {
    this.showPopup = true;
    document.querySelector('body').classList.add('noscroll');
    setTimeout(() => {
      document.getElementById('noBtn') &&
        document.getElementById('noBtn').focus();
    }, 100);
  }
  showReason() {
    if (this.windowEvent?.frames) {
      this.windowEvent.close();
    }
    this.headerText = 'Tell us why you want to cancel';
    this.displayResons = true;
  }

  closeBox() {
    this.showPopup = false;
    if (this.windowEvent) {
      typeof this.windowEvent.focus == 'function' && this.windowEvent.focus();
    }
    document.querySelector('body').classList.remove('noscroll');
  }
  submitReason(closeReason = 'user closed') {
    let transactionDetails = this.sharedService.getTransactionData();
    this.transaction_id = transactionDetails['transaction_id'];

    let paymentStatus = this.checkstatusOftransaction();
    paymentStatus.then((res) => {
      console.log(res);

      this.order_details = JSON.parse(
        JSON.stringify(this.shredOrderService.getOrderDetails())
      );
      this.cookies.set('no_refresh', 'true');

      tracking.checkoutClosed({
        order_details: this.order_details,
        close_reason: closeReason,
        selected_reason: this.selectedReason,
      });
      let data = {
        order_id:
          this.order_details && this.order_details['order_id']
            ? this.order_details['order_id']
            : this.orderIdFromParams,
        order_level: 6,
        closed_reason: this.selectedReason || closeReason,
        checkout_id: this.order_details['checkout_id'],
      };
      this.apiService.updateOrderLevel(data).subscribe(
        (res) => {},
        (error) => {}
      );
      let orderUpdateData = {
        order_id: this.order_details['order_id'] || this.orderIdFromParams,
        cancellation_reason: this.selectedReason,
      };
      tracking.updateOrderAPIPayload({
        order_id: this.order_details['order_id'] || this.orderIdFromParams,
        close_reason: this.selectedReason,
        from: 'Header Component 1',
      })
      this.apiService.updateOrder(orderUpdateData).subscribe(
        (response) => {
          if (this.windowEvent.frames) {
            this.windowEvent.close();
          }
          this.sharedService.updateuseAnotherAccount('no');
          this.commonService.getCallBackPayloadResponse(this.order_details, this.transaction_id);
        },
        (error) => {
          throw error;
        }
      );
    });
  }
  async generateFingerPrint() {
    const fpPromise = FingerprintJS.load();
    const fp = await fpPromise;
    const result = await fp.get();

    // This is the visitor identifier:
    const visitorId = result.visitorId;
    this.sharedService.updateFingerPrint(visitorId);
  }

  // to show built in customization dialog on page refresh
  @HostListener('window:beforeunload')
  beforeUnloadHandler() {
    const order_id = this.order_details?.order_id || this.orderIdFromParams;
    if (!order_id) {
      return;
    }

    const cancellation_reason = "Browser/Tab closed by the user";

    const orderUpdateData = {
      order_id,
      cancellation_reason,
    };

    tracking.checkoutClosed({
      order_details: this.order_details,
      close_reason: cancellation_reason,
      selected_reason: this.selectedReason,
    });

    
    tracking.updateOrderAPIPayload({
      order_id: this.order_details['order_id'],
      close_reason: this.selectedReason,
      from: 'Header Component 2',
    })

    this.apiService.updateOrder(orderUpdateData)
      .subscribe(
        () => {},
        (error) => { throw error; }
      );

    if (!this.cookies.get('no_refresh')) {
      updateCheckoutId(order_id);
    }

    const isPopup = this.cookies.get('is_popup') === 'false';
    const hasCloseFunction = typeof this.windowEvent.close === 'function';

    if (isPopup && !this.cookies.get('no_refresh')) {
      this.cookies.set('pagerefresh', 'true', undefined, '/');
      if (hasCloseFunction) {
        this.windowEvent.close();
      }
      return false;
    }

    if (hasCloseFunction) {
      this.windowEvent.close();
    }

    return null;
  }

  redirectToHomePage() {
    this.activeRoute.queryParams.subscribe((params) => {
      if (params['order_id']) {
        this.stopCountDown();
        this.showConfirmationModal = false;
        let options = { order_id: params['order_id'] };
        let transaction_token = params['transaction_token'];
        if (transaction_token) {
          options['transaction_token'] = transaction_token;
        } else {
          options['expires'] = this.sharedService.getTokenExpiryTime();
        }
        // console.log("TriggerHomePage", options)
        this.router.navigate(['/'], { queryParams: options });
      }
    });
  }

  showReasonPopup() {
    this.showConfirmationModal = false;
    this.displayResons = true;
    this.showPopup = true;
    this.stopCountDown();
  }
  fetchMerchantDetail() {
    // console.log('fetch merchant Detail called');
    let merchantId = this.order_details['sub_merchant_id'];

    this.apiService.fetchMerchantDetail(merchantId).subscribe((res) => {
      this.merchant_detail = JSON.parse(JSON.stringify(res));
      this.shredOrderService.updateMerchantDetails(this.merchant_detail);
      /**
       * Set background color and text color from merchant_detail
       */
      const hasFooterObj = this.merchant_detail.hasOwnProperty('footer');
      const bgColor = this.merchant_detail['checkout_background_color'] || '#f93576';
      const textColor = this.merchant_detail['checkout_text_color'] || '#ffffff';
      const bgFooterColor = (hasFooterObj && this.merchant_detail['footer']['footer_color']) || '#000000';
      
      const isFooterTextColorWhite = hasFooterObj && 
        this.merchant_detail.footer['footer_text_color_white'] === true || 
        this.merchant_detail.footer['footer_text_color_white'] === undefined;
    
      const isHex = /^#([0-9a-f]{3}){1,2}$/i;
    
      const checkoutFooterFontColor = isFooterTextColorWhite ? '#FFFFFF' : '#000000';
      const checkoutBackgroundColor = isHex.test(bgColor) ? bgColor : null;
      const checkoutFontColor = textColor || null;
      const checkoutFooterBackgroundColor = isHex.test(bgFooterColor) ? bgFooterColor : null;
    
      if (
        this.merchant_detail.hasOwnProperty('footer') &&
        Object.keys(this.merchant_detail?.footer).length > 0 && 
        checkoutFooterFontColor
      ) {
        document.documentElement.style.setProperty('--checkout-footer-font-color', checkoutFooterFontColor);
      }
    
      if (checkoutBackgroundColor) {
        document.documentElement.style.setProperty('--checkout-background-color', checkoutBackgroundColor);
      }
    
      if (checkoutFontColor) {
        document.documentElement.style.setProperty('--checkout-font-color', checkoutFontColor);
      }
    
      if (checkoutFooterBackgroundColor) {
        document.documentElement.style.setProperty('--checkout-footer-background-color', checkoutFooterBackgroundColor);
      }
    });
  }
  closeModal() {
    this.showPopup = false;
    this.displayResons = false;
    document.querySelector('body').classList.remove('noscroll');
    this.selectedReason = null;
    this.headerText= "Are you sure you want to cancel this payment?";
  }
  checkstatusOftransaction() {
		let rr = new paymentStatusPollingRequest();
		rr.order_id = this.order_details['order_id'];
		rr.payment_mode = this.selectedPaymentMode;
		rr.transaction_id = this.transaction_id;

    return new Promise((resolve, reject) => {
      if (this.transaction_id) {
        this.apiService.paymentStatusConfirmation(rr).subscribe(
          (responseData) => {
            if (responseData) {
              resolve(responseData['status']);
            }
          },
          (error) => {
            resolve('failed');
          }
        );
      } else {
        resolve('failed');
      }
    });
  }
}
