<div
  (mouseenter)="stopCountDown()"
  (mouseleave)="countDown()"
  class="toast_wrapper"
  [@openClose]="toastService.data && toastService.data.show ? 'open' : 'closed'"
>
  <div class="inner_wrapper">
    <div class="flex" *ngIf="toastService.data">
      <div class="toast_content">
        <div class="toast_text">
          {{ toastService.data.content }}
        </div>
      </div>
    </div>
  </div>

  <div
    class="progress_bar"
    [ngClass]="{ success: toastService.data && toastService.data.type === 1 }"
    #element
  ></div>
</div>
