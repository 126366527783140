<div class="powerby">
  <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
    <defs>
      <style>
        .a {
          fill: none;
        }

        .b {
          fill: #fff;
        }
      </style>
    </defs>
    <path class="a" d="M0,0H14V14H0Z" />
    <path class="b"
      d="M12.333,6.667h.583a.583.583,0,0,1,.583.583v5.833a.583.583,0,0,1-.583.583H3.583A.583.583,0,0,1,3,13.083V7.25a.583.583,0,0,1,.583-.583h.583V6.083a4.083,4.083,0,1,1,8.167,0Zm-1.167,0V6.083a2.917,2.917,0,1,0-5.833,0v.583ZM7.667,9v2.333H8.833V9Z"
      transform="translate(-1.25 -0.833)" />
  </svg>
  <strong>powered by</strong><br />
  <img [src]="footer_logo_url" style="width: 50px; margin: 10px 0;"/>

  <div>{{ footer_sub_text }}</div>
  <div *ngIf="pci_dss_enabled" class="pci-dss"><img src="assets/images/icons/PCI-DSS-2.png" class="mr-1" alt=""> 256 bit encryption</div>
  <div><img src="assets/images/icons/ezgif.com-gif-maker.png" class="mr-1" alt=""> Made in India</div>
</div>
