<div class="top_bg">
    <app-new-on-nimbl [isOtpVerify]="true" [userFound]="true" [welcome]=false>
    </app-new-on-nimbl>
    <app-user-account></app-user-account>
    <app-another-account></app-another-account>
</div>

<app-order-summary [page]="'user-is-eligible'"></app-order-summary>
<app-pay-with></app-pay-with>
<app-payment-successful></app-payment-successful>
<app-payment-failure></app-payment-failure>
<app-pay-with-otp></app-pay-with-otp>
<app-payment-processing></app-payment-processing>