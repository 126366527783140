import { BrowserModule } from '@angular/platform-browser';
import { ErrorHandler, NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
// import { WelcomeComponent } from './component/welcome/welcome.component';
import { UserInfoComponent } from './component/user-info/user-info.component';
import { OrderSummaryComponent } from './component/order-summary/order-summary.component';
import { FastPaymentOptionComponent } from './component/fast-payment-option/fast-payment-option.component';
import { OtherpaymentOptionComponent } from './component/otherpayment-option/otherpayment-option.component';
import { PowerByComponent } from './component/power-by/power-by.component';
import { HomeComponent } from './pages/home/home.component';
import { UserDetailComponent } from './pages/user-detail/user-detail.component';
import { UserOtherDetailComponent } from './component/user-other-detail/user-other-detail.component';
import { NewOnNimblComponent } from './component/new-on-nimbl/new-on-nimbl.component';
import { VerifyOtpComponent } from './pages/verify-otp/verify-otp.component';
import { EnterOtpComponent } from './component/enter-otp/enter-otp.component';
import { PaymentComponent } from './pages/payment/payment.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HeaderComponent } from './component/header/header.component';
import { UserIsEligibleComponent } from './pages/user-is-eligible/user-is-eligible.component';
import { PayWithComponent } from './component/pay-with/pay-with.component';
import { TimerComponent } from './component/timer/timer.component';
import { PaymentSuccessfulComponent } from './component/payment-successful/payment-successful.component';
import { PaymentFailureComponent } from './component/payment-failure/payment-failure.component';
import { UserAccountComponent } from './component/user-account/user-account.component';
import { AnotherAccountComponent } from './component/another-account/another-account.component';
// import { ShopMoreComponent } from './pages/shop-more/shop-more.component';
// import { ShopMoreProductsComponent } from './component/shop-more-products/shop-more-products.component';
// import { OrderCompletedComponent } from './component/order-completed/order-completed.component';
import { PayWithOtpComponent } from './component/pay-with-otp/pay-with-otp.component';
import { PaymentProcessingComponent } from './component/payment-processing/payment-processing.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgHttpLoaderModule } from 'ng-http-loader';
// import { HttpLoaderComponent } from './component/http-loader/http-loader.component';
import { DatePipe } from '@angular/common';
import { MoneyDeductionConfirmationComponent } from './component/money-deduction-confirmation/money-deduction-confirmation.component';
import { MobileRedirectComponent } from './pages/mobile-redirect/mobile-redirect.component';
import { CountdownModule } from 'ngx-countdown';
import { PaymentResponseComponent } from './pages/payment-response/payment-response.component';
import { CreditCardDirectivesModule } from 'angular-cc-library';
import { CookieService } from 'ngx-cookie-service';
import { ListOfCountryCodeComponent } from './component/list-of-country-code/list-of-country-code.component';
import { GlobalErrorHandler } from './services/global-error-handler.service';
import { HttpLoadingInterceptor } from './services/http-loading-interceptor.service';
import { OffersComponent } from './component/offers/offers.component';
import { ToastComponent } from './component/toast/toast.component';
import { CloseCheckoutComponent } from './component/close-checkout/close-checkout.component';
import { EMIPlanCardComponent } from './component/emi-plan-card/emi-plan-card.component';
import { UpiQrCodeComponent } from './component/upi-qr-code/upi-qr-code.component';
import { UpiIntentComponent } from './component/upi-intent/upi-intent.component';

declare global {
  interface Window {
    analytics: any;
    newrelic: any;
    nimbbl_web: any;
    nimbbl_webview: any;
    ReactNativeWebView: any;
		$ns: any;
		ns: any;
    
  }
}
@NgModule({
  declarations: [
    AppComponent,
    // WelcomeComponent,
    UserInfoComponent, // imp
    OrderSummaryComponent, // First Load
    FastPaymentOptionComponent, // First Load
    OtherpaymentOptionComponent, // First Load
    PowerByComponent, // First Load
    HomeComponent, // Check if it is used or not
    UserDetailComponent, // First Load
    UserOtherDetailComponent,
    NewOnNimblComponent,
    VerifyOtpComponent,
    EnterOtpComponent,
    PaymentComponent, // First Load
    HeaderComponent, // First Load
    UserIsEligibleComponent,
    PayWithComponent,
    PaymentSuccessfulComponent,
    PaymentFailureComponent,
    UserAccountComponent,
    AnotherAccountComponent,
    // ShopMoreComponent,
    // ShopMoreProductsComponent,
    // OrderCompletedComponent,
    PayWithOtpComponent,
    PaymentProcessingComponent,
    // HttpLoaderComponent,
    MoneyDeductionConfirmationComponent, //imp
    MobileRedirectComponent,
    PaymentResponseComponent,
    ListOfCountryCodeComponent,
    TimerComponent,
    OffersComponent,
    ToastComponent,
    CloseCheckoutComponent,
    EMIPlanCardComponent,
    UpiQrCodeComponent,
    UpiIntentComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,
    NgHttpLoaderModule.forRoot(),
    CountdownModule,
    CreditCardDirectivesModule,
    BrowserAnimationsModule
  ],
  providers: [
    DatePipe,
    CookieService,
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
    {
      // interceptor to show loading spinner
      provide: HTTP_INTERCEPTORS,
      useClass: HttpLoadingInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
