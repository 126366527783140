import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { merchantDetails, orderDetails } from 'src/app/app.models';
import { OrderDetailService } from 'src/app/services/order-detail.service';
import { ApiService } from 'src/app/services/api.service';
import { PaymentModeService } from 'src/app/services/payment-mode.service';
import { SharedService } from 'src/app/services/shared.service';
import { isMobile } from './../../../app/utils/device.js'

import tracking from '../../utils/tracking';
import { runInThisContext } from 'vm';
@Component({
	selector: 'app-otherpayment-option',
	templateUrl: './otherpayment-option.component.html',
	styleUrls: ['./otherpayment-option.component.css'],
})
export class OtherpaymentOptionComponent implements OnInit {
	@Input() isEnable: boolean = false;
	@Input() paymentAllowed;
	@Input() showLoader: boolean = false;
	@Output() paymentModeSelected: EventEmitter<any> = new EventEmitter<any>();

	netbanking: boolean = false;
	creditCard: boolean = false;
	debitCard: boolean = false;
	prepaidCard: boolean = false;
	wallet: boolean = false;
	UPI: boolean = false;
	paymentEta = [];
	order_details: orderDetails;
	merchant_details: merchantDetails;
	showOtherPaymentCard: boolean = true;
	isCollapsibleOpen: boolean = false;
	isCardless: boolean = false;
	currency;
	isMobile;
	isUpiIntentAllowed: boolean = false;
	isUpiCollectAllowed: boolean = false;
	constructor(
		private paymentModeService: PaymentModeService,
		private cookies: CookieService,
		private orderDetails: OrderDetailService,
		private sharedService: SharedService,
		private apiService: ApiService
	) {
		// this.order_details=JSON.parse(JSON.stringify(this.orderDetails.getOrderDetails()));
		this.orderDetails.sharedOrderDetails.subscribe((resp) => {
			this.order_details = JSON.parse(JSON.stringify(resp));
		});
		this.paymentModeService.sharedPaymentMode.subscribe((res) => {
			if (res) {
				if (res.includes('UPI')) {
					this.UPI = true;
				}
				if (res.includes('Credit Card')) {
					this.creditCard = true;
				}
				if (res.includes('Debit Card')) {
					this.debitCard = true;
				}
				if (res.includes('Prepaid Card')) {
					this.prepaidCard = true;
				}
				if (res.includes('Netbanking')) {
					this.netbanking = true;
				}
				if (res.includes('Wallet')) {
					this.wallet = true;
				}
			}
		});

		this.sharedService.sharedCollapsibleStatus.subscribe((res) => {
			this.isCollapsibleOpen = res.otherPaymentOption;
		});
		this.orderDetails.sharedMerchantDetails.subscribe((merchant_data) => {
			this.merchant_details = JSON.parse(JSON.stringify(merchant_data));
			this.showOtherPaymentCard = this.merchant_details['other_payment_enabled'];
		});

		this.sharedService.sharedIsCardlessPayment.subscribe(res => {
			this.isCardless = res
		})
	}

	ngAfterViewInit(): void {
		// make payment body scroll as per previous scrollTop value after payment cancelation
		setTimeout(() => {
			document.documentElement.scrollTop = this.sharedService.getCollapsibleStatus().docOffset || 0;
		}, 600);
	}

	ngOnInit(): void {

		this.isMobile = isMobile();
		this.currency = this.sharedService.getCurrency();
		this.order_details = JSON.parse(JSON.stringify(this.orderDetails.getOrderDetails()));
		this.merchant_details = JSON.parse(JSON.stringify(this.orderDetails.getMerchantDetail()));
		let triggerTracker = setInterval(() => {
			if (!this.showLoader) {
				if (this.paymentAllowed) {
					clearInterval(triggerTracker);

					if (this.paymentEta.length) {
						tracking.renderOtherPayment({
							order_details: this.order_details,
							merchant_details: this.merchant_details,
							payment_eta: this.paymentEta,
						});
					}
				}
			}
		}, 100);
	}
	
	clickPaymentMethod(
		paymentMode,
		logo,
		eta,
		payment_partner,
		additionalCharges = 0,
		paymentModeFullObj
	) {

		if(payment_partner === 'Spur'){
			paymentModeFullObj.mode === paymentModeFullObj.modeName
		}

		let arr = {
			paymentMode: paymentMode,
			logo: logo,
			eta: eta,
			payment_partner: payment_partner,
			extra_charges: additionalCharges,
			isFastPayment: false,
			offerID: paymentModeFullObj.offerID,
			offerApplicable:
				paymentModeFullObj.offer_applicable === 'Y' ? paymentModeFullObj.offer_applicable : false,
			offerType: paymentModeFullObj.offer_type,
			offerName: paymentModeFullObj.offer_name,
			intentConvFee: paymentModeFullObj.intentConvFee ? paymentModeFullObj.intentConvFee : null,
			collectConvFee: paymentModeFullObj.collectConvFee ? paymentModeFullObj.collectConvFee : null,
		};
		this.sharedService.updateCollapsibleStatus({
			...this.sharedService.getCollapsibleStatus(),
			docOffset: document.documentElement.scrollTop,
		}); // set document offset so when we go back to payment, it will maintain the offsetTop
		this.paymentModeService.updateSelectedMode(arr);
		this.paymentModeSelected.emit(paymentMode);
	}
	ngOnChanges() {
		if (this.isEnable && this.paymentAllowed) {
			let color: string;
			let badge: string;
			this.paymentEta = [];
			
			
			const upiIndex = this.paymentAllowed.findIndex(mode => mode.mode === "UPI");
			if (upiIndex !== -1) {
				const upiMode = this.paymentAllowed.splice(upiIndex, 1)[0];
				this.isUpiIntentAllowed = upiMode.isIntentAllowed;
				this.isUpiCollectAllowed = upiMode.isCollect
				this.paymentAllowed.splice(1, 0, upiMode);

				console.log(this.paymentAllowed);
		}
		
			// this.paymentAllowed.sort((a, b) => a.eta - b.eta);
			this.paymentAllowed.forEach(element => {
				const convFees = element['ConvenienceFees'];
				
				if (convFees && convFees.includes('-')) {
					const [min, max] = convFees.split('-').map(value => value.trim());
			
					element['ConvenienceFees'] = (min === max) ? min : convFees;
					element['isrange'] = (min !== max);
				} else {
					element['isrange'] = false;
				}
			});
			
			this.paymentAllowed.forEach((element) => {
				this.paymentEta.push(element['mode']);
			});
		}
	}
	
	getPaymentModeText(mode: string): string {
		if (mode === 'Credit Card' || mode === 'Debit Card' || mode === 'Prepaid Card') {
			return 'select cards';
		}else if(mode === 'Netbanking'){
			return 'select banks'
		}
		return mode;
	}

	handleButtonClick = () => (this.isCollapsibleOpen ? this.fireCloseEvent() : this.fireOpenEvent());

	fireOpenEvent() {
		setTimeout(() => {
			tracking.openOtherPayment({
				order_details: this.order_details,
				merchant_details: this.merchant_details,
				interaction_type: 'manual',
			});

			this.sharedService.updateCollapsibleStatus({
				...this.sharedService.getCollapsibleStatus(),
				otherPaymentOption: true,
			});
		}, 100);
	}
	fireCloseEvent() {
		setTimeout(() => {
			// console.log('Other payment options closed');

			tracking.closeOtherPayment({
				order_details: this.order_details,
				merchant_details: this.merchant_details,
				interaction_type: 'manual',
			});

			this.sharedService.updateCollapsibleStatus({
				...this.sharedService.getCollapsibleStatus(),
				otherPaymentOption: false,
			});
		}, 100);
	}
}
