<div class="phone-number-btn my-3">
    <form [formGroup]="userForm" novalidate>
        <div class="formGrounContainer">
            <div class="flagMobile">
                <ng-container *ngIf="mca_allowed?.mca_allowed == true || mca_allowed?.ica_allowed == true else showDrop">
                    <div class="flagCC" (click)="showCountryCodeList()">
                        <span class="flag"><img src="assets/images/countryFlags/{{userCountryData?.flag}}" class="img-fluid"></span>
                        <input type="text" class="form-control input_01" [value]="userCountryData?.dailerCode" readonly>
                        <span *ngIf="mca_allowed?.ica_allowed" class="icon icon--chevron"></span>
                    </div>
                    <div class="TelePhone"><input type="tel" class="form-control input_01" formControlName="mobileNumber" placeholder="Enter Phone No." required ([ngModel])="user_mobileNumber"></div>
                </ng-container>
                <ng-template #showDrop>
                    <div class="flagCC">
                        <span *ngIf="mca_allowed?.ica_allowed" class="icon icon--chevron"></span>
                        <input type="text" class="form-control input_01" [value]="userCountryData?.dailerCode" readonly>
                        <span class="flag"><img src="assets/images/countryFlags/{{userCountryData?.flag}}" class="img-fluid"></span>
                    </div>
                    <div class="TelePhone"><input type="tel" class="form-control input_01" formControlName="mobileNumber" placeholder="Enter Phone No." required ([ngModel])="user_mobileNumber"></div>
                </ng-template>

            </div>

            <div class="btnContainer">
                <div *ngIf="userForm.controls['mobileNumber'].invalid && (userForm.controls['mobileNumber'].dirty || userForm.controls['mobileNumber'].touched)" class="alert alert-danger" [ngClass]="merchant_detail.continue_btn_is_white ? 'text-light' : 'text-dark'">
                    <div *ngIf="userForm.controls['mobileNumber'].errors.required">Please enter vaild mobile number
                        <span class="exclametry">!</span>
                    </div>
                    <div *ngIf="userForm.controls['mobileNumber'].errors.pattern">Please enter vaild mobile number <span class="exclametry">!</span>
                    </div>
                </div>
                <div *ngIf="errorMessage" class="alert alert-danger pt-4 errorMessage">{{errorMessage}}
                </div>
            </div>

            <button _ngcontent-wkn-c46="" class="btn btn-continue" (click)="sumbitForm(userForm.value)" [disabled]="userForm.controls['mobileNumber'].invalid || showLoader" [ngClass]="userForm.valid? merchant_detail?.continue_btn_is_white ? 'btn-light' : 'btn-dark' : merchant_detail?.continue_btn_is_white ? 'btn-outline-light' : 'btn-outline-dark'"><span *ngIf="!showLoader">Continue</span>
                <span *ngIf="showLoader" class="loader"> <div class="loaderhmtl mobileNumber"></div></span>
            </button>
        </div>



    </form>
    <!-- #TODO: this is temparory unless the fingerprint issue resolve -->
    <!-- <div *ngIf="dispalyBtn" class="useAnotherAccount text-center my-4">
        <div class="my-2 or">OR</div>
        <button class="btn btn-outline-orange"
            [ngClass]="getButtonClass(true)"
            (click)="redirectToAccountList()">Use existing account
        </button>
    </div> -->
</div>