<div class="phone-number-btn my-4">
    <input type="text" class="form-control input_01" id="formGroupExampleInput" placeholder="Enter Phone No." autocomplete="off">
    <a href="#" class="orange-arrowbtn"><svg xmlns="http://www.w3.org/2000/svg" width="34" height="34"
        fill="currentColor" class="bi bi-arrow-right-circle-fill" viewBox="0 0 16 16"
        >
        <path fill-rule="evenodd"
            d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-11.5.5a.5.5 0 0 1 0-1h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5z" />
    </svg></a>
</div>
<div class="my-4 fS-18 text-center"><strong>OR</strong></div>
<div class="my-4 text-center">
    <button type="button" class="btn btn btn-primary btn-orange me-2">Use another acount</button>
</div>