import { Component, NgZone, HostListener } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { merchantDetails, orderDetails, user } from './app.models';
import { OrderDetailService } from './services/order-detail.service';
import { Spinkit } from 'ng-http-loader';
import { ApiService } from './services/api.service';
import tracking from './utils/tracking';
import { SharedService } from './services/shared.service';
import { PaymentModeService } from 'src/app/services/payment-mode.service';
import { getCheckoutId } from './utils/checkoutId';
@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.css'],
})
export class AppComponent {
	title = 'nimbblWeb';
	spinnerStyle = Spinkit;
	user: user;
	order_details: orderDetails;
	merchant_details: merchantDetails;
	intentpaymentModes;
	constructor(
		public activateRoute: ActivatedRoute,
		public orderService: OrderDetailService,
		private apiService: ApiService,
		private ngZone: NgZone,
		private sharedService: SharedService,
		private sharedPaymentservice: PaymentModeService
	) {
		this.activateRoute.queryParams.subscribe((params) => {});
	}
	ngOnInit() {
		// console.log("Component: app component")
		this.addMessageEventListener();

		window.addEventListener('keyup', this.disableF5);
		window.addEventListener('keydown', this.disableF5);

		//in
		window.nimbbl_web = window.nimbbl_web || {};
		window.nimbbl_webview = window.nimbbl_webview || {};
		window.nimbbl_web.UPIIntentAvailable = this?.UPIIntentAvailable.bind(this);
		window.nimbbl_webview.nimbbl_sdk_status = '';

		/* window.nimbbl_web.UPIIntentAvailable(
			JSON.stringify({
				UPIApps: [
					{ name: 'SC Mobile', packagename: 'air.app.scb.breeze.android.main.in.prod' },
					{ name: 'iMobile Pay', packagename: 'com.csam.icici.bank.imobile' },
					{ name: 'Goibibo', packagename: 'com.goibibo' },
					{ name: 'GPay', packagename: 'com.google.android.apps.nbu.paisa.user' },
					{ name: 'U-Mobile', packagename: 'com.infrasoft.uboi' },
					{ name: 'PhonePe', packagename: 'com.phonepe.app' },
					{ name: 'WhatsApp', packagename: 'net.one97.paytm' },
					{ name: 'Mobikwik', packagename: 'net.one97.paytm' },
					{ name: 'PhonePe', packagename: 'net.one97.paytm' },
					{ name: 'BHIM', packagename: 'net.one97.paytm' },
					{ name: 'Cred', packagename: 'net.one97.paytm' },
					{ name: 'Amazon mShop', packagename: 'in.amazon.mShop.android.shopping' },
				],
			})
		);
		window.nimbbl_web.UPIIntentAvailable(
			JSON.stringify({
			UPIApps: [
				{
					logo_url: 'https://s3.ap-south-1.amazonaws.com/nimbbl/upi/phonepeupi_v2.png',
					name: 'PhonePe',
					package_name: 'com.phonepe.app',
					upi_app_code: 'phonepe',
				},
				{
					logo_url: 'https://s3.ap-south-1.amazonaws.com/nimbbl/upi/whatsapp_v1.png',
					name: 'WhatsApp',
					package_name: 'com.whatsapp',
					upi_app_code: 'whatsapp',
				},
				{
					logo_url: 'https://s3.ap-south-1.amazonaws.com/nimbbl/upi/jupiter_v1.png',
					name: 'Jupiter Money',
					package_name: 'money.jupiter',
					upi_app_code: 'jupiter',
				},
				{
					logo_url: 'https://s3.ap-south-1.amazonaws.com/nimbbl/upi/paytmupi_v9.png',
					name: 'PayTM',
					package_name: 'net.one97.paytm',
					upi_app_code: 'paytm',
				},
				{
					logo_url: 'https://s3.ap-south-1.amazonaws.com/nimbbl/upi/amzpayupi_v2.png',
					name: 'Amazon Pay',
					package_name: 'in.amazon.mShop.android.shopping',
					upi_app_code: 'amazon_pay',
				},
			],
		})
		);
		*/

		let OrderDetailsInterval = setInterval(() => {
			this.order_details = JSON.parse(JSON.stringify(this.orderService.getOrderDetails()));

			if (this.order_details['order_id']) {
				clearInterval(OrderDetailsInterval);
				this.merchant_details = JSON.parse(JSON.stringify(this.orderService.getMerchantDetail()));

				tracking.checkoutOpen({
					order_details: this.order_details,
					merchant_details: this.merchant_details,
					isReactNative: window?.nimbbl_webview?.isNative || false,
					intent: window?.nimbbl_webview?.UPIIntentAvailable || 'No Apps found'
				});

				let data = {
					order_id: this.order_details['order_id'],
					order_level: 2,
					checkout_id: this.order_details['checkout_id'] || getCheckoutId(this.order_details['order_id']),
				};

				if (this.order_details.status !== 'completed') {
					this.apiService.updateOrderLevel(data).subscribe(
						(res) => {},
						(error) => {}
					);
				}
			}
		}, 10);
	}

	ngOnDestroy() {
		this.removeMessageEventListener();
	}

	@HostListener('window:message', ['$event'])
	onMessage(event: MessageEvent) {
		const value = event.data || null;
		(value === 'cancelled' || value === 'exception') && this.sharedPaymentservice?.updateSharedHandleRnSdkAppStatus(value);
	}
	private addMessageEventListener() {
		window.addEventListener('message', this.onMessage);
	}
	private removeMessageEventListener() {
		window.removeEventListener('message', this.onMessage);
	}

	disableF5(e) {
		if ((e.which || e.keyCode) == 116) e.preventDefault();
	}

	UPIIntentAvailable(param) {
		this.ngZone.run(() => this.privateFunc(param));
	}
	privateFunc(param) {
		this.sharedService.updateintent(JSON.parse(param)['UPIApps']);
		this.intentpaymentModes = JSON.parse(param);
		this.intentpaymentModes = this.intentpaymentModes['UPIApps'];
	}
}
