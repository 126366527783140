import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { ApiService } from 'src/app/services/api.service';
import { OrderDetailService } from 'src/app/services/order-detail.service';
import { PaymentModeService } from 'src/app/services/payment-mode.service';
import { SharedService } from 'src/app/services/shared.service';
import { CommonService } from 'src/app/services/common.service';
import {
	merchantDetails,
	orderDetails,
	initPaymentRequest,
	resolveUserRequest,
} from 'src/app/app.models';
import tracking from '../../utils/tracking';

@Component({
	selector: 'app-payment-response',
	templateUrl: './payment-response.component.html',
	styleUrls: ['./payment-response.component.css'],
})
export class PaymentResponseComponent implements OnInit {
	response = 'success';
	eta_completion = 8;
	message;
	paymentModeName;
	nimbbl_user_name;
	showLoader: boolean = true;
	paymentResponse;
	order_id;
	//canMake payment
	order_details: orderDetails;
	gpayRequest: any;
	showGpayButton;
	merchant_details: merchantDetails;
	canMakePaymentCache = 'canMakePaymentCache';
	isAPiInvoked: boolean = false;
	next_step;
	pa;
	pn;
	tn;
	mc;
	tr;
	url;
	cu;
	amount;
	isAllModesLoaded: boolean = false;
	orderDetails: any;
	isPaymentSuccess: boolean = false;

	constructor(
		private sharedPaymentservice: PaymentModeService,
		public activateRoute: ActivatedRoute,
		private apiService: ApiService,
		private router: Router,
		private cookies: CookieService,
		private sharedOrderDetails: OrderDetailService,
		private sharedService: SharedService,
		private commonService: CommonService,
	) {
		this.nimbbl_user_name = cookies.get('nimbbl_user_name');
	}

	ngOnInit(): void {
		let validateToken = this.apiService.checkForValidToken();

		this.activateRoute.queryParams.subscribe((params:any) => {
			this.paymentResponse = JSON.parse(atob(params['response']));
			let nimbblOrderID = this.paymentResponse.nimbbl_order_id;
			if (!validateToken) {
				this.apiService.getEncryptedAuthorizationToken(nimbblOrderID).subscribe((resp) => {
					this.apiService.setTokenCookies(resp['token'], btoa(resp['expires_at']));
					this.apiService
						.fetchOrderById(this.paymentResponse['nimbbl_order_id'])
						.subscribe((resp) => {
							this.order_details = JSON.parse(JSON.stringify(resp));
							this.apiService
								.fetchMerchantDetail(this.order_details['sub_merchant_id'])
								.subscribe((res) => {
									this.merchant_details = JSON.parse(JSON.stringify(res));
									this.sharedOrderDetails.updateMerchantDetails(this.merchant_details);
									let token = this.sharedService.getAuthToken();

									if (!token) {
										let rr = new resolveUserRequest();
										rr.mobile_number = '' + this.order_details.user.mobile_number;
										rr.country_code = this.order_details.user['country_code'];
										rr.order_id = this.order_details['order_id'];
										this.apiService.resolveUser(rr).subscribe(
											(res) => {
												if (res['item']['token']) {
													this.sharedService.updateAuthToken(res['item']['token']);
													token = res['item']['token'];
													// this.checkGpayApi();

													setTimeout(() => {
														let options = {
															order_id: this.paymentResponse['nimbbl_order_id'],
															response: params['response'],
														};
														let transaction_token = params['transaction_token'];
														if (transaction_token) {
															options['transaction_token'] = transaction_token;
														} else {
															options['expires'] = this.sharedService.getTokenExpiryTime();
														}
														console.log('1');
														if(this.sharedService.getpaymentCookieData()['isEnforced'] === 'true'){
															this.sharedService.updateEnforcePaymentDetails(true);
															if(this.paymentResponse.status === 'success'){
																this.sharedService.updateEnforcePaymentSuccess(true);
																this.sharedService.sharedEnforcePaymentSuccess.subscribe(res => {
																	this.isPaymentSuccess = res
																})
															}
															
				
															options['payment_mode'] = this.sharedService.getpaymentCookieData().payment_mode; //Netbanking / Wallet / UPI

															if(this.sharedService.getpaymentCookieData()['bank_code']){
																this.sharedService.updateEnforceSubPaymentModeDetails(true);
																options['bank_code'] = this.sharedService.getpaymentCookieData().bank_code; //State Bank of India
															}else if(this.sharedService.getpaymentCookieData()['wallet_code']){
																this.sharedService.updateEnforceSubPaymentModeDetails(true);
																options['wallet_code'] = this.sharedService.getpaymentCookieData().wallet_code; ///Airtel Money
															}
															this.router.navigate(['payment/netbanking'], { queryParams: options });
														}else{
														this.router.navigate(['payment'], { queryParams: options });
														}
														
													}, 1000);
												}
											},
											(error) => {
												console.error('error caught in component');
												// console.log(error.statusText);
											}
										);
									} else {
										// this.checkGpayApi();

										setTimeout(() => {
											let options = {
												order_id: this.paymentResponse['nimbbl_order_id'],
												response: params['response'],
											};
											let transaction_token = params['transaction_token'];
											if (transaction_token) {
												options['transaction_token'] = transaction_token;
											} else {
												options['expires'] = this.sharedService.getTokenExpiryTime();
											}
											console.log('2');
											this.router.navigate(['payment'], { queryParams: options });
											// alert('navigated');
										}, 1000);
									}
								});
						});
				});
			} else {
				this.apiService.fetchOrderById(this.paymentResponse['nimbbl_order_id'])
					.subscribe((resp) => {
						this.order_details = JSON.parse(JSON.stringify(resp));
						this.apiService
							.fetchMerchantDetail(this.order_details['sub_merchant_id'])
							.subscribe((res) => {
								this.merchant_details = JSON.parse(JSON.stringify(res));
								this.sharedOrderDetails.updateMerchantDetails(this.merchant_details);
								let token = this.sharedService.getAuthToken();

								if (!token) {
									let rr = new resolveUserRequest();
									rr.mobile_number = '' + this.order_details.user.mobile_number;
									rr.country_code = this.order_details.user['country_code'];
									rr.order_id = this.order_details['order_id'];
									this.apiService.resolveUser(rr).subscribe(
										(res) => {
											if (res['item']['token']) {
												this.sharedService.updateAuthToken(res['item']['token']);
												token = res['item']['token'];
												// this.checkGpayApi();

												setTimeout(() => {
													let options = {
														order_id: this.paymentResponse['nimbbl_order_id'],
														response: params['response'],
													};
													let transaction_token = params['transaction_token'];
													if (transaction_token) {
														options['transaction_token'] = transaction_token;
													} else {
														options['expires'] = this.sharedService.getTokenExpiryTime();
													}
													console.log('3');
													this.router.navigate(['payment'], { queryParams: options });
													// alert('navigated');
												}, 1000);
											}
										},
										(error) => {
											console.error('error caught in component');
											// console.log(error.statusText);
										}
									);
								} else {
									// this.checkGpayApi();

									setTimeout(() => {
										let options = {
											order_id: this.paymentResponse['nimbbl_order_id'],
											response: params['response'],
										};
										let transaction_token = params['transaction_token'];
										if (transaction_token) {
											options['transaction_token'] = transaction_token;
										} else {
											options['expires'] = this.sharedService.getTokenExpiryTime();
										}
										console.log('4');
										this.router.navigate(['payment'], { queryParams: options });
										// alert('navigated');
									}, 1000);
								}
							});
					});
			}
		});
	}

	closeWindow() {
		this.postMessage();
	}
	postMessage() {
		this.commonService.getCallBackPayloadResponse(
			this.order_details, 
			this.paymentResponse['nimbbl_transaction_id']
		);
	}
	reinitiatePayment() {
		let options = { order_id: this.paymentResponse['nimbbl_order_id'] };
		let transaction_token = this.activateRoute.snapshot.queryParams['transaction_token'];
		if (transaction_token) {
			options['transaction_token'] = transaction_token;
		} else {
			options['expires'] = this.sharedService.getTokenExpiryTime();
		}
		this.router.navigate(['/'], { queryParams: options });
	}

	// can make payment calls

	showPaymentUI(request, canMakePayment) {
		this.gpayRequest = request;
		if (!canMakePayment) {
			this.showGpayButton = false;
			this.sharedPaymentservice.updateGpayVisibility(this.showGpayButton);
		} else {
			this.showGpayButton = true;
			this.sharedPaymentservice.updateGpayVisibility(this.showGpayButton);
		}

		tracking.receiveCanMakePayment({
			order_details: this.order_details,
			merchant_details: this.merchant_details,
			status: this.showGpayButton,
		});
	}

	checkCanMakePayment(request) {
		// Checks canMakePayment cache, and use the cache result if it exists.
		if (sessionStorage.hasOwnProperty(this.canMakePaymentCache)) {
			return Promise.resolve(JSON.parse(sessionStorage[this.canMakePaymentCache]));
		}

		// If canMakePayment() isn't available, default to assuming that the method is
		// supported.
		var canMakePaymentPromise = Promise.resolve(true);

		// Feature detect canMakePayment().
		if (request.canMakePayment) {
			canMakePaymentPromise = request.canMakePayment();
		}

		return canMakePaymentPromise
			.then((result) => {
				// Store the result in cache for future usage.
				sessionStorage[this.canMakePaymentCache] = result;
				return result;
			})
			.catch((err) => {});
	}

	// googlepay can make payment
	readSupportedInstruments() {
		let formValue = {};
		formValue['pa'] = this.pa;
		formValue['pn'] = this.pn == '' ? 'logicloop' : this.pn;
		formValue['tn'] = this.tn;
		formValue['mc'] = this.mc;
		formValue['tr'] = this.tr;
		formValue['url'] = this.url;
		return formValue;
	}

	onBuyClicked() {
		if (!window.PaymentRequest) {
			return;
		}

		let formValue = this.readSupportedInstruments();
		const supportedInstruments = [
			{
				supportedMethods: ['https://tez.google.com/pay'],
				data: formValue,
			},
		];
		const details = {
			total: {
				label: 'Total',
				amount: {
					currency: this.cu,
					value: this.amount,
				},
			},
			displayItems: [
				{
					label: 'Original amount',
					amount: {
						currency: this.cu,
						value: this.amount,
					},
				},
			],
		};

		let request = null;
		try {
			request = new PaymentRequest(supportedInstruments, details);
		} catch (e) {
			return;
		}
		if (!request) {
			return;
		}

		var canMakePaymentPromise = this.checkCanMakePayment(request);
		canMakePaymentPromise
			.then((result) => {
				this.showPaymentUI(request, result);
			})
			.catch((err) => {});
	}
}
