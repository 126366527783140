import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { SharedService } from 'src/app/services/shared.service';

@Component({
  selector: 'app-list-of-country-code',
  templateUrl: './list-of-country-code.component.html',
  styleUrls: ['./list-of-country-code.component.css']
})
export class ListOfCountryCodeComponent implements OnInit {
  dispplayCountryCodeList:boolean=false;
  @Output() countryCodeSelect:EventEmitter<any>=new EventEmitter<any>();
  listOfCountries
  filteredList=[];
  constructor(private sharedService:SharedService) { }

  ngOnInit(): void {
    this.listOfCountries=this.sharedService.listOfCountries;
    this.showCountryCodeList();
  }

  showCountryCodeList(){
    this.dispplayCountryCodeList=true;
    this.filteredList=this.listOfCountries;
  }
  filterList(event){
    this.filteredList=[];
    this.filteredList=this.listOfCountries.filter((arr)=>{
      return arr.countryName.toLowerCase().includes(event.target.value.toLowerCase()) || arr.dailerCode.toLowerCase().includes(event.target.value.toLowerCase());
    })
  }
  selectedCountry(i)
  {
    this.sharedService.updateuserCountry(this.filteredList[i]);
    this.countryCodeSelect.emit();

  }
}
