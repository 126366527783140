<div
	class="box-accor"
	*ngIf="
		selectedPaymentMode?.paymentMode != 'UPI' &&
		selectedPaymentMode?.paymentMode != 'Credit Card' &&
		selectedPaymentMode?.paymentMode != 'Prepaid Card' &&
		selectedPaymentMode?.paymentMode != 'Debit Card' &&
		selectedPaymentMode?.paymentMode != 'Card' &&
		selectedPaymentMode?.paymentMode != 'Netbanking' &&
		selectedPaymentMode?.paymentMode != 'Wallet' &&
		selectedPaymentMode?.paymentMode != 'Cardless EMI' &&
		!isEnforced &&
		!hasSubPaymentMode &&
		!isEMIPlanFlow
	"
>
	<div class="box-item">
		<div class="box-row pt-1">
			<div class="col-9 align-self-center">
				<div class="box-date-details">
					<div class="row">
						<div class="col-auto p-0">
							<img
								[src]="selectedPaymentMode?.logo"
								class="img-fluid"
								[ngClass]="
									vpa_provider == 'bhim' || selectedPaymentMode.paymentMode == 'BHIM' ? 'bhim' : ''
								"
							/>
						</div>
						<div class="col px-2 fS-18">
							<strong>Pay with {{ magicUPIDisplayName || paymentModeName }}</strong>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="row fS-15">
			<div class="col">
				<div class="pt-2" *ngIf="!selectedPaymentMode?.is_intent">{{ description }}</div>
				<div
					class="pt-1"
					*ngIf="
						selectedPaymentMode?.paymentMode == 'LazyPay' || 
						selectedPaymentMode?.paymentMode == 'X paylater' ||
						selectedPaymentMode?.paymentMode == 'Y paylater' ||
						selectedPaymentMode?.paymentMode == 'Dash Pay Later' ||
						selectedPaymentMode?.paymentMode == 'Flash Pay Later' ||
						selectedPaymentMode?.paymentMode == 'SBI Pay Later' ||
						selectedPaymentMode?.paymentMode == 'Paytm Postpaid'
					"
				>
					Your Next Due Date is {{ dueDate }}
				</div>
				<div class="pt-3">{{ subDescriptio }}</div>
			</div>
		</div>
		<div class="row fS-14 pt-3 priceBreakUp" *ngIf="convenienceFee">
			<div class="col-6">Total</div>
			<div class="col-6 text-right">
				<span [innerHTML]="currency.currency"></span> {{ order_details?.total_amount | number : '1.2-2' }}
			</div>
		</div>
		<div class="row fS-14 pt-3 priceBreakUp" *ngIf="convenienceFee">
			<div class="col-6">(+) Convenience Fee</div>
			<div class="col-6 text-right">
				<span [innerHTML]="currency.currency"></span> {{ convenienceFee | number : '1.2-2' }}
			</div>
		</div>
		<div class="row fS-14 pt-3 priceBreakUp grandTotal" *ngIf="convenienceFee">
			<div class="col-6">Grand Total</div>
			<div class="col-6 text-right">
				<span [innerHTML]="currency.currency"></span> {{ grandTotal | number : '1.2-2' }}
			</div>
		</div>
		<div class="payment-button-wrapper">
			<div class="flex" style="justify-content: space-between;">
				<button
					type="button"
					class="btn btn-outline-dark cancel-button"
					(click)="cancelPayment()"
				>
					Cancel
				</button>
				<button type="button" class="btn btn-primary btn-orange pay-button" (click)="initiatePayment()">
					<span
						*ngIf="
							!is_autoDebit &&
							(selectedPaymentMode?.paymentMode == 'LazyPay' ||
								selectedPaymentMode?.paymentMode == 'ICICI PayLater' ||
								selectedPaymentMode?.paymentMode == 'Olamoney' ||
								selectedPaymentMode?.paymentMode == 'X paylater' ||
								this.selectedPaymentMode['paymentMode'] == 'Y paylater' ||
								selectedPaymentMode?.paymentMode == 'Dash Pay Later' ||
								this.selectedPaymentMode['paymentMode'] == 'Flash Pay Later' ||
								this.selectedPaymentMode['paymentMode'] == 'SBI Pay Later' || 
								this.selectedPaymentMode['paymentMode'] == 'Mobikwik Zip')
						"
						>Continue</span
					>
					<span
						*ngIf="
							is_autoDebit &&
							(selectedPaymentMode?.paymentMode == 'LazyPay' ||
								selectedPaymentMode?.paymentMode == 'Olamoney' ||
								selectedPaymentMode?.paymentMode == 'X paylater' ||
								this.selectedPaymentMode['paymentMode'] == 'Y paylater' ||
								selectedPaymentMode?.paymentMode == 'Dash Pay Later' ||
								this.selectedPaymentMode['paymentMode'] == 'Flash Pay Later' ||
								this.selectedPaymentMode['paymentMode'] == 'SBI Pay Later' ||
								this.selectedPaymentMode['paymentMode'] == 'Mobikwik Zip')
						"
						>Pay <span [innerHTML]="currency.currency"></span> {{ grandTotal | number : '1.2-2' }}*
					</span>
					<span
						*ngIf="
							selectedPaymentMode?.paymentMode != 'LazyPay' &&
							selectedPaymentMode?.paymentMode != 'ICICI PayLater' &&
							selectedPaymentMode?.paymentMode != 'Olamoney' &&
							selectedPaymentMode?.paymentMode != 'X paylater' &&
							selectedPaymentMode?.paymentMode != 'Y paylater' &&
							selectedPaymentMode?.paymentMode != 'Dash Pay Later' &&
							selectedPaymentMode?.paymentMode != 'Flash Pay Later' &&
							selectedPaymentMode?.paymentMode != 'SBI Pay Later' &&
							selectedPaymentMode?.paymentMode != 'Mobikwik Zip'
						"
						>Pay <span [innerHTML]="currency.currency"></span> {{ grandTotal | number : '1.2-2' }}*
					</span>
				</button>
			</div>
			<div class="col-12 my-3 fS-12">
				*You agree with the
				<a
					[href]="termsLink ? termsLink : 'javascript:void(0)'"
					class="clr-16 td-ul"
					[target]="termsLink ? '_blank' : ''"
					>terms & conditions</a
				>
				by tapping pay
			</div>
		</div>
	</div>
</div>
<!-- Pay with UPI -->
<div
	class="box-accor enforced-upi-error-container"
	*ngIf="
		isEnforced &&
		selectedPaymentMode?.paymentMode == 'UPI' &&
		!isPaymentSuccess &&
		!enforcedInitPayment &&
		apiError
	"
>
	<div class="box-item">
		<div class="box-row py-3 title-payment">
			<div class="">
				<img [src]="selectedPaymentMode.logo" class="img-fluid" />
			</div>
			<div class="px-2 fS-18">
				<strong>{{ apiError }}</strong>
			</div>
		</div>
	</div>
</div>
<div
	class="box-accor upi-container"
	*ngIf="
		selectedPaymentMode?.paymentMode == 'UPI' &&
		!isPaymentSuccess &&
		!enforcedInitPayment && 
		!apiError
	"
>
	<!-- QR Block -->
	<div *ngIf="!isFastUpi && isIntentAllowed && !hasIntentFromSDK && !isIosDevice && !isReactNative">
		<div class="box-item cursor-pointer" (click)="triggerRadioClickQr()">
			<div class="box-row pt-1">
				<div class="col-9 align-self-center">
					<div class="box-date-details">
						<div class="row">
							<div class="col-auto p-0">
								<img
									[src]="vpa_provider ? vpaAppLogo : selectedPaymentMode?.logo"
									class="img-fluid"
									[ngClass]="
										vpa_provider == 'bhim' || selectedPaymentMode.paymentMode == 'BHIM'
											? 'bhim'
											: ''
									"
								/>
							</div>
							<div class="col px-2 fS-18 header-container">
								<strong *ngIf="!isIntentAndMobile">Pay with QR</strong>
								<strong *ngIf="isIntentAndMobile">Pay with UPI apps</strong>
								<input
									type="radio"
									checked
									name="upi-payment-selection"
									class="upi-payment-selection"
									(change)="handleRadioChange('qr')"
									#upiPaymentSelectionqr
								/>
							</div>
						</div>
						<div class="row">
							<div class="qr-container flex">
								<img
									*ngIf="!isIntentAndMobile"
									class="qr-blur-img"
									src="../../../assets/images/other/blurqrimg.png"
								/>
								<div class="qr-scan-apps">
									<p *ngIf="!isIntentAndMobile">Scan the QR with any UPI app on your phone.</p>
									<p *ngIf="isIntentAndMobile">Pay with your favorite UPI apps.</p>
									<div class="scan-img-apps">
										<span
											><img src="../../../assets/images/icons/googlepay.svg" class="img-fluid"
										/></span>
										<span
											><img src="../../../assets/images/icons/phone-pe.png" class="img-fluid"
										/></span>
										<span
											><img src="../../../assets/images/logos/bhim.png" class="img-fluid"
										/></span>
										<span
											><img src="../../../assets/images/icons/Paytm_Logo.png" class="img-fluid"
										/></span>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="divider"></div>
	</div>
	<div class="box-item cursor-pointer" (click)="triggerRadioClickUpi()" *ngIf="isUpiCollectAllowed">
		<div class="box-row pt-1">
			<div class="col-9 align-self-center">
				<div class="box-date-details">
					<div class="row">
						<div class="col-auto p-0">
							<img
								[src]="vpa_provider ? vpaAppLogo : selectedPaymentMode?.logo"
								class="img-fluid"
								[ngClass]="
									vpa_provider == 'bhim' || selectedPaymentMode.paymentMode == 'BHIM' ? 'bhim' : ''
								"
							/>
						</div>
						<div class="col px-2 fS-18 header-container">
							<strong>Pay with {{ magicUPIDisplayName || paymentModeName }} ID</strong>

							<input
								*ngIf="!isFastUpi && isIntentAllowed && !hasIntentFromSDK && !isIosDevice && !isReactNative"
								type="radio"
								name="upi-payment-selection"
								class="upi-payment-selection"
								(change)="handleRadioChange('upi')"
								#upiPaymentSelectionupi
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="row" *ngIf="isFastUpi && !isIntentAllowed">
			<div class="col-12">
				<div class="fS-13m" *ngIf="!isVpaValid">
					<span><img src="assets/images/icons/googlepay.svg" class="img-fluid" /></span>
					<span><img src="assets/images/icons/phone-pe.png" class="img-fluid" /></span>
					<span><img src="assets/images/icons/bhimupi.png" class="img-fluid" /></span> & More
				</div>
			</div>
		</div>

		<!-- Enter UPI ID Input Field  -->
		<div class="row my-3" *ngIf="!isVpaValid" style="position: relative">
			<div class="col-12">
				<div>
					<input
						type="text"
						[(ngModel)]="UPIId"
						class="form-control"
						id=""
						placeholder="yourname@bankname"
						aria-describedby=""
						(ngModelChange)="this.upiIDUpdate.next($event)"
						autocomplete="off"
						(blur)="handleUPIBlur($event)"
					/>
				</div>
				<div class="message hidden" [ngClass]="isVpaValid ? 'valid' : 'invalid'">
					<p *ngIf="isVpaValid">Verified {{ UPIUserName }}</p>
					<p *ngIf="!isVpaValid">{{ PAYMENT_ERRORS.UPI }}</p>
				</div>
			</div>
			<div class="optionLabList hidden">
				<ul>
					<li *ngFor="let vpa of filteredVPA; let id = index" (click)="checkVPAOnclick(id)">
						{{ vpa }}
					</li>
				</ul>
			</div>
		</div>

		<!-- Pay with UPI modal -->
		<div class="closeBox" *ngIf="UPIModal">
			<div class="confirmationBox" *ngIf="findUpiId">
				<div class="header">
					<p>How to find UPI ID?</p>
					<div class="closeModal text-right" (click)="closeModal()">
						<img src="./assets/images/icons/close.png" width="30" height="30" />
					</div>
				</div>
				<div class="popupBody container">
					<div class="row">
						<div class="col-6">
							<img src="./assets/images/find_upi_id_1.png" class="img-fluid" />
						</div>
						<div class="col-6">
							<div class="flex">
								<div class="rightArrow">
									<img src="./assets/images/righArrow_upi.png" class="img-fluid" />
								</div>
								<div>
									<p>Open your UPI App</p>
								</div>
							</div>
						</div>
					</div>
					<div class="row">
						<div class="col-6">
							<div class="flex">
								<div class="rightArrow">
									<img src="./assets/images/righArrow_upi.png" class="img-fluid" />
								</div>
								<div>
									<p>In the top right, tap your photo.</p>
								</div>
							</div>
						</div>
						<div class="col-6">
							<img src="./assets/images/find_upi_id_2.png" class="img-fluid" />
						</div>
					</div>
					<div class="row">
						<div class="col-6">
							<img src="./assets/images/find_upi_id_3.png" class="img-fluid" />
						</div>
						<div class="col-6">
							<div class="flex">
								<div class="rightArrow">
									<img src="./assets/images/righArrow_upi.png" class="img-fluid" />
								</div>
								<div>
									<p>Tap the bank account whose UPI ID you want to view</p>
								</div>
							</div>
						</div>
					</div>
					<div class="row">
						<div class="col-6">
							<div class="flex">
								<div class="rightArrow">
									<img src="./assets/images/righArrow_upi.png" class="img-fluid" />
								</div>
								<div>
									<p>You will find the associated UPI ID under "UPI IDs"</p>
								</div>
							</div>
						</div>
						<div class="col-6">
							<img src="./assets/images/find_upi_id_4.png" class="img-fluid" />
						</div>
					</div>
				</div>
			</div>
			<div class="confirmationBox" *ngIf="!findUpiId">
				<div class="header">
					<p>How to pay using UPI App?</p>
					<div class="closeModal text-right" (click)="closeModal()">
						<img src="./assets/images/icons/close.png" width="30" height="30" />
					</div>
				</div>
				<div class="popupBody container">
					<div class="row">
						<div class="col-6">
							<div class="flex">
								<div class="rightArrow">
									<img src="./assets/images/righArrow_upi.png" class="img-fluid" />
								</div>
								<div>
									<p>Enter your UPI Address and tap verify to initiate payment</p>
								</div>
							</div>
						</div>
						<div class="col-6">
							<img src="./assets/images/pay_uisng_upi1.png" class="img-fluid" />
						</div>
					</div>
					<div class="row">
						<div class="col-6">
							<img src="./assets/images/pay_uisng_upi2.png" class="img-fluid" />
						</div>
						<div class="col-6">
							<div class="flex">
								<div class="rightArrow">
									<img src="./assets/images/righArrow_upi.png" class="img-fluid" />
								</div>
								<div>
									<p>Go to the UPI linked bank/UPI app for the entered UPI address</p>
								</div>
							</div>
						</div>
					</div>
					<div class="row">
						<div class="col-6">
							<div class="flex">
								<div class="rightArrow">
									<img src="./assets/images/righArrow_upi.png" class="img-fluid" />
								</div>
								<div>
									<p>
										Approve payment request under UPI section on your bank/UPI app by entering UPI
										pin
									</p>
								</div>
							</div>
						</div>
						<div class="col-6">
							<img src="./assets/images/pay_uisng_upi3.png" class="img-fluid" />
						</div>
					</div>
				</div>
			</div>
		</div>
		<!--  -->

		<!-- Commenting Edit VPA section where Name and Valid VPA is displayed -->

		<div class="row my-3" *ngIf="isVpaValid">
			<div class="col-2" *ngIf="!vpa_provider">
				<img [src]="vpaAppLogo" class="img-fluid" />
			</div>
			<div class="col-10" [ngClass]="vpa_provider ? 'col-12' : 'col-10'">
				<p class="mb-1">
					<strong>Name: {{ UPIUserName | titlecase }}</strong>
				</p>
				<p class="mb-3">
					<strong>UPI ID: {{ validatedVPA }}</strong>
					<span class="float-right editbtn" (click)="editVPA()">Edit</span>
				</p>
			</div>
			<div class="col-12" *ngIf="isVpaValid">
				<div class="vpamessage">
					You will get a notification on your {{ magicUPIDisplayName || vpaAppName }} app, tap pay
					to complete the payment transaction
				</div>
			</div>
		</div>
		<!--  -->


		<!-- UPI Information Links -->
		<div class="row fs-14 pt-3 flexUPI">
			<div class="col-6 before_arrow" (click)="openUPIModal('find UPI Id')">
				How to find UPI ID?
			</div>
			<div class="col-6 before_arrow" (click)="openUPIModal('pay using UPI')">
				How to pay using UPI?
			</div>
		</div>
	</div>
	<!-- Convenience -->
	<div class="box-item">
		<div class="row fS-14 pt-3 priceBreakUp" *ngIf="convenienceFee">
			<div class="col-6">Total</div>
			<div class="col-6 text-right">
				<span [innerHTML]="currency.currency"></span> {{ order_details?.total_amount | number : '1.2-2' }}
			</div>
		</div>
		<div class="row fS-14 pt-3 priceBreakUp" *ngIf="convenienceFee">
			<div class="col-6">(+) Convenience Fee</div>
			<div class="col-6 text-right">
				<span [innerHTML]="currency.currency"></span> {{ convenienceFee | number : '1.2-2' }}
			</div>
		</div>
		<div class="row fS-14 pt-3 priceBreakUp grandTotal" *ngIf="convenienceFee">
			<div class="col-6">Grand Total</div>
			<div class="col-6 text-right">
				<span [innerHTML]="currency.currency"></span> {{ grandTotalWithConvenienceFees || grandTotal | number : '1.2-2' }}
			</div>
		</div>
	</div>
	<!-- Pay UPI Buttons -->
	<div class="payment-button-wrapper">
		<!-- *ngIf="isVpaValid" -->
		<div class="flex" style="justify-content: space-between;">
			<button type="button" class="btn btn-outline-dark cancel-button flex-grow" (click)="isEnforced ? cancelRef.showConfirmationMessage() : cancelPayment()">
				Cancel
			</button>
			<button
				type="button"
				class="btn btn-primary btn-orange pay-button"
				(click)="isVpaValid ? initiatePayment() : checkForValidUPI()"
				[disabled]="!isVerifybuttonEnabled"
				[ngClass]="isbuttonEnabled ? 'valid' : ''"
				*ngIf="!qrMode"
			>
				Pay <span [innerHTML]="currency.currency"></span> {{ grandTotalWithConvenienceFees || grandTotal | number : '1.2-2' }}*
			</button>

			<button
				id="intent-btn"
				type="button"
				class="btn btn-primary btn-orange pay-button flex btn-intent"
				(click)="handleQrCode()"
				*ngIf="qrMode"
			>
				<span *ngIf="!isIntentAndMobile && !hasIntentFromSDK && !isReactNative">Show QR</span>
				<span *ngIf="isIntentAndMobile">
					Pay
					<span [innerHTML]="currency.currency"></span> {{ grandTotalWithConvenienceFees || grandTotal | number : '1.2-2' }}*
				</span>
			</button>
		</div>
	</div>
	<!--  -->
</div>
<!-- Pay with cards -->
<div
	class="box-accor"
	*ngIf="
		(selectedPaymentMode?.paymentMode == 'Credit Card' ||
			selectedPaymentMode?.paymentMode == 'Debit Card' ||
			selectedPaymentMode?.paymentMode == 'Prepaid Card' ||
			selectedPaymentMode?.paymentMode == 'Card') &&
		!isEnforced &&
		!isPaymentSuccess &&
		!enforcedInitPayment
	"
>
	<app-toast></app-toast>
	<div class="box-item">
		<div class="row box-row py-3">
			<div class="col-auto">
				<img [src]="selectedPaymentMode.logo" class="img-fluid" />
			</div>
			<div class="col px-2 fS-18">
				<strong>Pay with {{ paymentModeName ? paymentModeName : 'Card' }}</strong>
			</div>
		</div>
		<div class="row my-3">
			<form [formGroup]="cardDetailForm">
				<div class="col-12">
					<div class="row my-3">
						<div class="col-8">
							<div style="position: relative">
								<input
									type="text"
									class="form-control card_number"
									formControlName="card_number"
									placeholder="Enter Card Number"
									aria-label="Enter Card Number"
									autofocus
									(blur)="handleOnCardNumberBlur($event)"
									(select)="handleOnCardNumberPaste($event)"
									(paste)="handleOnCardNumberPaste($event)"
									(input)="checkBin($event)"
									ccNumber
									autocomplete="off"
									id="cardNumber"
								/>
								<div class="cardTYpe" *ngIf="showCardType">
									<img
										src="{{
											'assets/images/logos/card_type/' + binServiceResponse?.cardType + '.svg'
										}}"
										[alt]="binServiceResponse?.cardType"
									/>
								</div>
							</div>
							<div
								class="danger"
								*ngIf="
									cardDetailForm.controls.card_number.invalid &&
									cardDetailForm.controls.card_number.touched &&
									cardDetailForm.controls['isValid'].valid
								"
							>
								{{ PAYMENT_ERRORS.CARD_INVALID }}
							</div>
							<div
								class="danger"
								*ngIf="
									(cardDetailForm.controls.card_number.invalid ||
										cardDetailForm.controls.card_number.valid) &&
									cardDetailForm.controls.card_number.touched &&
									cardDetailForm.controls['isValid'].invalid
								"
							>
								{{ PAYMENT_ERRORS.CARD_TYPE_NOT_SUPPORTED }}
							</div>
						</div>
						<div class="col-4">
							<input
								type="text"
								class="form-control"
								id="ccExp"
								placeholder="MM/YY"
								aria-label="MM/YY"
								formControlName="card_expiry"
								autocomplete="off"
								ccExp
								maxlength="12"
								(keyup)="setFocus()"
								(blur)="handleExpiryBlur($event)"
							/>
							<div
								class="danger"
								*ngIf="
									cardDetailForm.controls.card_expiry.invalid &&
									cardDetailForm.controls.card_expiry.touched
								"
							>
								{{ PAYMENT_ERRORS.EXPIRY_DATE }}
							</div>
						</div>
					</div>
					<div class="row my-3">
						<div class="col-8">
							<input
								type="text"
								class="form-control"
								placeholder="Card Holders Name"
								(blur)="holderName($event)"
								aria-label="Card Holders Name"
								id="holderName"
								formControlName="card_holder_name"
								autocomplete="off"
							/>
							<div
								class="danger"
								*ngIf="
									cardDetailForm.controls.card_holder_name.invalid &&
									cardDetailForm.controls.card_holder_name.touched &&
									cardDetailForm.controls.card_holder_name.errors.pattern
								"
							>
								{{ PAYMENT_ERRORS.CARD_HOLDER_NAME }}
							</div>
						</div>
						<div class="col-4">
							<input
								type="password"
								class="form-control"
								placeholder="CVV"
								aria-label="CVV"
								formControlName="cvv"
								autocomplete="off"
								ccCVC
								[maxlength]="cvvMaxLength"
								id="cvvId"
								(keyup)="handleCVVBlur($event)"
							/>
							<div
								class="danger"
								*ngIf="cardDetailForm.controls.cvv.invalid && cardDetailForm.controls.cvv.touched"
							>
								{{ PAYMENT_ERRORS.CVV }}
							</div>
						</div>
					</div>
					<div class="row fS-14 pt-3 priceBreakUp" *ngIf="offerAvailable || convenienceFee">
						<div class="col-6">Total</div>
						<div class="col-6 text-right">
							<span [innerHTML]="currency.currency"></span> {{ order_details?.total_amount | number : '1.2-2' }}
						</div>
					</div>
					<div class="row fS-14 pt-3 priceBreakUp green_text" *ngIf="offerAvailable">
						<div class="col-6">(-) Offer Applied</div>
						<div class="col-6 text-right">
							<span [innerHTML]="currency.currency"></span> {{ offerDiscount | number : '1.2-2' }}
						</div>
					</div>
					<div class="row fS-14 pt-3 priceBreakUp" *ngIf="convenienceFee">
						<div class="col-6">(+) Convenience Fee</div>
						<div class="col-6 text-right">
							<span [innerHTML]="currency.currency"></span> {{ convenienceFee | number : '1.2-2' }}
						</div>
					</div>
					<div
						class="row fS-14 pt-3 priceBreakUp grandTotal"
						*ngIf="offerAvailable || convenienceFee"
					>
						<div class="col-6">Grand Total</div>
						<div class="col-6 text-right">
							<span [innerHTML]="currency.currency"></span> {{ grandTotal | number : '1.2-2' }}
						</div>
					</div>
				</div>
			</form>
		</div>
		<div class="payment-button-wrapper">
			<div class="flex" style="justify-content: space-between;">
				<button
					type="button"
					class="btn btn-outline-dark cancel-button"
					(click)="isEnforced ? cancelRef.showConfirmationMessage() : cancelPayment()"
				>
					Cancel
				</button>
				<button
					type="button"
					class="btn btn-primary btn-orange pay-button"
					(click)="initiatePayment()"
					[disabled]="!cardDetailForm.valid || !isbuttonEnabled"
					[ngClass]="cardDetailForm.valid && isbuttonEnabled ? 'valid' : ''"
				>
					<!--
                        before merge to master it was
                        (cardDetailForm.pristine || cardDetailForm.invalid) || !isbuttonEnabled
                    -->
					Pay
					<span [innerHTML]="currency.currency"></span> {{ grandTotal | number : '1.2-2' }}*
				</button>
			</div>
		</div>
		<app-close-checkout #cancelRef></app-close-checkout>
		<div class="row fS-15" *ngIf="this.paymentModesWithOffers?.length">
			<div class="col">
				<app-offers [data_mode]="selectedPaymentMode?.paymentMode"></app-offers>
			</div>
		</div>
	</div>
</div>
<!-- Netbanking -->
<div
	class="box-accor netbanking-container"
	*ngIf="
		selectedPaymentMode?.paymentMode == 'Netbanking' &&
		!hasSubPaymentMode &&
		!isPaymentSuccess &&
		!enforcedInitPayment &&
		apiError
	"
>
	<div class="box-item">
		<div class="title-payment box-row py-3">
			<div class="">
				<img [src]="selectedPaymentMode.logo" class="img-fluid" />
			</div>
			<div class="px-2 fS-18">
				<strong> {{ apiError }}</strong>
			</div>
		</div>
	</div>
</div>
<div
	class="box-accor netbanking-container"
	*ngIf="
		selectedPaymentMode?.paymentMode == 'Netbanking' &&
		!hasSubPaymentMode &&
		!isPaymentSuccess &&
		!enforcedInitPayment &&
		!apiError
	"
>
	<div class="box-item">
		<div class="title-payment box-row py-3">
			<div class="">
				<img [src]="selectedPaymentMode.logo" class="img-fluid" />
			</div>
			<div class="px-2 fS-18">
				<strong>Pay with {{ selectedPaymentMode.paymentMode }}</strong>
			</div>
		</div>
		<div>
			<ul id="listOfBanksUl" class="bank-list-item clearfix">
				<div id="spinnerLoaderContainerBankList">
					<div class="css-loader"></div>
				</div>
				<li
					*ngFor="let bank of topSixBanks"
					(click)="updateBankData(bank.bankName, $event)"
					[ngClass]="{ active: selectedBank === bank.bankName }"
				>
					<div class="image">
						<img [src]="'assets/images/logos/' + bank.logo" class="img-fluid" alt="" />
					</div>
					<div class="name">{{ bank.bankName }}</div>
					<div class="fS-10 desc" *ngIf="bank?.additional_charges != 0">
						Con. Fee <span [innerHTML]="currency.currency"></span> {{ bank?.additional_charges }}
					</div>
					<div class="fS-10 desc green_text" *ngIf="bank.offers.length > 0">
						Offer <span [innerHTML]="currency.currency"></span> {{ bank.offers[0]['offer_discount'] | number : '1.2-2' }}
					</div>
				</li>
			</ul>
		</div>
		<div>
			<div class="row my-3">
				<div class="col-12">
					<ng-select
						[(ngModel)]="selectedBank"
						placeholder="Select Bank"
						single="true"
						(change)="updateBankData($event)"
					>
						<ng-option *ngFor="let bank of listOfBanks" [value]="bank.bank_name"
							><span class="bankName">{{ bank.bank_name }}</span
							><span class="convienceFee" *ngIf="bank?.additional_charges != 0">{{
								bank?.additional_charges
							}}</span
							><span class="convienceFee free" *ngIf="bank?.additional_charges == 0">Free</span>
						</ng-option>
					</ng-select>
				</div>
			</div>
			<div class="row fs-14 pt-3" *ngIf="isbuttonEnabled"></div>
			<div class="row fS-14 pt-3 priceBreakUp" *ngIf="offerAvailable || convenienceFee">
				<div class="col-6">Total</div>
				<div class="col-6 text-right">
					<span [innerHTML]="currency.currency"></span> {{ order_details?.total_amount | number : '1.2-2' }}
				</div>
			</div>
			<div class="row fS-14 pt-3 priceBreakUp green_text" *ngIf="offerAvailable">
				<div class="col-6">(-) Offer Applied</div>
				<div class="col-6 text-right">
					<span [innerHTML]="currency.currency"></span> {{ offerDiscount | number : '1.2-2' }}
				</div>
			</div>
			<div class="row fS-14 pt-3 priceBreakUp" *ngIf="convenienceFee">
				<div class="col-6">(+) Convenience Fee</div>
				<div class="col-6 text-right">
					<span [innerHTML]="currency.currency"></span> {{ convenienceFee | number : '1.2-2' }}
				</div>
			</div>
			<div class="row fS-14 pt-3 priceBreakUp grandTotal" *ngIf="offerAvailable || convenienceFee">
				<div class="col-6">Grand Total</div>
				<div class="col-6 text-right">
					<span [innerHTML]="currency.currency"></span> {{ grandTotal | number : '1.2-2' }}
				</div>
			</div>
			<div class="payment-button-wrapper">
				<div class="flex" style="justify-content: space-between;">
					<button
						type="button"
						class="btn btn-outline-dark cancel-button"
						(click)="isEnforced ? cancelRef.showConfirmationMessage() : cancelPayment()"
					>
						Cancel
					</button>
					<button
						type="button"
						class="btn btn-primary btn-orange pay-button"
						[disabled]="!isbuttonEnabled"
						[ngClass]="isbuttonEnabled ? 'valid' : ''"
						(click)="initiatePayment()"
					>
						Pay <span [innerHTML]="currency.currency"></span> {{ grandTotal | number : '1.2-2' }}*
					</button>
				</div>
			</div>
			<app-close-checkout #cancelRef></app-close-checkout>
			<div class="row fS-15" *ngIf="this.paymentModesWithOffers?.length && !isEnforced">
				<div class="col">
					<app-offers [data_mode]="selectedPaymentMode?.paymentMode"></app-offers>
				</div>
			</div>
		</div>
	</div>
</div>
<!-- Wallet -->
<div
	class="box-accor wallet-container"
	*ngIf="
		selectedPaymentMode?.paymentMode == 'Wallet' &&
		!hasSubPaymentMode &&
		!isPaymentSuccess &&
		!enforcedInitPayment &&
		apiError
	"
>
	<div class="box-item">
		<div class="box-row py-3 title-payment">
			<div class="">
				<img [src]="selectedPaymentMode.logo" class="img-fluid" />
			</div>
			<div class="px-2 fS-18">
				<strong>{{ apiError }}</strong>
			</div>
		</div>
	</div>
</div>
<div
	class="box-accor wallet-container"
	*ngIf="
		selectedPaymentMode?.paymentMode == 'Wallet' &&
		!hasSubPaymentMode &&
		!isPaymentSuccess &&
		!enforcedInitPayment &&
		!apiError
	"
>
	<div class="box-item">
		<div class="box-row py-3 title-payment">
			<div class="">
				<img [src]="selectedPaymentMode.logo" class="img-fluid" />
			</div>
			<div class="px-2 fS-18">
				<strong>Pay with {{ selectedPaymentMode.paymentMode }}</strong>
			</div>
		</div>
		<ul id="listOfWalletUl" class="bank-list-item clearfix">
			<div id="spinnerLoaderContainerWallet">
				<div class="css-loader"></div>
			</div>
			<li
				*ngFor="let wallet of topSixwallets"
				(click)="updateWalletData(wallet.walletName, $event)"
				[ngClass]="{ active: selectedWallet === wallet.walletName }"
			>
				<div class="image">
					<img [src]="'assets/images/logos/' + wallet.logo" class="img-fluid" alt="" />
				</div>
				<div class="name">{{ wallet.walletName }}</div>
				<div class="fS-10 desc" *ngIf="wallet?.additional_charges != 0">
					Con. Fee <span [innerHTML]="currency.currency"></span> {{ wallet?.additional_charges }}
				</div>
				<div class="fS-10 desc" *ngIf="wallet?.additional_charges == 0">No Con. Fee Applicable</div>
			</li>
		</ul>
		<div>
			<div class="row my-3" *ngIf="remainingWallets.length">
				<div class="col-12">
					<ng-select
						[(ngModel)]="selectedWallet"
						bindLabel="wallet_name"
						placeholder="Select wallet"
						single="true"
						(change)="updateWalletData($event)"
					>
						<ng-option *ngFor="let wallet of listOfWallets" [value]="wallet.wallet_name"
							><span class="bankName">{{ wallet.wallet_name }}</span
							><span
								class="convienceFee"
								*ngIf="
									wallet?.additional_charges != 0
								"
								>{{ wallet?.additional_charges }}</span
							><span
								class="convienceFee free"
								*ngIf="
									wallet?.additional_charges == 0
								"
								>Free</span
							>
						</ng-option>
					</ng-select>
				</div>
			</div>
			<div class="row fS-14 pt-3 priceBreakUp" *ngIf="convenienceFee">
				<div class="col-6">Total</div>
				<div class="col-6 text-right">
					<span [innerHTML]="currency.currency"></span> {{ order_details?.total_amount | number : '1.2-2' }}
				</div>
			</div>
			<div class="row fS-14 pt-3 priceBreakUp" *ngIf="convenienceFee">
				<div class="col-6">(+) Convenience Fee</div>
				<div class="col-6 text-right">
					<span [innerHTML]="currency.currency"></span> {{ convenienceFee | number : '1.2-2' }}
				</div>
			</div>
			<div class="row fS-14 pt-3 priceBreakUp grandTotal" *ngIf="convenienceFee">
				<div class="col-6">Grand Total</div>
				<div class="col-6 text-right">
					<span [innerHTML]="currency.currency"></span> {{ grandTotal | number : '1.2-2' }}
				</div>
			</div>
			<div class="payment-button-wrapper">
				<div class="flex" style="justify-content: space-between;">
					<button
						type="button"
						class="btn btn-outline-dark cancel-button"
						(click)="isEnforced ? cancelRef.showConfirmationMessage() : cancelPayment()"
					>
						Cancel
					</button>
					<button
						type="button"
						class="btn btn-primary btn-orange pay-button"
						[disabled]="!isbuttonEnabled"
						[ngClass]="isbuttonEnabled ? 'valid' : ''"
						(click)="initiatePayment()"
					>
						Pay <span [innerHTML]="currency.currency"></span> {{ grandTotal | number : '1.2-2' }}*
					</button>
				</div>
			</div>
			<app-close-checkout #cancelRef></app-close-checkout>
		</div>
	</div>
</div>

<!-- EMI Starts -->
<div
	class="box-accor emi-container"
	*ngIf="
		selectedPaymentMode?.paymentMode == 'Cardless EMI' &&
		!hasSubPaymentMode &&
		!isPaymentSuccess &&
		!enforcedInitPayment &&
		!apiError
	"
>
	<div class="box-item">
		<div class="box-row py-3 title-payment">
			<div class="">
				<img [src]="selectedPaymentMode.logo" class="img-fluid" />
			</div>
			<div class="px-2 fS-18">
				<strong>Pay with {{ selectedPaymentMode.paymentMode }}</strong>
			</div>
		</div>
		<ul id="listOfCardlessUl" class="bank-list-item cardless-item clearfix">
			<li
				*ngFor="let cardless of topSixCardlessEMI"
				(click)="updateCardlessData(cardless.mode, cardless)"
				[ngClass]="{ active: selectedWallet === cardless.modeName }"
			>
				<div class="image">
					<img [src]="cardless.logo" class="img-fluid" alt="" />
				</div>
				<div class="name">{{ cardless.modeName }}</div>
				<div class="fS-10 desc" *ngIf="cardless?.additional_charges != 0">
					Con. Fee <span [innerHTML]="currency.currency"></span> {{ cardless?.additional_charges }}
				</div>
				<div class="fS-10 desc" *ngIf="cardless?.additional_charges == 0">
					No Con. Fee Applicable
				</div>
			</li>
		</ul>
		<div class="payment-button-wrapper">
			<div class="flex" style="justify-content: space-between;">
				<button
					type="button"
					class="btn btn-outline-dark cancel-button"
					(click)="isEnforced ? cancelRef.showConfirmationMessage() : cancelPayment()"
				>
					Cancel
				</button>
				<button
					type="button"
					class="btn btn-primary btn-orange pay-button"
					[disabled]="!isbuttonEnabled"
					[ngClass]="isbuttonEnabled ? 'valid' : ''"
					(click)="initiatePayment()"
				>
					Pay <span [innerHTML]="currency.currency"></span> {{ grandTotal | number : '1.2-2' }}*
				</button>
			</div>
		</div>
	</div>
</div>
<!-- EMI Ends -->

<!-- Enforced Payment - Netbanking -->
<!-- Error Box start-->
<div
	class="box-accor enforced-netbanking-container"
	*ngIf="
		isEnforced &&
		hasSubPaymentMode &&
		selectedPaymentMode?.paymentMode == 'Netbanking' &&
		!isPaymentSuccess &&
		!enforcedInitPayment &&
		apiError
	"
>
	<div class="box-item">
		<div class="title-payment box-row py-3">
			<div class="">
				<img [src]="selectedPaymentMode.logo" class="img-fluid" />
			</div>
			<div class="px-2 fS-18">
				<strong> {{ apiError }}</strong>
			</div>
		</div>
	</div>
</div>
<!-- Error Box end-->
<div
	class="box-accor enforced-netbanking-container"
	*ngIf="
		isEnforced &&
		hasSubPaymentMode &&
		selectedPaymentMode?.paymentMode == 'Netbanking' &&
		!isPaymentSuccess &&
		!enforcedInitPayment &&
		!apiError
	"
>
	<div class="box-item">
		<div class="title-payment box-row py-3">
			<div class="">
				<img [src]="selectedPaymentMode.logo" class="img-fluid" />
			</div>
			<div class="px-2 fS-18">
				<strong
					>Pay with
					{{ selectedPaymentMode.paymentModeName || selectedPaymentMode.paymentMode }}</strong
				>
			</div>
		</div>
		<!-- list of Banks -->
		<div>
			<ul id="listOfBanksUl" class="bank-list-item clearfix" *ngIf="!hasSubPaymentMode">
				<div id="spinnerLoaderContainerBankList">
					<div class="css-loader"></div>
				</div>
				<li
					*ngFor="let bank of topSixBanks"
					(click)="updateBankData(bank.bankName, $event)"
					[ngClass]="{ active: selectedBank === bank.bankName }"
				>
					<div class="image">
						<img [src]="'assets/images/logos/' + bank.logo" class="img-fluid" alt="" />
					</div>
					<div class="name">{{ bank.bankName }}</div>
					<div class="fS-10 desc" *ngIf="bank?.additional_charges != 0">
						Con. Fee <span [innerHTML]="currency.currency"></span> {{ bank?.additional_charges }}
					</div>
					<div class="fS-10 desc green_text" *ngIf="bank.offers.length > 0">
						Offer <span [innerHTML]="currency.currency"></span> {{ bank.offers[0]['offer_discount'] | number : '1.2-2' }}
					</div>
				</li>
			</ul>
		</div>
		<div>
			<div
				class="row fS-14 pt-3 priceBreakUp"
				*ngIf="offerAvailable || convenienceFee || hasSubPaymentMode"
			>
				<div class="col-6">Total</div>
				<div class="col-6 text-right">
					<span [innerHTML]="currency.currency"></span> {{ order_details?.total_amount | number : '1.2-2' }}
				</div>
			</div>
			<div class="row fS-14 pt-3 priceBreakUp green_text" *ngIf="offerAvailable">
				<div class="col-6">(-) Offer Applied</div>
				<div class="col-6 text-right">
					<span [innerHTML]="currency.currency"></span> {{ offerDiscount | number : '1.2-2' }}
				</div>
			</div>
			<div class="row fS-14 pt-3 priceBreakUp" *ngIf="convenienceFee || hasSubPaymentMode">
				<div class="col-6">(+) Convenience Fee</div>
				<div class="col-6 text-right">
					<span [innerHTML]="currency.currency"></span> {{ convenienceFee | number : '1.2-2' }}
				</div>
			</div>
			<div
				class="row fS-14 pt-3 priceBreakUp grandTotal"
				*ngIf="offerAvailable || convenienceFee || hasSubPaymentMode"
			>
				<div class="col-6">Grand Total</div>
				<div class="col-6 text-right">
					<span [innerHTML]="currency.currency"></span> {{ grandTotal | number : '1.2-2' }}
				</div>
			</div>
			<div class="payment-button-wrapper">
				<div class="flex" style="justify-content: space-between;">
					<button
						type="button"
						class="btn btn-outline-dark cancel-button"
						(click)="isEnforced ? cancelRef.showConfirmationMessage() : cancelPayment()"
					>
						Cancel
					</button>
					<button
						type="button"
						class="btn btn-primary btn-orange pay-button"
						[disabled]="!isbuttonEnabled"
						[ngClass]="isbuttonEnabled ? 'valid' : ''"
						(click)="initiatePayment()"
					>
						Pay <span [innerHTML]="currency.currency"></span> {{ grandTotal | number : '1.2-2' }}*
					</button>
				</div>
			</div>
			<app-close-checkout #cancelRef></app-close-checkout>
			<!-- <div class="row fS-15" *ngIf="this.paymentModesWithOffers?.length">
				<div class="col">
					<app-offers [data_mode]="selectedPaymentMode?.paymentMode"></app-offers>
				</div>
			</div> -->
		</div>
	</div>
</div>

<!-- Enforced Payment - Wallet -->
<!-- Error box starts -->
<div
	class="box-accor enforced-wallet-container"
	*ngIf="
		isEnforced &&
		hasSubPaymentMode &&
		selectedPaymentMode?.paymentMode == 'Wallet' &&
		!isPaymentSuccess &&
		!enforcedInitPayment &&
		apiError
	"
>
	<div class="box-item">
		<div class="box-row py-3 title-payment">
			<div class="">
				<img [src]="selectedPaymentMode.logo" class="img-fluid" />
			</div>
			<div class="px-2 fS-18">
				<strong>{{ apiError }}</strong>
			</div>
		</div>
	</div>
</div>

<div
	class="box-accor enforced-wallet-container"
	*ngIf="
		isEnforced &&
		hasSubPaymentMode &&
		selectedPaymentMode?.paymentMode == 'Wallet' &&
		!isPaymentSuccess &&
		!enforcedInitPayment &&
		!apiError
	"
>
	<div class="box-item">
		<div class="box-row py-3 title-payment">
			<div class="">
				<img [src]="selectedPaymentMode.logo" class="img-fluid" />
			</div>
			<div class="px-2 fS-18">
				<strong
					>Pay with
					{{ selectedPaymentMode.paymentModeName || selectedPaymentMode.paymentMode }}</strong
				>
			</div>
		</div>
		<ul id="listOfWalletUl" class="bank-list-item clearfix" *ngIf="!hasSubPaymentMode">
			<div id="spinnerLoaderContainerWallet">
				<div class="css-loader"></div>
			</div>
			<li
				*ngFor="let wallet of topSixwallets"
				(click)="updateWalletData(wallet.walletName, $event)"
				[ngClass]="{ active: selectedWallet === wallet.walletName }"
			>
				<div class="image">
					<img [src]="'assets/images/logos/' + wallet.logo" class="img-fluid" alt="" />
				</div>
				<div class="name">{{ wallet.walletName }}</div>
				<div class="fS-10 desc" *ngIf="wallet?.additional_charges != 0">
					Con. Fee <span [innerHTML]="currency.currency"></span> {{ wallet?.additional_charges }}
				</div>
				<div class="fS-10 desc" *ngIf="wallet?.additional_charges == 0">No Con. Fee Applicable</div>
			</li>
		</ul>
		<div>
			<div class="row fS-14 pt-3 priceBreakUp" *ngIf="convenienceFee || hasSubPaymentMode">
				<div class="col-6">Total</div>
				<div class="col-6 text-right">
					<span [innerHTML]="currency.currency"></span> {{ order_details?.total_amount | number : '1.2-2' }}
				</div>
			</div>
			<div class="row fS-14 pt-3 priceBreakUp" *ngIf="convenienceFee || hasSubPaymentMode">
				<div class="col-6">(+) Convenience Fee</div>
				<div class="col-6 text-right">
					<span [innerHTML]="currency.currency"></span> {{ convenienceFee | number : '1.2-2' }}
				</div>
			</div>
			<div
				class="row fS-14 pt-3 priceBreakUp grandTotal"
				*ngIf="convenienceFee || hasSubPaymentMode"
			>
				<div class="col-6">Grand Total</div>
				<div class="col-6 text-right">
					<span [innerHTML]="currency.currency"></span> {{ grandTotal | number : '1.2-2' }}
				</div>
			</div>
			<div class="payment-button-wrapper">
				<div class="flex" style="justify-content: space-between;">
					<button
						type="button"
						class="btn btn-outline-dark cancel-button"
						(click)="isEnforced ? cancelRef.showConfirmationMessage() : cancelPayment()"
					>
						Cancel
					</button>
					<button
						type="button"
						class="btn btn-primary btn-orange pay-button"
						[disabled]="!isbuttonEnabled"
						[ngClass]="isbuttonEnabled ? 'valid' : ''"
						(click)="initiatePayment()"
					>
						Pay <span [innerHTML]="currency.currency"></span> {{ grandTotal | number : '1.2-2' }}*
					</button>
				</div>
			</div>
			<app-close-checkout #cancelRef></app-close-checkout>
		</div>
	</div>
</div>
<!-- Enforced Payment - Cards -->
<div
	class="box-accor enforced-card-error-container"
	*ngIf="
		isEnforced &&
		selectedPaymentMode?.paymentMode == 'Credit Card' &&
		!isPaymentSuccess &&
		!enforcedInitPayment &&
		apiError
	"
>
	<div class="box-item">
		<div class="box-row py-3 title-payment">
			<div class="">
				<img [src]="selectedPaymentMode.logo" class="img-fluid" />
			</div>
			<div class="px-2 fS-18">
				<strong>{{ apiError }}</strong>
			</div>
		</div>
	</div>
</div>
<div
	class="box-accor enforced-card-container"
	*ngIf="
		selectedPaymentMode?.paymentMode == 'Credit Card' &&
		isEnforced &&
		!isPaymentSuccess &&
		!enforcedInitPayment &&
		!apiError
	"
>
	<app-toast></app-toast>
	<div class="box-item">
		<div class="row box-row py-3">
			<div class="col-auto">
				<img [src]="selectedPaymentMode.logo" class="img-fluid" />
			</div>
			<div class="col px-2 fS-18">
				<strong>Pay with {{ paymentModeName ? paymentModeName : 'Card' }}</strong>
			</div>
		</div>
		<div class="row my-3">
			<form [formGroup]="cardDetailForm">
				<div class="col-12">
					<div class="row my-3">
						<div class="col-8">
							<div style="position: relative">
								<input
									type="text"
									class="form-control card_number"
									formControlName="card_number"
									placeholder="Enter Card Number"
									aria-label="Enter Card Number"
									autofocus
									(blur)="handleOnCardNumberBlur($event)"
									(select)="handleOnCardNumberPaste($event)"
									(paste)="handleOnCardNumberPaste($event)"
									(input)="checkBin($event)"
									ccNumber
									autocomplete="off"
									id="cardNumber"
								/>
								<div class="cardTYpe" *ngIf="showCardType">
									<img
										src="{{
											'assets/images/logos/card_type/' + binServiceResponse?.cardType + '.svg'
										}}"
										[alt]="binServiceResponse?.cardType"
									/>
								</div>
							</div>
							<div
								class="danger"
								*ngIf="
									cardDetailForm.controls.card_number.invalid &&
									cardDetailForm.controls.card_number.touched &&
									cardDetailForm.controls['isValid'].valid
								"
							>
								{{ PAYMENT_ERRORS.CARD_INVALID }}
							</div>
							<div
								class="danger"
								*ngIf="
									(cardDetailForm.controls.card_number.invalid ||
										cardDetailForm.controls.card_number.valid) &&
									cardDetailForm.controls.card_number.touched &&
									cardDetailForm.controls['isValid'].invalid
								"
							>
								{{ PAYMENT_ERRORS.CARD_TYPE_NOT_SUPPORTED }}
							</div>
						</div>
						<div class="col-4">
							<input
								type="text"
								class="form-control"
								id="ccExp"
								placeholder="MM/YY"
								aria-label="MM/YY"
								formControlName="card_expiry"
								autocomplete="off"
								ccExp
								maxlength="12"
								(keyup)="setFocus()"
								(blur)="handleExpiryBlur($event)"
							/>
							<div
								class="danger"
								*ngIf="
									cardDetailForm.controls.card_expiry.invalid &&
									cardDetailForm.controls.card_expiry.touched
								"
							>
								{{ PAYMENT_ERRORS.EXPIRY_DATE }}
							</div>
						</div>
					</div>
					<div class="row my-3">
						<div class="col-8">
							<input
								type="text"
								class="form-control"
								placeholder="Card Holders Name"
								(blur)="holderName($event)"
								aria-label="Card Holders Name"
								id="holderName"
								formControlName="card_holder_name"
								autocomplete="off"
							/>
							<div
								class="danger"
								*ngIf="
									cardDetailForm.controls.card_holder_name.invalid &&
									cardDetailForm.controls.card_holder_name.touched &&
									cardDetailForm.controls.card_holder_name.errors.pattern
								"
							>
								{{ PAYMENT_ERRORS.CARD_HOLDER_NAME }}
							</div>
						</div>
						<div class="col-4">
							<input
								type="password"
								class="form-control"
								placeholder="CVV"
								aria-label="CVV"
								formControlName="cvv"
								autocomplete="off"
								ccCVC
								[maxlength]="cvvMaxLength"
								id="cvvId"
								(keyup)="handleCVVBlur($event)"
							/>
							<div
								class="danger"
								*ngIf="cardDetailForm.controls.cvv.invalid && cardDetailForm.controls.cvv.touched"
							>
								{{ PAYMENT_ERRORS.CVV }}
							</div>
						</div>
					</div>
					<div class="row fS-14 pt-3 priceBreakUp" *ngIf="offerAvailable || convenienceFee">
						<div class="col-6">Total</div>
						<div class="col-6 text-right">
							<span [innerHTML]="currency.currency"></span> {{ order_details?.total_amount | number : '1.2-2' }}
						</div>
					</div>
					<div class="row fS-14 pt-3 priceBreakUp green_text" *ngIf="offerAvailable">
						<div class="col-6">(-) Offer Applied</div>
						<div class="col-6 text-right">
							<span [innerHTML]="currency.currency"></span> {{ offerDiscount | number : '1.2-2' }}
						</div>
					</div>
					<div class="row fS-14 pt-3 priceBreakUp" *ngIf="convenienceFee">
						<div class="col-6">(+) Convenience Fee</div>
						<div class="col-6 text-right">
							<span [innerHTML]="currency.currency"></span> {{ convenienceFee | number : '1.2-2' }}
						</div>
					</div>
					<div
						class="row fS-14 pt-3 priceBreakUp grandTotal"
						*ngIf="offerAvailable || convenienceFee"
					>
						<div class="col-6">Grand Total</div>
						<div class="col-6 text-right">
							<span [innerHTML]="currency.currency"></span> {{ grandTotal | number : '1.2-2' }}
						</div>
					</div>
				</div>
			</form>
		</div>
		<div class="flex fS-14 pt-3 pl-3">
			<div class="flex" style="justify-content: space-between; width:100%; gap:15px">
				<button 
					type="button"
					class="btn btn-outline-dark cancel-button"
					(click)="isEnforced ? cancelRef.showConfirmationMessage() : cancelPayment()"
				>
					Cancel
				</button>
				<button 
					type="button"
					class="btn btn-primary btn-orange pay-button"
					(click)="initiatePayment()"
					[disabled]="(cardDetailForm.pristine || cardDetailForm.invalid) && !isbuttonEnabled"
					[ngClass]="cardDetailForm.valid && isbuttonEnabled ? 'valid' : ''"
				>
					<!--
							before merge to master it was
							(cardDetailForm.pristine || cardDetailForm.invalid) || !isbuttonEnabled
					-->
					Pay
					<span [innerHTML]="currency.currency"></span> {{ grandTotal | number : '1.2-2' }}*
				</button>
			</div>
		</div>
		<app-close-checkout #cancelRef></app-close-checkout>
		<div class="row fS-15" *ngIf="this.paymentModesWithOffers?.length">
			<div class="col">
				<app-offers [data_mode]="selectedPaymentMode?.paymentMode"></app-offers>
			</div>
		</div>
	</div>
</div>

<!-- EMI List -->
<div class="box-accor emi-plans-container" *ngIf="isEMIPlanFlow">
	<div class="box-item">
		<div class="title-payment box-row py-3">
			<div class="">
				<img [src]="selectedPaymentMode.logo" class="img-fluid" />
			</div>
			<div class="px-2 fS-18">
				<strong
					>Pay with
					{{ selectedPaymentMode.paymentModeName || selectedPaymentMode.paymentMode }}</strong
				>
			</div>
		</div>
		<div class="emi-list-inner" *ngIf="!isContinueClicked">
			<!-- <p>Select your <span>EMI Plan:</span></p> -->

			<div *ngFor="let emiPlan of schemeList">
				<div class="emi-list">
					<div class="emi-input-title">
						<input
							class="emi-input"
							[(ngModel)]="emi"
							type="radio"
							name="emi"
							[value]="emiPlan.total_emi"
							(ngModelChange)="onEmiChange(emiPlan)"
						/>
						<div class="title-container">
							<span>{{ emiPlan?.total_emi }} Months X {{ emiPlan?.emi }}</span>
							<div class="emi-detail no-cost-emi" *ngIf="emiPlan?.interest === 0">No Cost EMI</div>
							<div class="emi-detail" *ngIf="emiPlan?.interest !== 0">
								Total interest of <span [innerHTML]="currency.currency"></span> {{ emiPlan?.interest }} at {{ emiPlan?.irr }}%
							</div>
						</div>
					</div>
					<div *ngIf="emi == emiPlan.total_emi">
						<div class="emi-detail-table">
							<div>
								<p>Interest ({{ emiPlan?.irr }}%)</p>
								<p><span [innerHTML]="currency.currency"></span> {{ emiPlan?.interest }}</p>
							</div>
							<div *ngIf="emiPlan?.interest === 0">
								<p>EMI Discount</p>
								<p class="no-cost-emi">
									- <span [innerHTML]="currency.currency"></span> {{ emiPlan?.interest }}
								</p>
							</div>
							<div *ngIf="emiPlan?.interest !== 0">
								<p>EMI</p>
								<p><span [innerHTML]="currency.currency"></span> {{ emiPlan?.emi }}</p>
							</div>
							<div>
								<p>Total Payable</p>
								<p>
									<span [innerHTML]="currency.currency"></span
									> {{ emiPlan?.total_emi * emiPlan?.emi | number : '1.2-2' }}
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="row fS-14 pt-3">
				<div class="emi-note" *ngIf="selectedEmi?.processing_fee">
					<p>
						You will be charged <span [innerHTML]="currency.currency"></span> {{ selectedEmi.processing_fee }} + GST as processing fee for EMI
						transactions.
					</p>
					<p>You agree to the Terms & Conditions by clicking on continue.</p>
				</div>
				<div class="flex" style="justify-content: space-between;">
					<button
						type="button"
						class="btn btn-outline-dark cancel-button"
						(click)="isEnforced ? cancelRef.showConfirmationMessage() : cancelPayment()"
					>
						Cancel
					</button>
					<button
						type="button"
						[disabled]="!isbuttonEnabled"
						class="btn btn-primary btn-orange pay-button"
						[ngClass]="isbuttonEnabled ? 'valid' : ''"
						(click)="initiatePayment()"
					>
						Continue
					</button>
				</div>
			</div>
		</div>
	</div>
</div>


<app-close-checkout #cancelRef></app-close-checkout>
