import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable } from 'rxjs';
import { SharedService } from './shared.service';

@Injectable({
	providedIn: 'root',
})
export class ModalService {
	errorMsg;
	constructor(private sharedService: SharedService){}
	private display: BehaviorSubject<'open' | 'close'> = new BehaviorSubject('close');

	watch(): Observable<'open' | 'close'> {
		return this.display.asObservable();
	}

	open(): void {	
		this.display.next('open');
	}

	close() {
		this.display.next('close');
	}
}
