<div class="my-2" *ngIf="welcome">
    <h2 class="welcome-nm">Hello,</h2>
    <div class="fS-13 fWM">Please provide your mobile number</div>
</div>
<div class="my-2" *ngIf="!welcome">
    <h2 class="welcome-nm" title="Change User">Hello 
        <span *ngIf="userFound">{{nimbbl_mobile_name|titlecase}}</span>,
    </h2>
    <div><span class="flag"><img src="assets/images/countryFlags/{{userCountryDetail.flag}}" class="img-fluid"></span><span class="mobile">{{userCountryDetail.dailerCode}}
            {{user_mobile_number}}</span>
        <span class="float-right cursor" (click)="useAnotherAcccount()">Change User</span>
    </div>

</div>
<div class="fS-15" *ngIf="!welcome && payment">
    <div class="mt-3 mb-2"><span class="enterOtpMessage">Please choose a payment method to proceed</span ></div>
</div>
