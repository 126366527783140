<div class="box-accor process-container">
    <div class="box-item container">
        <div class="row box-row pt-1">
            <div class="col-12 align-self-center">
                <div class="box-date-details">
                    <div class="row">
                        <div class="col-auto p-0"><img [src]="selectedPaymentMode?.logo" class="img-fluid" [ngClass]="(selectedPaymentMode.vpa_provider == 'bhim' || selectedPaymentMode.paymentMode == 'BHIM') ? 'bhim': ''"></div>
                        <div class="col px-2 fS-18">
                            <strong>Pay with {{paymentName || magicUPIDisplayName || paymentModeName}}</strong>
                            <!-- <strong *ngIf="paymentModeName=='Credit Card' ||paymentModeName=='Debit Card'||paymentModeName=='Prepaid Card'">Pay with Card</strong> -->

                        </div>
                    </div>

                </div>
            </div>

        </div>
        <!-- In case of UPI process, the User Name, VPA ID and Verify tick will be displayed -->
        <div class="row mt-3 mb-0" *ngIf="vpaDetail?.isUpiPayment">
			<div class="col-12">
                <div class="mb-1" *ngIf="vpaDetail?.vpaUserName"><strong>User Name: {{ vpaDetail.vpaUserName | uppercase }}</strong></div>
				<div class="mb-1" *ngIf="vpaDetail?.vpaId">UPI ID: {{ vpaDetail.vpaId }}</div>
				<div class="mb-1">&#x2705; Verified</div>
			</div>
		</div>
        <!--  -->
        <div class="row">
            <div class="payment-success text-center">
                <div class="processingScreen">
                    <div class="loaderhmtl processingLoader"></div>
                    <div class="countDown">
                        <countdown #cd [config]="config" (event)="countdownEvent($event)"></countdown>
                    </div>
                </div>
                <div class="fS-18 fWM mt-2 processing-description" *ngIf="paymentModeName != 'Netbanking' && paymentModeName != 'Wallet' && paymentModeName != 'UPI'">Processing</div>
                <div class="fS-18 fWM mt-2 processing-description" *ngIf="paymentModeName == 'Netbanking' || paymentModeName == 'Wallet'">Your payment with {{bank_name}} is being processed</div>
                <div class="fS-18 fWM mt-2 processing-description" *ngIf="paymentModeName == 'UPI' && !isIntent">Please approve the payment request for <span [innerHTML]="currency.currency"></span> {{order_details?.grand_total_with_convience_fees || order_details?.total_amount | number : '1.2-2'}} on your {{vpaDetail?.magicUPIDisplayName || vpaDetail?.vpaAppName}} app</div>

                <div class="fS-18 fWM mt-2 processing-description" *ngIf="paymentModeName == 'UPI' && isIntent">Please pay <span [innerHTML]="currency.currency"></span> {{order_details?.grand_total_with_convience_fees || order_details?.total_amount | number : '1.2-2'}} with your UPI app</div>

                <div *ngIf="paymentModeName == 'UPI' && !isIntent" class="mt-3">
                    <!-- <div class="col-12"> -->
                    <div *ngFor="let step of upi_steps" class="step_list">
                        <div class="steps_icon">
                            <img [src]="step.icon" class="img-fluid paymentModeLogo" />
                        </div>
                        <div class="steps_content">
                            <p class="step_title">
                                {{step.title}}
                            </p>
                            <p class="step_description">
                                {{step.description}}
                            </p>
                        </div>
                    </div>

                </div>
                <div *ngIf="paymentModeName == 'UPI'" class="step_note">
                    Please do not close the window until the payment is processed
                    <button type="button" class="btn btn-outline-dark yesBtn me-2 mr-3" id="cancelBtn" (click)="showReason()">Cancel</button>
                </div>
                <!-- isWindow handling -->
                <div *ngIf="(paymentModeName == 'Netbanking' || paymentModeName == 'Wallet') && !isWindowClosed">
                    <span class="goToPayment" (click)="setFocus()">Go to Payment</span>
                </div>
                <script>console.log(is_window_close)</script>
            </div>
        </div>
    </div>

</div>

<div class="closeBox" [ngClass]="showPopup === false ? 'd-none' : 'd-flex'">
    <div class="confirmationBox">
        <div class="header">Tell us why you want to cancel</div>
        <div class="popupBody">


            <div>
                <label *ngFor="let reason of reasons;let i=index;let last = last" for="reason+{{i}}" class="radioContainer">
                    {{reason}}
                    
                    <input type="radio" name="reson" id="reason+{{i}}" class="customradio" [value]="reason" [(ngModel)]="selectedReason" checked="last?'last':''">
                    <span class="radiomark"></span>
                </label>
                <div class="text-right">
                    <button type="button" class="btn btn-outline-dark yesBtn me-2 mr-3" id="backBtn" (click)="closePopup()">Back</button>
                    <button type="button" class="btn btn-outline-dark yesBtn me-2 mr-3" id="okBtn" [disabled]="!selectedReason" (click)="submitReason()">OK</button>

                </div>
            </div>
        </div>
    </div>
</div>