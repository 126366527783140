<div class="fS-15">
  <div class="mt-3 mb-2"><span class="enterOtpMessage">Let us get to know you better</span ></div>
</div>
<form class="mb-4" [formGroup]="user_other_info_form" novalidate (submit)="updateUserInfo(user_other_info_form)">
  <div class="mb-3">
    <input type="text" class="form-control input_01" formControlName="first_name" id="exampleInputEmail1" placeholder="Enter Name"
      required id="userName">
    <div *ngIf="user_other_info_form.controls['first_name'].invalid && (user_other_info_form.controls['first_name'].dirty || user_other_info_form.controls['first_name'].touched)"
      class="alert alert-danger">
      <div *ngIf="user_other_info_form.controls['first_name'].errors.required">Please enter vaild Name <span class="exclametry">!</span>
      </div>
      <div *ngIf="user_other_info_form.controls['first_name'].errors.pattern">Please enter vaild Name <span class="exclametry">!</span>
      </div>

    </div>
  </div>
  <div class="mb-3" *ngIf="merchant_details.email_id_required">
    <input type="text" class="form-control input_01" formControlName="email" id="exampleInputPassword1"
      placeholder="Enter Email id" required autocomplete="off" *ngIf="merchant_details.email_id_required">
    <div
      *ngIf="user_other_info_form.controls['email']?.invalid && (user_other_info_form.controls['email']?.dirty || user_other_info_form.controls['email']?.touched)"
      class="alert alert-danger">
      <div *ngIf="user_other_info_form.controls['email']?.errors.required">Please enter vaild Email ID <span class="exclametry">!</span>
      </div>
      <div *ngIf="user_other_info_form.controls['email']?.errors.pattern">Please enter vaild Email ID <span class="exclametry">!</span>
      </div>

    </div>
  </div>
  <button type="submit" class="btn btn-primary btn-orange" [disabled]="user_other_info_form.pristine || user_other_info_form.invalid" [ngClass]="user_other_info_form.valid?'valid':'invalid'">Continue</button>
</form>