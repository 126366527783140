import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { ApiService } from 'src/app/services/api.service';
import { PaymentModeService } from 'src/app/services/payment-mode.service';
import { SharedService } from 'src/app/services/shared.service';
import { orderDetails } from 'src/app/app.models';
import { OrderDetailService } from 'src/app/services/order-detail.service';
import { hasMaxRetriesReached } from 'src/app/utils';
@Component({
	selector: 'app-payment-failure',
	templateUrl: './payment-failure.component.html',
	styleUrls: ['./payment-failure.component.css'],
})
export class PaymentFailureComponent implements OnInit {
	@Input() message: string = null;
	@Input() UPIId: string = null;
	@Input() showTryAgainButton: boolean = false;
	@Input() showMaxRetriesReachedElement: boolean = false;
	@Output() initiatePay: EventEmitter<any> = new EventEmitter<any>();
	selectedPaymentMode;
	isPaymentTimedOut: boolean = false;
	paymentModeName;
	magicUPIDisplayName: string;
	order_details: orderDetails;
	paymentName: string = '';
	seconds: number;
	enforcedInitPayment: boolean = false;
	isEnforced = false;
	constructor(
		private sharedOrderDetails: OrderDetailService,
		private sharedPaymentService: PaymentModeService,
		private apiService: ApiService,
		private cookies: CookieService,
		private sharedService: SharedService
	) {
		this.sharedPaymentService.sharedSelectedMode.subscribe((res) => {
			this.selectedPaymentMode = res;
			this.apiService.fetchPaymentModesMessage().subscribe((res) => {
				if (
					this.selectedPaymentMode['paymentMode'] != 'Netbanking' &&
					(this.selectedPaymentMode['paymentMode'] != 'Debit Card' ||
						this.selectedPaymentMode['paymentMode'] != 'Credit Card' ||
						this.selectedPaymentMode['paymentMode'] != 'Prepaid Card') &&
					this.selectedPaymentMode['paymentMode'] != 'UPI' &&
					this.selectedPaymentMode['paymentMode'] != 'Wallet'
				) {
					// this.paymentModeName = res[this.selectedPaymentMode['paymentMode'].toLowerCase()]['modeName'] === 'Card' ?
					//   this.sharedService.getCardType() :
					//   res[this.selectedPaymentMode['paymentMode'].toLowerCase()]['modeName'];
					if (res && this.selectedPaymentMode && this.selectedPaymentMode?.paymentMode) {
						const modeName = res[this.selectedPaymentMode?.paymentMode.toLowerCase()]['modeName'];
						this.paymentModeName =
							modeName === 'Card' ? this.sharedService.getCardType() : modeName || ''; // default value when modeName is null or undefined
					} else {
						this.paymentModeName = ''; // default value when res or this.selectedPaymentMode is null or undefined
					}
				} else if (
					this.selectedPaymentMode['paymentMode'] == 'Credit Card' ||
					this.selectedPaymentMode['paymentMode'] == 'Debit Card' ||
					this.selectedPaymentMode['paymentMode'] == 'Prepaid Card'
				) {
					this.paymentModeName = this.selectedPaymentMode['paymentMode'];
				} else {
					this.paymentModeName = this.selectedPaymentMode['paymentMode'];
				}
				this.magicUPIDisplayName = this.selectedPaymentMode.is_magic_UPI
					? this.selectedPaymentMode['magicUPIDisplayName']
					: '';
			});
		});

		this.sharedService.updateCollapsibleStatus({
			...this.sharedService.getCollapsibleStatus(),
			docOffset: 0,
		}); // reset the document offset on failure
	}

	ngOnInit(): void {
		this.sharedService.updateEnforcePaymentInitiated(false);
		this.isEnforced = this.sharedService.getEnforcePaymentDetails();
		if (this.message == 'Since your previous payment timed out, please try another payment.') {
			this.isPaymentTimedOut = true;
		}

		this.order_details = JSON.parse(JSON.stringify(this.sharedOrderDetails.getOrderDetails()));
		if (
			this.order_details['grand_total_with_convience_fees'] &&
			!this.sharedService.getEnforcePaymentDetails()
		) {
			this.order_details['grand_total_with_convience_fees'] = 0;
			this.sharedOrderDetails.updateOrderDetails(this.order_details);
		}

		this.paymentName = this.sharedService.getPaymentNameDetails();

		if (this.selectedPaymentMode['paymentMode'] === 'Netbanking') {
			setTimeout(() => {
				document.getElementById('spinnerLoaderContainerBankList')?.remove();
				document.getElementById('listOfBanksUl')
					? (document.getElementById('listOfBanksUl').style.background = 'none')
					: '';
			}, 100);
		} else if (this.selectedPaymentMode['paymentMode'] === 'Wallet') {
			setTimeout(() => {
				document.getElementById('spinnerLoaderContainerWallet')?.remove();
				document.getElementById('listOfWalletUl')
					? (document.getElementById('listOfWalletUl').style.background = 'none')
					: '';
			}, 100);
		}

		this.apiService.fetchOrderById(this.order_details['order_id']).subscribe((response) => {

			this.showMaxRetriesReachedElement = hasMaxRetriesReached(response);
		});
	}

	redirectToURL(): void {
		window.location.href = this.order_details['callback_url'];
	}

	reinitiatePayment() {
		this.initiatePay.emit(this.UPIId);
	}
}
