import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { catchError, finalize, tap } from 'rxjs/operators';
import { orderDetails } from '../app.models';
import { OrderDetailService } from './order-detail.service';
import { LoadingModalService } from './loading-modal.service';
import tracking from '../utils/tracking';
declare var newrelic: any;
@Injectable()
export class HttpLoadingInterceptor implements HttpInterceptor {
  order_detail: orderDetails | any;
  loadingModalService: LoadingModalService;
  handledResponsefromAPI: HttpErrorResponse | any;
  constructor(private sharedOrderService: OrderDetailService) {
    this.sharedOrderService.sharedOrderDetails.subscribe((res) => {
      this.order_detail = JSON.parse(JSON.stringify(res));
    });
  }
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      tap((evt) => {
        if (evt instanceof HttpResponse) {
          this.handledResponsefromAPI = evt;
        }
      }),
      catchError((err: any) => {
        if (err instanceof HttpErrorResponse) {
          this.handledResponsefromAPI = err;
        }
        return throwError(err);
      }),
      finalize(() => {
        if(this.handledResponsefromAPI.status >= 300) {
          this.apiResponseLoggingNewRelic(this.handledResponsefromAPI, 'error');
        } else {
          this.apiResponseLoggingNewRelic(this.handledResponsefromAPI, 'success');
        }
      })
    );
  }
  apiResponseLoggingNewRelic(request, type) {
    let data = {
      apiResponseType: type,
      apiResponse: request,
      api_status: request['status'],
      api_status_text: request['statusText'],
      api_url:request.url,
      order_id: this.order_detail['order_id'] || request['body']['order_id'],
      sub_merchant_id: this.order_detail['sub_merchant_id'] || request['body']['sub_merchant_id'],
    };
    window.newrelic.addPageAction('API Response', data);
  }
}
