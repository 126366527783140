import { Component, Input, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { timeStamp } from 'console';
import { CookieService } from 'ngx-cookie-service';
import { orderDetails } from 'src/app/app.models';
import { OrderDetailService } from 'src/app/services/order-detail.service';
import { SharedService } from 'src/app/services/shared.service';
import { ApiService } from 'src/app/services/api.service';
import { hasMaxRetriesReached } from 'src/app/utils';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'app-new-on-nimbl',
  templateUrl: './new-on-nimbl.component.html',
  styleUrls: ['./new-on-nimbl.component.css']
})
export class NewOnNimblComponent implements OnInit {
  @Input() isOtpVerify: boolean = false;
  @Input() userFound: boolean = false;
  @Input() nimbbl_mobile_name: string = "";
  @Input() user_mobile_number;
  @Input() welcome: boolean = true;
  @Input() payment: boolean = false;
  order_details: orderDetails;
  userAccounts;
  userCountryDetail;
  constructor(private router: Router, private cookies: CookieService, private shareOrderDetail: OrderDetailService, private sharedService: SharedService, private activeRoute: ActivatedRoute, private apiService: ApiService, private commonService: CommonService, ) {
    this.sharedService.sharedUserCountry.subscribe(res => {
      this.userCountryDetail = res;
    });
    this.shareOrderDetail.sharedOrderDetails.subscribe(order => {
      this.order_details = JSON.parse(JSON.stringify(order));
    });
  }

  ngOnInit(): void { 
    this.activeRoute.queryParams.subscribe((params) =>{

      const userOrderId = params['order_id'];
      // before making the get-order call we are checking whether the token is valid
      this.sharedService.sharedTokenDetail.subscribe((res)=>{
        if(Object.keys(res).length > 0 && !this.shareOrderDetail.hasOrderDetailsInServices()){
          this.apiService.fetchOrderById(userOrderId).subscribe((response) => {
            this.shareOrderDetail.updateOrderDetails(response);
            
            if(hasMaxRetriesReached(response)) {
              this.sharedService.setHasMaxRetriesExpiredSubject(true);
              let paymentResponse = this.shareOrderDetail.getPaymentResponse();
              this.commonService.getCallBackPayloadResponse(
                response, 
                paymentResponse['nimbbl_transaction_id']
              );
            }
          })
        }       
      })
    })
  }
  ngOnchanges() {

  }

  useAnotherAcccount() {
    this.sharedService.updateuseAnotherAccount('yes');
    this.sharedService.updateFastPaymentViewMore(false);
    // this.cookies.set('useAnotherAccount', 'yes', 365, "", "", true, "None");
    let options = { order_id: this.order_details['order_id']  };
    let transaction_token = this.activeRoute.snapshot.queryParams['transaction_token'];
    if(transaction_token) {
      options['transaction_token'] = transaction_token;
    }
    else {
      options['expires'] = this.sharedService.getTokenExpiryTime();
    }
    this.router.navigate(['/'], { queryParams: options });
  }
}
