import { Injectable } from '@angular/core';
import { element } from 'protractor';
import { BehaviorSubject, Subject } from 'rxjs';
import { UserCountryData } from '../app.models';

@Injectable({
  providedIn: 'root'
})
export class SharedService {
  listOfCountries = [
    {
      "countryName": "Albania",
      "dailerCode": "+355",
      "flag": "albania.png"
    },
    {
      "countryName": "Algeria",
      "dailerCode": "+213",
      "flag": "algeria.png"
    },
    {
      "countryName": "Andorra",
      "dailerCode": "+376",
      "flag": "andorra.png"
    },
    {
      "countryName": "Argentina",
      "dailerCode": "+54",
      "flag": "argentina.png"
    },
    {
      "countryName": "Armenia",
      "dailerCode": "+374",
      "flag": "armenia.png"
    },
    {
      "countryName": "Aruba",
      "dailerCode": "+297",
      "flag": "aruba.png"
    },
    {
      "countryName": "Australia",
      "dailerCode": "+61",
      "flag": "australia.png"
    },
    {
      "countryName": "Bahamas",
      "dailerCode": "+1",
      "flag": "bahamas.png"
    },
    {
      "countryName": "Bangladesh",
      "dailerCode": "+880",
      "flag": "bangladesh.png"
    },
    {
      "countryName": "Barbados",
      "dailerCode": "+1",
      "flag": "barbados.png"
    },
    {
      "countryName": "Belize",
      "dailerCode": "+501",
      "flag": "belize.png"
    },
    {
      "countryName": "Bermuda",
      "dailerCode": "+1",
      "flag": "bermuda.png"
    },
    {
      "countryName": "Bolivia",
      "dailerCode": "+591",
      "flag": "bolivia.png"
    },
    {
      "countryName": "Botswana",
      "dailerCode": "+267",
      "flag": "botswana.png"
    },
    {
      "countryName": "Brunei",
      "dailerCode": "+673",
      "flag": "brunei.png"
    },
    {
      "countryName": "Cambodia",
      "dailerCode": "+855",
      "flag": "cambodia.png"
    },
    {
      "countryName": "Canada",
      "dailerCode": "+1",
      "flag": "canada.png"
    },
    {
      "countryName": "Cayman Islands",
      "dailerCode": "+1",
      "flag": "cayman_islands.png"
    },
    {
      "countryName": "China",
      "dailerCode": "+86",
      "flag": "china.png"
    },
    {
      "countryName": "Colombia",
      "dailerCode": "+57",
      "flag": "colombia.png"
    },
    {
      "countryName": "Costa Rica",
      "dailerCode": "+506",
      "flag": "costa_rica.png"
    },
    {
      "countryName": "Croatia",
      "dailerCode": "+385",
      "flag": "croatia.png"
    },
    {
      "countryName": "Cuba",
      "dailerCode": "+53",
      "flag": "cuba.png"
    },
    {
      "countryName": "Czech Republic",
      "dailerCode": "+420",
      "flag": "czech_republic.png"
    },
    {
      "countryName": "Denmark",
      "dailerCode": "+45",
      "flag": "denmark.png"
    },
    {
      "countryName": "Dominican Republic",
      "dailerCode": "+1",
      "flag": "dominican_republic.png"
    },
    {
      "countryName": "East Timor",
      "dailerCode": "+670",
      "flag": "east_timor.png"
    },
    {
      "countryName": "Egypt",
      "dailerCode": "+20",
      "flag": "egypt.png"
    },
    {
      "countryName": "El Salvador",
      "dailerCode": "+503",
      "flag": "el_salvador.png"
    },
    {
      "countryName": "Eswatini",
      "dailerCode": "+268",
      "flag": "eswatini.png"
    },
    {
      "countryName": "Ethiopia",
      "dailerCode": "+251",
      "flag": "ethiopia.png"
    },
    {
      "countryName": "Fiji",
      "dailerCode": "+679",
      "flag": "fiji.png"
    },
    {
      "countryName": "Gambia",
      "dailerCode": "+220",
      "flag": "gambia.png"
    },
    {
      "countryName": "Ghana",
      "dailerCode": "+233",
      "flag": "ghana.png"
    },
    {
      "countryName": "Gibraltar",
      "dailerCode": "+350",
      "flag": "gibraltar.png"
    },
    {
      "countryName": "Guatemala",
      "dailerCode": "+502",
      "flag": "guatemala.png"
    },
    {
      "countryName": "Guyana",
      "dailerCode": "+592",
      "flag": "guyana.png"
    },
    {
      "countryName": "Haiti",
      "dailerCode": "+509",
      "flag": "haiti.png"
    },
    {
      "countryName": "Honduras",
      "dailerCode": "+504",
      "flag": "honduras.png"
    },
    {
      "countryName": "Hong Kong",
      "dailerCode": "+852",
      "flag": "hong_kong.png"
    },
    {
      "countryName": "Hungary",
      "dailerCode": "+36",
      "flag": "hungary.png"
    },
    {
      "countryName": "India",
      "dailerCode": "+91",
      "flag": "india.png"
    },
    {
      "countryName": "Indonesia",
      "dailerCode": "+62",
      "flag": "indonesia.png"
    },
    {
      "countryName": "Israel",
      "dailerCode": "+972",
      "flag": "israel.png"
    },
    {
      "countryName": "Jamaica",
      "dailerCode": "+1",
      "flag": "jamaica.png"
    },
    {
      "countryName": "Kazakhstan",
      "dailerCode": "+7",
      "flag": "kazakhstan.png"
    },
    {
      "countryName": "Kenya",
      "dailerCode": "+254",
      "flag": "kenya.png"
    },
    {
      "countryName": "Kyrgyzstan",
      "dailerCode": "+996",
      "flag": "kyrgyzstan.png"
    },
    {
      "countryName": "Laos",
      "dailerCode": "+856",
      "flag": "laos.png"
    },
    {
      "countryName": "Lebanon",
      "dailerCode": "+961",
      "flag": "lebanon.png"
    },
    {
      "countryName": "Lesotho",
      "dailerCode": "+266",
      "flag": "lesotho.png"
    },
    {
      "countryName": "Liberia",
      "dailerCode": "+231",
      "flag": "liberia.png"
    },
    {
      "countryName": "Liechtenstein",
      "dailerCode": "+423",
      "flag": "liechtenstein.png"
    },
    {
      "countryName": "Macau",
      "dailerCode": "+853",
      "flag": "macau.png"
    },
    {
      "countryName": "Malawi",
      "dailerCode": "+265",
      "flag": "malawi.png"
    },
    {
      "countryName": "Malaysia",
      "dailerCode": "+60",
      "flag": "malaysia.png"
    },
    {
      "countryName": "Maldives",
      "dailerCode": "+960",
      "flag": "maldives.png"
    },
    {
      "countryName": "Mauritius",
      "dailerCode": "+230",
      "flag": "mauritius.png"
    },
    {
      "countryName": "Mexico",
      "dailerCode": "+52",
      "flag": "mexico.png"
    },
    {
      "countryName": "Moldova",
      "dailerCode": "+373",
      "flag": "moldova.png"
    },
    {
      "countryName": "Mongolia",
      "dailerCode": "+976",
      "flag": "mongolia.png"
    },
    {
      "countryName": "Morocco",
      "dailerCode": "+212",
      "flag": "morocco.png"
    },
    {
      "countryName": "Myanmar",
      "dailerCode": "+95",
      "flag": "myanmar.png"
    },
    {
      "countryName": "Namibia",
      "dailerCode": "+264",
      "flag": "namibia.png"
    },
    {
      "countryName": "Nepal",
      "dailerCode": "+977",
      "flag": "nepal.png"
    },
    {
      "countryName": "New Zealand",
      "dailerCode": "+64",
      "flag": "new_zealand.png"
    },
    {
      "countryName": "Nicaragua",
      "dailerCode": "+505",
      "flag": "nicaragua.png"
    },
    {
      "countryName": "Nigeria",
      "dailerCode": "+234",
      "flag": "nigeria.png"
    },
    {
      "countryName": "North Macedonia",
      "dailerCode": "+389",
      "flag": "north_macedonia.png"
    },
    {
      "countryName": "Norway",
      "dailerCode": "+47",
      "flag": "norway.png"
    },
    {
      "countryName": "Pakistan",
      "dailerCode": "+92",
      "flag": "pakistan.png"
    },
    {
      "countryName": "Papua New Guinea",
      "dailerCode": "+675",
      "flag": "papua_new_guinea.png"
    },
    {
      "countryName": "Peru",
      "dailerCode": "+51",
      "flag": "peru.png"
    },
    {
      "countryName": "Philippines",
      "dailerCode": "+63",
      "flag": "philippines.png"
    },
    {
      "countryName": "Qatar",
      "dailerCode": "+974",
      "flag": "qatar.png"
    },
    {
      "countryName": "Russia",
      "dailerCode": "+7",
      "flag": "russia.png"
    },
    {
      "countryName": "Saudi Arabia",
      "dailerCode": "+966",
      "flag": "saudi_arabia.png"
    },
    {
      "countryName": "Seychelles",
      "dailerCode": "+248",
      "flag": "seychelles.png"
    },
    {
      "countryName": "Sierra Leone",
      "dailerCode": "+232",
      "flag": "sierra_leone.png"
    },
    {
      "countryName": "Singapore",
      "dailerCode": "+65",
      "flag": "singapore.png"
    },
    {
      "countryName": "Somalia",
      "dailerCode": "+252",
      "flag": "somalia.png"
    },
    {
      "countryName": "South Africa",
      "dailerCode": "+27",
      "flag": "south_africa.png"
    },
    {
      "countryName": "South Sudan",
      "dailerCode": "+211",
      "flag": "south_sudan.png"
    },
    {
      "countryName": "Sri Lanka",
      "dailerCode": "+94",
      "flag": "sri_lanka.png"
    },
    {
      "countryName": "Sweden",
      "dailerCode": "+46",
      "flag": "sweden.png"
    },
    {
      "countryName": "Tanzania",
      "dailerCode": "+255",
      "flag": "tanzania.png"
    },
    {
      "countryName": "Thailand",
      "dailerCode": "+66",
      "flag": "thailand.png"
    },
    {
      "countryName": "Trinidad and Tobago",
      "dailerCode": "+1",
      "flag": "trinidad_and_tobago.png"
    },
    {
      "countryName": "United Arab Emirates",
      "dailerCode": "+971",
      "flag": "united_arab_emirates.png"
    },
    {
      "countryName": "United Kingdom",
      "dailerCode": "+44",
      "flag": "united_kingdom.png"
    },
    {
      "countryName": "Uruguay",
      "dailerCode": "+598",
      "flag": "uruguay.png"
    },
    {
      "countryName": "Uzbekistan",
      "dailerCode": "+998",
      "flag": "uzbekistan.png"
    },
    {
      "countryName": "United States",
      "dailerCode": "+1",
      "flag": "usa.png"
    },
    {
      "countryName": "Yemen",
      "dailerCode": "+967",
      "flag": "yemen.png"
    }
  ]
  currencyList = [
    {
      "currency": "AED",
      "symbol": "AED"
    },
    {
      "currency": "ALL",
      "symbol": "&#76;&#101;&#107;"
    },
    {
      "currency": "AMD",
      "symbol": "&#x58F;"
    },
    {
      "currency": "ARS",
      "symbol": "&#36;"
    },
    {
      "currency": "AUD",
      "symbol": "&#36;"
    },
    {
      "currency": "AWG",
      "symbol": "&#402;"
    },
    {
      "currency": "BBD",
      "symbol": "&#36;"
    },
    {
      "currency": "BDT",
      "symbol": "&#2547;"
    },
    {
      "currency": "BMD",
      "symbol": "&#36;"
    },
    {
      "currency": "BND",
      "symbol": "&#36;"
    },
    {
      "currency": "BOB",
      "symbol": "&#36;&#98;"
    },
    {
      "currency": "BSD",
      "symbol": "&#36;"
    },
    {
      "currency": "BWP",
      "symbol": "&#80;"
    },
    {
      "currency": "BZD",
      "symbol": "฿"
    },
    {
      "currency": "CAD",
      "symbol": "&#36;"
    },
    {
      "currency": "CHF",
      "symbol": "&#67;&#72;&#70;"
    },
    {
      "currency": "CNY",
      "symbol": "&#165;"
    },
    {
      "currency": "COP",
      "symbol": "&#36;"
    },
    {
      "currency": "CRC",
      "symbol": "&#8353;"
    },
    {
      "currency": "CUP",
      "symbol": "&#8369;"
    },
    {
      "currency": "CZK",
      "symbol": "&#75;&#269;"
    },
    {
      "currency": "DKK",
      "symbol": "&#107;&#114;"
    },
    {
      "currency": "DOP",
      "symbol": "&#82;&#68;&#36;"
    },
    {
      "currency": "DZD",
      "symbol": ""
    },
    {
      "currency": "EGP",
      "symbol": "&#163;"
    },
    {
      "currency": "ETB",
      "symbol": ""
    },
    {
      "currency": "EUR",
      "symbol": "&#8364;"
    },
    {
      "currency": "FJD",
      "symbol": "&#36;"
    },
    {
      "currency": "GBP",
      "symbol": "&#163;"
    },
    {
      "currency": "GHS",
      "symbol": "&#162;"
    },
    {
      "currency": "GIP",
      "symbol": "&#163;"
    },
    {
      "currency": "GMD",
      "symbol": "&#x44;"
    },
    {
      "currency": "GTQ",
      "symbol": "&#81;"
    },
    {
      "currency": "GYD",
      "symbol": "&#36;"
    },
    {
      "currency": "HKD",
      "symbol": "&#36;"
    },
    {
      "currency": "HNL",
      "symbol": "&#76;"
    },
    {
      "currency": "HRK",
      "symbol": "&#107;&#110;"
    },
    {
      "currency": "HTG",
      "symbol": "&#x47;"
    },
    {
      "currency": "HUF",
      "symbol": "&#70;&#116;"
    },
    {
      "currency": "IDR",
      "symbol": "&#82;&#112;"
    },
    {
      "currency": "ILS",
      "symbol": "&#8362;"
    },
    {
      "currency": "INR",
      "symbol": "&#x20b9;"
    },
    {
      "currency": "JMD",
      "symbol": "&#74;&#36;"
    },
    {
      "currency": "KES",
      "symbol": "&#x4b;&#x73;&#x68;"
    },
    {
      "currency": "KGS",
      "symbol": "&#1083;&#1074;"
    },
    {
      "currency": "KHR",
      "symbol": "&#6107;"
    },
    {
      "currency": "KYD",
      "symbol": "&#36;"
    },
    {
      "currency": "KZT",
      "symbol": "&#1083;&#1074;"
    },
    {
      "currency": "LAK",
      "symbol": "&#8365;"
    },
    {
      "currency": "LBP",
      "symbol": "&#163;"
    },
    {
      "currency": "LKR",
      "symbol": "&#8360;"
    },
    {
      "currency": "LRD",
      "symbol": "&#36;"
    },
    {
      "currency": "LSL",
      "symbol": "&#x4c;"
    },
    {
      "currency": "MAD",
      "symbol": "&#x2e;&#x62f;&#x2e;&#x645;"
    },
    {
      "currency": "MDL",
      "symbol": "&#x4d;&#x44;&#x4c;"
    },
    {
      "currency": "MKD",
      "symbol": "&#1076;&#1077;&#1085;"
    },
    {
      "currency": "MMK",
      "symbol": "&#x4b;"
    },
    {
      "currency": "MNT",
      "symbol": "&#8366;"
    },
    {
      "currency": "MOP",
      "symbol": "&#x4d;&#x4f;&#x50;&#36;"
    },
    {
      "currency": "MUR",
      "symbol": "&#8360;"
    },
    {
      "currency": "MVR",
      "symbol": "&#x52;&#x66;"
    },
    {
      "currency": "MWK",
      "symbol": "&#x4d;&#x4b;"
    },
    {
      "currency": "MXN",
      "symbol": "&#36;"
    },
    {
      "currency": "MYR",
      "symbol": "&#82;&#77;"
    },
    {
      "currency": "NAD",
      "symbol": "&#36;"
    },
    {
      "currency": "NGN",
      "symbol": "&#8358;"
    },
    {
      "currency": "NIO",
      "symbol": "&#67;&#36;"
    },
    {
      "currency": "NOK",
      "symbol": "&#107;&#114;"
    },
    {
      "currency": "NPR",
      "symbol": "&#8360;"
    },
    {
      "currency": "NZD",
      "symbol": "&#36;"
    },
    {
      "currency": "PEN",
      "symbol": "&#83;&#47;&#46;"
    },
    {
      "currency": "PGK",
      "symbol": "&#x4b;"
    },
    {
      "currency": "PHP",
      "symbol": "&#8369;"
    },
    {
      "currency": "PKR",
      "symbol": "&#8360;"
    },
    {
      "currency": "QAR",
      "symbol": "&#65020;"
    },
    {
      "currency": "RUB",
      "symbol": "&#8381;"
    },
    {
      "currency": "SAR",
      "symbol": "&#65020;"
    },
    {
      "currency": "SCR",
      "symbol": "&#8360;"
    },
    {
      "currency": "SEK",
      "symbol": "&#107;&#114;"
    },
    {
      "currency": "SGD",
      "symbol": "&#36;"
    },
    {
      "currency": "SLL",
      "symbol": "&#x4c;&#x65;"
    },
    {
      "currency": "SOS",
      "symbol": "&#83;"
    },
    {
      "currency": "SSP",
      "symbol": "&#x53;&#x53;&#x20A4;"
    },
    {
      "currency": "SVC",
      "symbol": "&#36;"
    },
    {
      "currency": "SZL",
      "symbol": ""
    },
    {
      "currency": "THB",
      "symbol": "&#3647;"
    },
    {
      "currency": "TTD",
      "symbol": "&#84;&#84;&#36;"
    },
    {
      "currency": "TZS",
      "symbol": "&#x54;&#x53;&#x68;"
    },
    {
      "currency": "USD",
      "symbol": "&#36;"
    },
    {
      "currency": "UYU",
      "symbol": "&#36;&#85;"
    },
    {
      "currency": "UZS",
      "symbol": "&#1083;&#1074;"
    },
    {
      "currency": "YER",
      "symbol": "&#65020;"
    },
    {
      "currency": "ZAR",
      "symbol": "&#82;"
    }
  ]
  constructor() { }
  private tokenDetails = new BehaviorSubject([]);
  sharedTokenDetail = this.tokenDetails.asObservable();
  updateTokenDetails(updatedTokenDetails) {
    this.tokenDetails.next(updatedTokenDetails);

  }
  getTokenDetails() {
    return this.tokenDetails.value;
  }

  private tokenExpiryTime = new BehaviorSubject(undefined);
  sharedTokenExpiryTime = this.tokenExpiryTime.asObservable();
  updateTokenExpiryTime(updateTokenExpiryTime) {
    this.tokenExpiryTime.next(updateTokenExpiryTime);
  }
  getTokenExpiryTime() {
    return this.tokenExpiryTime.value;
  }


  private card_type = new BehaviorSubject<string>(null);
  shared_card_type = this.card_type.asObservable();
  updateCardtype(cardtype) {
    this.card_type.next(cardtype);

  }
  getCardType() {
    return this.card_type.value;

  }
  private card_provider = new BehaviorSubject([]);
  shared_card_provider = this.card_provider.asObservable();
  updateCardprovider(provider) {
    this.card_provider.next(provider);

  }
  getCardProvider() {
    return this.card_provider.value;

  }


  private sharewindow = new BehaviorSubject([]);
  shareWindowService = this.sharewindow.asObservable();
  closeWindow(windowEvent) {
    this.sharewindow.next(windowEvent);

  }

/* 
this.sharedService.shareWindowService.subscribe(res => {
  console.log('Shared Closed Services', res)
})
*/

  private fingerPrint = new BehaviorSubject(null);
  sharedFingerPrint = this.fingerPrint.asObservable();
  updateFingerPrint(finger_print) {
    this.fingerPrint.next(finger_print);
  }
  getFingerPrint() {
    return this.fingerPrint.value;
  }


  private authToken = new BehaviorSubject(null);
  sharedAuthToken = this.authToken.asObservable();
  updateAuthToken(token) {
    this.authToken.next(token);
  }
  getAuthToken() {
    return this.authToken.value;
  }

  private otpStatus = new BehaviorSubject(null);
  sharedOtpStatus = this.otpStatus.asObservable();

  updateOtpStatus(status) {
    this.otpStatus.next(status);
  }
  getOtpStatus() {
    return this.otpStatus.value;
  }

  private useAnotherAccount = new BehaviorSubject(null);
  shareduseAnotherAccount = this.useAnotherAccount.asObservable();
  updateuseAnotherAccount(change) {
    this.useAnotherAccount.next(change);
  }

  getuseAnotherAccount() {
    return this.useAnotherAccount.value
  }
  
  private accountSwitched = new BehaviorSubject(null);
  sharedaccountSwitched = this.accountSwitched.asObservable();
  updateaccountSwitched(change) {
    this.accountSwitched.next(change);
  }

  getaccountSwitched() {
    return this.accountSwitched.value
  }

  private userCountry = new BehaviorSubject<object>({
    "countryName": "India",
    "dailerCode": '+91',
    "flag": "india.png"
  });
  sharedUserCountry = this.userCountry.asObservable();

  updateuserCountry(change) {
    this.userCountry.next(change);
  }

  getauserCountry() {
    return this.userCountry.value;
  }
  updateUserCountryFromAPI(countryCode) {
    this.listOfCountries.filter(element => {
      if (element.dailerCode == countryCode) {
        this.updateuserCountry(element);
      }
    });
  }


  private Currency = new BehaviorSubject<object>({
    "currency": "INR",
    "symbol": "&#x20b9;"
  });
  sharedCurrency = this.Currency.asObservable();

  UpdateCurrency(currency) {
    this.Currency.next(currency);
  }

  getCurrency() {
    return this.Currency.value;
  }
  updateCurrencyFromAPI(currency) {
    this.currencyList.filter(element => {
      if (element.currency == currency) {
        this.UpdateCurrency(element);
      }
    });
  }
  private UPIDetail = new BehaviorSubject([]);
  sharedUPIDetail = this.UPIDetail.asObservable();
  updateUPIDetail(updatedUPIDetail) {
    this.UPIDetail.next(updatedUPIDetail);
  }
  getupdatedUPIDetail(){
    return this.UPIDetail.value;
  }


  // collaspbile (fast payment/other payment) open/close
  private collapsibleStatus = new BehaviorSubject({
    docOffset: 0,
    fastPaymentOption: false,
    otherPaymentOption: false
  });
  sharedCollapsibleStatus = this.collapsibleStatus.asObservable();

  updateCollapsibleStatus(updatedValue) {
    this.collapsibleStatus.next(updatedValue);
  }

  getCollapsibleStatus() {
    return this.collapsibleStatus.value;
  }
  private intent = new BehaviorSubject([]);
  sharedintent = this.intent.asObservable();
  updateintent(updatedintent) {
    this.intent.next(updatedintent);
  }
  getupdatedintent(){
    return this.intent.value;
  }

  private intentUrl = new BehaviorSubject([]);
  sharedintentUrl = this.intentUrl.asObservable();
  updateintentUrl(updatedintentUrl) {
    this.intentUrl.next(updatedintentUrl);
  }
  getupdatedintentUrl(){
    return this.intent.value;
  }


  private fastPaymentViewMore = new BehaviorSubject(false);
  sharedFastPaymentViewMore = this.fastPaymentViewMore.asObservable();
  updateFastPaymentViewMore(value) {
    this.fastPaymentViewMore.next(value);
  }
  getFastPaymentViewMore() {
    return this.fastPaymentViewMore.value;
  }

  private ccdcPublicKey = new BehaviorSubject(null);
  sharedccdcPublicKey = this.ccdcPublicKey.asObservable();
  updateccdcPublicKey(value) {
    this.ccdcPublicKey.next(value);
  }
  getccdcPublicKey() {
    return this.ccdcPublicKey.value;
  }



  private transaction_id = new BehaviorSubject([]);
  shared_transaction_id = this.transaction_id.asObservable();
  updateTransactionData(transactionId) {
    this.transaction_id.next(transactionId);
  }
  getTransactionData(){
    return this.transaction_id.value;
  }
  
  private OffersDetails = new BehaviorSubject([]);
  sharedOffersDetails = this.OffersDetails.asObservable();
  updateOffersDetails(updatedOffersDetails) {
    this.OffersDetails.next(updatedOffersDetails);
  }
  getupdatedOffersDetails(){
    return this.OffersDetails.value;
  }

  private ErrorDetails = new BehaviorSubject([]);
  sharedErrorDetails = this.ErrorDetails.asObservable();
  updateErrorDetails(updatedErrorDetails) {
    this.ErrorDetails.next(updatedErrorDetails);
  }
  getUpdatedErrorDetails(){
    return this.ErrorDetails.value;
  }

  private ResourceDetails = new BehaviorSubject([]);
  sharedResourceDetails = this.ResourceDetails.asObservable();
  updateResourceDetails(updatedResourceDetails) {
    this.ResourceDetails.next(updatedResourceDetails);
  }
  getUpdatedResourceDetails(){
    return this.ResourceDetails.value;
  }

  private EnforcePaymentDetails = new BehaviorSubject(false);
  sharedEnforcePaymentDetails = this.EnforcePaymentDetails.asObservable();
  updateEnforcePaymentDetails(value) {
    this.EnforcePaymentDetails.next(value);
  }
  getEnforcePaymentDetails() {
    return this.EnforcePaymentDetails.value;
  }

  private ListOfBanksDetails = new BehaviorSubject([]);
  sharedListOfBanksDetails = this.ListOfBanksDetails.asObservable();
  updateListOfBanksDetails(value) {
    this.ListOfBanksDetails.next(value);
  }
  getListOfBanksDetails() {
    return this.ListOfBanksDetails.value;
  }

  private ListOfWalletsDetails = new BehaviorSubject([]);
  sharedListOfWalletsDetails = this.ListOfWalletsDetails.asObservable();
  updateListOfWalletsDetails(value) {
    this.ListOfWalletsDetails.next(value);
  }
  getListOfWalletsDetails() {
    return this.ListOfWalletsDetails.value;
  }

  private EnforceSubPaymentModeDetails = new BehaviorSubject(false);
  sharedEnforceSubPaymentModeDetails = this.EnforceSubPaymentModeDetails.asObservable();
  updateEnforceSubPaymentModeDetails(value) {
    this.EnforceSubPaymentModeDetails.next(value);
  }
  getSubPaymentMode() {
    return this.EnforceSubPaymentModeDetails.value;
  }

  private PaymentNameDetails = new BehaviorSubject('');
  sharedPaymentNameDetails = this.PaymentNameDetails.asObservable();
  updatePaymentNameDetails(value) {
    this.PaymentNameDetails.next(value);
  }
  getPaymentNameDetails() {
    return this.PaymentNameDetails.value;
  }
  private GlobalErrorDetails = new BehaviorSubject([]);
  sharedGlobalErrorDetails = this.GlobalErrorDetails.asObservable();
  updateGlobalErrorDetails(updatedGlobalErrorDetails) {
    this.GlobalErrorDetails.next(updatedGlobalErrorDetails);
  }
  getUpdatedGlobalErrorDetails(){
    return this.GlobalErrorDetails.value;
  }

  private EnforcePaymentSuccess = new BehaviorSubject(false);
  sharedEnforcePaymentSuccess = this.EnforcePaymentSuccess.asObservable();
  updateEnforcePaymentSuccess(value) {
    this.EnforcePaymentSuccess.next(value);
  }
  getEnforcePaymentSuccess() {
    return this.EnforcePaymentSuccess.value;
  }

  getpaymentCookieData(){
    // @ts-ignore
    return Object.fromEntries(document.cookie.split('; ').map(c => c.split('=')));
  }

  private EnforcePaymentInitiated = new BehaviorSubject(false);
  sharedEnforcePaymentInitiated = this.EnforcePaymentInitiated.asObservable();
  updateEnforcePaymentInitiated(value) {
    this.EnforcePaymentInitiated.next(value);
  }
  getEnforcePaymentInitiated() {
    return this.EnforcePaymentInitiated.value;
  }

  private IsCardPayment = new BehaviorSubject(false);
  sharedIsCardPayment = this.IsCardPayment.asObservable();
  updateIsCardPayment(value) {
    this.IsCardPayment.next(value);
  }
  getIsCardPayment() {
    return this.IsCardPayment.value;
  }

  private IsCardlessPayment = new BehaviorSubject(false);
  sharedIsCardlessPayment = this.IsCardlessPayment.asObservable();
  updateIsCardlessPayment(value) {
    this.IsCardlessPayment.next(value);
  }
  getIsCardlessPayment() {
    return this.IsCardlessPayment.value;
  }

  private ListOfPaymentModes = new BehaviorSubject([]);
  sharedListOfPaymentModes = this.ListOfPaymentModes.asObservable();
  updateListOfPaymentModes(value) {
    this.ListOfPaymentModes.next(value);
  }
  getListOfPaymentModes() {
    return this.ListOfPaymentModes.value;
  }

  private EMIPlansDetails = new BehaviorSubject({});
  sharedEMIPlansDetails = this.EMIPlansDetails.asObservable();
  updateEMIPlansDetails(updatedEMIPlansDetails) {
    this.EMIPlansDetails.next(updatedEMIPlansDetails);
  }
  getUpdatedEMIPlansDetails(){
    return this.EMIPlansDetails.value;
  }

  private EMISelectedPlanDetail = new BehaviorSubject({});
  sharedEMISelectedPlanDetail = this.EMISelectedPlanDetail.asObservable();
  updateEMISelectedPlanDetail(updatedEMISelectedPlanDetail) {
    this.EMISelectedPlanDetail.next(updatedEMISelectedPlanDetail);
  }
  getUpdatedEMISelectedPlanDetail(){
    return this.EMISelectedPlanDetail.value;
  }

  private CardlessPaymentSelected = new BehaviorSubject({});
  sharedCardlessPaymentSelected = this.CardlessPaymentSelected.asObservable();
  updateCardlessPaymentSelected(updatedCardlessPaymentSelected) {
    this.CardlessPaymentSelected.next(updatedCardlessPaymentSelected);
  }
  getCardlessPaymentSelected(){
    return this.CardlessPaymentSelected.value;
  }

  private hasMaxRetriesExpiredSubject: BehaviorSubject<boolean> = new BehaviorSubject(false);
  sharedHasMaxRetriesExpiredSubject = this.hasMaxRetriesExpiredSubject.asObservable();
  setHasMaxRetriesExpiredSubject = (value: boolean) => {
    this.hasMaxRetriesExpiredSubject.next(value);
  }
  
  private bankResponse = new BehaviorSubject([]);
  sharedBankResponse = this.bankResponse.asObservable();
  updatebankResponse(value) {
    this.bankResponse.next(value);
  }
  getbankResponse(){
    return this.bankResponse.value;
  }

  private walletResponse = new BehaviorSubject([]);
  sharedwalletResponse = this.walletResponse.asObservable();
  updatewalletResponse(value) {
    this.walletResponse.next(value);
  }
  getwalletResponse(){
    return this.walletResponse.value;
  }
  
  /* Stores Generated Transaction details after every initiate payment calls */
  private transactionResponse = new BehaviorSubject([]);
  sharedTransactionResponse = this.transactionResponse.asObservable();
  updateTransactionResponse(value) {
    this.transactionResponse.next(value);
  }
  getTransactionResponse(){
    return this.transactionResponse.value;
  }
}

