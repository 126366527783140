<div class="top_bg">
	<app-header></app-header>
	<app-new-on-nimbl
		*ngIf="!isEnforced"
		[isOtpVerify]="true"
		[userFound]="true"
		[nimbbl_mobile_name]="nimbbl_user_name"
		[welcome]="false"
		[user_mobile_number]="nimbbl_user_mobile"
		[payment]="true"
	>
	</app-new-on-nimbl>
</div>

<div class="mainContentDisplay">
	<app-order-summary
		*ngIf="!isEnforced"
		[isOPen]="showOrderSummary"
		[page]="'payment'"
	></app-order-summary>
	<app-payment-failure
		*ngIf="(showMaxRetriesReachedElement || ispaymentFail)"
		[message]="paymentErrorMessage"
		[showTryAgainButton]="showTryAgainButton"
		(initiatePay)="initPayment($event)"
		[UPIId]="UPIId"
		[showMaxRetriesReachedElement]="showMaxRetriesReachedElement"
	></app-payment-failure>

	<app-fast-payment-option
		[isEnalbe]="true"
		*ngIf="!isPaymentClicked && !isEnforced && !showMaxRetriesReachedElement"
		(paymentModeSelected)="displayPaymentCard($event)"
		[paymentAllowed]="allowedPaymentTypeArray"
		[showLoader]="showLoader"
		[allowedModes]="fastPaymentModes"
		[googlePay_paymentPartner]="gpayPartner"
		[Razorpay_keyID]="razr_key_id"
		[isFatpaymentFound]="isFastPaymentFound"
		(savedUPIClicked)="savedVPAclicked($event, $vpaName)"
	></app-fast-payment-option>

	<app-otherpayment-option
		[isEnable]="true"
		*ngIf="!isPaymentClicked && !isEnforced && !showMaxRetriesReachedElement"
		[paymentAllowed]="otherPaymentModes"
		(paymentModeSelected)="displayPaymentCard($event)"
		[showLoader]="showLoader"
	></app-otherpayment-option>

	<!-- Not Enforced -->
	<app-pay-with
		*ngIf="isPyamentInitiate && !isEnforced"
		(initiatePay)="initPayment($event)"
		(cancelPay)="cancelPayment()"
		[payment_partner]="payment_partner"
		[savedVPAClicked]="savedVPAClicked"
		[savedVapId]="savedVpaId"
		[savedVpaName]="savedVpaName"
		(payNow)="makePayment($event)"
		[isIntentAllowed]="isUpiIntentAllowed"
		[isUpiCollectAllowed] = "isUpiCollectAllowed"
		[hasIntentFromSDK]="intentpaymentModes.length > 0"
		[isIosDevice]="isIosDevice"
		[isReactNative]="isReactNative"
	></app-pay-with>

	<!-- is Enforced -->
	<app-pay-with
		*ngIf="!isPyamentInitiate && isEnforced && !isTransactionTimeout"
		(initiatePay)="initPayment($event)"
		(cancelPay)="cancelPayment()"
		[payment_partner]="payment_partner"
		[savedVPAClicked]="savedVPAClicked"
		[savedVapId]="savedVpaId"
		[savedVpaName]="savedVpaName"
		(payNow)="makePayment($event)"
		(qrCodeScanned)="handleQrCodeScanned($event)"
		[isIntentAllowed]="isUpiIntentAllowed"
		[isUpiCollectAllowed] = "isUpiCollectAllowed"
		[hasIntentFromSDK]="intentpaymentModes.length > 0"
		[isIosDevice]="isIosDevice"
		[isReactNative]="isReactNative"
	></app-pay-with>

	<app-pay-with-otp
		*ngIf="isPyamentInitiateWithOtp"
		(payNow)="makePayment($event)"
		(openInRedirectURL)="openInRedirectURL($event)"
		(cancelPay)="cancelPayment()"
		(resendLazyPayOtp)="resendLazypayOtp()"
	></app-pay-with-otp>

	<app-payment-processing
		*ngIf="processing && !isIntentAndDesktop"
		[timeout]="paymentTimeOutInSsecond"
		(windowFocus)="setFocus()"
		(cancelPayment)="cancelTransaction($event)"
		[intentFlow]="paymentMethodDetails['is_intent']"
		(closeWindow)="windowEvent.close()"
	></app-payment-processing>

	<app-payment-successful *ngIf="isPyamentSucess" [eta]="completionEta"></app-payment-successful>

	<app-money-deduction-confirmation
		*ngIf="isTransactionTimeout"
		(displayErrorScreen)="showPaymentError()"
		(displayTimeOutMessage)="displatTImeOutMessage()"
	></app-money-deduction-confirmation>

	<app-upi-qr-code
		*ngIf="isIntentAndDesktop && (!isPyamentSucess || !ispaymentFail)"
		(cancelPayment)="cancelTransaction($event)"
		[iframeSrc]="intentUrl"
		[isEnforced]="isEnforced"
	></app-upi-qr-code>
	<app-power-by></app-power-by>
</div>
