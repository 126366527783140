<div class="accordion box-accor" id="accordionExample" *ngIf="order_details!=undefined">
    <div class="card">
        <div class="card-header" id="headingOne" *ngIf="order_details.order_line_item?.length && merchant_detail?.order_summary_enabled">
            <h2 class="mb-0">
                <button type="button" class="btn accordion-button collapse-one-btn" data-toggle="collapse" data-target="#collapseOne" id="collapseOneBtn" [ngClass]="isOPen?'':'collapsed'" [disabled]='!isOPen'>
                    <div class="box-item">
                        <div class="title">Order Summary</div>
                        <div class="details" *ngIf="isOPen"><strong>Products</strong>:
                            <span>{{order_details?.order_line_item[0]?.quantity}} </span>
                            <span>{{order_details?.order_line_item[0]?.title}}</span> <span
                                *ngIf="order_details.order_line_item?.length>1"> &
                                {{order_details.order_line_item?.length-1}} more <span
                                    *ngIf="order_details.order_line_item?.length==2">item</span><span
                                    *ngIf="order_details.order_line_item?.length>2">items</span></span>
                        </div>
                        <div class="details" *ngIf="order_details.address"><strong>Delivering to</strong>:
                            <span *ngIf="order_details?.address.address_1">{{order_details?.address.address_1}}, </span>
                            <span *ngIf="order_details?.address.street">{{order_details?.address.street}}, </span>
                            <span *ngIf="order_details?.address.area">{{order_details?.address.area}}, </span>
                            <span *ngIf="order_details?.address.city">{{order_details?.address.city}}, </span>
                            <span *ngIf="order_details?.address.state">{{order_details?.address.state}},</span>
                            <span *ngIf="order_details?.address.pincode">{{order_details?.address.pincode}}</span>
                        </div>
                    </div>
                    <span class="icon" [ngClass]="isOPen ? 'icon--minus': 'icon--plus'" *ngIf="isOPen"></span>
                </button>
            </h2>
        </div>
        <div id="collapseOne" #collapseOne class="collapse" [ngClass]="isOPen?'show':''" *ngIf="merchant_detail.order_summary_enabled" aria-labelledby="headingOne" data-parent="#accordionExample">
            <div class="card-body">
                <div class="row box-row pt-1 pb-3 first" *ngFor="let order_item of order_details.order_line_item | slice:0:1">
                    <div class="col-2">
                        <img [src]="order_item?.image_url?order_item?.image_url:'assets/images/other/51mirE17vHL._SX522_.png'" class="ProductImage">
                    </div>
                    <div class="col-7">
                        <div class="box-date-details fS-12 clr-72">
                            <div class="row">
                                <div class="col-11 fS-14 clr-16"><span class="productTitle">{{order_item.title}}</span>
                                </div>
                                <div class="col-11 orderDesc" [innerHTML]="order_item.description"></div>
                                <div class="col-11 orderQty">Quantity: {{order_item.quantity}}</div>
                            </div>
                        </div>
                    </div>
                    <div class="col-3"><span class="align-middle pt-2 text-right d-block amount"><span [innerHTML]="currency.currency"></span> {{order_item.total_amount}}</span>
                    </div>
                </div>
                <div class="row box-row pt-1 pb-3 first" *ngFor="let order_item of order_details.order_line_item | slice:0:0">
                    <div class="col-2">
                        <img [src]="order_item?.image_url?order_item?.image_url:'assets/images/other/51mirE17vHL._SX522_.png'" class="ProductImage">
                    </div>
                    <div class="col-7">
                        <div class="box-date-details fS-12 clr-72">
                            <div class="row">
                                <div class="col-11 fS-14 clr-16">{{order_item.title}} </div>
                                <div class="col-11 ">{{order_item.description}}</div>
                                <div class="col-11">Quantity.: {{order_item.quantity}}</div>
                                ] </div>
                        </div>
                    </div>
                    <div class="col-3"><strong class="align-middle pt-2 text-right d-block"><span [innerHTML]="currency.currency"></span> {{order_item.total_amount}}</strong></div>
                </div>
                <div *ngIf="showMore">
                    <div class="row box-row pt-1 pb-3 first" *ngFor="let order_item of order_details.order_line_item | slice:1:order_details.order_line_item.length">
                        <div class="col-2">
                            <img [src]="order_item?.image_url?order_item?.image_url:'assets/images/other/51mirE17vHL._SX522_.png'" class="ProductImage">
                        </div>
                        <div class="col-7">
                            <div class="box-date-details fS-12 clr-72">
                                <div class="row">
                                    <div class="col-11 fS-14 clr-16">{{order_item.title}} </div>
                                    <div class="col-11 ">{{order_item.description}}</div>
                                    <div class="col-11">Quantity.: {{order_item.quantity}}</div>
                                </div>
                            </div>
                        </div>
                        <div class="col-3"><strong class="align-middle pt-2 text-right d-block"><span [innerHTML]="currency.currency"></span> {{order_item.total_amount}}</strong></div>
                    </div>
                </div>



            </div>
            <div class="row view_more" *ngIf="order_details.order_line_item?.length>1">
                <div class="col"><a (click)="viewMore()" class="view_more" *ngIf="!showMore">View More</a></div>
            </div>
        </div>
        
        <div class="card-footer">
            <div class="box-item-bottom">
                <div class="fL totalAmount">Order Amount:</div>
                <div class="fR" *ngIf="order_details.total_amount || order_details?.grand_total_with_convience_fees">
                    <strong>
                        <span [innerHTML]="currency.currency"></span> 
                        {{
                            order_details.total_amount || 
                            order_details?.grand_total_with_convience_fees || 
                            order_details.grand_total_amoun
                        }}
                    </strong>
                </div>
            </div>
        </div>

    </div>
</div>