<div
	class="box-accor emi-plans-container"
>
<div class="box-item">
  <div class="title-payment box-row py-3">
    <div class="">
      <img [src]="selectedPaymentMode.logo" class="img-fluid" />
    </div>
    <div class="px-2 fS-18">
      <strong
        >Pay with
        {{ selectedPaymentMode.paymentModeName || selectedPaymentMode.paymentMode }}</strong
      >
    </div>
  </div>
  <!-- <div>
    <div
      class="row fS-14 pt-3 priceBreakUp"
      *ngIf="offerAvailable || convenienceFee || hasSubPaymentMode"
    >
      <div class="col-6">Total</div>
      <div class="col-6 text-right">
        <span [innerHTML]="currency.currency"></span>
        {{ order_details?.total_amount | number : '1.2-2' }}
      </div>
    </div>
    <div class="row fS-14 pt-3 priceBreakUp green_text" *ngIf="offerAvailable">
      <div class="col-6">(-) Offer Applied</div>
      <div class="col-6 text-right">
        <span [innerHTML]="currency.currency"></span> {{ offerDiscount }}
      </div>
    </div>
    <div class="row fS-14 pt-3 priceBreakUp" *ngIf="convenienceFee || hasSubPaymentMode">
      <div class="col-6">(+) Convenience Fee</div>
      <div class="col-6 text-right">
        <span [innerHTML]="currency.currency"></span> {{ convenienceFee | number : '1.2-2' }}
      </div>
    </div>
    <div
      class="row fS-14 pt-3 priceBreakUp grandTotal"
      *ngIf="offerAvailable || convenienceFee || hasSubPaymentMode"
    >
      <div class="col-6">Grand Total</div>
      <div class="col-6 text-right">
        <span [innerHTML]="currency.currency"></span> {{ grandTotal | number : '1.2-2' }}
      </div>
    </div>
    <div class="row fS-14 pt-3">
      <div class="col flex">
        <button
          type="button"
          class="btn btn-outline-dark me-2 mr-3 flex50"
          (click)="cancelRef.showConfirmationMessage()"
        >
          Cancel
        </button>
        <button
          type="button"
          class="btn btn-primary btn-orange flex50"
          [disabled]="!isbuttonEnabled"
          [ngClass]="isbuttonEnabled ? 'valid' : ''"
          (click)="initiatePayment()"
        >
          Pay <span [innerHTML]="currency.currency"></span> {{ grandTotal | number : '1.2-2' }}*
        </button>
      </div>
    </div>
    <app-close-checkout #cancelRef></app-close-checkout>
  </div> -->
</div>
</div>
