<div *ngIf="!showErrorScreen">
    <div class="homeComponent top_bg">
        <app-header></app-header>
        <app-new-on-nimbl [welcome]=true></app-new-on-nimbl>
        <app-user-info (onMobileNumberSubmit)="resolveuserWithFingerPrint($event.mobileNumber)" [user_mobileNumber]="nimbbl_user_mobile" (onCountryListClicked)="displsyCountryList()" [errorMessage]="errorMessage" [showLoader]="showMobileLoader"></app-user-info>
    </div>
    <div class="mainContentDisplay">
        <app-list-of-country-code *ngIf="showCountryList" (countryCodeSelect)="closeCountryCodeList()"></app-list-of-country-code>
        <app-order-summary [isOPen]=isOrder_line_item [page]="'home'" *ngIf="!showCountryList"></app-order-summary>
    </div>
</div>
<div *ngIf="showLoader">
    <div class="loaderContainer">
        <div class="loader">
            <div class="loaderhmtl"></div>
        </div>
    </div>
</div>
<div *ngIf="showErrorScreen" class="egt_error_msg">
    <div *ngIf="errorStatusCode" class="error-code-text">{{ errorStatusCode }}</div>
    <div class="error-code-message">{{ errorMessageText }}</div>
</div>
<app-power-by></app-power-by>