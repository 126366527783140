import { Component, OnInit } from '@angular/core';
import { merchantDetails } from 'src/app/app.models';
import { OrderDetailService } from 'src/app/services/order-detail.service';

@Component({
  selector: 'app-power-by',
  templateUrl: './power-by.component.html',
  styleUrls: ['./power-by.component.css'],
})
export class PowerByComponent implements OnInit {

  footer_logo_url: string;
  footer_sub_text: string;
  pci_dss_enabled: boolean;
  constructor(private orderDetails: OrderDetailService) {
    this.orderDetails.sharedMerchantDetails.subscribe((merchant_data) => {
      this.saveMerchantDetails(merchant_data);
    });
  }

  ngOnInit(): void {

  }
  saveMerchantDetails(response) {
    this.footer_logo_url = response.footer?.footer_logo_url ? response.footer?.footer_logo_url : './assets/images/footerlogo.png';

    this.footer_sub_text = response.footer?.footer_sub_text ? response.footer?.footer_sub_text : '100% secure transactions';

    if(response.footer && response.footer.hasOwnProperty('pci_dss_enabled')){
      this.pci_dss_enabled = response.footer?.pci_dss_enabled
    }else{
      this.pci_dss_enabled = true
    }

    // this.pci_dss_enabled = response.footer?.pci_dss_enabled;
    let newRes = {
      footer_logo_url: this.footer_logo_url,
      footer_sub_text: this.footer_sub_text,
      pci_dss_enabled: this.pci_dss_enabled
    }
    
    this.orderDetails.updateFooterDetails(newRes);
  }
}
