import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SharedBankingService {

  constructor() { }
  private bankDetails = new BehaviorSubject([]);
  updateBankDetails(bankData) {
    this.bankDetails.next([]);
    this.bankDetails.next(bankData);
  }
  getbankDetails() {
    return this.bankDetails.value;
  }



  private wallet = new BehaviorSubject([]);
  updateWallet(walletData) {
    this.wallet.next([]);
    this.wallet.next(walletData);
  }
  getWallet() {
    return this.wallet.value;
  }
}
