type TimerColor = 'green' | 'orange' | 'red';

class CheckoutSession {
	/**
	 * default checkout session duration (including payment processing time)
	 */
	static readonly defaultSessionDuration = 19;
	/**
	 * time taken to process the payment
	 */
	static readonly processingTime = 4;

	/**
	 * 	accepts current time in minutes and checkout session duration
	 *	and returns the background color for timer component
	 *
	 * 	logic for this function is explained in ticket CHPY-2621
	 *
	 * 	@param time current time in minutes
	 * 	@param sessionDuration checkout session duration in minutes
	 * 	@returns "green" or "orange" or "red"
	 */
	static getColor(time: number, sessionDuration: number): TimerColor {
		const minutesLeft = (sessionDuration || this.defaultSessionDuration) - this.processingTime;
		if (time > Math.round(minutesLeft * 0.4)){ 
			return 'green'
		};
		if (time >= Math.round(minutesLeft * 0.35)){ 
			return 'orange'
		};
		return 'red';
	}

	/**
	 * takes checkout_session_duration as input
	 * returns the time by when the checkout should expire
	 *
	 * @param sessionDuration checkout session duration in minutes
	 * @returns a string representing the date interpreted in the local timezone
	 */
	static getTime(sessionDuration: number): string {
		const minutesLeft = sessionDuration - this.processingTime;
		const expiryDate = new Date(new Date().getTime() + minutesLeft * 60000);

		console.log("merchant's checkout duration", sessionDuration);
		console.log(
			'checkout session expiry',
			expiryDate.toLocaleDateString(),
			expiryDate.toLocaleTimeString()
		);

		return expiryDate.toString();
	}
}

export default CheckoutSession;
