import { Component, OnInit } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { merchantDetails, orderDetails } from 'src/app/app.models';
import { ApiService } from 'src/app/services/api.service';
import { OrderDetailService } from 'src/app/services/order-detail.service';
import { SharedService } from 'src/app/services/shared.service';
import { CommonService } from 'src/app/services/common.service';
import tracking from '../../utils/tracking';
import { Router } from '@angular/router';
import { isObjectEmpty } from 'src/app/utils';

@Component({
  selector: 'app-close-checkout',
  templateUrl: './close-checkout.component.html',
  styleUrls: ['./close-checkout.component.css']
})
export class CloseCheckoutComponent implements OnInit {
  headerText: string = null;
  showPopup: boolean = false;
  showConfirmationModal: boolean = false;
  displayResons: boolean = false;
  reasons;
  selectedReason;
  windowEvent;
  order_details: orderDetails;
  merchant_detail: merchantDetails;
  constructor(
    private apiService: ApiService,
    private shredOrderService: OrderDetailService,
    private sharedService: SharedService,
    private cookies: CookieService,
    private router: Router,
    private commonService: CommonService,
  ) { 
    this.sharedService.shareWindowService.subscribe((res) => {
      this.windowEvent = res;
    });
  }

  ngOnInit(): void {

    this.reasons = [
      'Unable to find my payment method',
      'Unable to complete the payment',
      "Don't want to make a purchase right now",
      "Don't understand how to proceed",
      'Others',
    ];
    this.headerText = 'Are you sure you want to cancel this payment?';


    this.shredOrderService.sharedOrderDetails.subscribe((orderDetail) => {
      // console.log('order detail form shared service');

      this.order_details = JSON.parse(JSON.stringify(orderDetail));
      // console.log(this.order_details['order_id']);
      if (this.order_details['order_id']) {
        this.order_details = JSON.parse(JSON.stringify(orderDetail));
        if (!this.merchant_detail) this.fetchMerchantDetail();
      }
    });
  }

  fetchMerchantDetail() {
    // console.log('fetch merchant Detail called');
    let merchantId = this.order_details['sub_merchant_id'];
    this.apiService.fetchMerchantDetail(merchantId).subscribe((res) => {
      this.merchant_detail = JSON.parse(JSON.stringify(res));
      this.shredOrderService.updateMerchantDetails(this.merchant_detail);
    
      /**
       * Set background color and text color from merchant_detail
       */
      const hasFooterObj = this.merchant_detail.hasOwnProperty('footer');
      const bgColor = this.merchant_detail['checkout_background_color'] || '#f93576';
      const textColor = this.merchant_detail['checkout_text_color'] || '#ffffff';
      const bgFooterColor = (hasFooterObj && this.merchant_detail['footer']['footer_color']) || '#000000';
      const isFooterTextWhite = hasFooterObj && 
                                  this.merchant_detail.footer['footer_text_color_white'] === true || 
                                  this.merchant_detail.footer['footer_text_color_white'] === undefined;
    
      const isHex = /^#([0-9a-f]{3}){1,2}$/i;
    
      const checkoutFooterFontColor = isFooterTextWhite ? '#FFFFFF' : '#000000';
      const checkoutBackgroundColor = isHex.test(bgColor) ? bgColor : null;
      const checkoutFontColor = textColor || null;
      const checkoutFooterBackgroundColor = isHex.test(bgFooterColor) ? bgFooterColor : null;
    
      if (
        this.merchant_detail.hasOwnProperty('footer') && 
        Object.keys(this.merchant_detail?.footer).length > 0 && 
        checkoutFooterFontColor) {
        document.documentElement.style.setProperty('--checkout-footer-font-color', checkoutFooterFontColor);
      }
    
      if (checkoutBackgroundColor) {
        document.documentElement.style.setProperty('--checkout-background-color', checkoutBackgroundColor);
      }
    
      if (checkoutFontColor) {
        document.documentElement.style.setProperty('--checkout-font-color', checkoutFontColor);
      }
    
      if (checkoutFooterBackgroundColor) {
        document.documentElement.style.setProperty('--checkout-footer-background-color', checkoutFooterBackgroundColor);
      }
    });    
  }

  // startCountDown() {
  //   this.seconds = 59;
  //   this.interval = setInterval(() => {
  //     if (this.seconds > 0) {
  //       this.seconds = this.seconds - 1;
  //     } else {
  //       this.stopCountDown();
  //       this.redirectToFailure();
  //     }
  //   }, 1000);
  // }

  // stopCountDown() {
  //   this.interval && clearInterval(this.interval);
  // }


  showConfirmationMessage() {
    this.showPopup = true;
    document.querySelector('body').classList.add('noscroll');
    setTimeout(() => {
      document.getElementById('noBtn') &&
        document.getElementById('noBtn').focus();
    }, 100);
  }

  closeBox() {
    this.showPopup = false;
    if (this.windowEvent) {
      typeof this.windowEvent.focus == 'function' && this.windowEvent.focus();
    }
    document.querySelector('body').classList.remove('noscroll');
  }

  showReasonPopup() {
    this.showConfirmationModal = false;
    this.displayResons = true;
    this.showPopup = true;
    // this.stopCountDown();
  }

  closeModal() {
    this.showPopup = false;
    this.displayResons = false;
    document.querySelector('body').classList.remove('noscroll');
    this.selectedReason = null;
    this.headerText= "Are you sure you want to cancel this payment?";
    // setTimeout(() => {
    //   document.getElementById('noBtn') && document.getElementById('noBtn').focus();
    // }, 100)
  }

  showReason() {
    if (this.windowEvent?.frames) {
      this.windowEvent.close();
    }
    this.headerText = 'Tell us why you want to cancel';
    this.displayResons = true;
  }

  checkstatusOftransaction() {
    let transactionData = this.sharedService.getTransactionData();
    return new Promise((resolve, reject) => {
      if (transactionData['transaction_id']) {
        this.apiService.paymentStatusConfirmation(transactionData).subscribe(
          (responseData) => {
            if (responseData) {
              resolve(responseData['status']);
            }
          },
          (error) => {
            resolve('failed');
          }
        );
      } else {
        resolve('failed');
      }
    });
  }

  submitReason(closeReason = 'user closed') {
    let paymentStatus = this.checkstatusOftransaction();
    paymentStatus.then((res) => {
      console.log(res);

      this.order_details = JSON.parse(
        JSON.stringify(this.shredOrderService.getOrderDetails())
      );
      this.cookies.set('no_refresh', 'true');

      tracking.checkoutClosed({
        order_details: this.order_details,
        close_reason: closeReason,
        selected_reason: this.selectedReason,
      });
      let data = {
        order_id:
          this.order_details && this.order_details['order_id']
            ? this.order_details['order_id']
            : null,
        order_level: 6,
        closed_reason: this.selectedReason || closeReason,
        checkout_id: this.order_details['checkout_id'],
      };
      this.apiService.updateOrderLevel(data).subscribe(
        (res) => {},
        (error) => {}
      );
      let orderUpdateData = {
        order_id: this.order_details['order_id'],
        cancellation_reason: this.selectedReason,
      };
      tracking.updateOrderAPIPayload({
        order_id: this.order_details['order_id'],
        close_reason: this.selectedReason,
        from: 'Close Checkout Component',
      })
      this.apiService.updateOrder(orderUpdateData).subscribe(
        (response) => {
          if (this.windowEvent.frames) {
            this.windowEvent.close();
          }
          this.sharedService.updateuseAnotherAccount('no');
        },
        (error) => {
          throw error;
        }
      );
      this.commonService.getCallBackPayloadResponse(this.order_details)
    });
  }

}

