import { Component, OnInit } from '@angular/core';
import { orderDetails } from 'src/app/app.models';
import { OrderDetailService } from 'src/app/services/order-detail.service';
import { PaymentModeService } from 'src/app/services/payment-mode.service';

@Component({
	selector: 'app-emi-plan-card',
	templateUrl: './emi-plan-card.component.html',
	styleUrls: ['./emi-plan-card.component.css'],
})
export class EMIPlanCardComponent implements OnInit {
	selectedPaymentMode;
	UPIId;
	isbuttonEnabled: boolean = true;
	isVpaValid: boolean = false;
	UPIUserName: string = '';
	convenienceFee = 0;
	grandTotal = 0;
	offerType: string;
	offerName: string;
	offerGrandTotal: number;
	offerAvailable: boolean = false;
	offerDiscount: number;
	offerID: string;
	offerTotalAmount: number;
	order_details: orderDetails;
	is_autoDebit: boolean = false;

	constructor(
		private sharedPaymentService: PaymentModeService,
		private sharedorderDetails: OrderDetailService
	) {
		this.sharedPaymentService.sharedSelectedMode.subscribe((res) => {
			this.selectedPaymentMode = {
				paymentMode: 'Kreditbee Paylater',
				logo: 'assets/images/icons/kreditbee.png',
				vpaId: '',
				payment_partner: 'Spur',
				extra_charges: 0,
				isFastPayment: true,
				is_intent: false,
				isAutodebitFlow: false,
				vpa_provider: null,
				magicUPIDisplayName: null,
				is_magic_UPI: false,
				intentTextAboveBtns: null,
				intentDisplayName: null,
				compareName: 'kreditbeeupi',
			};
			this.selectedPaymentMode.offerApplicable
				? (this.offerType = this.selectedPaymentMode.offerType)
				: '';
			this.selectedPaymentMode.offerApplicable
				? (this.offerName = this.selectedPaymentMode.offerName)
				: '';
			if (this.selectedPaymentMode['isAutodebitFlow']) {
				this.is_autoDebit = true;
			}
			if (
				parseFloat(this.selectedPaymentMode.extra_charges) &&
				!this.selectedPaymentMode.extra_charges.includes('-')
			) {
				this.convenienceFee = parseFloat(this.selectedPaymentMode.extra_charges);
				this.grandTotal = this.order_details['grand_total_amount'];

				this.order_details['grand_total_with_convience_fees'] =
					this.grandTotal + this.convenienceFee;
				this.sharedorderDetails.updateOrderDetails(this.order_details);
			}
			if (
				this.selectedPaymentMode['paymentMode'] == 'UPI' ||
				this.selectedPaymentMode['paymentMode'] == 'Netbanking'
			) {
				this.isbuttonEnabled = false;
			}
		});
	}

	ngOnInit(): void {}
}
