<div class="box-accor clr-16">
    <div class="box-item login-details">
        <div class="row box-row cursor" *ngFor="let user of Users; let i=index" (click)="useExistingAccount(i)">
            <div class="col-2">
                <div class="name-start">{{user.initial | uppercase}}</div>
            </div>
            <div class="col-7">
                <div class="box-date-details">
                    <div class="row">
                        <div class="col p-0">
                            <div class="name">{{user.name}}</div>
                            <div class="email-number" *ngIf="user.mobile_number==currentUser">{{user.email}} | {{user.mobile}}</div>
                            <div class="email-number" *ngIf="user.mobile_number!=currentUser">{{user.maskedEmail}} | {{user.maskedMobile}}</div>
                        </div>
                    </div>

                </div>
            </div>
            <div class="col-3">
                <a class="logged-in" href="javascript:void(0)" *ngIf="user.mobile==currentUser">Current User</a>


            </div>
        </div>
        <div class="row">
            <div class="col"><span class="another-account cursor"  (click)="useAnotherAcccount()">Use another account</span></div>
        </div>
    </div>
</div>