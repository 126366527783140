<div class="box-accor">
	<div class="box-item">
		<div class="box-row pt-1" *ngIf="!showMaxRetriesReachedElement && !isEnforced">
			<div class="col-12 align-self-center">
				<div class="box-date-details">
					<div class="row">
						<div class="col-auto p-0">
							<img
								[src]="selectedPaymentMode?.logo"
								class="img-fluid"
								[ngClass]="
									selectedPaymentMode.vpa_provider == 'bhim' ||
									selectedPaymentMode.paymentMode == 'BHIM'
										? 'bhim'
										: ''
								"
							/>
						</div>
						<div class="col px-2 fS-18">
							<strong>Pay with {{ paymentName || magicUPIDisplayName || paymentModeName }}</strong>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="payment-success">
			<div class="failure-msg-wrapper">
				<div class="col-auto px-0" *ngIf="!isPaymentTimedOut">
					<img src="./assets/images/icons/cancel-1.png" alt="" />
				</div>
				<div style="width: 50px" *ngIf="isPaymentTimedOut">
					<div class="timeOut">!</div>
				</div>
				<div>
					<div><strong *ngIf="!isPaymentTimedOut"> Your payment was unsuccessful</strong></div>
					{{ message }}
				</div>
			</div>
			<div class="col-12" *ngIf="!showMaxRetriesReachedElement && showTryAgainButton">
				<button type="button" class="btn btn-outline-dark mt-3" (click)="reinitiatePayment()">
					Try again
				</button>
			</div>
		</div>
	</div>
</div>
