import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import * as QRCode from 'qrcode';
import { orderDetails, paymentStatusPollingRequest } from 'src/app/app.models';
import { OrderDetailService } from 'src/app/services/order-detail.service';
import tracking from 'src/app/utils/tracking';
import { SharedService } from 'src/app/services/shared.service';
import { ApiService } from 'src/app/services/api.service';
import { CookieService } from 'ngx-cookie-service';
import { Router } from '@angular/router';
import { CommonService } from 'src/app/services/common.service';

@Component({
	selector: 'app-upi-qr-code',
	templateUrl: './upi-qr-code.component.html',
	styleUrls: [
		'./upi-qr-code.component.css',
		'../payment-processing/payment-processing.component.css',
	],
})
export class UpiQrCodeComponent implements OnInit {
	qrcodeDataUrl: SafeUrl; // Use SafeUrl type
	@Input() iframeSrc: string = '';
	@Input() isEnforced: boolean = false;
	@Output() cancelPayment: EventEmitter<any> = new EventEmitter<any>();
	@Output() fetchTransactionEnquiry: EventEmitter<any> = new EventEmitter<any>();
	reasons = [
		'UPI app did not open',
		"Don't want to make a purchase right now",
		'Payment failed in the APP',
		'Others',
	];
	selectedReason;
	showPopup: boolean = false;
	displayReasons: boolean = false;
	order_details: orderDetails;
	config;
	windowEvent;
	transaction_id: any;
	transactionEnquiryPolling;
	showQR: boolean = false;
	constructor(
		private sanitizer: DomSanitizer,
		private sharedOrderService: OrderDetailService,
		private sharedService: SharedService,
		// private apiService: ApiService,
		// private cookies: CookieService,
		// private router: Router,
		// private commonService: CommonService,
	) {}
	ngOnInit(): void {
		this.order_details = JSON.parse(JSON.stringify(this.sharedOrderService.getOrderDetails()));
		this.sharedService.shareWindowService.subscribe((res) => {
			this.windowEvent = res;
		});
		this.showQR = false;
	}

	ngOnChanges(changes: SimpleChanges) {
		this.config = {
			leftTime: 4 * 60,
			format: 'mm:ss',
		};
		// console.log(changes);
		if(changes.iframeSrc.currentValue){
			const upiUrlString = this.iframeSrc;
			QRCode.toDataURL(upiUrlString, (error, url) => {
				if (error) {
					this.showQR = false;
					console.error('Error generating QR code:', error);
					return;
				}
				// Sanitize the data URL before binding
				this.qrcodeDataUrl = this.sanitizer.bypassSecurityTrustUrl(url);
				setTimeout(() => {
					this.showQR = true;
				}, 1000);
	
				this.sharedService.shared_transaction_id.subscribe((res) => {
					console.log(res);
					this.transaction_id = res['transaction_id'];
				});
			});
		}	
	}

	countdownEvent(event: any): void {
		if (event.action === 'done') {
			// Countdown has ended
			if(this.isEnforced){
				this.goToMerchant();
			}else{
				this.cancelPayment.emit(this.selectedReason);
			}
			
		}
	}

	goToMerchant(): void {
		// Code to navigate to the merchant page
		if (this.order_details?.callback_url) {
			window.location.href = this.order_details['callback_url'];
		} else {
			alert('Time Out');
		}
	}

	showReason() {
		this.showPopup = true;
		this.displayReasons = true;
	}

	closePopup() {
		this.selectedReason = null;
		this.showPopup = false;
		this.displayReasons = false;
	}

	ngOndestroy() {
		this.closePopup();
	}
	submitReason() {
		this.cancelPayment.emit(this.selectedReason);
		this.closePopup();
	}
}
