import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class PaymentModeService {
	constructor() {}
	private paymentMode = new BehaviorSubject([]);
	sharedPaymentMode = this.paymentMode.asObservable();
	updatePaymentMode(paymentModes) {
		const UpdatedPaymentmodes = paymentModes;
		this.paymentMode.next(UpdatedPaymentmodes);
	}

	private selectedMode = new BehaviorSubject([]);
	sharedSelectedMode = this.selectedMode.asObservable();

	updateSelectedMode(upatedMode) {
		this.selectedMode.next(upatedMode);
	}

	getSelectedMode(){
    return this.selectedMode.value;
  }

	// googlepayButton Logic
	private isGpayEnabled = new BehaviorSubject(undefined);
	enableGooglePay = this.isGpayEnabled.asObservable();
	updateGpayVisibility(visibility) {
		this.isGpayEnabled.next(visibility);
	}

	getGpayVisibility() {
		return this.isGpayEnabled.value;
	}

	// cred button logic

	private isCredEnabled = new BehaviorSubject(undefined);
	enableCred = this.isCredEnabled.asObservable();
	updateCred(visibility) {
		this.isCredEnabled.next(visibility);
	}

	// Fast Payment
	private fastPaymentMode = new BehaviorSubject([]);
	sharedFastPaymentMode = this.fastPaymentMode.asObservable();

	updateFastPaymentMode(fastPaymentMode) {
		this.fastPaymentMode.next(fastPaymentMode);
	}

	getFastPaymentMode() {
		return this.fastPaymentMode.value;
	}

	// other Payment
	private otherPaymentMode = new BehaviorSubject([]);
	sharedOtherPaymentMode = this.otherPaymentMode.asObservable();

	updateOtherPaymentMode(otherPaymentMode) {
		this.otherPaymentMode.next(otherPaymentMode);
	}

	getOtherPaymentMode() {
		return this.otherPaymentMode.value;
	}

	// Cardless Payment
	private cardlessPaymentMode = new BehaviorSubject([]);
	sharedCardlessPaymentMode = this.cardlessPaymentMode.asObservable();

	updateCardlessPaymentMode(cardlessPaymentMode) {
		this.cardlessPaymentMode.next(cardlessPaymentMode);
	}

	getCardlessPaymentMode() {
		return this.cardlessPaymentMode.value;
	}

	// allowed payment type array
	private allowedPaymentTypeArray = new BehaviorSubject([]);
	sharedAllowedPaymentTypeArray = this.allowedPaymentTypeArray.asObservable();

	updateAllowedPaymentTypeArray(allowedPaymentTypeArray) {
		this.allowedPaymentTypeArray.next(allowedPaymentTypeArray);
	}

	getAllowedPaymentTypeArray() {
		return this.allowedPaymentTypeArray.value;
	}

	private cardOffers = new BehaviorSubject([]);
	sharedCardOffers = this.cardOffers.asObservable();
	updateCardOffers(cardOffers) {
		this.cardOffers.next(cardOffers);
	}
	
	// RN SDK APP STATUS
	private rnSdkAppStatus = new BehaviorSubject(undefined);
	sharedHandleRnSdkAppStatus = this.rnSdkAppStatus.asObservable();
	updateSharedHandleRnSdkAppStatus(visibility) {
		this.rnSdkAppStatus.next(visibility);
	}
	getSharedHandleRnSdkAppStatus() {
		return this.rnSdkAppStatus.value;
	}

}
