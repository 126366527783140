import { Component, OnInit, HostListener } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { merchantDetails, orderDetails } from 'src/app/app.models';
import { ApiService } from 'src/app/services/api.service';
import * as CryptoJS from 'crypto-js';
import { CookieService } from 'ngx-cookie-service';
import { OrderDetailService } from 'src/app/services/order-detail.service';
import { PaymentModeService } from 'src/app/services/payment-mode.service';
import { SharedService } from 'src/app/services/shared.service';
import { CommonService } from 'src/app/services/common.service';

import tracking from '../../utils/tracking';
import CheckoutSession from 'src/app/utils/CheckoutSession';
import { convertLocatDateToUTC, isObjectEmpty } from 'src/app/utils';
declare var globalCloseCheckoutModal: Function;

@Component({
	selector: 'app-timer',
	templateUrl: './timer.component.html',
	styleUrls: ['./timer.component.css'],
})
export class TimerComponent implements OnInit {
	time = '';
	hours;
	min;
	sec;
	expires_at = undefined;
	interval = null;
	checkoutSessionDuration: number = null;

	showTimerBar = false;
	showTimeOutModal = false;
	paymentInProgress: boolean = false;
	rotate = 179;
	heading = 'You have exceeded the time available to complete the checkout.';
	subText = 'You can retry the transaction from the Merchant site.';
	btnText = 'Go to Merchant site';
	bgColor = 'green';
	maxRetriesHeading = `You've exhausted all your attempts in this session`;
	orderCompletedHeading = `Order Already Completed!`;
	orderCompletedSubText = `You've already completed the payment for this Order.`;
	requestFrame = undefined;
	isPaymentCompleted = false;
	order_details: orderDetails;
	selectedReason;
	retriesExpired = false;
	expiryTimer: string;
	hasMerchantDetails = false;

  transaction_id: any = null;

	constructor(
		private apiService: ApiService,
		private router: Router,
		private cookies: CookieService,
		private sharedOrderService: OrderDetailService,
		private sharedService: SharedService,
		private activeRoute: ActivatedRoute,
		private sharedPaymentservice: PaymentModeService,
		private commonService: CommonService,
	) {
		this.sharedService.sharedTokenExpiryTime.subscribe((res) => {
			if (this.hasMerchantDetails && !this.retriesExpired && !this.showTimerBar && res) {
				this.expires_at = atob(res);
				this.createTimer();
			}
		});

		this.sharedOrderService.sharedPaymentStatus.subscribe((res) => {
			this.paymentInProgress = res;
		});

		this.sharedOrderService.sharedPaymentCompleted.subscribe((res) => {
			this.isPaymentCompleted = res;
		});

		this.sharedOrderService.sharedOrderDetails.subscribe((res) => {
			if (res['status'] === 'completed') {
				this.isPaymentCompleted = true;
			}
		});

		this.sharedOrderService.sharedMerchantDetails.subscribe(res => {
			this.hasMerchantDetails = true;
			const { checkout_session_duration } = res as unknown as merchantDetails;
			this.checkoutSessionDuration = checkout_session_duration;
		})
	}

	timeDiffCalc(dateFuture, dateNow) {
		const diff = dateFuture - dateNow.getTime();
		if (diff < 0 && this.requestFrame) {
			clearInterval(this.requestFrame);
			return;
		}

		let diffInMilliSeconds = Math.abs(dateFuture - dateNow) / 1000;

		// calculate days
		let days = Math.floor(diffInMilliSeconds / 86400);
		diffInMilliSeconds -= days * 86400;

		// calculate hours
		let hours = Math.floor(diffInMilliSeconds / 3600) % 24;
		diffInMilliSeconds -= hours * 3600;

		// calculate minutes
		let minutes = Math.floor(diffInMilliSeconds / 60) % 60;
		diffInMilliSeconds -= minutes * 60;

		// calculate seconds
		let seconds = Math.floor(diffInMilliSeconds) % 60;
		diffInMilliSeconds -= seconds;

		let difference = '';
		if (days > 0) {
			difference += days === 1 ? `${days} day, ` : `${days} days, `;
		}

		difference += hours === 0 || hours === 1 ? `${hours} hour, ` : `${hours} hours, `;

		difference += minutes === 0 || hours === 1 ? `${minutes} minutes` : `${minutes} minutes`;

		difference += seconds === 0 || minutes === 1 ? `${seconds} seconds` : `${seconds} seconds`;

		return {
			hours: hours,
			min: minutes,
			sec: seconds,
		};
	}

	setTimerBarColor = ({ hours, min, sec }) => {
		if (!hours && min < 3) {
			return 'red';
		} else if (!hours && min < 8) {
			return 'orange';
		} else {
			return 'green';
		}
	};

	setTimer(remainingTime) {
		const time = this.timeDiffCalc(convertLocatDateToUTC(new Date(remainingTime)), convertLocatDateToUTC(new Date()));

		if (time) {
			this.hours = time.hours < 10 ? `0${time.hours}` : time.hours;
			this.min = time.min < 10 ? `0${time.min}` : time.min;
			this.sec = time.sec < 10 ? `0${time.sec}` : time.sec;

			this.time = `${this.min}:${this.sec}`;
			if (Number(this.sec) < 3) {				
				let rotate = this.rotate - 55;
				this.rotate = rotate > 0 ? rotate : 0;
			} else {
				this.rotate = 180;
			}

			if (!time.hours && !time.min && time.sec <= 10 && !this.retriesExpired) {
				this.showTimeOutModal = true;
			} else {
				this.showTimerBar = true;
			}

			this.bgColor = this.checkoutSessionDuration && CheckoutSession.getColor(time.min, this.checkoutSessionDuration);
		} else {
			if(this.retriesExpired) {
				this.showTimerBar = true;
			} else {
				this.showTimerBar = false;
			}
			// this.showTimerBar = false;
			if (this.requestFrame) {
				clearInterval(this.requestFrame);
			}
			this.redirectToFailure();
		}
	}
	createTimer() {
		this.expiryTimer = this.expires_at.split("=");
		if (this.requestFrame) {
			clearInterval(this.requestFrame);
		}
		this.requestFrame = setInterval(() => this.setTimer(this.expiryTimer[0]), 1000);
	}
	ngOnInit(): void {
		this.sharedService.sharedHasMaxRetriesExpiredSubject.subscribe((res) => {
			if (res) {
				if (this.requestFrame) {
					clearInterval(this.requestFrame);
				}
				// Get the current date and time
				const currentDate = new Date();
				// Add 12 seconds
				currentDate.setSeconds(currentDate.getSeconds() + 3);
				this.retriesExpired = res;
				this.requestFrame = setInterval(() => this.setTimer(currentDate), 1000);
				this.showTimeOutModal = true;
				setTimeout(() => {
					this.redirectToFailure();
				}, 3000);
				this.showTimerBar = false;
			}
		});
	}

	redirectToHomePage() {
		this.redirectToFailure();
	}

	redirectToFailure() {
		this.order_details = JSON.parse(JSON.stringify(this.sharedOrderService.getOrderDetails()));
		!isObjectEmpty(this.order_details) && this.submitReason('Checkout timer has expired');
	}
	submitReason(closeReason = 'user closed') {
		if (this.requestFrame) {
			clearInterval(this.requestFrame);
		}
		this.checkstatusOftransaction();
		this.cookies.set('no_refresh', 'true');

		tracking.checkoutClosed({
			order_details: this.order_details,
			close_reason: closeReason,
			selected_reason: this.selectedReason,
		});
		let data = {
			order_id:
				this.order_details && this.order_details['order_id']
					? this.order_details['order_id']
					: null,
			order_level: 6,
			closed_reason: this.selectedReason,
			checkout_id: this.order_details['checkout_id'],
		};
		this.apiService.updateOrderLevel(data).subscribe(
			(res) => {},
			(error) => {}
		);
		let orderUpdateData = {
			order_id: this.order_details['order_id'],
			cancellation_reason: this.selectedReason || closeReason,
		};

		
		tracking.updateOrderAPIPayload({
			order_id: this.order_details['order_id'],
			close_reason: this.selectedReason || closeReason,
			from: 'Timer Component',
		})

		this.apiService.updateOrder(orderUpdateData).subscribe(
			(response) => {
				this.activeRoute.queryParams.subscribe((params) => {
					if (params['response']) {
						let paymentResponse = JSON.parse(atob(params['response']));
						this.transaction_id = paymentResponse['nimbbl_transaction_id'];
					}
				});
				this.commonService.getCallBackPayloadResponse(this.order_details, this.transaction_id);
			},
			(error) => {
				throw error;
			}
		);
	}
	checkstatusOftransaction() {
		let transactionData = this.sharedService.getTransactionData();
		this.transaction_id = transactionData['transaction_id'];

		return new Promise((resolve, reject) => {
			if (transactionData['transaction_id']) {
				this.apiService.paymentStatusConfirmation(transactionData).subscribe(
					(responseData) => {
						if (responseData) {
							resolve(responseData['status']);
						}
					},
					(error) => {
						resolve('failed');
					}
				);
			} else {
				resolve('failed');
			}
		});
	}
}
