<div class="paymentResponse top_bg payment">
	<app-header></app-header>
	<app-new-on-nimbl
		[isOtpVerify]="true"
		[userFound]="true"
		[nimbbl_mobile_name]="nimbbl_user_name"
		[welcome]="false"
	>
	</app-new-on-nimbl>
</div>
<div class="mainContentDisplay">
	<div class="box-accor" *ngIf="response == 'success'">
		<div class="box-item container">
			<div class="box-row pt-1">
				<div class="col-12 align-self-center">
					<div class="box-date-details">
						<div class="row">
							<div class="col-auto p-0"><img src="assets/images/logos/credit-card.png" /></div>
							<div class="col px-2 fS-18"><strong>Pay with card</strong></div>
						</div>
					</div>
				</div>
				<div class="col-2"><a href="#" class="align-middle pt-2 text-center d-block"></a></div>
			</div>
			<div class="row">
				<div class="payment-success text-center">
					<div>
						<img src="assets/images/success.png" class="img-fluid" />
					</div>
					<div class="fS-14 pt-1 smessage">Payment Successful</div>
					<div class="fS-24m my-3">
						Your payment was<br />
						completed in {{ eta_completion }} secs
					</div>
					<div>
						<button type="button" class="btn btn-primary btn-orange" (click)="closeWindow()">
							Done
						</button>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="box-accor" *ngIf="response == 'failed'">
		<div class="box-item">
			<div class="box-row pt-1">
				<div class="col-12 align-self-center">
					<div class="box-date-details">
						<div class="row">
							<div class="col-auto p-0"><img src="assets/images/logos/credit-card.png" /></div>
							<div class="col px-2 fS-18"><strong>Pay with card</strong></div>
						</div>
					</div>
				</div>
			</div>
			<div class="payment-success">
				<div class="row">
					<div class="col-auto px-0" *ngIf="!isPaymentTimedOut">
						<img src="./assets/images/icons/cancel-1.png" alt="" />
					</div>
					<div class="col-auto px-0" *ngIf="isPaymentTimedOut">
						<div class="timeOut">!</div>
					</div>
					<div class="col fS-12">
						<div class="fS-15">
							<strong *ngIf="!isPaymentTimedOut"> Your payment was unsuccessful</strong>
						</div>
						{{ message }}
					</div>
					<div class="col-12">
						<button type="button" class="btn btn-outline-dark mt-3" (click)="reinitiatePayment()">
							Try again
						</button>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div *ngIf="showLoader">
		<div class="loaderContainer">
			<div class="loader">
				<div class="loaderhmtl"></div>
			</div>
		</div>
	</div>
</div>
<app-power-by></app-power-by>
