import { Injectable } from '@angular/core';
import { allowedPaymentPartnersRequest } from 'src/app/app.models';
import { OrderDetailService } from 'src/app/services/order-detail.service';
import { ApiService } from 'src/app/services/api.service';
import { PaymentModeService } from 'src/app/services/payment-mode.service';
import { SharedService } from 'src/app/services/shared.service';
import tracking from '../utils/tracking';
import { hasMaxRetriesReached, mergeObjects } from '../utils';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';

const isMagicUpi = (element) => {
	const { payment_mode, extra_info } = element;
	return payment_mode === 'UPI' && extra_info && extra_info?.vpa_provider;
};

const getOffersOnPaymentMode = (allModes) => {
	return allModes.filter((mode) => mode.offer_applicable === 'Y');
};

@Injectable({
	providedIn: 'root',
})
export class CommonService {
	isIntent_allowed: boolean = false;
	intent_convenience_fees = 0;
	isRNFlag: boolean = false;
	mergedOtherPaymentModes = [];
	urlPaymentFlow: boolean = false;
	intentConv: string;
	collectConv: string;
	constructor(
		private sharedPaymentservice: PaymentModeService,
		public orderService: OrderDetailService,
		public apiService: ApiService,
		private sharedService: SharedService,
		private router: Router,
		public activateRoute: ActivatedRoute
	) {}

	setPaymentModes(validateToken, fn = null, component = '') {
		// For home page, if we don't have authToken (as we can get it in otp screen), it should return without setting payment mode

		this.urlPaymentFlow = this.activateRoute.snapshot.queryParams['payment_flow'] === 'intent';

		if (component === 'home') {
			const authToken = this.sharedService.getAuthToken();
			if (!authToken) {
				if (fn) {
					fn();
				}
				return;
			}
		}

		const order_details = JSON.parse(JSON.stringify(this.orderService.getOrderDetails()));
		const merchant_details = JSON.parse(JSON.stringify(this.orderService.getMerchantDetail()));
		if (!order_details) return;

		if(hasMaxRetriesReached(order_details)) {
			this.sharedService.setHasMaxRetriesExpiredSubject(true);
		}

		this.sharedService.sharedHasMaxRetriesExpiredSubject.subscribe(resRetriesExhausted => {
			if (resRetriesExhausted) {
				return;
			} else {
				let rr = new allowedPaymentPartnersRequest();
				rr.order_id = order_details['order_id'];
				let apiPaymentModeItems;
				let allowedModes = [];
				let fastPaymentModes = [];
				let otherPaymentModes = [];
				let cardlessPaymentModes = [];
				let copycardlessPaymentModes = [];
				let fastPaymentList = [];
				let magicUpi = [];
				let is_card_includes: boolean = false;
				let isCardless: boolean = false;
				const intentList = [];
				const intentPkgList = [];
				let cardlessData = [];
				function getMinimumConvFee(data) {
					let minValue = 0;
					let maxValue = 0;
					for (let i = 0; i < data.length; i++) {
						if (data[i] < minValue) {
							minValue = data[i];
						}
					}
					return Number(minValue);
				}
		
				function getMaximumConvFee(data) {
					let maxValue = 0;
					for (let i = 0; i < data.length; i++) {
						if (data[i] > maxValue) {
							maxValue = data[i];
						}
					}
					return Number(maxValue);
				}
		
				this.isRNFlag = window?.nimbbl_webview?.isNative || false;
				if (this.isRNFlag) {
					this.sharedService.updateintent(window?.nimbbl_webview?.UPIIntentAvailable || []);
				}
		
				this.apiService.loadAllowedPaymentTypes(rr).subscribe((res) => {
					let offerFilteredPaymentMode = getOffersOnPaymentMode(res);
		
					this.sharedService.updateOffersDetails(offerFilteredPaymentMode);
					apiPaymentModeItems = res;
					const showGpayButton = this.sharedPaymentservice.getGpayVisibility();
					this.sharedPaymentservice.enableGooglePay.subscribe((resp) => {
						console.log(resp, res);
					});
					this.sharedService.updateListOfPaymentModes(res);
					let paymentModeDetails;
					let conFeeMinArr = [];
					let conFeeMaxArr = [];
					this.apiService.fetchPaymentModesMessage().subscribe(
						(res) => {
							paymentModeDetails = res;
							apiPaymentModeItems.forEach((apiRespElement) => {
								
								const { payment_mode, extra_info } = apiRespElement;

								if (payment_mode == 'UPI') {
									if (extra_info['flow'] == 'intent') {
										this.isIntent_allowed = true;
									}
									this.intent_convenience_fees = apiRespElement?.extra_info?.additional_charges
										? apiRespElement?.extra_info?.additional_charges
										: 0;
								}
								const el = paymentModeDetails[payment_mode.toLowerCase()];
								let data;
								if (el) {
									const isVPA = !!extra_info['vpa_provider'];
									const hasOffers = apiRespElement.hasOwnProperty('offer_applicable');
									const isOfferAsPercentage =
										hasOffers &&
										apiRespElement.offers.length &&
										apiRespElement.offers[0]['offer_construct'] === 'fixed_percentage'
											? true
											: false;
									/*
									 * getOffersRange(element) function will extract offers and
									 * create new key called offer_range with offers to
									 * show on payment mode list on landing page
									 */
									hasOffers && this.setOffersRangeInPaymentModeElement(apiRespElement);
		
									const cardlessLogo = 'assets/images/logos/cardless.png';
		
									let hasIntentInUPI = this.isIntent_allowed || this.urlPaymentFlow;

									if (payment_mode === 'UPI') {
										if (extra_info.flow === 'collect' && !extra_info.vpa_id) {
											this.collectConv = extra_info.additional_charges;
										} else if (extra_info.flow === 'intent') {
											this.intentConv = extra_info.additional_charges;
										}
									}

		
									data = {
										mode:
											extra_info['user_vpa'] || extra_info['vpa_id'] ? 'savedUPI' : payment_mode,
										eta: extra_info['eta_completion'],
										savedVPA: extra_info['user_vpa'] ? extra_info['user_vpa'].toLowerCase() : '',
										savedVPAId: extra_info['vpa_id'] ? extra_info['vpa_id'].toLowerCase() : '',
										logo: extra_info['vpa_provider']
											? paymentModeDetails[extra_info['vpa_provider'].toLowerCase()]
												? paymentModeDetails[extra_info['vpa_provider'].toLowerCase()]['logo']
												: 'assets/images/icons/bhim.png'
											: paymentModeDetails[payment_mode.toLowerCase()]['logo'],
										desc: extra_info['educational_text']
											? extra_info['educational_text']
											: paymentModeDetails[payment_mode.toLowerCase()]['listPagedescription'],
										payment_partner: extra_info['partner_name'] ? extra_info['partner_name'] : '',
										ConvenienceFees: apiRespElement?.extra_info?.additional_charges
											? apiRespElement?.extra_info?.additional_charges
											: 0,
										autoDebitFlowPossible: extra_info['auto_debit_flow_possible']
											? extra_info['auto_debit_flow_possible'] == 'yes'
											: false,
										modeName: payment_mode,
										compareName:
											isVPA && extra_info['vpa_provider'] !== 'Other'
												? paymentModeDetails[extra_info['vpa_provider'].toLowerCase()].compareName
												: el['compareName'],
										flow: payment_mode === 'UPI' ? extra_info['flow'] : '',
										offerID: hasOffers ? apiRespElement.offers[0]['offer_id'] : '',
										offerApplicable: hasOffers ? hasOffers : false,
										offerName: hasOffers ? apiRespElement.offers[0]['offer_name'] : '',
										offerType: hasOffers ? apiRespElement.offers[0]['offer_type'] : '',
										offerUnitRsOrPercentage: isOfferAsPercentage,
										offerRange: apiRespElement?.offer_range,
										extraInfo: apiRespElement?.['extra_info'] || {},
										isIntentAllowed: this.isIntent_allowed,
										intentConvFee: this.intentConv,
										collectConvFee: this.collectConv,
									};
									// console.log(data);
									const displayName =
										isVPA &&
										paymentModeDetails[extra_info['vpa_provider'].toLowerCase()] &&
										paymentModeDetails[extra_info['vpa_provider'].toLowerCase()]
											.magicUPIDisplayName;
									if (displayName) {
										data['magicUPIDisplayName'] = displayName;
									}
									//
									if (
										(
											apiRespElement['payment_mode'].toLowerCase().includes(el['modeName'].toLowerCase()) &&
											apiRespElement['payment_mode'] !== 'Netbanking' &&
											apiRespElement['payment_mode'] !== 'Wallet' &&
											apiRespElement['payment_mode'] !== 'UPI' &&
											!apiRespElement['payment_mode'].includes('Card')
										) ||
										(
											validateToken && 
											(apiRespElement['payment_mode'] == 'Cred' || apiRespElement['payment_mode'] == 'ICICI PayLater') || 
											apiRespElement['payment_mode'] == 'Mobikwik Zip'
										) ||
										(
											apiRespElement['payment_mode'] == 'UPI' && 
											(apiRespElement['extra_info']['user_vpa'] || 
											apiRespElement['extra_info']['vpa_id'])
										)
									) {
										// if (payment_mode == 'Google Pay' && !showGpayButton) {
		
										// }
										// else {
										// console.log(apiRespElement);
										if (extra_info?.partner_name !== 'Spur') {
											if (!isMagicUpi(apiRespElement)) {
												fastPaymentList.push(data['compareName']); //push it to compare the elements to compare magic and intent
												fastPaymentModes.push(data);
											} else {
												data['is_magic_UPI'] = true;
												data['vpa_provider'] =
													extra_info['vpa_provider'].toLowerCase();
												magicUpi.push(data);
											}
										} else if (
											/* 
											*** user_type 1
												** is elgible and it will always come under FPmode
												** incase of 1 scheme: the flow will be similar to Lazypay
												** if autodebit is true, will not have OTP screen.
												** if we have two or more schemes: will appear under FPmode but 
														the flow will be of selecting the EMI and then OTP
		
											*** user_type 2 / 3 - this will always come under OthersP mode as cardless emi
												** This will alway open in redirect flow after initiate pay.
											*/
											extra_info?.partner_name === 'Spur' &&
											extra_info?.user_type === 1
										) {
											fastPaymentList.push(data['compareName']); //push it to compare the elements to compare magic and intent
											fastPaymentModes.push(data);
										} else if (
											extra_info?.partner_name === 'Spur' &&
											(extra_info?.user_type === 2 ||
												extra_info?.user_type === 3)
										) {
											cardlessPaymentModes.push(data);
											// data.logo = 'assets/images/logos/cardless.png';
											copycardlessPaymentModes.push(data);
											cardlessData = [
												{
													mode: 'Cardless EMI',
													savedVPA: '',
													savedVPAId: '',
													logo: 'assets/images/logos/cardless.png',
													desc: 'Shop Now, Pay Later',
													payment_partner: 'Spur',
													ConvenienceFees: apiRespElement?.extra_info?.additional_charges
														? apiRespElement?.extra_info?.additional_charges
														: 0,
													autoDebitFlowPossible: extra_info[
														'auto_debit_flow_possible'
													]
														? extra_info['auto_debit_flow_possible'] == 'yes'
														: false,
													modeName: 'Cardless EMI',
													compareName: 'Cardless EMI',
													flow: '',
													offerID: hasOffers ? apiRespElement.offers[0]['offer_id'] : '',
													offerApplicable: hasOffers ? hasOffers : false,
													offerName: hasOffers ? apiRespElement.offers[0]['offer_name'] : '',
													offerType: hasOffers ? apiRespElement.offers[0]['offer_type'] : '',
													offerUnitRsOrPercentage: isOfferAsPercentage,
													offerRange: apiRespElement?.offer_range,
													isIntentAllowed: this.isIntent_allowed
												},
											];
										}
									} else {
										if (
											['Credit Card', 'Debit Card', 'Prepaid Card'].includes(data['mode']) &&
											!is_card_includes
										) {
											// console.log(data);
											// is_card_includes = true;
											otherPaymentModes.push(data);
										} else if (
											(data['mode'] == 'Credit Card' ||
												data['mode'] == 'Debit Card' ||
												data['mode'] == 'Prepaid Card') &&
											is_card_includes &&
											!data['offerApplicable'] === false
										) {
											otherPaymentModes.map((item, index) => {
												if (
													item['mode'] == 'Credit Card' ||
													item['mode'] == 'Debit Card' ||
													item['mode'] == 'Prepaid Card'
												) {
													otherPaymentModes.splice(index, 1);
												}
											});
		
											otherPaymentModes.push(data);
										}

										if (!['Credit Card', 'Debit Card', 'Prepaid Card', 'UPI'].includes(data['mode'])) {
											otherPaymentModes.push(data);
										} else if (hasIntentInUPI && data['mode'] === 'UPI') {
											otherPaymentModes.push(data);
										} else if (data['mode'] === 'UPI' && data['flow'] !== 'intent') {
											otherPaymentModes.push(data);
										}
									}
								}
		
								if (cardlessPaymentModes.length > 0) {
									this.sharedService.updateIsCardlessPayment(true);
									this.sharedPaymentservice.updateCardlessPaymentMode(cardlessPaymentModes);
								} else {
									this.sharedService.updateIsCardlessPayment(false);
								}
								allowedModes.push(payment_mode);
							});
		
							function getOfferRange(data) {
								// Function to filter objects by mode
								const filterByMode = (mode) => data.filter((item) => item.mode === mode);
		
								// Function to extract offer range from an object
								const getOfferRange = (obj) => obj.offerRange;
		
								// Find offer range from "Credit Card," "Debit Card," and "Prepaid Card" modes
								const creditCardOffers = filterByMode('Credit Card').map(getOfferRange).filter(Boolean);
								const debitCardOffers = filterByMode('Debit Card').map(getOfferRange).filter(Boolean);
								const prepaidCardOffers = filterByMode('Prepaid Card')
									.map(getOfferRange)
									.filter(Boolean);
		
								// Combine all offer ranges into a single array
								const offerRanges = [...creditCardOffers, ...debitCardOffers, ...prepaidCardOffers];
								// Function to extract numeric values from the string
								const extractNumbers = (str) => {
									const [min, max] = str.split(' - ').map(parseFloat);
									return [min, max];
								};
								// Convert each string element to an array of numeric values
								const numericValues = offerRanges.map((str) => {
									const [min, max] = extractNumbers(str);
									return isNaN(min) || isNaN(max) ? parseFloat(str) : [min, max];
								});
								// Find the minimum and maximum values from the numeric array
								let min = Infinity;
								let max = -Infinity;
		
								numericValues.forEach((item) => {
									if (Array.isArray(item)) {
										const [minVal, maxVal] = item;
										min = Math.min(min, minVal);
										max = Math.max(max, maxVal);
									} else {
										min = Math.min(min, item);
										max = Math.max(max, item);
									}
								});
		
								if (min === max) {
									return `${max.toFixed(2)}`;
								} else {
									return ` ${min?.toFixed(2)} - ${max.toFixed(2)} `;
								}
							}
		
							// Function to merge modes with the same compareName (creditcard in this case)
							function mergeModes(data) {
								const offerRange = getOfferRange(data);
								const mergedData = [];
								let isCollect = false;
								const mergedCompareNames = new Set();
								for (const entry of data) {
									const compareName = entry.compareName;
									console.log(entry);
									if (entry.mode === 'UPI' && entry.flow === 'collect') {
										isCollect = true
									}
									if (!mergedCompareNames.has(compareName)) {
										const mergedEntry = { ...entry };
										for (const otherEntry of data) {
											if (otherEntry.compareName === compareName) {
												if (
													otherEntry?.ConvenienceFees === '0' &&
													mergedEntry?.ConvenienceFees === '0'
												) {
													mergedEntry.ConvenienceFees = '0';
													mergedEntry.isrange = mergedEntry.isrange || otherEntry.isrange;
													mergedEntry.flow = mergedEntry.flow || otherEntry.flow;
													mergedEntry.offerID = mergedEntry.offerID || otherEntry.offerID;
													mergedEntry.offerApplicable =
														mergedEntry.offerApplicable || otherEntry.offerApplicable;
													mergedEntry.offerName = mergedEntry.offerName || otherEntry.offerName;
													mergedEntry.offerType = mergedEntry.offerType || otherEntry.offerType;
													mergedEntry.offerUnitRsOrPercentage =
														mergedEntry.offerUnitRsOrPercentage || otherEntry.offerUnitRsOrPercentage;
													mergedEntry.offerRange = offerRange && compareName.includes('card')
														? offerRange
														: mergedEntry.offerRange || otherEntry.offerRange;
													mergedEntry.isCollect = isCollect;
													mergedEntry.intentConvFee = otherEntry.intentConvFee || mergedEntry.intentConvFee;
													mergedEntry.collectConvFee = otherEntry.collectConvFee || mergedEntry.collectConvFee;
												} else {
													let minConFee = otherEntry?.ConvenienceFees;
													let minMergedConFee = mergedEntry?.ConvenienceFees;

													if (minConFee.includes('-')) {
														const [min, max] = minConFee?.split('-');
														conFeeMinArr.push(min);
														conFeeMaxArr.push(max);
													}
													if (minMergedConFee.includes('-')) {
														const [min, max] = minMergedConFee?.split('-');
														conFeeMinArr.push(min);
														conFeeMaxArr.push(max);
													}
													mergedEntry.isrange = mergedEntry.isrange || otherEntry.isrange;
													mergedEntry.flow = mergedEntry.flow || otherEntry.flow;
													mergedEntry.offerID = mergedEntry.offerID || otherEntry.offerID;
													mergedEntry.offerApplicable =
														mergedEntry.offerApplicable || otherEntry.offerApplicable;
													mergedEntry.offerName = mergedEntry.offerName || otherEntry.offerName;
													mergedEntry.offerType = mergedEntry.offerType || otherEntry.offerType;
													mergedEntry.offerUnitRsOrPercentage =
														mergedEntry.offerUnitRsOrPercentage || otherEntry.offerUnitRsOrPercentage;
													mergedEntry.offerRange = offerRange && compareName.includes('card')
														? offerRange
														: mergedEntry.offerRange || otherEntry.offerRange;
													mergedEntry.isCollect = isCollect;
													mergedEntry.intentConvFee = otherEntry.intentConvFee || mergedEntry.intentConvFee;
													mergedEntry.collectConvFee = otherEntry.collectConvFee || mergedEntry.collectConvFee;
												}
											}
										}
										mergedData.push(mergedEntry);
										mergedCompareNames.add(compareName);
									}
								}

								let minArrayValue = getMinimumConvFee(conFeeMinArr);
								let maxArrayValue = getMaximumConvFee(conFeeMaxArr);

								mergedData.forEach(item => {
									if (item?.compareName === 'creditcard' && item?.ConvenienceFees?.includes('-')) {
										const [min, max] = item.ConvenienceFees.split('-');
										item.ConvenienceFees = (min === "0" && max === "0") ? 0 : `${min} - ${max} `;
									}

									if (item.compareName === 'upi') {
										item.isCollect = isCollect;
									}
								});
								return mergedData;
							}
							this.mergedOtherPaymentModes = mergeModes(otherPaymentModes);
							console.log(otherPaymentModes);
							
							let intentpaymentModes = this.sharedService.getupdatedintent() || [];
							this.sharedService.sharedintent.subscribe((res) => {
								intentpaymentModes = JSON.parse(JSON.stringify(res));
								if (this.isIntent_allowed && intentpaymentModes && intentpaymentModes.length) {
									intentpaymentModes.forEach((element) => {
										let data;
										intentList.push(element['name']);
										this.isRNFlag
											? intentPkgList.push(element['package_name'])
											: intentPkgList.push(element['packagename']);
		
										let el = paymentModeDetails[element['name'].toLowerCase()];
										if (el) {
											data = {
												mode: element['name'],
												eta: 0,
												savedVPA: '',
												savedVPAId: '',
												logo: paymentModeDetails[element['name'].toLowerCase()]
													? paymentModeDetails[element['name'].toLowerCase()]['logo']
													: '',
												desc: paymentModeDetails[element['name'].toLowerCase()]
													? paymentModeDetails[element['name'].toLowerCase()]['listPagedescription']
													: '',
												intentPayWithDesc: paymentModeDetails[element['name'].toLowerCase()]
													? paymentModeDetails[element['name'].toLowerCase()]['intentPayWithDesc']
													: '',
												payment_partner: '',
												ConvenienceFees: this.intent_convenience_fees,
												is_intent: true,
												magicUPIDisplayName:
													paymentModeDetails[element['name'].toLowerCase()]?.intentDisplayName,
												compareName: el?.compareName,
											};
											mergeObjects(fastPaymentModes, data);
											fastPaymentList.push(data['compareName']);
										}
										allowedModes.push(element['name']);
									});
								}
							});
							if (magicUpi.length) {
								magicUpi.forEach((element) => {
									if (!fastPaymentList.includes(element.compareName)) {
										// fastPaymentList.push(element['vpa_provider'].toLowerCase());
										fastPaymentModes.push(element);
									}
								});
							}
							this.sharedPaymentservice.updatePaymentMode(allowedModes);
							// this.showLoader = false;
							this.sharedPaymentservice.updateAllowedPaymentTypeArray(apiPaymentModeItems);
							this.sharedPaymentservice.updateOtherPaymentMode(this.mergedOtherPaymentModes);
							this.sharedPaymentservice.updateFastPaymentMode(fastPaymentModes);
		
							let otherPaymentModesArr = this.sharedPaymentservice.getOtherPaymentMode();
		
							copycardlessPaymentModes.map(() => {
								this.sharedPaymentservice.updateOtherPaymentMode([
									...otherPaymentModes,
									cardlessData[0],
								]);
							});
		
							otherPaymentModesArr.map((item) => {
								if (item.mode.includes('Card')) {
									this.sharedService.updateIsCardPayment(true);
								}
							});
		
							if (intentpaymentModes && intentpaymentModes.length) {
								tracking.foundIntentApp({
									order_details: order_details,
									merchant_details: merchant_details,
									intentList: intentList,
									intentPkgList: intentPkgList,
								});
							}
							if (fastPaymentModes.length && merchant_details?.fast_payment_enabled) {
								this.sharedService.updateCollapsibleStatus({
									...this.sharedService.getCollapsibleStatus(),
									fastPaymentOption: true,
									otherPaymentOption: true,
								});
								tracking.openFastPayment({
									order_details: order_details,
									merchant_details: merchant_details,
									interaction_type: 'auto',
								});
							} else {
								this.sharedService.updateCollapsibleStatus({
									...this.sharedService.getCollapsibleStatus(),
									fastPaymentOption: true,
									otherPaymentOption: true,
								});
								tracking.openOtherPayment({
									order_details: order_details,
									merchant_details: merchant_details,
									interaction_type: 'auto',
								});
							}
							fn && fn();
						},
						(error) => {
							alert(error['message']);
						}
					);
				});
			}			
		});
	}

	setOffersRangeInPaymentModeElement = (paymentModeItemForOffers) => {
		if (paymentModeItemForOffers.offers && paymentModeItemForOffers.offers.length > 0) {
			const offerDiscounts = paymentModeItemForOffers.offers.map((offer) => offer.offer_discount);
			const minDiscount = Math.min(...offerDiscounts);
			const maxDiscount = Math.max(...offerDiscounts);
			paymentModeItemForOffers.offer_range =
				minDiscount === maxDiscount
					? minDiscount.toFixed(2)
					: `${minDiscount.toFixed(2)} - ${maxDiscount.toFixed(2)}`;
		}
	};

	getCallBackPayloadResponse = async (orderDetails: any, transactionID: string = null) => {
		const callbackRes = await this.apiService
			.getCallBackPayload(orderDetails['order_id'], transactionID)
			.toPromise();

		let response = {
			event_type: 'globalHandleCheckoutResponse',
			payload: callbackRes,
		};

		const delay = 2000;

		if (orderDetails['callback_mode'] === 'callback_handler') {
			const responseclose = {
				event_type: 'globalCloseCheckoutModal',
			};
			tracking.checkoutClosed(
				{
					order_details: orderDetails['order_id'],
				},
				true
			);

			tracking.callBackResponse({
				callbackMode: 'callback_handler',
				payload: callbackRes,
				nimbbl_signature: callbackRes['nimbbl_signature'],
			});

			setTimeout(() => {
				window.parent.postMessage(JSON.stringify(response), '*');
				window.parent.postMessage(JSON.stringify(responseclose), '*');
			}, delay);
			this.sharedService.updateuseAnotherAccount('no');
		} else if (orderDetails['callback_mode'] === 'callback_url_noredirect') {
			setTimeout(() => {
				window.close();
			}, delay);
		} else if (orderDetails['callback_mode'] === 'callback_url_redirect') {
			tracking.callBackResponse({
				callbackMode: 'callback_url_redirect',
				payload: response,
				nimbbl_signature: callbackRes['nimbbl_signature'],
			});
			setTimeout(() => {
				const responseStr = btoa(JSON.stringify(response));
				window.location.href = `${orderDetails['callback_url']}?response=${responseStr}`;
			}, delay);
		} else if (orderDetails['callback_mode'] === 'callback_mobile') {
			tracking.callBackResponse({
				callbackMode: 'callback_mobile',
				payload: response,
				nimbbl_signature: callbackRes['nimbbl_signature'],
			});
			this.router.navigate(['mobile/redirect'], {
				queryParams: { response: btoa(JSON.stringify(response)) },
			});
		} else {
			window.close();
		}
	};
}