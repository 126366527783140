import { JsonPipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { merchantDetails, orderDetails, user } from 'src/app/app.models';
import { ApiService } from 'src/app/services/api.service';
import { CommonService } from 'src/app/services/common.service';
import { OrderDetailService } from 'src/app/services/order-detail.service';
import { SharedService } from 'src/app/services/shared.service';
import { getCheckoutId } from '../../utils/checkoutId';

import tracking from '../../utils/tracking';

@Component({
  selector: 'app-user-detail',
  templateUrl: './user-detail.component.html',
  styleUrls: ['./user-detail.component.css']
})
export class UserDetailComponent implements OnInit {
  nimbbl_user: any
  merchant_detail: merchantDetails;
  order_detail: orderDetails;
  mobileNumber;
  
  constructor(public apiService: ApiService, public router: Router, private cookies: CookieService, private shareOrderService: OrderDetailService,private sharedService:SharedService, private activatedRoute: ActivatedRoute,private commonService: CommonService) { }

  ngOnInit(): void {
    
    this.order_detail = JSON.parse(JSON.stringify(this.shareOrderService.getOrderDetails()));
    this.merchant_detail = JSON.parse(JSON.stringify(this.shareOrderService.getMerchantDetail()));

    tracking.openPage({
      page_name: 'User Detail',
      order_details: this.order_detail,
      merchant_details: this.merchant_detail
    })
    this.mobileNumber = this.order_detail['user']['mobile_number'];
    let chars = this.mobileNumber.split('');
    for (let index = 2; index < 8; index++) {

      chars[index] = 'x';
    }
    this.mobileNumber = chars?.join('');
    setTimeout(() => {
      document.querySelector('.top_bg').classList.add('userInfo');
    }, 500);
  }

  ngAfterViewInit(): void {
    tracking.renderPage({
      page_name: 'User Detail',
      order_details: this.order_detail,
      merchant_details: this.merchant_detail
    })
  }

  updateUserInfo(formData) {
    formData['id'] = this.order_detail['user']['id'];
    let validateToken = this.apiService.checkForValidToken();
    if (!validateToken) {
      this.apiService.getEncryptedAuthorizationToken(this.order_detail['order_id']).subscribe(resp => {
        this.apiService.setTokenCookies(resp['token'], btoa(resp['expires_at']));
        this.apiService.updateUserInfo(formData).subscribe(resp => {
          
          this.nimbbl_user = resp;
          let data = {
            user_id: resp['id'],
            order_id: this.order_detail['order_id']
          }
          this.apiService.updateUserOnOrder(data).subscribe(res => {
            res['checkout_id'] = getCheckoutId(data.order_id);
            this.shareOrderService.updateOrderDetails(res);
            let otpStatus=this.sharedService.getOtpStatus();
            let options = { order_id: this.order_detail['order_id'] };
            let transaction_token = this.activatedRoute.snapshot.queryParams['transaction_token'];
            if(transaction_token) {
              options['transaction_token'] = transaction_token;
            }
            else {
              options['expires'] = this.sharedService.getTokenExpiryTime();
            }
            if (otpStatus) {
              this.router.navigate(['verify-otp'], { queryParams: options });

            }
            else {
              this.router.navigate(['payment'], { queryParams: options });
            }
          });
        });
      });
    }
    else {
      this.apiService.updateUserInfo(formData).subscribe(resp => {
        this.nimbbl_user = resp;

        let data = {
          user_id: resp['id'],
          order_id: this.order_detail['order_id']
        }
        this.apiService.updateUserOnOrder(data).subscribe(res => {
          this.shareOrderService.updateOrderDetails(res);
          let otpStatus=this.sharedService.getOtpStatus();

            let options = { order_id: this.order_detail['order_id'] };
            let transaction_token = this.activatedRoute.snapshot.queryParams['transaction_token'];
            if(transaction_token) {
              options['transaction_token'] = transaction_token;
            }
            else {
              options['expires'] = this.sharedService.getTokenExpiryTime();
            }
            if (otpStatus) {
            this.router.navigate(['verify-otp'], { queryParams: options });

          }
          else {
            this.commonService.setPaymentModes(true, () => { 
              setTimeout(() => {
                // console.log('redirecting to user Payment');
                //tracking code for identify with the user id
                // window.analytics.identify(this.nimbbl_user['user_id']);
  
               
  
                // this.closeCountryCodeList();
                // this.showLoader=false;
                let options = { order_id: this.order_detail['id'] };
                
                this.router.navigate(['payment'], { queryParams: options });
  
              }, 1000);
            });
            
          }
        });
      });
    }

  }
}
