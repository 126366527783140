import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './pages/home/home.component';
import { MobileRedirectComponent } from './pages/mobile-redirect/mobile-redirect.component';
import { PaymentComponent } from './pages/payment/payment.component';
// import { ShopMoreComponent } from './pages/shop-more/shop-more.component';
import { UserDetailComponent } from './pages/user-detail/user-detail.component';
import { UserIsEligibleComponent } from './pages/user-is-eligible/user-is-eligible.component';
import { VerifyOtpComponent } from './pages/verify-otp/verify-otp.component';
import { PaymentResponseComponent } from './pages/payment-response/payment-response.component';
import { EMIPlanCardComponent } from './component/emi-plan-card/emi-plan-card.component';


const routes: Routes = [
  { path: '', component: HomeComponent, pathMatch: 'full' },
  { path: 'user-detail', component: UserDetailComponent, pathMatch: 'full' },
  { path: 'verify-otp', component: VerifyOtpComponent, pathMatch: 'full' },
  { path: 'payment', component: PaymentComponent, pathMatch: 'full' },
  { path: 'user-is-eligible', component: UserIsEligibleComponent, pathMatch: 'full' },
  /* { path: 'shop-more', component: ShopMoreComponent, pathMatch: 'full' }, */
  { path: 'mobile/redirect', component: MobileRedirectComponent, pathMatch: 'full' },
  { path: 'payment/response', component:PaymentResponseComponent, pathMatch: 'full' },
  // { path: 'payment/netbanking', component: PayWithNbComponent, pathMatch: 'full' },
  { path: 'payment/netbanking', component: PaymentComponent, pathMatch: 'full' },
  { path: 'payment/emi-plans', component: EMIPlanCardComponent, pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes,{scrollPositionRestoration: 'enabled',
  anchorScrolling: 'enabled',}),],
  exports: [RouterModule]
})
export class AppRoutingModule { }