import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-user-is-eligible',
  templateUrl: './user-is-eligible.component.html',
  styleUrls: ['./user-is-eligible.component.css']
})
export class UserIsEligibleComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
