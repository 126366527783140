<div
	class="timerBox"
	*ngIf="showTimerBar && !paymentInProgress"
	[ngClass]="showTimeOutModal ? 'timeoutBoxContainer modalPosition' : ''"
>
	<div class="timeoutBox confirmationBox">
		<div class="popupBody">
			<div class="timeoutBoxHeader">
				<span class="timeoutBoxClockIcon"></span>
				<p *ngIf="isPaymentCompleted">{{orderCompletedHeading}}</p>
				<p *ngIf="isPaymentCompleted" style="font-size: 14px;font-weight: normal;">{{orderCompletedSubText}}</p>
				<p *ngIf="!isPaymentCompleted">{{ retriesExpired ? maxRetriesHeading : heading }}</p>
			</div>
			<div class="timeoutBoxTimerContainer" *ngIf="sec">
				<div class="timeoutBoxTimer">
					<div class="circle-wrap">
						<div class="circle">
							<div [ngStyle]="{ transform: 'rotate(' + rotate + 'deg)' }" class="mask full">
								<div [ngStyle]="{ transform: 'rotate(' + rotate + 'deg)' }" class="fill"></div>
							</div>

							<div class="mask half">
								<div [ngStyle]="{ transform: 'rotate(' + rotate + 'deg)' }" class="fill"></div>
							</div>

							<div class="inside-circle">
								{{ time }}
								<!-- 00:<span class="sec">{{sec}}</span> -->
							</div>
						</div>
					</div>
				</div>
				<p class="timeoutBoxTimerTitle">{{ subText }}</p>
			</div>

			<div class="timeoutBoxButtonContainer">
				<div class="timeoutBoxButtons">
					<button
						type="button"
						class="btn btn-outline-dark yesBtn me-2 mr-3"
						(click)="redirectToHomePage()"
					>
						{{ btnText }}
					</button>
				</div>
			</div>
		</div>
	</div>
	<!-- <div class="timerBoxTextContainer"> -->
	<div
		class="timerBoxText"
		[ngClass]="
			bgColor == 'red' ? 'bg-bar-red' : bgColor == 'orange' ? 'bg-bar-orange' : 'bg-bar-green'
		"
	>
		<span class="timerBoxTextContent">Time Remaining&nbsp;</span>
		<span class="time">{{ time }}</span>
	</div>
	<!-- </div> -->
</div>
