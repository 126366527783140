import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { SharedService } from 'src/app/services/shared.service';

@Component({
	selector: 'app-offers',
	templateUrl: './offers.component.html',
	styleUrls: ['./offers.component.css'],
	encapsulation: ViewEncapsulation.None,
})
export class OffersComponent implements OnInit {
	@Input() isOfferOpen: boolean = true;
	@Input() cardOfferCount: number = 0;
	@Input() data_mode: string = null;
	isOfferApplicable: boolean = false;
	paymentModesWithOffers;
	selectedOffers;
	isCardOffer;
	constructor(private sharedService: SharedService) {
		this.paymentModesWithOffers = this.sharedService.getupdatedOffersDetails();
	}

	ngOnInit(): void {
		let cardOffer = [];
		let otherOffer = [];
		this.paymentModesWithOffers.map((item) => {
			if (item.payment_mode === this.data_mode) {
				this.isOfferApplicable = true;
			}else if(item.payment_mode.includes('Card')){
				this.isOfferApplicable = true;
			}

			//if there are any offer available for the given mode then add it to an array
			if (item.payment_mode.includes('Card')) {
				cardOffer.push(...item.offers);
			} else {
				otherOffer.push(...item.offers);
			}
		});

		const offerSet = new Set();
		const uniqueOffers = cardOffer.filter((item) => {
			const duplicate = offerSet.has(item.offer_id);
			offerSet.add(item.offer_id);
			return !duplicate;
		});

    uniqueOffers.map(item => item.offer_id);

		if (this.data_mode.includes('Card')) {
			this.selectedOffers = uniqueOffers;
			this.isCardOffer = true;
		} else {
			this.selectedOffers = otherOffer;
			this.isCardOffer = false;
		}

		setTimeout(() => {
			this.handleCardAccordion();
		}, 100); 
	}

	handleCardAccordion = () => {
		let offersCard = document.getElementById('offersHeading');

		if (offersCard) {
			offersCard.addEventListener('click', () => {
				this.isOfferOpen = !this.isOfferOpen;
			});
		}
	}
}
