import { Component, OnInit, HostListener } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { orderDetails, merchantDetails } from 'src/app/app.models';
import { ApiService } from 'src/app/services/api.service';
import { PaymentModeService } from 'src/app/services/payment-mode.service';
import { OrderDetailService } from 'src/app/services/order-detail.service';
import { SharedService } from 'src/app/services/shared.service';
import { updateCheckoutId } from '../../utils/checkoutId';
import { getCheckoutId } from '../../utils/checkoutId';

import tracking from '../../utils/tracking';

@Component({
  selector: 'app-user-account',
  templateUrl: './user-account.component.html',
  styleUrls: ['./user-account.component.css']
})
export class UserAccountComponent implements OnInit {
  order_details: orderDetails;
  merchant_details: merchantDetails;
  Users;
  currentUser
  constructor(private router: Router, private cookies: CookieService, private sharedOrderDetails: OrderDetailService, private apiService: ApiService,private sharedService:SharedService, private activeRoute: ActivatedRoute) { 


    this.sharedOrderDetails.sharedOrderDetails.subscribe(order_data=>{
      this.order_details=JSON.parse(JSON.stringify(order_data));
    })

  }

  ngOnInit(): void {
    // console.log("Component: user account component")
    this.order_details = JSON.parse(JSON.stringify(this.sharedOrderDetails.getOrderDetails()));
    this.merchant_details = JSON.parse(JSON.stringify(this.sharedOrderDetails.getMerchantDetail()));
    
    this.Users = [];
    tracking.openPage({
      page_name: 'User Account',
      order_details: this.order_details,
      merchant_details: this.merchant_details
    })
    if(!this.order_details['user'] && !this.cookies.get('no_refresh') && this.cookies.get('is_popup') !== 'true' && this.cookies.get('pagerefresh')) { // to handle refresh checkout. here, `no_refresh` cookie is for CC/DC
      this.activeRoute.queryParams.subscribe(params => {
        if(params['order_id']) {
          this.cookies.delete('pagerefresh', '/');
          this.cookies.delete('no_refresh', '/');
          this.cookies.delete('pagerefresh', '/payment'); //#TODO: rectify it. currently in preprod same cookie store in two diff path `/` and `/payment`
          this.cookies.delete('no_refresh', '/payment'); //#TODO: rectify it. currently in preprod same cookie store in two diff path `/` and `/payment`
          let options = { order_id: params['order_id']  };
          let transaction_token = params['transaction_token'];
          if(transaction_token) {
            options['transaction_token'] = transaction_token;
          }
          else {
            options['expires'] = this.sharedService.getTokenExpiryTime();
          }
          this.router.navigate(['/'], { queryParams: options });
        }
      });
    }
    this.currentUser = this.order_details['user']['mobile_number'];
    ///fetching user on device on the basis of api
    this.apiService.fetchUserOnDevice().subscribe(res => {
      let usersList=[];
      usersList=JSON.parse(JSON.stringify(res));
      
      usersList.forEach(element => {
        let chars = element['mobile_number'].split('');
        if (element['email']) {
          let email = element['email'].split('');
          for (let index = 2; index < 8; index++) {

            chars[index] = 'x';
          }
          let maskedMobile = chars?.join('');
          let data = {
            name: element['first_name'],
            email: element['email'],
            mobile: element['mobile_number'],
            maskedMobile: maskedMobile,
            maskedEmail: this.censorEmail(element['email']),
            user_id:element['id'],
            initial: element['first_name'].charAt(0)
          }
          this.Users.push(data);
        }
      });
    })

  }
  useAnotherAcccount() {
    this.sharedService.updateuseAnotherAccount('yes');
    this.sharedService.updateFastPaymentViewMore(false);
    // this.cookies.set('useAnotherAccount', 'yes', 365, "", "", true, "None");
    let options = { order_id: this.order_details['order_id']  };
    let transaction_token = this.activeRoute.snapshot.queryParams['transaction_token'];
    if(transaction_token) {
      options['transaction_token'] = transaction_token;
    }
    else {
      options['expires'] = this.sharedService.getTokenExpiryTime();
    }
    this.router.navigate(['/'], { queryParams: options });
  }
  censorWord(str) {
    return str[0] + "*".repeat(str.length - 2) + str.slice(-1);
  }

  censorEmail(email) {
    var arr = email.split("@");
    return this.censorWord(arr[0]) + "@" + this.censorWord(arr[1]);
  }
  useExistingAccount(index) {
    // this.sharedPaymentservice.updateAllowedPaymentTypeArray([])
    this.updateUserOnOrder(this.Users[index]['user_id']);
    this.sharedService.updateaccountSwitched('yes');
  }

  ngAfterViewInit(): void {
    tracking.renderPage({
      page_name: 'User Account',
      order_details: this.order_details,
      merchant_details: this.merchant_details
    })
  }
  updateUserOnOrder(user_id) {
    let data = {
      user_id: user_id,
      order_id: this.order_details['order_id']
    }
    let validateToken = this.apiService.checkForValidToken();
    if (!validateToken) {
      this.apiService.getEncryptedAuthorizationToken(this.order_details['order_id']).subscribe(resp => {
        this.apiService.setTokenCookies(resp['token'], btoa(resp['expires_at']));
        this.apiService.updateUserOnOrder(data).subscribe(res => {
          res['checkout_id'] = getCheckoutId(data.order_id);
          this.sharedOrderDetails.updateOrderDetails(res);
          let options = { order_id: this.order_details['order_id']  };
          let transaction_token = this.activeRoute.snapshot.queryParams['transaction_token'];
          if(transaction_token) {
            options['transaction_token'] = transaction_token;
          }
          else {
            options['expires'] = this.sharedService.getTokenExpiryTime();
          }
          this.router.navigate(['/'], { queryParams: options });
        }, error => {
        });
      });
    }
    else {
      this.apiService.updateUserOnOrder(data).subscribe(res => {
        res['checkout_id'] = getCheckoutId(data.order_id);
        this.sharedOrderDetails.updateOrderDetails(res);
        let options = { order_id: this.order_details['order_id']  };
        let transaction_token = this.activeRoute.snapshot.queryParams['transaction_token'];
        if(transaction_token) {
          options['transaction_token'] = transaction_token;
        }
        else {
          options['expires'] = this.sharedService.getTokenExpiryTime();
        }
        this.router.navigate(['/'], { queryParams: options });

      }, error => {
      });
    }

  }

  // to show built in customization dialog on page refresh
  @HostListener('window:beforeunload')
  beforeUnloadHandler() {

    if(!this.cookies.get('no_refresh')) {
      updateCheckoutId(this.order_details["order_id"])
    }

    if(this.cookies.get('is_popup') === 'false' && !this.cookies.get('no_refresh')) {
      this.cookies.set( 'pagerefresh', 'true', undefined, '/' );
      return false;
    }
  }
}
