import { Component, NgZone, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import {
	allowedPaymentPartnersRequest,
	merchantDetails,
	orderDetails,
	resolveUserRequest,
	token,
	user,
	initPaymentRequest,
} from 'src/app/app.models';
import { ApiService } from 'src/app/services/api.service';
import { OrderDetailService } from 'src/app/services/order-detail.service';
import { SharedService } from 'src/app/services/shared.service';
import { CommonService } from 'src/app/services/common.service';
import { PaymentModeService } from 'src/app/services/payment-mode.service';
// import { throwError, timer } from 'rxjs';

import { isMobile } from '../../utils/device.js';
import tracking, { renderCheckoutEntries } from '../../utils/tracking';
import { getCheckoutId } from '../../utils/checkoutId';
import { hasMaxRetriesReached } from 'src/app/utils/index.js';

@Component({
	selector: 'app-home',
	templateUrl: './home.component.html',
	styleUrls: ['./home.component.css'],
})
export class HomeComponent implements OnInit {
	nimbbl_user_mobile: string;
	nimbbl_device_verified: boolean = false;
	tokenRes = token;
	user: user[];
	userFounOnDevice: boolean = false;
	order_details: orderDetails;
	showLoader: boolean = false;
	showErrorScreen: boolean = false;
	errorMessageText: string = '';
	errorStatusCode: number;
	render: boolean = false;
	isOrder_line_item: boolean = false;
	orderDetail: orderDetails;
	order_id;
	merchant_details: merchantDetails;
	fingerPrint;
	showCountryList: boolean = false;
	isAPiInvoked: boolean = false;
	errorMessage = ''; // for error handling
	next_step;
	pa;
	pn;
	tn;
	mc;
	tr;
	url;
	cu;
	gpayRequest: any;
	showGpayButton;
	canMakePaymentCache = 'canMakePaymentCache';
	amount;
	isAllModesLoaded: boolean = false;
	authToken = undefined;
	showMobileLoader = false;
	isEnforcedPaymentModeFlag : boolean = false;
	hasSubPaymentModeFlag: boolean = false;
	isPaymentSuccess: boolean = false;

	constructor(
		private sharedPaymentservice: PaymentModeService,
		public apiService: ApiService,
		public router: Router,
		public activateRoute: ActivatedRoute,
		public orderService: OrderDetailService,
		private cookies: CookieService,
		private sharedService: SharedService,
		private commonService: CommonService,
	) {
		this.showLoader = true;
	}

	ngOnInit(): void {
		this.activateRoute.queryParams.subscribe((params) => {
			this.order_id = params['order_id'];
		});
		console.log(this.sharedPaymentservice.getFastPaymentMode(), 'From Home');
		
		this.sharedService.sharedHasMaxRetriesExpiredSubject.subscribe((responseRetriesAllowed) => {
			if (responseRetriesAllowed) {
				return false;
			}
		})
		
		let check_generateToken = setInterval(() => {
			this.merchant_details = JSON.parse(JSON.stringify(this.orderService.getMerchantDetail()));
			this.fingerPrint = this.sharedService.getFingerPrint();
			let tokenDetail = this.sharedService.getTokenDetails();
			if (tokenDetail['accessToken'] && this.fingerPrint) {
				clearInterval(check_generateToken);
				// console.log('calling from home');
				let validateToken = this.apiService.checkForValidToken();
				if (!validateToken) {
					this.apiService.getEncryptedAuthorizationToken(this.order_id).subscribe((resp) => {
						this.apiService.setTokenCookies(resp['token'], btoa(resp['expires_at']));
						this.apiService.fetchOrderById(this.order_id).subscribe(
							(res) => {
								if (res['user']) {
									this.user = res['user'];
								}
								tracking.openPage({
									page_name: 'Home',
									order_details: res,
									merchant_details: this.merchant_details,
								});
								res['checkout_id'] = getCheckoutId(this.order_id);
								this.orderService.updateOrderDetails(res);
							
								if (res['currency']) {
									this.sharedService.updateCurrencyFromAPI(res['currency']);
								}
								
								if (res['order_line_item'].length) {
									this.isOrder_line_item = true;
								} else {
									this.isOrder_line_item = false;
								}

								this.orderService.updateOrderDetails(res);

								if (
									this.sharedService.getaccountSwitched() != 'yes' &&
									this.sharedService.getuseAnotherAccount() != 'yes'
								) {
									setTimeout(() => {
										tracking.renderCheckout({
											order_details: res,
											merchant_details: this.merchant_details,
											step_name: renderCheckoutEntries[window.location.pathname],
										});

										let data = {
											order_id: res['order_id'],
											order_level: 3,
											checkout_id: res['checkout_id'],
										};
										// console.log(data);
										if (res['status'] !== 'completed') {
											this.apiService.updateOrderLevel(data).subscribe(
												(res) => {
													// console.log(res);
												},
												(error) => {
													// console.log(error);
												}
											);
										}
									}, 4000);
								}

								if (
									this.sharedService.getaccountSwitched() != 'yes' &&
									this.sharedService.getuseAnotherAccount() != 'yes'
								) {
									if (this.user) {
										this.nimbbl_user_mobile = this.user['mobile_number'];
										this.resolveuserWithFingerPrint(this.nimbbl_user_mobile);
									} else {
										this.showLoader = false;
									}
								} else if (
									this.sharedService.getaccountSwitched() == 'yes' &&
									this.sharedService.getuseAnotherAccount() != 'yes'
								) {
									if (this.user) {
										this.nimbbl_user_mobile = this.user['mobile_number'];
										this.resolveuserWithFingerPrint(this.nimbbl_user_mobile);
									} else {
										this.showLoader = false;
									}
								} else if (this.sharedService.getuseAnotherAccount() == 'yes') {
									this.nimbbl_user_mobile = null;
									this.showLoader = false;
								}
								// this.resolveUser(this.nimbbl_user_mobile, true);

								if (this.sharedService.getuseAnotherAccount() == 'yes') {
									document.querySelector('.top_bg').classList.add('useAnotherAccount');
								} else {
									document.querySelector('.top_bg').classList.add('mobile');
								}
								this.sharedService.updateuseAnotherAccount('no');
								// this.cookies.delete('useAnotherAccount');
								// this.cookies.delete('accountSwitched');
								this.sharedService.updateaccountSwitched('no');
								this.merchant_details = JSON.parse(
									JSON.stringify(this.orderService.getMerchantDetail())
								);
								tracking.renderPage({
									page_name: 'Home',
									order_details: this.orderDetail,
									merchant_details: this.merchant_details,
								});
							},
							(error) => {
								// console.log(error);
							}
						);
					});
				} else {
					this.apiService.fetchOrderById(this.order_id).subscribe(
						(res) => {
							if (res['user']) {
								this.user = res['user'];
							}
							res['checkout_id'] = getCheckoutId(this.order_id);

							this.orderService.updateOrderDetails(res);

							tracking.openPage({
								page_name: 'Home',
								order_details: res,
								merchant_details: this.merchant_details,
							});
							
							if (res['currency']) {
								this.sharedService.updateCurrencyFromAPI(res['currency']);
							}
							
							if (res['order_line_item'].length) {
								this.isOrder_line_item = true;
							} else {
								this.isOrder_line_item = false;
							}
							// console.log(this.isOrder_line_item);
							// this.cookies.set('sub_merchant_id', res['sub_merchant_id'], 365, "", "", true, "None");

							if (
								this.sharedService.getaccountSwitched() != 'yes' &&
								this.sharedService.getuseAnotherAccount() != 'yes'
							) {
								setTimeout(() => {
									tracking.renderCheckout({
										order_details: res,
										merchant_details: this.merchant_details,
										step_name: renderCheckoutEntries[window.location.pathname],
									});

									let data = {
										order_id: res['order_id'],
										order_level: 3,
										checkout_id: res['checkout_id'],
									};
									// console.log(data);
									if (res['status'] !== 'completed') {
										this.apiService.updateOrderLevel(data).subscribe(
											(res) => {
												// console.log(res);
											},
											(error) => {
												// console.log(error);
											}
										);
									}
								}, 4000);
							}

							if (
								this.sharedService.getaccountSwitched() != 'yes' &&
								this.sharedService.getuseAnotherAccount() != 'yes'
							) {
								if (this.user) {
									this.nimbbl_user_mobile = this.user['mobile_number'];
									this.resolveuserWithFingerPrint(this.nimbbl_user_mobile);
								} else {
									this.showLoader = false;
								}
							} else if (
								this.sharedService.getaccountSwitched() == 'yes' &&
								this.sharedService.getuseAnotherAccount() != 'yes'
							) {
								if (this.user) {
									this.nimbbl_user_mobile = this.user['mobile_number'];
									this.resolveuserWithFingerPrint(this.nimbbl_user_mobile);
								} else {
									this.showLoader = false;
								}
							} else if (this.sharedService.getuseAnotherAccount() == 'yes') {
								this.nimbbl_user_mobile = null;
								this.showLoader = false;
							}
							// this.resolveUser(this.nimbbl_user_mobile, true);
							setTimeout(() => {
								if (this.sharedService.getuseAnotherAccount() == 'yes') {
									document.querySelector('.top_bg').classList.add('useAnotherAccount');
								} else {
									document.querySelector('.top_bg').classList.add('mobile');
								}
								this.merchant_details = JSON.parse(
									JSON.stringify(this.orderService.getMerchantDetail())
								);
								tracking.renderPage({
									page_name: 'Home',
									order_details: res,
									merchant_details: this.merchant_details,
								});
							}, 500);

							setTimeout(() => {
								// this.showLoader = false;
							}, 1000);
						},
						(error) => {
							// console.log(error);
						}
					);
				}
			}else {
				const getError: any = this.sharedService.getUpdatedErrorDetails();
				// console.log(getError['status']);
				
				if(getError?.status >= 400){
					clearInterval(check_generateToken);
					this.showLoader = false;
					this.showErrorScreen = true;
					this.errorMessageText = getError?.error
						? getError?.error?.error?.message
						: getError?.message;
					this.errorStatusCode = getError?.error
						? getError?.error?.error?.code
						: getError?.status; 

					// 	throw Error(`${getError?.error?.error?.code} : ${getError?.error?.error?.message}`)//Error(`${getError.name}: ${getError.message}`); 
					throw Error(`${getError.name} : ${getError.message}`);
				}

				
			}
		}, 100);
	}
	updateUserOnOrder(user_id) {
		let GpayTimeout = 0;
		if (this.showGpayButton != undefined) {
			GpayTimeout = 0;
		} else {
			GpayTimeout = 10000;
		}
		let data = {
			user_id: user_id,
			order_id: this.order_id,
		};
		this.orderDetail = JSON.parse(JSON.stringify(this.orderService.getOrderDetails()));
		// let validateToken = this.apiService.checkForValidToken();

		this.apiService.updateUserOnOrder(data).subscribe(
			(res) => {
				res['checkout_id'] = getCheckoutId(this.order_id);
				this.orderService.updateOrderDetails(res);

				if(res['status'] === "completed") {
					let expTime = this.sharedService.getTokenExpiryTime();
					let options = { 
						order_id: this.order_id,
						expires: expTime
					};
					this.router.navigate(['payment'], { queryParams: options });
					if(hasMaxRetriesReached(res)) {
						this.sharedService.setHasMaxRetriesExpiredSubject(true);
					}
					return;
				}


				if (
					this.sharedService.getaccountSwitched() == 'yes' ||
					this.sharedService.getuseAnotherAccount() == 'yes'
				) {
					setTimeout(() => {
						this.sharedService.updateuseAnotherAccount('no');
						// this.cookies.delete('useAnotherAccount');
						// this.cookies.delete('accountSwitched');
						this.sharedService.updateaccountSwitched('no');
						// this.showLoader = false;
					}, 0);
				}
				if (this.next_step == 'otp_validation') {
					setTimeout(() => {
						document.querySelector('.top_bg').classList.remove('mobile');
					}, 0);
					setTimeout(() => {
						//tracking code for identify with the user id
						this.checkoutRendered();
						/* window.analytics.identify(this.user['user_id'], {
							user_id: this.user['user_id'],
							name: this.user['first_name'],
							email: this.user['email'],
							mobile: this.user['mobile'],
						}); */

						tracking.recogniseUser({
							user: this.user,
							order_details: this.orderDetail,
							merchant_details: this.merchant_details,
						});
						// console.log('redirecting to user verify OTP');

						this.closeCountryCodeList();
						// this.showLoader=false;
						let options = { order_id: this.order_id };
						let transaction_token = this.activateRoute.snapshot.queryParams['transaction_token'];
						if (transaction_token) {
							options['transaction_token'] = transaction_token;
						} else {
							options['expires'] = this.sharedService.getTokenExpiryTime();
						}
						this.router.navigate(['verify-otp'], { queryParams: options });
					}, 1000);
				} else if (this.next_step == 'payment_mode') {
					this.commonService.setPaymentModes(true, () => {
						setTimeout(() => {
							// console.log('redirecting to user Payment');
							//tracking code for identify with the user id
							this.checkoutRendered();
							/* window.analytics.identify(this.user['user_id']); */

							tracking.recogniseUser(
								{
									user: this.user,
									order_details: this.orderDetail,
									merchant_details: this.merchant_details,
								},
								false
							);

							// this.closeCountryCodeList();
							// this.showLoader=false;
							let options = { order_id: this.order_id };
							let transaction_token = this.activateRoute.snapshot.queryParams['transaction_token'];
							let payment_mode = this.activateRoute.snapshot.queryParams['payment_mode'];
							let bank_name = this.activateRoute.snapshot.queryParams['bank_code'];
							let wallet_name = this.activateRoute.snapshot.queryParams['wallet_code'];
							let payment_flow = this.activateRoute.snapshot.queryParams['payment_flow'];
							let upi_id = this.activateRoute.snapshot.queryParams['upi_id'];
							let upi_app_code = this.activateRoute.snapshot.queryParams['upi_app_code'];
							 
							//* Need to subscribe access key here  */
							let expTime = this.sharedService.getTokenExpiryTime();

							if (transaction_token) {
								options['transaction_token'] = transaction_token;
							} else {
								options['expires'] = expTime;
								if(payment_mode){
									this.sharedService.updateEnforcePaymentDetails(true);
									options['payment_mode'] = payment_mode; //Netbanking / Wallet / UPI
									if(bank_name){
										this.sharedService.updateEnforceSubPaymentModeDetails(true);
										options['bank_code'] = bank_name; //State Bank of India
									}else if(wallet_name){
										this.sharedService.updateEnforceSubPaymentModeDetails(true);
										options['wallet_code'] = wallet_name; ///Airtel Money
									}else if(payment_flow){
										if(payment_flow === 'collect'){
											options['payment_flow'] = 'collect';
											upi_id ? options['upi_id'] = upi_id : null;
										}else if(payment_flow === 'intent'){
											options['payment_flow'] = 'intent';
											upi_app_code ? options['upi_app_code'] = upi_app_code : null;
										}
									}
								}
								
							}
						
							
							this.sharedService.sharedEnforcePaymentDetails.subscribe(res => {
								this.isEnforcedPaymentModeFlag = res
							})

							this.sharedService.sharedEnforceSubPaymentModeDetails.subscribe(res => {
								this.hasSubPaymentModeFlag = res
							})

							let urlParams = this.isEnforcedPaymentModeFlag ? 'payment/netbanking' : 'payment';

							console.log(urlParams, options);
							

							this.router.navigate([urlParams], { queryParams: options });
						}, 1000);
					});
				} else if (this.next_step == 'update_details') {
					/* window.analytics.identify(this.user['user_id'], {
						source: this.merchant_details['display_name'],
						type: 'consumer',
					}); */
					tracking.registerUser({
						order_details: this.orderDetail,
						merchant_details: this.merchant_details,
					});
					this.closeCountryCodeList();
					this.checkoutRendered();
					this.showLoader = false;
					let options = { order_id: this.order_id };
					let transaction_token = this.activateRoute.snapshot.queryParams['transaction_token'];
					if (transaction_token) {
						options['transaction_token'] = transaction_token;
					} else {
						options['expires'] = this.sharedService.getTokenExpiryTime();
					}
					this.router.navigate(['user-detail'], { queryParams: options });
				}
			},
			(error) => {
				// console.log(error);
			}
		);
	}

	resolveuserWithFingerPrint(mobileNumber) {
		this.showMobileLoader = true;
		let rr = new resolveUserRequest();
		rr.mobile_number = mobileNumber;
		rr.country_code = JSON.parse(JSON.stringify(this.sharedService.getauserCountry()))[
			'dailerCode'
		];
		let interval = setInterval(() => {
			this.order_details = JSON.parse(JSON.stringify(this.orderService.getOrderDetails()));

			if (this.order_details['order_id']) {
				rr.order_id = this.order_details['order_id'];
				clearInterval(interval);
				// console.log(this.order_details['user']);
				this.apiService.resolveUser(rr).subscribe(
					(res) => {
						this.user = res['item'];
						this.sharedService.updateOtpStatus(res['otp_sent']);
						this.next_step = res['next_step'];
						// if(res['next_step']!='update_details')
						// {}
						if (res['item']['token']) {
							this.sharedService.updateAuthToken(res['item']['token']);
							this.authToken = res['item']['token'];
						}
						if (res['item']['token']) {
							this.sharedService.updateAuthToken(res['item']['token']);
						}
						this.sharedService.updateUserCountryFromAPI(res['item']['country_code']);
						setTimeout(() => {
							this.updateUserOnOrder(res['item']['id']);
						}, 700);
					},
					(error) => {
						this.showMobileLoader = false;
						console.error('error caught in component');
						this.errorMessage = error.statusText;
						//  console.log(this.errorMessage);
					}
				);
			}
		}, 1);
	}
	displsyCountryList() {
		if (this.showCountryList) {
			this.closeCountryCodeList();
		} else {
			this.showCountryList = true;
			document.querySelector('.top_bg').classList.remove('useAnotherAccount');
			document.querySelector('.top_bg').classList.add('countryCode');
		}
	}
	closeCountryCodeList() {
		this.showCountryList = false;
		document.querySelector('.top_bg').classList.add('useAnotherAccount');
		document.querySelector('.top_bg').classList.remove('countryCode');
	}
	// googlepay can make payment
	readSupportedInstruments() {
		let formValue = {};
		formValue['pa'] = this.pa;
		formValue['pn'] = this.pn == '' ? 'logicloop' : this.pn;
		formValue['tn'] = this.tn;
		formValue['mc'] = this.mc;
		formValue['tr'] = this.tr;
		formValue['url'] = this.url;
		return formValue;
	}

	onBuyClicked() {
		if (!window.PaymentRequest || !isMobile()) {
			//Web payments are not supported in this browser.
			this.showGpayButton = false;
			this.sharedPaymentservice.updateGpayVisibility(this.showGpayButton);
			return;
		}

		let formValue = this.readSupportedInstruments();
		// console.log(formValue);
		const supportedInstruments = [
			{
				supportedMethods: ['https://tez.google.com/pay'],
				data: formValue,
			},
		];
		// console.log(supportedInstruments);
		const details = {
			total: {
				label: 'Total',
				amount: {
					currency: this.cu,
					value: this.amount,
				},
			},
			displayItems: [
				{
					label: 'Original amount',
					amount: {
						currency: this.cu,
						value: this.amount,
					},
				},
			],
		};

		let request = null;
		try {
			request = new PaymentRequest(supportedInstruments, details);
		} catch (e) {
			// console.log('Payment Request Error: ' + e.message);
			return;
		}
		if (!request) {
			// console.log('Web payments are not supported in this browser.');
			return;
		}

		var canMakePaymentPromise = this.checkCanMakePayment(request);
		canMakePaymentPromise
			.then((result) => {
				this.showPaymentUI(request, result);
			})
			.catch((err) => {
				// console.log(err);
				// throwError(err);
			});
	}

	showPaymentUI(request, canMakePayment) {
		this.gpayRequest = request;
		// console.log(request);
		// Redirect to play store if can't make payment.
		if (!canMakePayment) {
			this.showGpayButton = false;
			this.sharedPaymentservice.updateGpayVisibility(this.showGpayButton);

			// return;
		} else {
			this.showGpayButton = true;
			this.sharedPaymentservice.updateGpayVisibility(this.showGpayButton);
			// setTimeout(() => {
			//   this.showLoader = false;
			//   this.isAllModesLoaded = true;
			//   let collapseOne = document.getElementById('collapseOneBtn');
			//   let collapseCardOne = document.getElementById(collapseOne.getAttribute('data-target').replace('#', ''));
			//   if (collapseCardOne.classList.contains('show')) {
			//     collapseOne.click();

			//   }

			// }, 1000);
		}

		tracking.receiveCanMakePayment({
			order_details: this.order_details,
			merchant_details: this.merchant_details,
			status: this.showGpayButton,
		});
	}

	checkCanMakePayment(request) {
		// Checks canMakePayment cache, and use the cache result if it exists.
		if (sessionStorage.hasOwnProperty(this.canMakePaymentCache)) {
			return Promise.resolve(JSON.parse(sessionStorage[this.canMakePaymentCache]));
		}

		// If canMakePayment() isn't available, default to assuming that the method is
		// supported.
		var canMakePaymentPromise = Promise.resolve(true);

		// Feature detect canMakePayment().
		if (request.canMakePayment) {
			canMakePaymentPromise = request.canMakePayment();
		}

		return canMakePaymentPromise
			.then((result) => {
				// Store the result in cache for future usage.
				sessionStorage[this.canMakePaymentCache] = result;
				return result;
			})
			.catch((err) => {
				// console.log('Error calling canMakePayment: ' + err);
			});
	}
	checkoutRendered() {
		// console.log()
		// this.cookies.set('accessToken', resp['token'], 365, '', '', true, "None");
		// this.cookies.set('token_expiry', btoa(resp['expires_at']), 365, '', '', true, 'None');
		// console.log('fetchOrderById');
		let OrderDetailsInterval = setInterval(() => {
			this.order_details = JSON.parse(JSON.stringify(this.orderService.getOrderDetails()));

			if (this.order_details['order_id']) {
				// console.log('render');
				clearInterval(OrderDetailsInterval);
				// this.merchant_details = JSON.parse(JSON.stringify(this.orderService.getMerchantDetail()));

				/* if (this.order_details['user']) {
					window.analytics.identify({
						mobile: this.order_details['user']['mobile_number'],
					});
				} */
			}
		}, 10);
	}
}
