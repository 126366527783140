import { Component, ElementRef, Input, NgZone, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import {
	initPaymentRequest,
	makePaymentRequest,
	paymentStatusPollingRequest,
	razorPayCCDCRequest,
	payuCcDcRequest,
	user,
	lazypayResednOtpRequest,
	orderDetails,
	merchantDetails,
} from 'src/app/app.models';
import { ApiService } from 'src/app/services/api.service';
import { CardDetailService } from 'src/app/services/card-detail.service';
import { OrderDetailService } from 'src/app/services/order-detail.service';
import { PaymentModeService } from 'src/app/services/payment-mode.service';
import { SharedBankingService } from 'src/app/services/shared-banking.service';
import { SharedService } from 'src/app/services/shared.service';
import { CommonService } from 'src/app/services/common.service';
import { getCheckoutId } from '../../utils/checkoutId';
import { hasMaxRetriesReached, isObjectEmpty } from 'src/app/utils';

declare var Razorpay: any;
declare var CashFree: any;
import tracking from '../../utils/tracking';

import { isMobile } from '../../utils/device.js';
import { environment } from 'src/environments/environment';
import { hasTouchSupport } from 'src/app/utils';
import { ModalService } from 'src/app/services/modal.service';

@Component({
	selector: 'app-payment',
	templateUrl: './payment.component.html',
	styleUrls: ['./payment.component.css'],
})
export class PaymentComponent implements OnInit {
	allowedPaymentTypeArray;
	autoFlow;
	canMakePaymentCache = 'canMakePaymentCache';
	nimbbl_user_name: string;
	@ViewChild('payuForm') payuRequestForm: ElementRef;
	amount;
	pa;
	pn;
	tn;
	mc;
	tr;
	url;
	cu;
	intentpaymentModes;
	selectedIntentAppName;
	isAutoDebit;
	isPyamentInitiate = false;
	isPyamentSucess: boolean = false;
	isPaymentClicked: boolean = false;
	isPyamentInitiateWithOtp: boolean = false;
	isAutodebitFlow: boolean = true;
	ispaymentFail: boolean = false;
	processing: boolean = false;
	transaction_details;
	paymentMethodDetails;
	transactionEnquiryPolling;
	payment_partner: string = null;
	razorPayCCDCRequest: razorPayCCDCRequest;
	payuCcDcReques: payuCcDcRequest;
	completionEta: any;
	razorpayPayload: any;
	razorpay: any;
	windowEvent;
	paymentErrorMessage: string = null;
	transaction_id;
	showLoader: boolean = false;
	paymentTimeOut = 4 * 60000;
	requestTimeOut = 0;
	is_window_close: boolean = false;
	fastPaymentModes = [];
	otherPaymentModes = [];
	gpayPartner;
	razr_key_id;
	isTransactionTimeout;
	showOrderSummary: boolean = true;
	nimbbl_signature: string = null;
	showTryAgainButton: boolean = false;
	UPIId: string = null;
	order_details: orderDetails;
	app_present: boolean;
	merchant_details: merchantDetails;
	supportedInstruments;
	nimbbl_user_mobile;
	isFastPaymentFound: boolean;
	savedVPAClicked: boolean = false;
	savedVpaId: string = '';
	savedVpaName: string = '';
	card_type: any;
	showGpayButton;
	bank_name;
	isEnforced: boolean;
	selectedCardlessData: {} = {};
	paymentResponse: any;
	showMaxRetriesReachedElement: boolean = false;
	isReactNative: boolean = false;
	isIntentAndDesktop: boolean = false;
	isIntentAndMobile: boolean = false;
	isUpiIntentAllowed: boolean = false;
	isUpiCollectAllowed: boolean = false;
	intentUrl: string = '';
	iframeSrc: string = '';
	isIosDevice: boolean = false;
	checkWindowEventClosedInterval;
	constructor(
		private apiService: ApiService,
		private sharedPaymentservice: PaymentModeService,
		private CardDetailService: CardDetailService,
		private sharedBankingService: SharedBankingService,
		private router: Router,
		private cookies: CookieService,
		private sharedOrderDetails: OrderDetailService,
		public activateRoute: ActivatedRoute,
		private orderService: OrderDetailService,
		private sharedService: SharedService,
		private commonService: CommonService,
		private ngzone: NgZone,
		private modalService: ModalService
	) {
		this.sharedService.sharedHasMaxRetriesExpiredSubject.subscribe((retriesExpired) => {
			if(retriesExpired) {
				this.showMaxRetriesReachedElement = retriesExpired;
			}
		});


		this.sharedPaymentservice.sharedSelectedMode.subscribe((res) => {
			if (res) {
				this.paymentMethodDetails = res;
				this.payment_partner = res['payment_partner'];
			}
		});

		this.sharedPaymentservice.sharedAllowedPaymentTypeArray.subscribe((res) => {
			if (res) {
				this.allowedPaymentTypeArray = res;
			}
		});

		this.sharedPaymentservice.sharedFastPaymentMode.subscribe((res) => {
			if (res) {
				this.fastPaymentModes = res;
				if (this.fastPaymentModes.length > 0) {
					this.isFastPaymentFound = true;
				} else {
					this.isFastPaymentFound = false;
				}
			}
		});

		this.sharedPaymentservice.sharedOtherPaymentMode.subscribe((res) => {
			if (res) {
				this.otherPaymentModes = res;
				const upiIndex = res.findIndex(mode => mode.mode === "UPI");
				if(upiIndex !== -1){
					this.isUpiIntentAllowed  = res.filter(item => item.mode === "UPI")[0]?.isIntentAllowed;

					this.isUpiCollectAllowed = res.filter(item => item.mode === "UPI")[0]?.isCollect

					console.log(this.isUpiIntentAllowed);
					console.log(this.isUpiCollectAllowed);
					
					
				}

			}
		});

		this.sharedService.shared_card_type.subscribe((resp) => {
			this.card_type = JSON.parse(JSON.stringify(resp));
		});
		this.sharedService.sharedintent.subscribe((res) => {
			this.intentpaymentModes = JSON.parse(JSON.stringify(res));
		});
	}

	ngOnInit(): void {
		window.nimbbl_web = window.nimbbl_web || {};
		window.nimbbl_web.nimbbl_sdk_status = this?.sdkErrorHandlingCallback.bind(this);

		this.sharedPaymentservice.sharedHandleRnSdkAppStatus.subscribe((res)=> {
			res && this.sdkStatushandling(res);
		});
		this.isReactNative = window?.nimbbl_webview?.isNative || false;

		let paymetnStatus;
		this.activateRoute.queryParams.subscribe((params) => {
			if (params['response']) {
				let arr;
				let paymentResponse = JSON.parse(atob(params['response']));
				paymetnStatus = paymentResponse['status'];
				this.paymentMethodDetails = [];

				if (paymentResponse['transaction']['payment_mode'] == 'Netbanking') {
					arr = {
						logo: 'assets/images/logos/bank.png',
						paymentMode: paymentResponse['transaction']['payment_mode'],
						payment_partner: paymentResponse['transaction']['payment_partner'],
					};

					let bankData = {
						bank_name: paymentResponse['transaction']['sub_payment_mode']['bank_name'],
					};
					this.sharedBankingService.updateBankDetails(bankData);
				} else if (paymentResponse['transaction']['payment_mode'] == 'Wallet') {
					arr = {
						logo: 'assets/images/logos/wallet.svg',
						paymentMode: paymentResponse['transaction']['payment_mode'],
						payment_partner: paymentResponse['transaction']['payment_partner'],
					};

					let walletData = {
						wallet_name: paymentResponse['transaction']['sub_payment_mode']['wallet_name'],
					};
					this.sharedBankingService.updateWallet(walletData);
				} else {
					arr = {
						logo: 'assets/images/logos/credit-card.png',
						paymentMode: paymentResponse['transaction']['payment_mode'],
						payment_partner: paymentResponse['transaction']['payment_partner'],
					};
				}
				this.sharedPaymentservice.updateSelectedMode(arr);
				let validateToken = this.apiService.checkForValidToken();
				if (!validateToken) {
					this.apiService
						.getEncryptedAuthorizationToken(this.order_details['order_id'])
						.subscribe((resp) => {
							this.apiService.setTokenCookies(resp['token'], btoa(resp['expires_at']));
							this.fetchOrderDetails(this.order_details['order_id']);
							this.card_type = paymentResponse?.transaction ? 
								paymentResponse['transaction']['payment_mode'] == 'Credit Card' ||
								paymentResponse['transaction']['payment_mode'] == 'Debit Card' ||
								paymentResponse['transaction']['payment_mode'] == 'Prepaid Card'
									? paymentResponse['transaction']['payment_mode']
									: null : null;
							this.FetchTransactionEnquiry(
								paymentResponse['transaction']['payment_mode'],
								paymentResponse['nimbbl_transaction_id']
							);
						});
				} else {
					let fetchOrderDetailsParams = this.order_details ? this.order_details['order_id'] : paymentResponse.nimbbl_order_id;
					this.fetchOrderDetails(fetchOrderDetailsParams);
					this.card_type = paymentResponse?.transaction ? 
						paymentResponse['transaction']['payment_mode'] == 'Credit Card' ||
						paymentResponse['transaction']['payment_mode'] == 'Debit Card' ||
						paymentResponse['transaction']['payment_mode'] == 'Prepaid Card'
							? paymentResponse['transaction']['payment_mode']
							: null : null;
					this.FetchTransactionEnquiry(
						paymentResponse['transaction']['payment_mode'],
						paymentResponse['nimbbl_transaction_id']
					);
				}
			}
		});

		if (this.sharedService.getuseAnotherAccount() == 'yes') {
			this.sharedService.updateuseAnotherAccount('no');
		}
		setTimeout(() => {
			!this.isEnforced && document.querySelector('.top_bg').classList.add('payment');
			// tracking.renderPage({
			// 	page_name: 'Payment',
			// 	order_details: this.order_details,
			// 	merchant_details: this.merchant_details,
			// });
	
			let data = {
				order_id: this.order_details['order_id'],
				order_level: 4,
				checkout_id: this.order_details['checkout_id'] || getCheckoutId(this.order_details['order_id']),
			};
			if (!isObjectEmpty(this.order_details) && this.order_details.status !== 'completed') {
				this.apiService.updateOrderLevel(data).subscribe(
					(res) => {},
					(error) => {
						// throw Error(error.message);
					}
				);
			}
		}, 900);

		let order_detailInterval = setInterval(() => {
			this.order_details = JSON.parse(JSON.stringify(this.sharedOrderDetails.getOrderDetails()));
			if (this.order_details['order_id']) {
				clearInterval(order_detailInterval);
				this.merchant_details = JSON.parse(
					JSON.stringify(this.sharedOrderDetails.getMerchantDetail())
				);
				// tracking.openPage({
				// 	page_name: 'Payment',
				// 	order_details: this.order_details,
				// 	merchant_details: this.merchant_details,
				// });
				this.nimbbl_user_name = this.order_details['user']['first_name'];
				this.nimbbl_user_mobile = this.order_details['user']['mobile_number'];
				let chars = this.nimbbl_user_mobile.split('');
				for (let index = 2; index < 8; index++) {
					chars[index] = 'x';
				}
				this.nimbbl_user_mobile = chars?.join('');
			}
		}, 10);
		let interval = setInterval(() => {
			this.order_details = JSON.parse(JSON.stringify(this.sharedOrderDetails.getOrderDetails()));
			if (this.order_details['user']) {
				clearInterval(interval);
				this.nimbbl_user_name = this.order_details['user']['first_name'];
				this.nimbbl_user_mobile = this.order_details['user']['mobile_number'];

				let chars = this.nimbbl_user_mobile.split('');
				for (let index = 2; index < 8; index++) {
					chars[index] = 'x';
				}
				this.nimbbl_user_mobile = chars?.join('');
				this.merchant_details = JSON.parse(
					JSON.stringify(this.sharedOrderDetails.getMerchantDetail())
				);
				if (paymetnStatus != 'success' || paymetnStatus != 'Success') {
					this.loadPaymentOptions();
				}
			}
		}, 1);

		this.isEnforced = this.sharedService.getEnforcePaymentDetails()
		console.log(this.isEnforced);

		if(this.cookies.get('isEnforced')){
			this.sharedService.updateEnforcePaymentDetails(true);
		}

		if (/iPad|iPhone|iPod/.test(navigator.userAgent) && isMobile()) {
			this.isIosDevice = true;
		}
		
	}

	fetchOrderDetails(orderID) {
		this.apiService.fetchOrderById(orderID).subscribe((response) => {

			if(hasMaxRetriesReached(response)) {
				this.sharedService.setHasMaxRetriesExpiredSubject(true);
			}
			
			this.order_details = JSON.parse(JSON.stringify(response));
			response['checkout_id'] = getCheckoutId(response['order_id']);
			this.orderService.updateOrderDetails(response);
			this.sharedService.updateUserCountryFromAPI(response['user']['country_code']);
		});
	}

	loadPaymentOptions() {
		const orderDetail = JSON.parse(JSON.stringify(this.orderService.getOrderDetails()));

		if (orderDetail.status === 'completed') return;

		// added below code if cc/dc fails than it directly redirects to payment page
		if (
			!this.sharedPaymentservice.getAllowedPaymentTypeArray().length &&
			!this.sharedPaymentservice.getAllowedPaymentTypeArray().length &&
			!this.sharedPaymentservice.getAllowedPaymentTypeArray().length
		) {
			if (!window.PaymentRequest || !isMobile()) {
				this.sharedPaymentservice.updateGpayVisibility(false);
			} else {
				const token = orderDetail.user['token'];
				if (token && !this.sharedService.getAuthToken()) {
					this.sharedService.updateAuthToken(token);
				}
			}

			setTimeout(() => {
				let validateToken = this.apiService.checkForValidToken();
				if (!validateToken) {
					this.apiService
						.getEncryptedAuthorizationToken(this.order_details['order_id'])
						.subscribe((resp) => {
							this.apiService.setTokenCookies(resp['token'], btoa(resp['expires_at']));
							this.commonService.setPaymentModes(false);
						});
				} else {
					this.commonService.setPaymentModes(true);
				}
			}, 800);
		}
	}
	/// display payment card
	displayPaymentCard() {
		this.isPaymentClicked = true;
		this.isPyamentInitiate = true;
		this.ispaymentFail = false;
		this.savedVpaId = '';
		this.savedVpaName = '';
		this.savedVPAClicked = false;
	}

	createNimbblRedirectUrlPopupWindow() {
		let nimbblPopupWindow = window.open(
			'',
			'Nimbbl Payment',
			'directories=0,titlebar=0,toolbar=0,location=0,status=0,menubar=0,scrollbars=no,resizable=no,width=800,height=800'
		);
		
		let divElement = nimbblPopupWindow.document.createElement('div');
		divElement.className = 'wrapper-div';
		
		let spinnerElement = nimbblPopupWindow.document.createElement('div');
		spinnerElement.className = 'spinner';
		
		let imageElement = nimbblPopupWindow.document.createElement('img');
		imageElement.className = 'nimbbl-logo';
		imageElement.src = 'https://nimbbl.biz/assets/images/logos/nimbbl_logo_black.png';
		
		let textElement = nimbblPopupWindow.document.createElement('p');
		textElement.className = 'nimbbl-msg';
		textElement.textContent = 'Do not press back or close the window.';
		
		divElement.appendChild(spinnerElement);
		divElement.appendChild(imageElement);
		divElement.appendChild(textElement);
		
		nimbblPopupWindow.document.body.appendChild(divElement);
		
		// Add CSS for the page
		let styleElement = nimbblPopupWindow.document.createElement('style');
		styleElement.innerHTML = `
		.spinner {
			width:250px;
			height:250px;
			border-radius:50%;
			background:conic-gradient(#0000 10%,#000000);
			-webkit-mask:radial-gradient(farthest-side,#0000 calc(100% - 16px),#000 0);
			animation:spinnerkeyframes 1s infinite linear;
			position: absolute;
		}
		@keyframes spinnerkeyframes {to{transform: rotate(1turn)}}
		body {
			font-family: sans-serif;
		}
		.wrapper-div {
			display: flex;
			height: 100vh;
			position: relative;
			flex-direction: column;
			align-items: center;
			justify-content: center;
		}
		.nimbbl-logo {
			height: 78px;
		}
		.nimbbl-msg {
			font-size: 18px;
			position: absolute;
			top: 540px;
		}
		`;
		nimbblPopupWindow.document.head.appendChild(styleElement);	
		
		nimbblPopupWindow && nimbblPopupWindow.focus();
		this.windowEvent = nimbblPopupWindow;
		this.sharedService.closeWindow(this.windowEvent);
	}

	checkIfWindowClosed() {
		if (this.windowEvent && this.windowEvent.closed) {
			clearInterval(this.transactionEnquiryPolling);
			clearInterval(this.checkWindowEventClosedInterval);
			this.FetchTransactionEnquiry(this.paymentMethodDetails['paymentMode'], this.transaction_id);
		}
	}

	startPaymentProcessInNewWindow(url, transaction_id) {
		this.openPopupWindow(url);
		this.transaction_id = transaction_id;
		this.FetchTransactionEnquiry(this.paymentMethodDetails['paymentMode'], this.transaction_id);
		this.transactionEnquiryPolling = setInterval(() => {
			this.FetchTransactionEnquiry(this.paymentMethodDetails['paymentMode'], this.transaction_id);
			this.checkTimeOut();
		}, 10000);
	}

	detectBrowserName() { 
    const agent = window.navigator.userAgent.toLowerCase()
    switch (true) {
      case agent.indexOf('edge') > -1:
        return 'edge';
      case agent.indexOf('opr') > -1 && !!(<any>window).opr:
        return 'opera';
      case agent.indexOf('chrome') > -1 && !!(<any>window).chrome:
        return 'chrome';
      case agent.indexOf('trident') > -1:
        return 'ie';
      case agent.indexOf('firefox') > -1:
        return 'firefox';
      case agent.indexOf('safari') > -1:
        return 'safari';
      default:
        return 'other';
    }
  }

	getDeviceInfo(){
		let device = {}
		// Get accept header
		device["accept_header"] = 'application/json, text/plain, */*';
		// Get browser information
		device["user_agent"] = navigator.userAgent;
		device["browser_language"] = navigator.language;
		// The browser supports JavaScript
		device["browser_javascript_enabled"] = typeof navigator !== 'undefined' && 'serviceWorker' in navigator ? true : false;
		device["browser_java_enabled"] = navigator.javaEnabled();

		// Get device information
		device["browser"] = this.detectBrowserName();
		// Convert browser_tz, browser_color_depth, browser_screen_height, and browser_screen_width to strings
		device["browser_tz"] = new Date().getTimezoneOffset().toString();
		device["browser_color_depth"] = window.screen.colorDepth.toString();
		device["browser_screen_height"] = window.screen.height.toString();
		device["browser_screen_width"] = window.screen.width.toString();
		
		// Convert fingerprint to string
		device["fingerprint"] = this.sharedService.getFingerPrint().toString();

		return device;
			
	}

	//initialize payment
	initPayment(event, gpayRequest = null) {
		this.selectedCardlessData = this.sharedService.getCardlessPaymentSelected();
		console.log(this.paymentMethodDetails);

		if(this.paymentMethodDetails['payment_partner'] === 'Spur' && this.paymentMethodDetails['paymentMode'] === 'Cardless EMI'){
			this.paymentMethodDetails['paymentMode'] = this.selectedCardlessData['modeName'];
		}
		
		this.sharedService.updateTransactionData([]);
		let scheme_id = null;
		let partner_name = null;
		this.transaction_details = null;
		if (this.paymentMethodDetails['is_intent']) {
			this.selectedIntentAppName = this.paymentMethodDetails['paymentMode'];
		}
		let data = {
			order_id: this.order_details['order_id'],
			order_level: 5,
			checkout_id: this.order_details['checkout_id'],
		};
		if (this.order_details && this.order_details.status !== 'completed') {
			this.apiService.updateOrderLevel(data).subscribe(
				(res) => {},
				(error) => {}
			);
		}

		this.showTryAgainButton = false;
		this.is_window_close = false;
		this.isTransactionTimeout = false;
		const modeTwo = this.paymentMethodDetails['paymentMode'].toUpperCase();
		let modeOne = '';

		this.allowedPaymentTypeArray.forEach((element) => {
			modeOne = element['payment_mode'].toUpperCase();
			const result = modeOne === modeTwo;
			if (result) {
				if (element.extra_info.hasOwnProperty('schemes')) {
					scheme_id =
						element?.extra_info?.schemes[0]?.id || element?.extra_info?.schemes[0]?.scheme_id;
				} else {
					scheme_id = null;
				}

				partner_name = element['extra_info']['partner_name'];
				this.autoFlow = element['extra_info']['auto_debit_flow_possible'];
			}
		});
		// console.log(this.paymentMethodDetails);
		if (this.merchant_details['payment_experience'] == 'redirect') {
			this.cookies.set('no_refresh', 'true');
		}
		if (
			(this.merchant_details['payment_experience'] != 'redirect' &&
				(this.paymentMethodDetails['paymentMode'] === 'Netbanking' ||
					this.paymentMethodDetails['paymentMode'] === 'Wallet' ||
					this.paymentMethodDetails['paymentMode'] == 'PayTM' ||
					this.paymentMethodDetails['paymentMode'] == 'Freecharge' ||
					this.paymentMethodDetails['paymentMode'] == 'Phonepe' ||
					(this.paymentMethodDetails['paymentMode'] == 'Simpl' && this.autoFlow == 'no'))) ||
			((this.paymentMethodDetails['paymentMode'] == 'Credit Card' ||
				this.paymentMethodDetails['paymentMode'] == 'Debit Card' ||
				this.paymentMethodDetails['paymentMode'] == 'Prepaid Card') &&
				this.merchant_details['payment_experience'] != 'redirect')
		) {

			this.createNimbblRedirectUrlPopupWindow();
		}

		this.paymentErrorMessage = null;
		this.ispaymentFail = false;
		this.requestTimeOut = 0;
		this.isTransactionTimeout = false;
		this.showTryAgainButton = false;

		/*
		 ** TODO: check if this is required
		 */
		this.apiService.fetchPaymentModesMessage().subscribe(
			(res) => {
				console.log(res, 'fetch pay modes');
			},
			(error) => {
				alert(error['message']);
			}
		);
		/* Above code to be checked if required */

		let rr = new initPaymentRequest();
		/* Adding device key to initpayment request object
		*  https://nimbbl.atlassian.net/browse/CHPY-3567
		*/
		rr['device'] = this.getDeviceInfo();
		rr.order_id = this.order_details['order_id'];
		rr.payment_mode =
			this.paymentMethodDetails['paymentMode'] == 'LazyPay'
				? 'Lazypay'
				: this.paymentMethodDetails['paymentMode'];
		rr.payment_mode_id = this.paymentMethodDetails['mode_id'];
		rr.callback_url =
			this.merchant_details['payment_experience'] == 'redirect'
				? environment.callbakURL + '/payment/response'
				: 'checkout_callback_url';
		rr.scheme_id =
			this.paymentMethodDetails['paymentMode'] == 'Dash Pay Later' ||
			this.paymentMethodDetails['paymentMode'] == 'Flash Pay Later' ||
			this.paymentMethodDetails['paymentMode'] == 'X paylater' ||
			this.paymentMethodDetails['paymentMode'] == 'Y paylater' ||
			this.paymentMethodDetails['paymentMode'] == 'SBI Pay Later' || 
			this.paymentMethodDetails['payment_partner'] == 'Spur' ||
			this.paymentMethodDetails['paymentMode'] == 'Mobikwik Zip'
				? scheme_id
				: null;
		rr.partner_name = partner_name;

		if (this.paymentMethodDetails['paymentMode'] == 'Cred') {
			rr.app_present = this.app_present;
		}
		if (
			this.paymentMethodDetails['paymentMode'] == 'Credit Card' ||
			this.paymentMethodDetails['paymentMode'] == 'Debit Card' ||
			this.paymentMethodDetails['paymentMode'] == 'Prepaid Card'
		) {
			this.sharedPaymentservice.sharedCardOffers.subscribe((res) => {
				rr.offer_id = res['offer_id'];
			});

			rr['card_details'] = this.CardDetailService.generateCardDetail();
			rr.payment_mode = this.card_type;

			this.cookies.set('no_refresh', 'true');
		} else if (this.paymentMethodDetails['paymentMode'] == 'Netbanking') {
			rr.offer_id = this.sharedBankingService.getbankDetails()['offer_id'];
		}
		if (
			this.paymentMethodDetails['paymentMode'] == 'LazyPay' ||
			this.paymentMethodDetails['paymentMode'] == 'Lazypay' ||
			this.paymentMethodDetails['paymentMode'] == 'Olamoney' ||
			this.paymentMethodDetails['paymentMode'] == 'X paylater' ||
			this.paymentMethodDetails['paymentMode'] == 'Y paylater' ||
			this.paymentMethodDetails['paymentMode'] == 'Dash Pay Later' ||
			this.paymentMethodDetails['paymentMode'] == 'Flash Pay Later' ||
			this.paymentMethodDetails['paymentMode'] == 'SBI Pay Later' ||
			this.paymentMethodDetails['payment_partner'] == 'Spur' ||
			this.paymentMethodDetails['paymentMode'] == 'Mobikwik Zip'
		) {
			if (
				this.paymentMethodDetails['paymentMode'] == 'LazyPay' ||
				this.paymentMethodDetails['paymentMode'] == 'Lazypay'
			) {
				this.paymentMethodDetails['paymentMode'] = 'Lazypay';
			}

			this.allowedPaymentTypeArray.forEach((element) => {
				if (element['payment_mode'] === this.paymentMethodDetails['paymentMode']) {
					if (element['extra_info']['auto_debit_flow_possible'] == 'no') {
						this.isAutodebitFlow = false;
					}
				}
			});
			if (!this.isAutodebitFlow) {
				this.isPyamentInitiate = false;
				this.isAutoDebit = false;
				this.isPaymentClicked = true;
				this.processing = false;
				this.orderService.updatePaymentStatus(false);
				let validateToken = this.apiService.checkForValidToken();
				if (!validateToken) {
					this.apiService
						.getEncryptedAuthorizationToken(this.order_details['order_id'])
						.subscribe((resp) => {
							this.apiService.setTokenCookies(resp['token'], btoa(resp['expires_at']));
							this.apiService.initiatePayment(rr).subscribe(
								(res) => {
									this.transaction_details = res;
									this.transaction_details['payment_partner'] =
										this.paymentMethodDetails['paymentMode'];
									this.transaction_id = res['transaction_id'];
								},
								(error) => {
									if (this.windowEvent) {
										this.windowEvent.close();
									}
									this.fetchOrderDetails(this.order_details['order_id']);
									this.isPyamentSucess = false;
									this.isPaymentClicked = false;
									this.isPyamentInitiate = false;
									this.isPyamentInitiateWithOtp = false;
									this.ispaymentFail = true;
									this.processing = false;
									this.orderService.updatePaymentStatus(false);
									this.paymentErrorMessage = error?.error?.error?.message //error['error']['error']['message']
										? error?.error?.error?.message
										: error?.error?.error?.c_message //error['error']['error']['c_message'];
									this.showTryAgainButton = error?.retry_allowed // error['retry_allowed']
										? error?.retry_allowed //error['retry_allowed']
										: error?.error?.error?.retry_allowed //error['error']['error']['retry_allowed'];
								}
							);
						});
				} else {
					this.apiService.initiatePayment(rr).subscribe(
						(res) => {
							this.sharedService.updateTransactionResponse(res);
							this.transaction_details = res;
							this.transaction_details['payment_partner'] =
								this.paymentMethodDetails['paymentMode'];
							this.transaction_id = res['transaction_id'];

							let redirectUrl = res['extra_info']?.data?.redirectUrl 
							if(res['authentication_mechanism'] === 0 && this.payment_partner === 'Spur' && redirectUrl){
								this.openInRedirectURL(redirectUrl);
								this.isPyamentInitiateWithOtp = false;
							}else {
								this.isPyamentInitiateWithOtp = true;
							}
							
						},
						(error) => {
							if (this.windowEvent) {
								this.windowEvent.close();
							}
							this.fetchOrderDetails(this.order_details['order_id']);
							this.isPyamentSucess = false;
							this.isPaymentClicked = false;
							this.isPyamentInitiate = false;
							this.isPyamentInitiateWithOtp = false;
							this.ispaymentFail = true;
							this.processing = false;
							this.orderService.updatePaymentStatus(false);
							this.paymentErrorMessage = error?.error?.error?.message //error['error']['error']['message']
								? error?.error?.error?.message
								: error?.error?.error?.c_message;
							this.showTryAgainButton = error?.retry_allowed
								? error?.retry_allowed
								: error?.error?.error?.retry_allowed;
							tracking.failPayment(
								{
									called_from: '1',
									order_details: this.order_details,
									transaction_id: this.transaction_id,
									merchant_details: this.merchant_details,
									paymentMethodDetails: this.paymentMethodDetails,
									failure_reason: this.paymentErrorMessage,
									showTryAgainButton: this.showTryAgainButton,
									card_type:
										this.paymentMethodDetails?.paymentMode == 'Credit Card' ||
										this.paymentMethodDetails?.paymentMode == 'Debit Card' ||
										this.paymentMethodDetails?.paymentMode == 'Prepaid Card'
											? this.card_type
											: null,
									card_provider:
										this.paymentMethodDetails?.paymentMode == 'Credit Card' ||
										this.paymentMethodDetails?.paymentMode == 'Debit Card' ||
										this.paymentMethodDetails?.paymentMode == 'Prepaid Card'
											? this.sharedService.getCardProvider()
											: null,
									bank_name:
										this.paymentMethodDetails?.paymentMode == 'Netbanking' ||
										this.paymentMethodDetails?.paymentMode == 'Wallet'
											? this.bank_name
											: null,
								},
								this.paymentMethodDetails?.paymentMode == 'Netbanking' ||
									this.paymentMethodDetails?.paymentMode == 'Wallet'
							);
						}
					);
				}
			} else {
				this.isPaymentClicked = true;
				this.processing = true;
				this.orderService.updatePaymentStatus(true);
				this.isPaymentClicked = true;
				this.makePayment(null, this.paymentMethodDetails['paymentMode']);
			}
		}

		//simpl pay
		else if (this.paymentMethodDetails['paymentMode'] == 'Simpl') {
			this.isPyamentSucess = false;
			this.isPaymentClicked = true;
			this.isPyamentInitiate = false;
			this.processing = true;
			this.orderService.updatePaymentStatus(true);
			if (this.autoFlow == 'yes') {
				this.makePayment(null, this.paymentMethodDetails['paymentMode']);
			} else {
				let validateToken = this.apiService.checkForValidToken();
				if (!validateToken) {
				} else {
					this.apiService.initiatePayment(rr).subscribe(
						(res) => {
							if(this.windowEvent) {
								this.checkWindowEventClosedInterval = setInterval(() => {
									this.checkIfWindowClosed();
								}, 1000);
							}
							this.sharedService.updateTransactionResponse(res);
							this.transaction_id = res['transaction_id'];
							if (this.merchant_details['payment_experience'] != 'redirect') {
								this.openPopupWindow(res['extra_info']['data']['redirectUrl']);
							} else {
								this.cookies.set('no_refresh', 'true');
								window.location.href = res['extra_info']['data']['redirectUrl'];
							}
							this.FetchTransactionEnquiry(this.paymentMethodDetails['paymentMode'], this.transaction_id);
							this.transactionEnquiryPolling = setInterval(() => {
								this.FetchTransactionEnquiry(this.paymentMethodDetails['paymentMode'], this.transaction_id);
								this.checkTimeOut();
							}, 10000);
						},
						(error) => {
							if (this.windowEvent) {
								this.windowEvent.close();
							}
							this.isPyamentSucess = false;
							this.isPaymentClicked = false;
							this.isPyamentInitiate = false;
							this.ispaymentFail = true;
							this.processing = false;
							this.orderService.updatePaymentStatus(false);
							this.paymentErrorMessage = error?.error?.error?.message
								? error?.error?.error?.message
								: error?.error?.error?.c_message;
							this.showTryAgainButton = error?.retry_allowed
								? error?.retry_allowed
								: error?.error?.error?.retry_allowed;
							tracking.failPayment(
								{
									called_from: '2',
									order_details: this.order_details,
									transaction_id: this.transaction_id,
									merchant_details: this.merchant_details,
									paymentMethodDetails: this.paymentMethodDetails,
									failure_reason: this.paymentErrorMessage,
									showTryAgainButton: this.showTryAgainButton,
									card_type:
										this.paymentMethodDetails?.paymentMode == 'Credit Card' ||
										this.paymentMethodDetails?.paymentMode == 'Debit Card' ||
										this.paymentMethodDetails?.paymentMode == 'Prepaid Card'
											? this.card_type
											: null,
									card_provider:
										this.paymentMethodDetails?.paymentMode == 'Credit Card' ||
										this.paymentMethodDetails?.paymentMode == 'Debit Card' ||
										this.paymentMethodDetails?.paymentMode == 'Prepaid Card'
											? this.sharedService.getCardProvider()
											: null,
									bank_name:
										this.paymentMethodDetails?.paymentMode == 'Netbanking' ||
										this.paymentMethodDetails?.paymentMode == 'Wallet'
											? this.bank_name
											: null,
								},
								this.paymentMethodDetails?.paymentMode == 'Netbanking' ||
									this.paymentMethodDetails?.paymentMode == 'Wallet'
							);
						}
					);
				}
			}
		}

		//icici pay later implementation
		else if (this.paymentMethodDetails['paymentMode'] == 'ICICI PayLater') {
			this.isPyamentInitiateWithOtp = true;
			this.isPyamentInitiate = false;
			this.isAutoDebit = false;
			this.isPaymentClicked = true;
			this.processing = false;
			this.orderService.updatePaymentStatus(false);
			let validateToken = this.apiService.checkForValidToken();
			if (!validateToken) {
				this.apiService
					.getEncryptedAuthorizationToken(this.order_details['order_id'])
					.subscribe((resp) => {
						this.apiService.setTokenCookies(resp['token'], btoa(resp['expires_at']));
						this.apiService.initiatePayment(rr).subscribe(
							(res) => {
								this.transaction_details = res;
								this.transaction_details['payment_partner'] =
									this.paymentMethodDetails['paymentMode'];
								this.transaction_id = res['transaction_id'];
							},
							(error) => {
								if (this.windowEvent) {
									this.windowEvent.close();
								}
								this.isPyamentSucess = false;
								this.isPaymentClicked = false;
								this.isPyamentInitiate = false;
								this.ispaymentFail = true;
								this.processing = false;
								this.isPyamentInitiateWithOtp = false;
								this.orderService.updatePaymentStatus(false);
								this.paymentErrorMessage = error?.error?.error?.message
									? error?.error?.error?.message
									: error?.error?.error?.c_message;
								this.showTryAgainButton = error?.retry_allowed
									? error?.retry_allowed
									: error?.error?.error?.retry_allowed;
								tracking.failPayment(
									{
										called_from: '3',
										order_details: this.order_details,
										transaction_id: this.transaction_id,
										merchant_details: this.merchant_details,
										paymentMethodDetails: this.paymentMethodDetails,
										failure_reason: this.paymentErrorMessage,
										showTryAgainButton: this.showTryAgainButton,
										card_type:
											this.paymentMethodDetails?.paymentMode == 'Credit Card' ||
											this.paymentMethodDetails?.paymentMode == 'Debit Card' ||
											this.paymentMethodDetails?.paymentMode == 'Prepaid Card'
												? this.card_type
												: null,
										card_provider:
											this.paymentMethodDetails?.paymentMode == 'Credit Card' ||
											this.paymentMethodDetails?.paymentMode == 'Debit Card' ||
											this.paymentMethodDetails?.paymentMode == 'Prepaid Card'
												? this.sharedService.getCardProvider()
												: null,
										bank_name:
											this.paymentMethodDetails?.paymentMode == 'Netbanking' ||
											this.paymentMethodDetails?.paymentMode == 'Wallet'
												? this.bank_name
												: null,
									},
									this.paymentMethodDetails?.paymentMode == 'Netbanking' ||
										this.paymentMethodDetails?.paymentMode == 'Wallet'
								);
							}
						);
					});
			} else {
				this.apiService.initiatePayment(rr).subscribe(
					(res) => {
						this.sharedService.updateTransactionResponse(res);
						this.transaction_details = res;
						this.transaction_details['payment_partner'] = this.paymentMethodDetails['paymentMode'];
						this.transaction_id = res['transaction_id'];
					},
					(error) => {
						if (this.windowEvent) {
							this.windowEvent.close();
						}
						this.isPyamentSucess = false;
						this.isPaymentClicked = false;
						this.isPyamentInitiate = false;
						this.ispaymentFail = true;
						this.processing = false;
						this.isPyamentInitiateWithOtp = false;
						this.orderService.updatePaymentStatus(false);
						this.paymentErrorMessage = error?.error?.error?.message
							? error?.error?.error?.message
							: error?.error?.error?.c_message;
						this.showTryAgainButton = error?.retry_allowed
							? error?.retry_allowed
							: error?.error?.error?.retry_allowed
						tracking.failPayment(
							{
								called_from: '4',
								order_details: this.order_details,
								transaction_id: this.transaction_id,
								merchant_details: this.merchant_details,
								paymentMethodDetails: this.paymentMethodDetails,
								failure_reason: this.paymentErrorMessage,
								showTryAgainButton: this.showTryAgainButton,
								card_type:
									this.paymentMethodDetails?.paymentMode == 'Credit Card' ||
									this.paymentMethodDetails?.paymentMode == 'Debit Card' ||
									this.paymentMethodDetails?.paymentMode == 'Prepaid Card'
										? this.card_type
										: null,
								card_provider:
									this.paymentMethodDetails?.paymentMode == 'Credit Card' ||
									this.paymentMethodDetails?.paymentMode == 'Debit Card' ||
									this.paymentMethodDetails?.paymentMode == 'Prepaid Card'
										? this.sharedService.getCardProvider()
										: null,
								bank_name:
									this.paymentMethodDetails?.paymentMode == 'Netbanking' ||
									this.paymentMethodDetails?.paymentMode == 'Wallet'
										? this.bank_name
										: null,
							},
							this.paymentMethodDetails?.paymentMode == 'Netbanking' ||
								this.paymentMethodDetails?.paymentMode == 'Wallet'
						);
					}
				);
			}
		} else if (this.paymentMethodDetails['paymentMode'] === 'Netbanking') {
			this.isPyamentSucess = false;
			this.isPaymentClicked = true;
			this.isPyamentInitiate = false;
			this.processing = true;
			this.orderService.updatePaymentStatus(true);
			let bankDetail = this.sharedBankingService.getbankDetails();

			rr.bank = bankDetail['bank_name'];
			this.bank_name = bankDetail['bank_name'];
			let validateToken = this.apiService.checkForValidToken();
			if (!validateToken) {
				this.apiService
					.getEncryptedAuthorizationToken(this.order_details['order_id'])
					.subscribe((resp) => {
						this.apiService.setTokenCookies(resp['token'], btoa(resp['expires_at']));
						this.apiService.initiatePayment(rr).subscribe(
							(res) => {
								if(this.windowEvent) {
									this.checkWindowEventClosedInterval = setInterval(() => {
										this.checkIfWindowClosed();
									}, 1000);
								}
								if (this.merchant_details['payment_experience'] != 'redirect') {
									this.openPopupWindow(res['extra_info']['data']['redirectUrl']);
								} else {
									this.cookies.set('no_refresh', 'true');
									window.location.href = res['extra_info']['data']['redirectUrl'];
								}
								this.transaction_id = res['transaction_id'];
								this.FetchTransactionEnquiry(this.paymentMethodDetails['paymentMode'], this.transaction_id);
								this.transactionEnquiryPolling = setInterval(() => {
									this.FetchTransactionEnquiry(
										this.paymentMethodDetails['paymentMode'],
										this.transaction_id
									);
									this.checkTimeOut();
								}, 10000);
							},
							(error) => {
								if (this.windowEvent) {
									this.windowEvent.close();
								}
								this.isPyamentSucess = false;
								this.isPaymentClicked = false;
								this.isPyamentInitiate = false;
								this.ispaymentFail = true;
								this.processing = false;
								this.orderService.updatePaymentStatus(false);
								this.paymentErrorMessage = error?.error?.error?.message
								? error?.error?.error?.message
								: error?.error?.error?.c_message;
							this.showTryAgainButton = error?.retry_allowed
								? error?.retry_allowed
								: error?.error?.error?.retry_allowed
								tracking.failPayment(
									{
										called_from: '5',
										order_details: this.order_details,
										transaction_id: this.transaction_id,
										merchant_details: this.merchant_details,
										paymentMethodDetails: this.paymentMethodDetails,
										failure_reason: this.paymentErrorMessage,
										showTryAgainButton: this.showTryAgainButton,
										card_type:
											this.paymentMethodDetails?.paymentMode == 'Credit Card' ||
											this.paymentMethodDetails?.paymentMode == 'Debit Card' ||
											this.paymentMethodDetails?.paymentMode == 'Prepaid Card'
												? this.card_type
												: null,
										card_provider:
											this.paymentMethodDetails?.paymentMode == 'Credit Card' ||
											this.paymentMethodDetails?.paymentMode == 'Debit Card' ||
											this.paymentMethodDetails?.paymentMode == 'Prepaid Card'
												? this.sharedService.getCardProvider()
												: null,
										bank_name:
											this.paymentMethodDetails?.paymentMode == 'Netbanking' ||
											this.paymentMethodDetails?.paymentMode == 'Wallet'
												? this.bank_name
												: null,
									},
									this.paymentMethodDetails?.paymentMode == 'Netbanking' ||
										this.paymentMethodDetails?.paymentMode == 'Wallet'
								);
							}
						);
					});
			} else {
				this.sharedService.updateEnforcePaymentInitiated(true);
				this.apiService.initiatePayment(rr).subscribe(
					(res) => {
						if(this.windowEvent) {
							this.checkWindowEventClosedInterval = setInterval(() => {
								this.checkIfWindowClosed();
							}, 1000);
						}
						this.sharedService.updateTransactionResponse(res);

						if (this.merchant_details['payment_experience'] != 'redirect') {
							this.openPopupWindow(res['extra_info']['data']['redirectUrl']);
						} else {
							this.cookies.set('no_refresh', 'true');
							window.location.href = res['extra_info']['data']['redirectUrl'];
						}
						this.transaction_id = res['transaction_id'];
						this.FetchTransactionEnquiry(this.paymentMethodDetails['paymentMode'], this.transaction_id);
						this.transactionEnquiryPolling = setInterval(() => {
							this.FetchTransactionEnquiry(this.paymentMethodDetails['paymentMode'], this.transaction_id);
							this.checkTimeOut();
						}, 10000);
					},
					(error) => {
						if (this.windowEvent) {
							this.windowEvent.close();
						}
						this.fetchOrderDetails(this.order_details['order_id']);
						this.isPyamentSucess = false;
						this.isPaymentClicked = false;
						this.isPyamentInitiate = false;
						this.ispaymentFail = true;
						this.processing = false;
						this.orderService.updatePaymentStatus(false);
						this.sharedService.updateEnforcePaymentInitiated(false);
						this.paymentErrorMessage = error?.error?.error?.message
						? error?.error?.error?.message
						: error?.error?.error?.c_message;
					this.showTryAgainButton = error?.retry_allowed
						? error?.retry_allowed
						: error?.error?.error?.retry_allowed
						tracking.failPayment(
							{
								called_from: '6',
								order_details: this.order_details,
								transaction_id: this.transaction_id,
								merchant_details: this.merchant_details,
								paymentMethodDetails: this.paymentMethodDetails,
								failure_reason: this.paymentErrorMessage,
								showTryAgainButton: this.showTryAgainButton,
								card_type:
									this.paymentMethodDetails['paymentMode'] == 'Credit Card' ||
									this.paymentMethodDetails['paymentMode'] == 'Debit Card' ||
									this.paymentMethodDetails['paymentMode'] == 'Prepaid Card'
										? this.card_type
										: null,
								card_provider:
									this.paymentMethodDetails['paymentMode'] == 'Credit Card' ||
									this.paymentMethodDetails['paymentMode'] == 'Debit Card' ||
									this.paymentMethodDetails['paymentMode'] == 'Prepaid Card'
										? this.sharedService.getCardProvider()
										: null,
								bank_name:
									this.paymentMethodDetails['paymentMode'] == 'Netbanking' ||
									this.paymentMethodDetails['paymentMode'] == 'Wallet'
										? this.bank_name
										: null,
							},
							this.paymentMethodDetails['paymentMode'] == 'Netbanking' ||
								this.paymentMethodDetails['paymentMode'] == 'Wallet'
						);
						setTimeout(() => {
							document.getElementById('spinnerLoaderContainerBankList')?.remove();
								document.getElementById('listOfBanksUl')
									? (document.getElementById('listOfBanksUl').style.background = 'none')
									: '';
						}, 100);
					}
				)
			}
		} else if (this.paymentMethodDetails['paymentMode'] === 'Wallet') {
			this.isPyamentSucess = false;
			this.isPaymentClicked = true;
			this.isPyamentInitiate = false;
			this.processing = true;
			this.orderService.updatePaymentStatus(true);
			let WalletDetail = this.sharedBankingService.getWallet();
			rr.bank = WalletDetail['wallet_name'];
			this.bank_name = WalletDetail['wallet_name'];
			if (!this.apiService.checkForValidToken) {
				this.apiService
					.getEncryptedAuthorizationToken(this.order_details['order_id'])
					.subscribe((resp) => {
						this.apiService.setTokenCookies(resp['token'], btoa(resp['expires_at']));

						this.apiService.initiatePayment(rr).subscribe(
							(res) => {
								if(this.windowEvent) {
									this.checkWindowEventClosedInterval = setInterval(() => {
										this.checkIfWindowClosed();
									}, 1000);
								}
								if (this.merchant_details['payment_experience'] != 'redirect') {
									this.openPopupWindow(res['extra_info']['data']['redirectUrl']);
									/* This is wallet code */
								} else {
									this.cookies.set('no_refresh', 'true');
									window.location.href = res['extra_info']['data']['redirectUrl'];
								}
								this.transaction_id = res['transaction_id'];

								this.FetchTransactionEnquiry(this.paymentMethodDetails['paymentMode'], this.transaction_id);
								this.transactionEnquiryPolling = setInterval(() => {
									this.FetchTransactionEnquiry(
										this.paymentMethodDetails['paymentMode'],
										this.transaction_id
									);
									this.checkTimeOut();
								}, 10000);
							},
							(error) => {
								if (this.windowEvent) {
									this.windowEvent.close();
								}
								this.isPyamentSucess = false;
								this.isPaymentClicked = false;
								this.isPyamentInitiate = false;
								this.ispaymentFail = true;
								this.processing = false;
								this.orderService.updatePaymentStatus(false);

								this.paymentErrorMessage = error?.error?.error?.message
								? error?.error?.error?.message
								: error?.error?.error?.c_message;
							this.showTryAgainButton = error?.retry_allowed
								? error?.retry_allowed
								: error?.error?.error?.retry_allowed
								tracking.failPayment(
									{
										called_from: '7',
										order_details: this.order_details,
										transaction_id: this.transaction_id,
										merchant_details: this.merchant_details,
										paymentMethodDetails: this.paymentMethodDetails,
										failure_reason: this.paymentErrorMessage,
										showTryAgainButton: this.showTryAgainButton,
										card_type:
											this.paymentMethodDetails?.paymentMode == 'Credit Card' ||
											this.paymentMethodDetails?.paymentMode == 'Debit Card' ||
											this.paymentMethodDetails?.paymentMode == 'Prepaid Card'
												? this.card_type
												: null,
										card_provider:
											this.paymentMethodDetails?.paymentMode == 'Credit Card' ||
											this.paymentMethodDetails?.paymentMode == 'Debit Card' ||
											this.paymentMethodDetails?.paymentMode == 'Prepaid Card'
												? this.sharedService.getCardProvider()
												: null,
										bank_name:
											this.paymentMethodDetails?.paymentMode == 'Netbanking' ||
											this.paymentMethodDetails?.paymentMode == 'Wallet'
												? this.bank_name
												: null,
									},
									this.paymentMethodDetails?.paymentMode == 'Netbanking' ||
										this.paymentMethodDetails?.paymentMode == 'Wallet'
								);
							}
						);
					});
			} else {
				this.sharedService.updateEnforcePaymentInitiated(true);
				this.apiService.initiatePayment(rr).subscribe(
					(res) => {
						if(this.windowEvent) {
							this.checkWindowEventClosedInterval = setInterval(() => {
								this.checkIfWindowClosed();
							}, 1000);
						}
						this.sharedService.updateTransactionResponse(res);
						if (this.merchant_details['payment_experience'] != 'redirect') {
							this.openPopupWindow(res['extra_info']['data']['redirectUrl']);
						} else {
							this.cookies.set('no_refresh', 'true');
							window.location.href = res['extra_info']['data']['redirectUrl'];
						}
						this.transaction_id = res['transaction_id'];
						this.transaction_id = res['transaction_id'];
						this.FetchTransactionEnquiry(this.paymentMethodDetails['paymentMode'], this.transaction_id);
						this.transactionEnquiryPolling = setInterval(() => {
							this.FetchTransactionEnquiry(this.paymentMethodDetails['paymentMode'], this.transaction_id);
							this.checkTimeOut();
						}, 10000);
					},
					(error) => {
						if (this.windowEvent) {
							this.windowEvent.close();
						}
						this.fetchOrderDetails(this.order_details['order_id']);
						this.sharedService.updateEnforcePaymentInitiated(false);
						this.isPyamentSucess = false;
						this.isPaymentClicked = false;
						this.isPyamentInitiate = false;
						this.ispaymentFail = true;
						this.processing = false;
						this.orderService.updatePaymentStatus(false);
						this.paymentErrorMessage = error?.error?.error?.message
						? error?.error?.error?.message
						: error?.error?.error?.c_message;
					this.showTryAgainButton = error?.retry_allowed
						? error?.retry_allowed
						: error?.error?.error?.retry_allowed
						tracking.failPayment(
							{
								called_from: '8',
								order_details: this.order_details,
								transaction_id: this.transaction_id,
								merchant_details: this.merchant_details,
								paymentMethodDetails: this.paymentMethodDetails,
								failure_reason: this.paymentErrorMessage,
								showTryAgainButton: this.showTryAgainButton,
								card_type:
									this.paymentMethodDetails?.paymentMode == 'Credit Card' ||
									this.paymentMethodDetails?.paymentMode == 'Debit Card' ||
									this.paymentMethodDetails?.paymentMode == 'Prepaid Card'
										? this.card_type
										: null,
								card_provider:
									this.paymentMethodDetails?.paymentMode == 'Credit Card' ||
									this.paymentMethodDetails?.paymentMode == 'Debit Card' ||
									this.paymentMethodDetails?.paymentMode == 'Prepaid Card'
										? this.sharedService.getCardProvider()
										: null,
								bank_name:
									this.paymentMethodDetails?.paymentMode == 'Netbanking' ||
									this.paymentMethodDetails?.paymentMode == 'Wallet'
										? this.bank_name
										: null,
							},
							this.paymentMethodDetails['paymentMode'] == 'Netbanking' ||
								this.paymentMethodDetails['paymentMode'] == 'Wallet'
						);

						setTimeout(() => {
							document.getElementById('spinnerLoaderContainerWallet')?.remove();
							document.getElementById('listOfWalletUl')
						? (document.getElementById('listOfWalletUl').style.background = 'none')
						: '';
						}, 100);
					}
				);
			}
		} else if (this.paymentMethodDetails['paymentMode'] === 'Cred' && !this.paymentMethodDetails['is_intent']) {
			this.isPyamentSucess = false;
			this.isPaymentClicked = true;
			this.isPyamentInitiate = false;
			this.processing = true;
			this.orderService.updatePaymentStatus(true);

			this.apiService.initiatePayment(rr).subscribe(
				(res) => {
					this.sharedService.updateTransactionResponse(res);
					this.transaction_id = res['transaction_id'];
					this.FetchTransactionEnquiry(this.paymentMethodDetails['paymentMode'], this.transaction_id);
					this.transactionEnquiryPolling = setInterval(() => {
						this.FetchTransactionEnquiry(this.paymentMethodDetails['paymentMode'], this.transaction_id);
						this.checkTimeOut();
					}, 10000);
				},
				(error) => {
					if (this.windowEvent) {
						this.windowEvent.close();
					}
					this.isPyamentSucess = false;
					this.isPaymentClicked = false;
					this.isPyamentInitiate = false;
					this.ispaymentFail = true;
					this.processing = false;
					this.orderService.updatePaymentStatus(false);
					this.paymentErrorMessage = error?.error?.error?.message
					? error?.error?.error?.message
					: error?.error?.error?.c_message;
				this.showTryAgainButton = error?.retry_allowed
					? error?.retry_allowed
					: error?.error?.error?.retry_allowed
					tracking.failPayment(
						{
							called_from: '9',
							order_details: this.order_details,
							transaction_id: this.transaction_id,
							merchant_details: this.merchant_details,
							paymentMethodDetails: this.paymentMethodDetails,
							failure_reason: this.paymentErrorMessage,
							showTryAgainButton: this.showTryAgainButton,
							card_type:
								this.paymentMethodDetails?.paymentMode == 'Credit Card' ||
								this.paymentMethodDetails?.paymentMode == 'Debit Card' ||
								this.paymentMethodDetails?.paymentMode == 'Prepaid Card'
									? this.card_type
									: null,
							card_provider:
								this.paymentMethodDetails?.paymentMode == 'Credit Card' ||
								this.paymentMethodDetails?.paymentMode == 'Debit Card' ||
								this.paymentMethodDetails?.paymentMode == 'Prepaid Card'
									? this.sharedService.getCardProvider()
									: null,
							bank_name:
								this.paymentMethodDetails?.paymentMode == 'Netbanking' ||
								this.paymentMethodDetails?.paymentMode == 'Wallet'
									? this.bank_name
									: null,
						},
						this.paymentMethodDetails?.paymentMode == 'Netbanking' ||
							this.paymentMethodDetails?.paymentMode == 'Wallet'
					);
				}
			);
		} else {
			this.isPyamentSucess = false;
			this.isPaymentClicked = true;
			this.isPyamentInitiate = false;
			this.processing = true;
			this.orderService.updatePaymentStatus(true);

			// UPI payment
			if (this.paymentMethodDetails['is_intent']) {
				this.makePayment(event, 'UPI');
			} else if (this.paymentMethodDetails['paymentMode'] === 'UPI') {
				this.makePayment(event, this.paymentMethodDetails['paymentMode']);
			} else {
				let validateToken = this.apiService.checkForValidToken();
				if (!validateToken) {
					this.apiService
						.getEncryptedAuthorizationToken(this.order_details['order_id'])
						.subscribe((resp) => {
							this.apiService.setTokenCookies(resp['token'], btoa(resp['expires_at']));

							this.apiService.initiatePayment(rr).subscribe(
								(res) => {
									this.transaction_details = res['transaction'];
									this.transaction_id = res['transaction_id'];
									this.processing = true;
									this.orderService.updatePaymentStatus(true);
									this.isPaymentClicked = true;
									this.transaction_id = res['transaction_id'];
									//gpay implementation
									if (this.paymentMethodDetails['paymentMode'] === 'Google Pay') {
										// gpay with payu
										// if (res['extra_info']['payment_partner'] == 'Payu') {

										this.amount = res['extra_info']['data']['am'];
										this.cu = res['extra_info']['data']['cu'];
										this.pa = res['extra_info']['data']['pa'];
										this.pn = res['extra_info']['data']['pn'];
										this.mc = '1234';
										this.tr = res['extra_info']['data']['tr'];
										this.tn = res['extra_info']['data']['tn'];
										this.url = 'https://uatshop.nimbbl.tech/?order_id=' + rr.order_id;

										this.FetchTransactionEnquiry(
											this.paymentMethodDetails['paymentMode'],
											this.transaction_id
										);
										this.transactionEnquiryPolling = setInterval(() => {
											this.FetchTransactionEnquiry(
												this.paymentMethodDetails['paymentMode'],
												this.transaction_id
											);
											this.checkTimeOut();
										}, 10000);
									}

									// credit Card
									if (
										this.paymentMethodDetails['paymentMode'] == 'Credit Card' ||
										this.paymentMethodDetails['paymentMode'] == 'Debit Card' ||
										this.paymentMethodDetails['paymentMode'] == 'Prepaid Card'
									) {
										this.transaction_id = res['transaction_id'];
										if (this.merchant_details['payment_experience'] != 'redirect') {
											this.openPopupWindow(res['extra_info']['data']['redirectUrl']);
										} else {
											window.location.href = res['extra_info']['data']['redirectUrl'];
										}

										this.transactionEnquiryPolling = setInterval(() => {
											this.FetchTransactionEnquiry(
												this.paymentMethodDetails['paymentMode'],
												this.transaction_id
											);
											this.checkTimeOut();
										}, 10000);
									}

									// paytm
									if (this.paymentMethodDetails['paymentMode'] == 'PayTM') {
										let browserName = 'googlechrome';
										let userAgent = window.navigator.userAgent;
										let isAndroidorIos = false;

										switch (true) {
											case this.osBrowserTest(/android/i, /Chrome/i, userAgent) &&
												!/wv/i.test(userAgent) &&
												!/SamsungBrowser/i.test(userAgent) &&
												!/MiuiBrowser/i.test(userAgent):
												isAndroidorIos = true;
												browserName = 'googlechrome';
												break;
											case this.osBrowserTest(/iPad|iPhone|iPod/i, /Safari/i, userAgent):
												isAndroidorIos = true;
												browserName = 'safari';
												break;
										}
										this.transaction_id = res['transaction_id'];
										let paytmUrl =
											res['extra_info']['data']['applink'] +
											'?mid=' +
											res['extra_info']['data']['mid'] +
											'&orderId=' +
											res['transaction_id'] +
											'&txnToken=' +
											res['extra_info']['data']['txnToken'] +
											'&amount=' +
											parseFloat(res['extra_info']['data']['amount']).toFixed(1) +
											'&sourceName' +
											browserName +
											'&sourceUrl=' +
											res['extra_info']['data']['applink'] +
											'&isType=offus';
										if (this.merchant_details['payment_experience'] != 'redirect') {
											this.openPopupWindow(res['extra_info']['data']['redirectUrl']);
										} else {
											window.location.href = res['extra_info']['data']['redirectUrl'];
										}
										this.transaction_id = res['transaction_id'];
										this.transactionEnquiryPolling = setInterval(() => {
											this.FetchTransactionEnquiry(
												this.paymentMethodDetails['paymentMode'],
												this.transaction_id
											);
											this.checkTimeOut();
										}, 10000);
									}

									// freecharge

									if (this.paymentMethodDetails['paymentMode'] == 'Freecharge' && !this.paymentMethodDetails['is_intent']) {
										let redirectURL = res['extra_info']['data']['redirectUrl'];
										this.transaction_id = res['transaction_id'];
										if (this.merchant_details['payment_experience'] != 'redirect') {
											this.openPopupWindow(res['extra_info']['data']['redirectUrl']);
										} else {
											window.location.href = res['extra_info']['data']['redirectUrl'];
										}
										this.transactionEnquiryPolling = setInterval(() => {
											this.FetchTransactionEnquiry(
												this.paymentMethodDetails['paymentMode'],
												this.transaction_id
											);
											this.checkTimeOut();
										}, 10000);
									}

									if (this.paymentMethodDetails['paymentMode'] == 'Phonepe') {
										let redirectURL = res['extra_info']['data']['redirectURL'];
										this.transaction_id = res['transaction_id'];
										if (this.merchant_details['payment_experience'] != 'redirect') {
											this.openPopupWindow(res['extra_info']['data']['redirectUrl']);
										} else {
											window.location.href = res['extra_info']['data']['redirectUrl'];
										}
										this.transactionEnquiryPolling = setInterval(() => {
											this.FetchTransactionEnquiry(
												this.paymentMethodDetails['paymentMode'],
												this.transaction_id
											);
											this.checkTimeOut();
										}, 10000);
									}
									if (this.paymentMethodDetails['paymentMode'] == 'Simpl') {
										// let redirectURL = res['extra_info']['data']['redirectURL']
										this.transaction_id = res['transaction_id'];
										if (this.merchant_details['payment_experience'] != 'redirect') {
											this.openPopupWindow(res['extra_info']['data']['redirectUrl']);
										} else {
											window.location.href = res['extra_info']['data']['redirectUrl'];
										}
										this.transactionEnquiryPolling = setInterval(() => {
											this.FetchTransactionEnquiry(
												this.paymentMethodDetails['paymentMode'],
												this.transaction_id
											);
											this.checkTimeOut();
										}, 10000);
									}
								},
								(error) => {
									if (this.windowEvent) {
										this.windowEvent.close();
									}
									this.isPyamentSucess = false;
									this.isPaymentClicked = false;
									this.isPyamentInitiate = false;
									this.ispaymentFail = true;
									this.processing = false;
									this.orderService.updatePaymentStatus(false);
									this.paymentErrorMessage = error?.error?.error?.message
									? error?.error?.error?.message
									: error?.error?.error?.c_message;
								this.showTryAgainButton = error?.retry_allowed
									? error?.retry_allowed
									: error?.error?.error?.retry_allowed
									tracking.failPayment(
										{
											called_from: '10',
											order_details: this.order_details,
											transaction_id: this.transaction_id,
											merchant_details: this.merchant_details,
											paymentMethodDetails: this.paymentMethodDetails,
											failure_reason: this.paymentErrorMessage,
											showTryAgainButton: this.showTryAgainButton,
											card_type:
												this.paymentMethodDetails?.paymentMode == 'Credit Card' ||
												this.paymentMethodDetails?.paymentMode == 'Debit Card' ||
												this.paymentMethodDetails?.paymentMode == 'Prepaid Card'
													? this.card_type
													: null,
											card_provider:
												this.paymentMethodDetails?.paymentMode == 'Credit Card' ||
												this.paymentMethodDetails?.paymentMode == 'Debit Card' ||
												this.paymentMethodDetails?.paymentMode == 'Prepaid Card'
													? this.sharedService.getCardProvider()
													: null,
											bank_name:
												this.paymentMethodDetails?.paymentMode == 'Netbanking' ||
												this.paymentMethodDetails?.paymentMode == 'Wallet'
													? this.bank_name
													: null,
										},
										this.paymentMethodDetails?.paymentMode == 'Netbanking' ||
											this.paymentMethodDetails?.paymentMode == 'Wallet'
									);
								}
							);
						});
				} else {
					this.apiService.initiatePayment(rr).subscribe(
						(res) => {
							this.sharedService.updateTransactionResponse(res);
							this.transaction_details = res['transaction'];
							this.transaction_id = res['transaction_id'];
							this.processing = true;
							this.orderService.updatePaymentStatus(true);
							this.isPaymentClicked = true;
							this.transaction_id = res['transaction_id'];
							//gpay implementation
							if (this.paymentMethodDetails['paymentMode'] === 'Google Pay') {
								// gpay with payu
								// if (res['extra_info']['payment_partner'] == 'Payu') {

								this.amount = res['extra_info']['data']['am'];
								this.cu = res['extra_info']['data']['cu'];
								this.pa = res['extra_info']['data']['pa'];
								this.pn = res['extra_info']['data']['pn'];
								this.mc = '1234';
								this.tr = res['extra_info']['data']['tr'];
								this.tn = res['extra_info']['data']['tn'];
								this.url = 'https://uatshop.nimbbl.tech/?order_id=' + rr.order_id;

								this.FetchTransactionEnquiry(this.paymentMethodDetails['paymentMode'], this.transaction_id);
								this.transactionEnquiryPolling = setInterval(() => {
									this.FetchTransactionEnquiry(
										this.paymentMethodDetails['paymentMode'],
										this.transaction_id
									);
									this.checkTimeOut();
								}, 10000);
							}

							// credit Card

							if (
								this.paymentMethodDetails['paymentMode'] == 'Credit Card' ||
								this.paymentMethodDetails['paymentMode'] == 'Debit Card' ||
								this.paymentMethodDetails['paymentMode'] == 'Prepaid Card'
							) {
								this.transaction_id = res['transaction_id'];
								if (this.merchant_details['payment_experience'] != 'redirect') {
									this.openPopupWindow(res['extra_info']['data']['redirectUrl']);
								} else {
									window.location.href = res['extra_info']['data']['redirectUrl'];
								}

								this.transactionEnquiryPolling = setInterval(() => {
									this.FetchTransactionEnquiry(
										this.paymentMethodDetails['paymentMode'],
										this.transaction_id
									);
									this.checkTimeOut();
								}, 10000);
							}
							// paytm
							if (this.paymentMethodDetails['paymentMode'] == 'PayTM') {
								let browserName = 'googlechrome';
								let userAgent = window.navigator.userAgent;
								let isAndroidorIos = false;

								switch (true) {
									case this.osBrowserTest(/android/i, /Chrome/i, userAgent) &&
										!/wv/i.test(userAgent) &&
										!/SamsungBrowser/i.test(userAgent) &&
										!/MiuiBrowser/i.test(userAgent):
										isAndroidorIos = true;
										browserName = 'googlechrome';
										break;
									case this.osBrowserTest(/iPad|iPhone|iPod/i, /Safari/i, userAgent):
										isAndroidorIos = true;
										browserName = 'safari';
										break;
								}
								this.transaction_id = res['transaction_id'];
								if (this.merchant_details['payment_experience'] != 'redirect') {
									this.openPopupWindow(res['extra_info']['data']['redirectUrl']);
								} else {
									window.location.href = res['extra_info']['data']['redirectUrl'];
								}
								this.transaction_id = res['transaction_id'];
								this.transactionEnquiryPolling = setInterval(() => {
									this.FetchTransactionEnquiry(
										this.paymentMethodDetails['paymentMode'],
										this.transaction_id
									);
									this.checkTimeOut();
								}, 10000);
							}
							// freecharge

							if (this.paymentMethodDetails['paymentMode'] == 'Freecharge' && !this.paymentMethodDetails['is_intent']) {
								let redirectURL = res['extra_info']['data']['redirectUrl'];
								this.transaction_id = res['transaction_id'];
								if (this.merchant_details['payment_experience'] != 'redirect') {
									this.openPopupWindow(res['extra_info']['data']['redirectUrl']);
								} else {
									window.location.href = res['extra_info']['data']['redirectUrl'];
								}
								this.transactionEnquiryPolling = setInterval(() => {
									this.FetchTransactionEnquiry(
										this.paymentMethodDetails['paymentMode'],
										this.transaction_id
									);
									this.checkTimeOut();
								}, 10000);
							}
							if (this.paymentMethodDetails['paymentMode'] == 'Phonepe') {
								// let redirectURL = res['extra_info']['data']['redirectURL']
								this.transaction_id = res['transaction_id'];
								if (this.merchant_details['payment_experience'] != 'redirect') {
									this.openPopupWindow(res['extra_info']['data']['redirectUrl']);
								} else {
									window.location.href = res['extra_info']['data']['redirectUrl'];
								}
								this.transactionEnquiryPolling = setInterval(() => {
									this.FetchTransactionEnquiry(
										this.paymentMethodDetails['paymentMode'],
										this.transaction_id
									);
									this.checkTimeOut();
								}, 10000);
							}
							if (this.paymentMethodDetails['paymentMode'] == 'Simpl') {
								// let redirectURL = res['extra_info']['data']['redirectURL']
								this.transaction_id = res['transaction_id'];
								if (this.merchant_details['payment_experience'] != 'redirect') {
									this.openPopupWindow(res['extra_info']['data']['redirectUrl']);
								} else {
									window.location.href = res['extra_info']['data']['redirectUrl'];
								}
								this.transactionEnquiryPolling = setInterval(() => {
									this.FetchTransactionEnquiry(
										this.paymentMethodDetails['paymentMode'],
										this.transaction_id
									);
									this.checkTimeOut();
								}, 10000);
							}
						},
						(error) => {
							if (this.windowEvent) {
								this.windowEvent.close();
							}
							this.fetchOrderDetails(this.order_details['order_id']);
							this.isPyamentSucess = false;
							this.isPaymentClicked = false;
							this.isPyamentInitiate = false;
							this.ispaymentFail = true;
							this.processing = false;
							this.orderService.updatePaymentStatus(false);
							this.sharedService.updateEnforcePaymentInitiated(false);
							this.paymentErrorMessage = error?.error?.error?.message
							? error?.error?.error?.message
							: error?.error?.error?.c_message;
						this.showTryAgainButton = error?.retry_allowed
							? error?.retry_allowed
							: error?.error?.error?.retry_allowed
							tracking.failPayment(
								{
									called_from: '11',
									order_details: this.order_details,
									transaction_id: this.transaction_id,
									merchant_details: this.merchant_details,
									paymentMethodDetails: this.paymentMethodDetails,
									failure_reason: this.paymentErrorMessage,
									showTryAgainButton: this.showTryAgainButton,
									card_type:
										this.paymentMethodDetails?.paymentMode == 'Credit Card' ||
										this.paymentMethodDetails?.paymentMode == 'Debit Card' ||
										this.paymentMethodDetails?.paymentMode == 'Prepaid Card'
											? this.card_type
											: null,
									card_provider:
										this.paymentMethodDetails?.paymentMode == 'Credit Card' ||
										this.paymentMethodDetails?.paymentMode == 'Debit Card' ||
										this.paymentMethodDetails?.paymentMode == 'Prepaid Card'
											? this.sharedService.getCardProvider()
											: null,
									bank_name:
										this.paymentMethodDetails?.paymentMode == 'Netbanking' ||
										this.paymentMethodDetails?.paymentMode == 'Wallet'
											? this.bank_name
											: null,
								},
								this.paymentMethodDetails?.paymentMode == 'Netbanking' ||
									this.paymentMethodDetails?.paymentMode == 'Wallet'
							);
						}
					);
				}
			}
		}
	}

	openInRedirectURL(url) {
		// console.log('This is ',url, this.transaction_id)
		this.createNimbblRedirectUrlPopupWindow();
		this.isPyamentSucess = false;
		this.isPaymentClicked = true;
		this.isPyamentInitiate = false;
		this.isPyamentInitiateWithOtp = false;
		this.processing = true;
		this.orderService.updatePaymentStatus(true);
		this.startPaymentProcessInNewWindow(url, this.transaction_id);
	}

	// make payment from paymnet partner
	makePayment(otp, paymentMode = this.paymentMethodDetails['paymentMode']) {
		this.activateRoute.queryParams.subscribe((params) => {
			this.paymentMethodDetails['payment_flow'] = params.payment_flow;
			this.paymentMethodDetails['upi_app_code'] = params.upi_app_code;

			if(this.paymentMethodDetails['payment_flow'] === 'intent' || otp.is_intent){
				this.paymentMethodDetails['is_intent'] = 'intent';
				if(hasTouchSupport()){
					this.processing = true;
				}
			}
		});
		this.sharedService.updateTransactionData([]);
		// console.log(this.paymentMethodDetails);
		this.paymentErrorMessage = null;
		this.requestTimeOut = 0;
		this.completionEta = null;
		this.showTryAgainButton = false;

		// payment method event tracking added
		tracking.submitPayment(
			{
				order_details: this.order_details,
				transaction_id: this.transaction_id,
				merchant_details: this.merchant_details,
				selected_payment_mode: this.paymentMethodDetails,
				card_type:
					this.paymentMethodDetails?.paymentMode == 'Credit Card' ||
					this.paymentMethodDetails?.paymentMode == 'Debit Card' ||
					this.paymentMethodDetails?.paymentMode == 'Prepaid Card'
						? this.card_type
						: null,
				card_provider:
					this.paymentMethodDetails?.paymentMode == 'Credit Card' ||
					this.paymentMethodDetails?.paymentMode == 'Debit Card' ||
					this.paymentMethodDetails?.paymentMode == 'Prepaid Card'
						? this.sharedService.getCardProvider()
						: null,
				bank_name:
					this.paymentMethodDetails?.paymentMode == 'Netbanking' ||
					this.paymentMethodDetails?.paymentMode == 'Wallet'
						? this.bank_name
						: null,
			},
			true
		);

		let rr = new makePaymentRequest();
		if (this.transaction_details) {
			if (this.transaction_details['payment_partner'] === 'Lazypay') {
				this.transaction_details['payment_partner'] =
					this.transaction_details['payment_partner'].toLowerCase();
				this.transaction_details['payment_partner'] =
					this.transaction_details['payment_partner'].charAt(0).toUpperCase() +
					this.transaction_details['payment_partner'].slice(1);
			}
			rr.order_id = this.transaction_details['order_id']
				? this.transaction_details['order_id']
				: this.order_details['order_id'];
			rr.payment_mode = this.transaction_details['payment_partner'];
			rr.payment_type = this.isAutoDebit ? 'auto_debit' : 'otp';
			rr.transaction_id = this.transaction_details['transaction_id'];
			rr.otp = this.isAutoDebit ? null : otp;
			rr.flow = this.paymentMethodDetails['is_intent'] ? 'intent' : rr.flow;
		} else {
			if (paymentMode === 'Lazypay') {
				this.isPyamentInitiate = false;
				paymentMode = paymentMode.toLowerCase();
				paymentMode = paymentMode.charAt(0).toUpperCase() + paymentMode.slice(1);
			}
			rr.order_id = this.order_details['order_id'];
			rr.payment_mode = paymentMode;
			rr.payment_type = 'auto_debit';
			rr.flow = this.paymentMethodDetails['is_intent'] ? 'intent' : rr.flow;
		}
		if (paymentMode === 'UPI') {
			if (this.paymentMethodDetails['is_intent']) {
				rr.payment_mode = paymentMode;
			}
			rr.payment_type = 'app-redirect';
			rr.upi_id = otp?.is_intent ? '' :otp;
			this.UPIId = otp?.is_intent ? '' : otp;
		}

		let validateToken = this.apiService.checkForValidToken();
		if (!validateToken) {
			this.apiService
				.getEncryptedAuthorizationToken(this.order_details['order_id'])
				.subscribe((resp) => {
					this.apiService.setTokenCookies(resp['token'], btoa(resp['expires_at']));
					this.apiService.makePaymentAPI(rr).subscribe(
						(res) => {
							this.transaction_id = res['transaction_id'];
							this.is_window_close = false;
							this.isPyamentInitiateWithOtp = false;
							this.completionEta = res['completion_time'];
							if (paymentMode == 'UPI' && res['status'] == 'pending') {
								this.transaction_id = res['transaction_id'];
								this.FetchTransactionEnquiry(
									this.paymentMethodDetails['is_intent']
										? paymentMode
										: this.paymentMethodDetails['paymentMode'],
									this.transaction_id
								);
								this.transactionEnquiryPolling = setInterval(() => {
									this.FetchTransactionEnquiry(
										this.paymentMethodDetails['is_intent']
											? paymentMode
											: this.paymentMethodDetails['paymentMode'],
										this.transaction_id
									);
									this.checkTimeOut();
								}, 10000);
								this.isPyamentSucess = false;
								this.isPaymentClicked = true;
								this.isPyamentInitiate = false;
								this.isPyamentInitiateWithOtp = false;
							}

							if (paymentMode == 'Lazypay' ||  this.paymentMethodDetails['payment_partner'] === 'Spur') {
								this.transaction_id = res['transaction_id'];
								this.transaction_id = res['transaction_id'];

								this.FetchTransactionEnquiry(this.paymentMethodDetails['paymentMode'], this.transaction_id);
								this.transactionEnquiryPolling = setInterval(() => {
									this.transaction_id = res['transaction_id'];
									this.FetchTransactionEnquiry(
										this.paymentMethodDetails['paymentMode'],
										this.transaction_id
									);
								}, 10000);
							}
							// simple
							if (paymentMode == 'Simpl') {
								this.transaction_id = res['transaction_id'];
								this.transaction_id = res['transaction_id'];
								this.FetchTransactionEnquiry(this.paymentMethodDetails['paymentMode'], this.transaction_id);
								this.transactionEnquiryPolling = setInterval(() => {
									this.FetchTransactionEnquiry(
										this.paymentMethodDetails['paymentMode'],
										this.transaction_id
									);
								}, 10000);
							}

							//ICICI
							if (paymentMode == 'ICICI PayLater') {
								this.transaction_id = res['transaction_id'];

								this.FetchTransactionEnquiry(this.paymentMethodDetails['paymentMode'], this.transaction_id);
								this.transactionEnquiryPolling = setInterval(() => {
									this.FetchTransactionEnquiry(
										this.paymentMethodDetails['paymentMode'],
										this.transaction_id
									);
								}, 10000);
							}

							if (res['status'] == 'pending') {
								this.isPyamentSucess = false;
								this.isPaymentClicked = true;
								this.ispaymentFail = false;
								this.isPyamentInitiate = false;
								this.processing = true;
								this.orderService.updatePaymentStatus(true);
								this.isPyamentInitiateWithOtp = false;
							} else if (res['status'] == 'success' || res['status'] == 'SUCCESS') {
								this.isPyamentSucess = false;
								this.isPaymentClicked = true;
								this.ispaymentFail = false;
								this.isPyamentInitiate = false;
								this.processing = true;
								this.transaction_id = res['transaction_id'];
								this.orderService.updatePaymentStatus(true);
								this.isPyamentInitiateWithOtp = false;
								this.FetchTransactionEnquiry(this.paymentMethodDetails['paymentMode'], this.transaction_id);
								this.transactionEnquiryPolling = setInterval(() => {
									this.FetchTransactionEnquiry(
										this.paymentMethodDetails['paymentMode'],
										this.transaction_id
									);
									this.checkTimeOut();
								}, 10000);
							}
						},
						(error) => {
							// console.log(error);
							this.isPyamentSucess = false;
							this.isPaymentClicked = false;
							this.isPyamentInitiate = false;
							this.ispaymentFail = true;
							this.processing = false;
							this.orderService.updatePaymentStatus(false);
							this.isPyamentInitiateWithOtp = false;
							this.paymentErrorMessage = error?.error?.error?.message
							? error?.error?.error?.message
							: error?.error?.error?.c_message;
						this.showTryAgainButton = error?.retry_allowed
							? error?.retry_allowed
							: error?.error?.error?.retry_allowed

							tracking.failPayment(
								{
									called_from: '12',
									order_details: this.order_details,
									transaction_id: this.transaction_id,
									merchant_details: this.merchant_details,
									paymentMethodDetails: this.paymentMethodDetails,
									failure_reason: this.paymentErrorMessage,
									showTryAgainButton: this.showTryAgainButton,
									card_type:
										this.paymentMethodDetails?.paymentMode == 'Credit Card' ||
										this.paymentMethodDetails?.paymentMode == 'Debit Card' ||
										this.paymentMethodDetails?.paymentMode == 'Prepaid Card'
											? this.card_type
											: null,
									card_provider:
										this.paymentMethodDetails?.paymentMode == 'Credit Card' ||
										this.paymentMethodDetails?.paymentMode == 'Debit Card' ||
										this.paymentMethodDetails?.paymentMode == 'Prepaid Card'
											? this.sharedService.getCardProvider()
											: null,
									bank_name:
										this.paymentMethodDetails?.paymentMode == 'Netbanking' ||
										this.paymentMethodDetails?.paymentMode == 'Wallet'
											? this.bank_name
											: null,
								},
								this.paymentMethodDetails?.paymentMode == 'Netbanking' ||
									this.paymentMethodDetails?.paymentMode == 'Wallet'
							);
						}
					);
				});
		} else {
			this.isPyamentSucess = false;
			this.isPaymentClicked = true;
			this.ispaymentFail = false;
			this.isPyamentInitiate = false;
			// this.processing = true;
			this.orderService.updatePaymentStatus(true);
			this.isPyamentInitiateWithOtp = false;
			this.apiService.makePaymentAPI(rr).subscribe(
				(res) => {

					let transactionDataPayload = new paymentStatusPollingRequest();
						transactionDataPayload.order_id = this.order_details['order_id'];
						transactionDataPayload.payment_mode =
							this.paymentMethodDetails['paymentMode'] == 'Credit Card' ||
							this.paymentMethodDetails['paymentMode'] == 'Debit Card' ||
							this.paymentMethodDetails['paymentMode'] == 'Prepaid Card'
								? this.card_type
								: this.paymentMethodDetails['paymentMode'];
						if (this.paymentMethodDetails && this.paymentMethodDetails['is_intent']) {
							transactionDataPayload.payment_mode = 'UPI';
						}
						transactionDataPayload.transaction_id = res['transaction_id'];
					
					this.sharedService.updateTransactionData(transactionDataPayload);
					this.is_window_close = false;
					this.isPyamentInitiateWithOtp = false;
					this.completionEta = res['completion_time'];

					if (this.paymentMethodDetails['is_intent']) {
						this.intentUrl = res['extra_info']['data']['redirectUrl'];
						let newIntentUrl = this.intentUrl;
						this.transaction_id = res['transaction_id'];
						if(this.order_details['callback_mode'] !== 'callback_mobile') {
							if (isMobile()) {
								// isMobile
								this.isIntentAndMobile = true;
								this.isIntentAndDesktop = false;
								this.processing = true;
								this.iframeSrc = this.intentUrl;
								
								if (!this.isReactNative) {
									this.openIntentUrlInBlankTab(newIntentUrl);
								}
								
							} else {
								// isDesktop
								this.orderService.updatePaymentStatus(true);
								this.isIntentAndDesktop = true;
								this.iframeSrc = this.intentUrl;
								// this.isIntentAndMobile = false;
								this.processing = false;
								// return
							}
						}
						
						let paymentModenme = this.selectedIntentAppName;
						// console.log('payment Mode Name', paymentModenme);
						let sdkData = {
							url: this.intentUrl,
						};
						this.intentpaymentModes.forEach((element) => {
							if (element['name']?.toLowerCase() == (paymentModenme?.toLowerCase() || this.paymentMethodDetails['upi_app_code']?.toLowerCase())) {
								this.isReactNative ? 
									sdkData['package_name'] = element['package_name'] :
									sdkData['packagename'] = element['packagename'];
							}
						});

						/* This is for React Native SDK. */
						if (this.isReactNative && (window as any).ReactNativeWebView) {
							window.ReactNativeWebView.postMessage(JSON.stringify(sdkData));
						}
						
						if ((window as any).NimbblSDK) {
							(window as any).NimbblSDK.openUpiIntent(JSON.stringify(sdkData));
						}

						//  ios intent flow
						if ((window as any).webkit) {
							(window as any).webkit.messageHandlers.upiListener.postMessage(
								JSON.stringify(sdkData)
							);
						}

						console.log('SDK DATA: ',sdkData);
						
					}
					if (paymentMode == 'UPI' && res['status'] == 'pending') {
						this.processing = true;
						this.transaction_id = res['transaction_id'];
						this.FetchTransactionEnquiry(
							this.paymentMethodDetails['is_intent']
								? paymentMode
								: this.paymentMethodDetails['paymentMode'],
							this.transaction_id
						);
						this.transactionEnquiryPolling = setInterval(() => {
							this.FetchTransactionEnquiry(
								this.paymentMethodDetails['is_intent']
									? paymentMode
									: this.paymentMethodDetails['paymentMode'],
								this.transaction_id
							);
							this.checkTimeOut();
						}, 10000);
						this.isPyamentSucess = false;
						this.isPaymentClicked = true;
						this.isPyamentInitiate = false;
						this.isPyamentInitiateWithOtp = false;
					}
					if (paymentMode == 'Lazypay' || this.paymentMethodDetails['payment_partner'] === 'Spur') {
						this.processing = true;
						this.transaction_id = res['transaction_id'];
						this.FetchTransactionEnquiry(this.paymentMethodDetails['paymentMode'], this.transaction_id);
						this.transactionEnquiryPolling = setInterval(() => {
							this.FetchTransactionEnquiry(this.paymentMethodDetails['paymentMode'], this.transaction_id);
						}, 10000);
					}

					//simpl
					if (paymentMode == 'Simpl') {
						this.processing = true;
						this.transaction_id = res['transaction_id'];
						this.FetchTransactionEnquiry(this.paymentMethodDetails['paymentMode'], this.transaction_id);
						this.transactionEnquiryPolling = setInterval(() => {
							this.FetchTransactionEnquiry(this.paymentMethodDetails['paymentMode'], this.transaction_id);
						}, 10000);
					}

					if (paymentMode == 'ICICI PayLater') {
						this.processing = true;
						this.transaction_id = res['transaction_id'];
						this.FetchTransactionEnquiry(this.paymentMethodDetails['paymentMode'], this.transaction_id);
						this.transactionEnquiryPolling = setInterval(() => {
							this.FetchTransactionEnquiry(this.paymentMethodDetails['paymentMode'], this.transaction_id);
						}, 10000);
					}
					console.log('Make Payment Response', res)
					if (res['status'] == 'pending') {
						this.isPyamentSucess = false;
						this.isPaymentClicked = true;
						this.ispaymentFail = false;
						this.isPyamentInitiate = false;
						this.processing = true;
						this.orderService.updatePaymentStatus(true);
						this.isPyamentInitiateWithOtp = false;
					} else if (
						res['status'] == 'success' ||
						(res['status'] == 'SUCCESS' && (paymentMode != 'Lazypay' || this.paymentMethodDetails['payment_partner'] !== 'Spur'))
					) {

						this.isPyamentSucess = false;
						this.isPaymentClicked = true;
						this.ispaymentFail = false;
						this.isPyamentInitiate = false;
						this.processing = true;
						this.orderService.updatePaymentStatus(true);
						this.isPyamentInitiateWithOtp = false;
						this.transaction_id = res['transaction_id'];
						this.FetchTransactionEnquiry(this.paymentMethodDetails['paymentMode'], this.transaction_id);
						
						this.transactionEnquiryPolling = setInterval(() => {
							this.FetchTransactionEnquiry(this.paymentMethodDetails['paymentMode'], this.transaction_id);
							this.checkTimeOut();
						}, 10000);
					}
				},
				(error) => {
					// console.log(error);
					this.isPyamentSucess = false;
					this.isPaymentClicked = false;
					this.isPyamentInitiate = false;
					this.ispaymentFail = true;
					this.processing = false;
					this.orderService.updatePaymentStatus(false);
					this.isPyamentInitiateWithOtp = false;
					this.paymentErrorMessage = error?.error?.error?.message
					? error?.error?.error?.message
					: error?.error?.error?.c_message;
				this.showTryAgainButton = error?.retry_allowed
					? error?.retry_allowed
					: error?.error?.error?.retry_allowed

					tracking.failPayment(
						{
							called_from: '13',
							order_details: this.order_details,
							transaction_id: this.transaction_id,
							merchant_details: this.merchant_details,
							paymentMethodDetails: this.paymentMethodDetails,
							failure_reason: this.paymentErrorMessage,
							showTryAgainButton: this.showTryAgainButton,
							card_type:
								this.paymentMethodDetails?.paymentMode == 'Credit Card' ||
								this.paymentMethodDetails?.paymentMode == 'Debit Card' ||
								this.paymentMethodDetails?.paymentMode == 'Prepaid Card'
									? this.card_type
									: null,
							card_provider:
								this.paymentMethodDetails?.paymentMode == 'Credit Card' ||
								this.paymentMethodDetails?.paymentMode == 'Debit Card' ||
								this.paymentMethodDetails?.paymentMode == 'Debit Card'
									? this.sharedService.getCardProvider()
									: null,
							bank_name:
								this.paymentMethodDetails?.paymentMode == 'Netbanking' ||
								this.paymentMethodDetails?.paymentMode == 'Wallet'
									? this.bank_name
									: null,
						},
						this.paymentMethodDetails?.paymentMode == 'Netbanking' ||
							this.paymentMethodDetails?.paymentMode == 'Wallet'
					);
				}
			);
		}
	}
	openIntentUrlInBlankTab(baseURL) {
		let intentPopupWindow = window.open(
			baseURL,
			'Nimbbl Payment Intent Flow',
			'directories=0,titlebar=0,toolbar=0,location=1,status=0,menubar=0,scrollbars=no,resizable=no,width=10,height=10'
		);

		if (intentPopupWindow) {
			intentPopupWindow.focus();

			this.windowEvent = intentPopupWindow;

			setTimeout(() => {
				if (this.windowEvent) {
					this.windowEvent.close();
				}
			}, 500);
		} else {
			// Handle the case where the pop-up was blocked
			console.error('Pop-up blocked. Please allow pop-ups for this site.');
		}
	}
	cancelPayment() {
		this.fetchOrderDetails(this.order_details['order_id']);
		this.transaction_details = null;
		this.isPaymentClicked = false;
		this.isPyamentSucess = false;
		this.isPyamentInitiate = false;
		this.processing = false;
		this.orderService.updatePaymentStatus(false);
		this.isPyamentInitiateWithOtp = false;
	}

	FetchTransactionEnquiry(payment_mode, transaction_id) {
		let rr = new paymentStatusPollingRequest();
		this.activateRoute.queryParams.subscribe(params => {
			if(params['response']) {
				let paramsJson = JSON.parse(atob(params['response']));
				rr.order_id = paramsJson.nimbbl_order_id;
			} else {
				rr.order_id = this.order_details['order_id']
			}
		});
		rr.payment_mode =
			payment_mode == 'Credit Card' ||
			payment_mode == 'Debit Card' ||
			payment_mode == 'Prepaid Card'
				? this.card_type
				: payment_mode;
		if (this.paymentMethodDetails && this.paymentMethodDetails['is_intent']) {
			rr.payment_mode = 'UPI';
		}
		rr.transaction_id = transaction_id;
		if (!this.sharedService.getTransactionData()['transaction_id']) {
			this.sharedService.updateTransactionData(rr);
		}
		this.apiService.paymentStatusConfirmation(rr).subscribe(
			(res) => {
				if (res['status'] == 'pending' && this.is_window_close) {
					if (this.paymentMethodDetails['paymentMode'] == 'Netbanking') {
						this.bank_name = this.sharedBankingService.getbankDetails()['bank_name'];
					}
					if (this.paymentMethodDetails['paymentMode'] == 'Wallet') {
						this.bank_name = this.sharedBankingService.getWallet()['wallet_name'];
					}
					this.isPyamentSucess = false;
					this.isPaymentClicked = true;
					this.isPyamentInitiate = false;
					this.ispaymentFail = false;
					this.processing = false;
					this.orderService.updatePaymentStatus(false);
					this.isTransactionTimeout = true;
					this.showTryAgainButton = false;
					tracking.failPayment(
						{
							called_from: '14',
							order_details: this.order_details,
							transaction_id: this.transaction_id,
							merchant_details: this.merchant_details,
							paymentMethodDetails: this.paymentMethodDetails,
							failure_reason: this.paymentErrorMessage,
							showTryAgainButton: this.showTryAgainButton,
							card_type:
								this.paymentMethodDetails?.paymentMode == 'Credit Card' ||
								this.paymentMethodDetails?.paymentMode == 'Debit Card' ||
								this.paymentMethodDetails?.paymentMode == 'Prepaid Card'
									? this.card_type
									: null,
							card_provider:
								this.paymentMethodDetails?.paymentMode == 'Credit Card' ||
								this.paymentMethodDetails?.paymentMode == 'Debit Card' ||
								this.paymentMethodDetails?.paymentMode == 'Prepaid Card'
									? this.sharedService.getCardProvider()
									: null,
							bank_name:
								this.paymentMethodDetails?.paymentMode == 'Netbanking' ||
								this.paymentMethodDetails?.paymentMode == 'Wallet'
									? this.bank_name
									: null,
						},
						true
					);
					// this.fetchOrderDetails(this.order_details['order_id']);
				}
				if (res['status'] == 'success') {
					this.cookies.set('no_refresh', 'true');

					this.orderService.updatePaymentResponse(res);
					this.orderService.updatePaymentCompleted(true);

					this.order_details = JSON.parse(
						JSON.stringify(this.sharedOrderDetails.getOrderDetails())
					);
					this.bank_name = '';

					if (this.paymentMethodDetails['paymentMode'] == 'Netbanking') {
						this.bank_name = this.sharedBankingService.getbankDetails()['bank_name'];
					}
					if (this.paymentMethodDetails['paymentMode'] == 'Wallet') {
						this.bank_name = this.sharedBankingService.getWallet()['wallet_name'];
					}
					this.isPyamentSucess = true;
					this.isPaymentClicked = true;
					this.isPyamentInitiate = false;
					this.ispaymentFail = false;
					this.processing = false;
					this.orderService.updatePaymentStatus(false);
					this.nimbbl_signature = res['nimbbl_signature'];
					this.completionEta = res['completion_time'];
					clearInterval(this.transactionEnquiryPolling);

					tracking.successfulPayment({
						order_details: this.order_details,
						transaction_id: this.transaction_id,
						merchant_details: this.merchant_details,
						paymentMethodDetails: this.paymentMethodDetails,
						card_type:
							this.paymentMethodDetails?.paymentMode == 'Credit Card' ||
							this.paymentMethodDetails?.paymentMode == 'Debit Card' ||
							this.paymentMethodDetails?.paymentMode == 'Prepaid Card'
								? this.card_type
								: null,
						card_provider:
							this.paymentMethodDetails?.paymentMode == 'Credit Card' ||
							this.paymentMethodDetails?.paymentMode == 'Debit Card' ||
							this.paymentMethodDetails?.paymentMode == 'Prepaid Card'
								? this.sharedService.getCardProvider()
								: null,
						bank_name:
							this.paymentMethodDetails?.paymentMode == 'Netbanking' ||
							this.paymentMethodDetails?.paymentMode == 'Wallet'
								? this.bank_name
								: null,
						time_taken:	this.completionEta ? this.completionEta : 'N/A'
					});

					tracking.merchantResponse({
						order_details: this.order_details,
						res: res,
					});

					tracking.checkoutClosed(
						{
							order_details: this.order_details,
						},
						true
					);

					let data = {
						order_id:
							this.order_details && this.order_details['order_id']
								? this.order_details['order_id']
								: null,
						order_level: 6,
						closed_reason: 'payment success',
						checkout_id: this.order_details['checkout_id'],
					};
					this.apiService.updateOrderLevel(data).subscribe(
						(res) => {},
						(error) => {}
					);
					if (this.windowEvent) {
						this.windowEvent.close();
					}
					this.paymentErrorMessage = res['message'];

					if (res['status'] === 'success') {
						this.sharedService.updateEnforcePaymentDetails(true);
						this.sharedService.updateEnforcePaymentSuccess(true);
					}

					this.commonService.getCallBackPayloadResponse(this.order_details, transaction_id);

				} else if (res['status'] == 'failed') {
					this.fetchOrderDetails(this.order_details['order_id']);
					this.bank_name = '';
					if (this.paymentMethodDetails['paymentMode'] == 'Netbanking') {
						this.bank_name = this.sharedBankingService.getbankDetails()['bank_name'];
						// console.log(this.bank_name);
					}
					if (this.paymentMethodDetails['paymentMode'] == 'Wallet') {
						this.bank_name = this.sharedBankingService.getWallet()['wallet_name'];
						// console.log(this.bank_name);
					}
					this.isPyamentSucess = false;
					this.isPaymentClicked = false;
					this.isPyamentInitiate = false;
					this.ispaymentFail = true;
					this.processing = false;
					this.orderService.updatePaymentStatus(false);
					this.showTryAgainButton = res['retry_allowed'];
					if (this.windowEvent) {
						this.windowEvent.close();
					}
					clearInterval(this.transactionEnquiryPolling);
					this.paymentErrorMessage = res['message'];
					// console.log(this.paymentMethodDetails);
					tracking.failPayment(
						{
							called_from: '16',
							order_details: this.order_details,
							transaction_id: this.transaction_id,
							merchant_details: this.merchant_details,
							paymentMethodDetails: this.paymentMethodDetails,
							failure_reason: this.paymentErrorMessage,
							showTryAgainButton: this.showTryAgainButton,
							card_type:
								this.paymentMethodDetails?.paymentMode == 'Credit Card' ||
								this.paymentMethodDetails?.paymentMode == 'Debit Card' ||
								this.paymentMethodDetails?.paymentMode == 'Prepaid Card'
									? this.card_type
									: null,
							card_provider:
								this.paymentMethodDetails?.paymentMode == 'Credit Card' ||
								this.paymentMethodDetails?.paymentMode == 'Debit Card' ||
								this.paymentMethodDetails?.paymentMode == 'Prepaid Card'
									? this.sharedService.getCardProvider()
									: null,
							bank_name:
								this.paymentMethodDetails?.paymentMode == 'Netbanking' ||
								this.paymentMethodDetails?.paymentMode == 'Wallet'
									? this.bank_name
									: null,
						},
						true
					);
				}
			}
		);
	}

	// googlepay can make payment
	readSupportedInstruments() {
		let formValue = {};
		formValue['pa'] = this.pa;
		formValue['pn'] = this.pn == '' ? 'logicloop' : this.pn;
		formValue['tn'] = this.tn;
		formValue['mc'] = this.mc;
		formValue['tr'] = this.tr;
		formValue['url'] = this.url;
		return formValue;
	}
	onBuyClicked(fn = null) {
		if (!window.PaymentRequest) {
			return;
		}

		let formValue = this.readSupportedInstruments();
		this.supportedInstruments = [
			{
				supportedMethods: ['https://tez.google.com/pay'],
				data: formValue,
			},
		];
		const details = {
			total: {
				label: 'Total',
				amount: {
					currency: this.cu,
					value: this.amount,
				},
			},
			displayItems: [
				{
					label: 'Original amount',
					amount: {
						currency: this.cu,
						value: this.amount,
					},
				},
			],
		};

		let request = null;
		try {
			if (!fn) {
				window.newrelic.addPageAction('Payu Google pay processing', {
					order_id: this.order_details['order_id'],
					payload: {
						supportedInstruments: this.supportedInstruments,
						detail: details,
					},
					payment_mode: 'Google Pay',
				});
			}

			request = new PaymentRequest(this.supportedInstruments, details);
		} catch (e) {
			if (!fn) {
				window.newrelic.addPageAction('Payu Google pay error', {
					order_id: this.order_details['order_id'],
					payload: {
						supportedInstruments: this.supportedInstruments,
						detail: details,
					},
					error: e,
					payment_mode: 'Google Pay',
				});
			}
			return;
		}
		if (!request) {
			return;
		}

		var canMakePaymentPromise = this.checkCanMakePayment(request);
		canMakePaymentPromise
			.then((result) => {
				if (fn) {
					fn(request, result);
				} else {
					this.prcessGpayPaymentWithPayu(request);
				}
			})
			.catch((err) => {});
	}
	checkCanMakePayment(request) {
		if (sessionStorage.hasOwnProperty(this.canMakePaymentCache)) {
			return Promise.resolve(JSON.parse(sessionStorage[this.canMakePaymentCache]));
		}

		// If canMakePayment() isn't available, default to assuming that the method is
		// supported.
		var canMakePaymentPromise = Promise.resolve(true);

		// Feature detect canMakePayment().
		if (request.canMakePayment) {
			canMakePaymentPromise = request.canMakePayment();
		}

		return canMakePaymentPromise
			.then((result) => {
				// Store the result in cache for future usage.
				sessionStorage[this.canMakePaymentCache] = result;
				return result;
			})
			.catch((err) => {});
	}
	prcessGpayPaymentWithPayu(request) {
		let paymentTimeout = window.setTimeout(function () {
			window.clearTimeout(paymentTimeout);
			request
				.abort()
				.then(function () {})
				.catch(function () {});
		}, 20 * 60 * 10000); /* 20 minutes */

		request
			.show()
			.then((instrument) => {
				window.clearTimeout(paymentTimeout);
				this.processResponse(instrument); // Handle response from browser.
			})
			.catch(function (err) {});
	}
	processResponse(instrument) {
		var instrumentString = JSON.stringify(instrument);

		fetch('/buy', {
			method: 'POST',
			headers: new Headers({ 'Content-Type': 'application/json' }),
			body: instrumentString,
		})
			.then((buyResult) => {
				if (buyResult.ok) {
					alert(buyResult);
					return buyResult.json();
				}
			})
			.then((buyResultJson) => {
				this.completePayment(instrument, buyResultJson.status, buyResultJson.message);
			})
			.catch((err) => {
				alert(err);
			});
	}
	completePayment(instrument, result, msg) {
		instrument
			.complete(result)
			.then(() => {
				window.newrelic.addPageAction('Payu Google pay response', {
					order_id: this.order_details['order_id'],
					response: {
						response: result,
						message: msg,
						instrument: instrument,
					},
					payment_mode: 'Google Pay',
				});
			})
			.catch((err) => {
				window.newrelic.addPageAction('Payu Google pay response error', {
					order_id: this.order_details['order_id'],
					error: err,
					payment_mode: 'Google Pay',
				});
			});
	}

	// gpay with razor pay
	makeGpayPaymentWithRazorPay() {
		this.razorpay.once('ready', function (response) {});
		window.newrelic.addPageAction('Razor Pay js call', {
			function_name: 'createPayment',
			payload: this.razorpayPayload,
			gpay: true,
			payment_mode: 'Google Pay',
			order_id: this.order_details['order_id'],
		});

		this.razorpay.createPayment(this.razorpayPayload, { gpay: true });
		this.razorpay.on('payment.success', (resp) => {
			window.newrelic.addPageAction('Razor Pay js response', {
				response: resp,
				payment_mode: 'Google Pay',
				order_id: this.order_details['order_id'],
			});

			let transaction_data = {
				razorpay_payment_id: resp['razorpay_payment_id'],
			};
			let validateToken = this.apiService.checkForValidToken();
			if (!validateToken) {
				this.apiService
					.getEncryptedAuthorizationToken(this.order_details['order_id'])
					.subscribe((resp) => {
						this.apiService.setTokenCookies(resp['token'], btoa(resp['expires_at']));
						this.apiService.transactionUpdate(transaction_data, this.transaction_id).subscribe(
							(res) => {},
							(error) => {}
						);
					});
			} else {
				this.apiService.transactionUpdate(transaction_data, this.transaction_id).subscribe(
					(res) => {},
					(error) => {}
				);
			}
		}); // will pass payment ID, order ID, and Razorpay signature to success handler.
	}
	osBrowserTest(regExpOS, regExpBrowser, userAgent) {
		return regExpOS.test(userAgent) && regExpBrowser.test(userAgent);
	}

	openPopupWindow(url = null) {
		if (this.paymentMethodDetails['paymentMode'] == 'Netbanking') {
			this.bank_name = this.sharedBankingService.getbankDetails()['bank_name'];
		}
		if (this.paymentMethodDetails['paymentMode'] == 'Wallet') {
			this.bank_name = this.sharedBankingService.getWallet()['wallet_name'];
		}
		
		if (url != null) {
			this.windowEvent.location.href = url;

			tracking.paymentIntermediateOpened(
				{
					order_details: this.order_details,
					transaction_id: this.transaction_id,
					merchant_details: this.merchant_details,
					selected_payment_mode: this.paymentMethodDetails,
					card_type:
						this.paymentMethodDetails?.paymentMode == 'Credit Card' ||
						this.paymentMethodDetails?.paymentMode == 'Debit Card' ||
						this.paymentMethodDetails?.paymentMode == 'Prepaid Card'
							? this.card_type
							: null,
					card_provider:
						this.paymentMethodDetails?.paymentMode == 'Credit Card' ||
						this.paymentMethodDetails?.paymentMode == 'Debit Card' ||
						this.paymentMethodDetails?.paymentMode == 'Prepaid Card'
							? this.sharedService.getCardProvider()
							: null,
					bank_name:
						this.paymentMethodDetails?.paymentMode == 'Netbanking' ||
						this.paymentMethodDetails?.paymentMode == 'Wallet'
							? this.bank_name
							: null,
				},
				true
			);
		}
		
		this.paymentErrorMessage = null;
		this.is_window_close = false;

		let popupTick = setInterval(() => {
			if (this.windowEvent.closed) {
				clearInterval(popupTick);
				clearInterval(this.transactionEnquiryPolling);
				this.windowCloseEvent();
				this.sharedService.closeWindow(this.windowEvent);
			}
		}, 2000);
	}

	resendLazypayOtp() {
		let rr = new lazypayResednOtpRequest();
		rr.payment_mode = this.paymentMethodDetails.paymentMode;
		rr.order_id = this.order_details['order_id'];
		rr.transaction_id = this.transaction_id;
		let validateToken = this.apiService.checkForValidToken();
		if (!validateToken) {
			this.apiService
				.getEncryptedAuthorizationToken(this.order_details['order_id'])
				.subscribe((resp) => {
					this.apiService.setTokenCookies(resp['token'], btoa(resp['expires_at']));

					this.apiService.resendLazyPayOtp(rr).subscribe(
						(res) => {},
						(error) => {}
					);
				});
		} else {
			this.apiService.resendLazyPayOtp(rr).subscribe(
				(res) => {},
				(error) => {}
			);
		}
	}
	windowCloseEvent() {
		let paymentTimeOut = 0;

		if (!this.paymentErrorMessage && this.requestTimeOut <= this.paymentTimeOut) {
			this.transactionEnquiryPolling = setInterval(() => {
				if (paymentTimeOut < 5000) {
					this.FetchTransactionEnquiry(
						this.paymentMethodDetails['is_intent']
							? 'UPI'
							: this.paymentMethodDetails['paymentMode'],
						this.transaction_id
					);
					paymentTimeOut = paymentTimeOut + 1000;
				} else {
					clearInterval(this.transactionEnquiryPolling);
					this.is_window_close = true;
					this.FetchTransactionEnquiry(
						this.paymentMethodDetails['is_intent']
							? 'UPI'
							: this.paymentMethodDetails['paymentMode'],
						this.transaction_id
					);
				}
			}, 1000);
		}
		tracking.paymentIntermediateClosed(
			{
				order_details: this.order_details,
				transaction_id: this.transaction_id,
				merchant_details: this.merchant_details,
				selected_payment_mode: this.paymentMethodDetails,
				card_type:
					this.paymentMethodDetails?.paymentMode == 'Credit Card' ||
					this.paymentMethodDetails?.paymentMode == 'Debit Card' ||
					this.paymentMethodDetails?.paymentMode == 'Prepaid Card'
						? this.card_type
						: null,
				card_provider:
					this.paymentMethodDetails?.paymentMode == 'Credit Card' ||
					this.paymentMethodDetails?.paymentMode == 'Debit Card' ||
					this.paymentMethodDetails?.paymentMode == 'Prepaid Card'
						? this.sharedService.getCardProvider()
						: null,
				bank_name:
					this.paymentMethodDetails?.paymentMode == 'Netbanking' ||
					this.paymentMethodDetails?.paymentMode == 'Wallet'
						? this.bank_name
						: null,
			},
			true
		);
	}
	showPaymentError() {
		if (this.merchant_details['delayed_authorisation_allowed'] == true) {
			this.checkstatusOftransaction().then((res) => {
				this.commonService.getCallBackPayloadResponse(this.order_details, this.transaction_id);
			});
		} else {
			this.isPyamentSucess = false;
			this.isPaymentClicked = false;
			this.isPyamentInitiate = false;
			this.ispaymentFail = true;
			this.processing = false;
			this.orderService.updatePaymentStatus(false);
			this.isTransactionTimeout = false;
			this.fetchOrderDetails(this.order_details['order_id']);
		}
	}

	displatTImeOutMessage() {
		this.isPyamentSucess = false;
		this.isPaymentClicked = false;
		this.isPyamentInitiate = false;
		this.ispaymentFail = true;
		this.processing = false;
		this.orderService.updatePaymentStatus(false);
		this.isTransactionTimeout = false;
		this.showTryAgainButton = true;
		this.paymentErrorMessage = 'Since your previous payment timed out, please try another payment.';
		this.fetchOrderDetails(this.order_details['order_id']);
	}
	savedVPAclicked(vpaDetail) {
		this.savedVpaId = vpaDetail.VpaId;
		this.savedVpaName = vpaDetail.vpaName;
		this.savedVPAClicked = true;
		this.isPaymentClicked = true;
		this.isPyamentInitiate = true;
		this.ispaymentFail = false;
	}
	setFocus() {
		if (this.windowEvent) {
			this.windowEvent.focus();
		}
	}
	censorWord(str) {
		let chars = str.split('');
		for (let index = 6; index < str.length - 4; index++) {
			chars[index] = 'x';
		}
		chars = chars?.join('');
		return chars;
	}
	checkTimeOut() {
		if (this.requestTimeOut >= this.paymentTimeOut) {
			this.isPyamentSucess = false;
			this.isPaymentClicked = true;
			this.isPyamentInitiate = false;
			this.ispaymentFail = false;
			this.processing = false;
			this.orderService.updatePaymentStatus(true);
			this.isTransactionTimeout = true;
			this.showTryAgainButton = false;
			this.paymentErrorMessage =
				'Since your previous payment timed out, please try another payment.';
			clearInterval(this.transactionEnquiryPolling);
			if (this.windowEvent) {
				this.windowEvent.close();
			}

			if (this.paymentMethodDetails['paymentMode'] == 'Netbanking') {
				this.bank_name = this.sharedBankingService.getbankDetails()['bank_name'];
			}
			if (this.paymentMethodDetails['paymentMode'] == 'Wallet') {
				this.bank_name = this.sharedBankingService.getWallet()['wallet_name'];
			}

			tracking.timeOutPayment({
				order_details: this.order_details,
				transaction_id: this.transaction_id,
				merchant_details: this.merchant_details,
				selected_payment_mode: this.paymentMethodDetails,
				card_type:
					this.paymentMethodDetails?.paymentMode == 'Credit Card' ||
					this.paymentMethodDetails?.paymentMode == 'Debit Card' ||
					this.paymentMethodDetails?.paymentMode == 'Prepaid Card'
						? this.card_type
						: null,
				card_provider:
					this.paymentMethodDetails?.paymentMode == 'Credit Card' ||
					this.paymentMethodDetails?.paymentMode == 'Debit Card' ||
					this.paymentMethodDetails?.paymentMode == 'prepaid Card'
						? this.sharedService.getCardProvider()
						: null,
				bank_name:
					this.paymentMethodDetails?.paymentMode == 'Netbanking' ||
					this.paymentMethodDetails?.paymentMode == 'Wallet'
						? this.bank_name
						: null,
				time_taken: this.completionEta
			});

			if (this.windowEvent) {
				this.windowEvent.close();
			}
		} else {
			this.requestTimeOut = this.requestTimeOut + 10000;
		}
	}
	credClicked(event) {
		this.app_present = event;
		this.displayPaymentCard();
	}

	showPaymentUI(request, canMakePayment) {
		// Redirect to play store if can't make payment.
		if (!canMakePayment) {
			this.showGpayButton = false;
			this.sharedPaymentservice.updateGpayVisibility(this.showGpayButton);
		} else {
			this.showGpayButton = true;
			this.sharedPaymentservice.updateGpayVisibility(this.showGpayButton);
		}

		tracking.receiveCanMakePayment({
			order_details: this.order_details,
			merchant_details: this.merchant_details,
			status: this.showGpayButton,
		});
	}

	opencashfreeCCDCUi(res) {
		let card = this.CardDetailService.getCardDetails();
		let card_number = card['card_number'].split(' ').join('');
		let card_expiry_year =
			card['card_expiry'].split('/')[1].trim().length < 4
				? '20' + card['card_expiry'].split('/')[1].trim()
				: card['card_expiry'].split('/')[1].trim();

		var site = '<html>';
		site += '<script>';
		site += 'setTimeout(()=>{';
		site += 'document.getElementById("redirectForm").submit();';
		site += '},1000)';
		site += '</script>';
		site += '<body>';
		site += '<!-- Register Content -->';
		site += "<div class='pulldown'>";
		site += "<div class='content content-boxed overflow-hidden'>";
		site += "<div class='row'>";
		site +=
			'<div class="bg-white col-sm-8 col-sm-offset-2 col-md-6 col-md-offset-3 col-lg-4 col-lg-offset-4">';
		site += '<!-- Block Tabs Justified Alternative Style -->';
		site +=
			'<form id = "redirectForm" method = "post" action = ' +
			res['extra_info']['data']['action_url'] +
			' ">';
		site +=
			'<input type="hidden" name="appId" value ="' + res['extra_info']['data']['appId'] + '" />';
		site += '<input type="hidden" name="orderId" value =' + res['transaction_id'] + ' />';
		site +=
			'<input type="hidden" name="orderAmount" value =' +
			res['extra_info']['data']['orderAmount'] +
			' />';
		site +=
			'<input type="hidden" name="orderCurrency" value =' +
			res['extra_info']['data']['orderCurrency'] +
			' />';
		site +=
			'<input type="hidden" name="orderNote" value =' +
			res['extra_info']['data']['orderNote'] +
			' />';
		site +=
			'<input type="hidden" name="customerName" value =' +
			res['extra_info']['data']['customerName'] +
			' />';
		site +=
			'<input type="hidden" name="customerEmail" value =' +
			res['extra_info']['data']['customerEmail'] +
			' />';
		site +=
			'<input type="hidden" name="customerPhone" value =' +
			res['extra_info']['data']['customerPhone'] +
			' />';
		site +=
			'<input type="hidden" name="returnUrl" value =' +
			res['extra_info']['data']['returnUrl'] +
			' />';
		site +=
			'<input type="input" name="signature" value ="' +
			res['extra_info']['data']['signature'] +
			'" />';
		site +=
			"<input type='hidden' name='paymentOption' value ='" +
			res['extra_info']['data']['paymentOption'] +
			"' />";
		site += '<input type="hidden" name="card_number" value ="' + card_number + '" />';
		site += '<input type="hidden" name="card_holder" value ="' + card['card_holder_name'] + '" />';
		site +=
			'<input type="hidden" name="card_expiryMonth" value ="' +
			card['card_expiry'].split('/')[0].trim() +
			'" />';
		site += '<input type="hidden" name="card_expiryYear" value ="' + card_expiry_year + '" />';
		site += '<input type="hidden" name="card_cvv" value ="' + card['cvv'] + '" />';
		site += '</form>';
		site += '<!--END Block Tabs Justified Default Style-- >';
		site += '</div>';
		site += '< /div>';
		site += '< /div>';
		site += '< /div>';
		site += '<!--END Register Content-- >';

		site += '</body>';

		site += '</html>';

		// console.log(site);
		window.newrelic.addPageAction('Cashfree Card', {
			order_id: this.order_details['order_id'],
			submited_HTML: site,
			payment_mode: this.card_type,
		});
		tracking.submitPayment({
			order_details: this.order_details,
			transaction_id: this.transaction_id,
			merchant_details: this.merchant_details,
			selected_payment_mode: this.paymentMethodDetails,
			card_type:
				this.paymentMethodDetails?.paymentMode == 'Credit Card' ||
				this.paymentMethodDetails?.paymentMode == 'Debit Card' ||
				this.paymentMethodDetails?.paymentMode == 'prepaid Card'
					? this.card_type
					: null,
			card_provider:
				this.paymentMethodDetails?.paymentMode == 'Credit Card' ||
				this.paymentMethodDetails?.paymentMode == 'Debit Card' ||
				this.paymentMethodDetails?.paymentMode == 'prepaid Card'
					? this.sharedService.getCardProvider()
					: null,
			bank_name:
				this.paymentMethodDetails?.paymentMode == 'Netbanking' ||
				this.paymentMethodDetails?.paymentMode == 'Wallet'
					? this.bank_name
					: null,
		});
		tracking.paymentIntermediateOpened(
			{
				order_details: this.order_details,
				transaction_id: this.transaction_id,
				merchant_details: this.merchant_details,
				selected_payment_mode: this.paymentMethodDetails,
				card_type:
					this.paymentMethodDetails?.paymentMode == 'Credit Card' ||
					this.paymentMethodDetails?.paymentMode == 'Debit Card' ||
					this.paymentMethodDetails?.paymentMode == 'prepaid Card'
						? this.card_type
						: null,
				card_provider:
					this.paymentMethodDetails?.paymentMode == 'Credit Card' ||
					this.paymentMethodDetails?.paymentMode == 'Debit Card' ||
					this.paymentMethodDetails?.paymentMode == 'prepaid Card'
						? this.sharedService.getCardProvider()
						: null,
				bank_name:
					this.paymentMethodDetails?.paymentMode == 'Netbanking' ||
					this.paymentMethodDetails?.paymentMode == 'Wallet'
						? this.bank_name
						: null,
			},
			true
		);
		this.windowEvent.document.write(site);

		let popupTick = setInterval(() => {
			if (this.windowEvent.closed) {
				clearInterval(popupTick);
				clearInterval(this.transactionEnquiryPolling);
				this.windowCloseEvent();
			}
		}, 2000);
	}

	// sdk error handling
	sdkErrorHandlingCallback(param) {
		this.ngzone.run(() => this.sdkStatushandling(param));
	}
	sdkStatushandling(status) {
		
		const sdkStatusTrackingData = {
			sdk_status: status || 'No Status Received',
			order_details: this.order_details,
			transaction_id: this.transaction_id,
			merchant_details: this.merchant_details,
			selected_payment_mode: this.paymentMethodDetails
		};
		tracking.sdkStatusTracking(sdkStatusTrackingData);
		clearInterval(this.transactionEnquiryPolling);
		this.transaction_id && this.FetchTransactionEnquiry(this.paymentMethodDetails['paymentMode'], this.transaction_id);
		
		if (status && status == 'exception') {
			this.isPyamentSucess = false;
			this.isPaymentClicked = false;
			this.isPyamentInitiate = false;
			this.ispaymentFail = true;
			this.processing = false;
			this.orderService.updatePaymentStatus(false);
			this.isPyamentInitiateWithOtp = false;
			this.paymentErrorMessage =
				'Sorry, your payment could not be processed. Please try again with another payment option.';
			this.showTryAgainButton = false;
			let transaction_update_data = {
				order_id: this.order_details['order_id'],
				user_id: this.order_details['user_id'],
				nimbbl_error_code: 'PAYMENT_SDK_ERROR',
				nimbbl_consumer_message:
					'Sorry, your payment could not be processed. Please try again with another payment option.',
				nimbbl_merchant_message: 'There was a problem with the payment.',
				status: 'failed',
			};
			this.apiService.transactionUpdate(transaction_update_data, this.transaction_id).subscribe(
				(resp) => {
					console.log(resp);
				},
				(error) => {
					console.log(error);
				}
			);
		}
	}

	updateTransactionStatus(transactiondata, cancelationReason) {
		
		this.apiService.transactionUpdate(transactiondata, this.transaction_id).subscribe(
			(resp) => {
				this.paymentMethodDetails.payment_partner = resp['payment_partner'];
				console.log(resp);
				if(resp['status'] === 'failed'){
					this.sharedService.updateEnforcePaymentSuccess(false);
					this.sharedService.updateEnforcePaymentInitiated(false);
					tracking.failPayment(
						{
							called_from: '17',
							order_details: this.order_details,
							transaction_id: this.transaction_id,
							merchant_details: this.merchant_details,
							paymentMethodDetails: this.paymentMethodDetails,
							failure_reason: cancelationReason || 'Others',
							nimbbl_error_code: 'CUSTOMER_PAYMENT_CANCELLED',
							showTryAgainButton: this.showTryAgainButton,
							card_type:
								this.paymentMethodDetails?.paymentMode == 'Credit Card' ||
								this.paymentMethodDetails?.paymentMode == 'Debit Card' ||
								this.paymentMethodDetails?.paymentMode == 'Prepaid Card'
									? this.card_type
									: null,
							card_provider:
								this.paymentMethodDetails?.paymentMode == 'Credit Card' ||
								this.paymentMethodDetails?.paymentMode == 'Debit Card' ||
								this.paymentMethodDetails?.paymentMode == 'Prepaid Card'
									? this.sharedService.getCardProvider()
									: null,
							bank_name:
								this.paymentMethodDetails?.paymentMode == 'Netbanking' ||
								this.paymentMethodDetails?.paymentMode == 'Wallet'
									? this.bank_name
									: null,
						},
						true
					);
					this.fetchOrderDetails(this.order_details['order_id']);
				}else if(resp['status'] === 'success'){
					this.sharedService.updateEnforcePaymentSuccess(true);
					this.sharedService.updateEnforcePaymentInitiated(true);
				}
			},
			(error) => {
				console.log(error);
			}
		);
	}
	cancelTransaction(cancelationReason) {
		let transaction_update_data = {
			order_id: this.order_details['order_id'],
			user_id: this.order_details['user_id'] || this.order_details['orignal_user_id'],
			nimbbl_error_code: 'CUSTOMER_PAYMENT_CANCELLED',
			nimbbl_consumer_message: cancelationReason || 'Others',
			nimbbl_merchant_message: 'Customer has cancelled the payment.',
		};
		
		this.updateTransactionStatus(transaction_update_data, cancelationReason);
		clearInterval(this.transactionEnquiryPolling);
		this.isPyamentSucess = false;
		this.isPaymentClicked = false;
		this.isPyamentInitiate = false;
		this.ispaymentFail = true;
		this.processing = false;
		this.isIntentAndDesktop = false;
		this.paymentErrorMessage = 'Payment Cancelled, Please try again from another payment option';
		this.orderService.updatePaymentStatus(false);
		this.isPyamentInitiateWithOtp = false;
		this.fetchOrderDetails(this.order_details['order_id']);
	}

	checkstatusOftransaction() {
		let transactionData = this.sharedService.getTransactionData();
		return new Promise((resolve, reject) => {
			if (transactionData['transaction_id']) {
				this.apiService.paymentStatusConfirmation(transactionData).subscribe(
					(responseData) => {
						if (responseData) {
							resolve(responseData['status']);
						}
					},
					(error) => {
						resolve('failed');
					}
				);
			} else {
				resolve('failed');
			}
		});
	}
}
