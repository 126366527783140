<div class="countryCodeLsit" *ngIf="dispplayCountryCodeList">
    <div class="searchBr">
        <p class="sectionTitle">Select a Country</p>
        <input type="text" (input)="filterList($event)" placeholder="Search ..." class="searchBox" />
    </div>
    <ul class="listsOfCountries">
        <li *ngFor=" let country of filteredList  | slice:0:3;let i=index " (click)="selectedCountry(i)">
            <span class="country_flag"><img src="assets/images/countryFlags/{{country.flag.replaceAll(' ','_')}}" class="img-fluid"
                    [alt]="country.flag" /></span> <span class="country_name">{{country.countryName}}</span> <span
                class="dailer_code">{{country.dailerCode}}</span>
        </li>
        <hr class="seprator">
        <li *ngFor=" let country of filteredList  | slice:3:filteredList.length;let i=index "
            (click)="selectedCountry(i+3)">
            <span class="country_flag"><img src="assets/images/countryFlags/{{country.flag.replaceAll(' ','_')}}" class="img-fluid"
                    [alt]="country.flag" /></span> <span class="country_name">{{country.countryName}}</span> <span
                class="dailer_code">{{country.dailerCode}}</span>
        </li>
    </ul>
</div>